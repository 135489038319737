<template>
    <header class="main-header">
        <a href="#" class="logo">
            <span class="logo-mini"><b>N</b></span>
            <span class="logo-lg"> <img src="@/assets/svg/logo-nuanu-cms.svg" /> </span>
        </a>
        <nav class="navbar navbar-static-top">
            <a href="#" class="sidebar-toggle" data-toggle="push-menu" role="button">
                <span class="sr-only">Toggle navigation</span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
            </a>

            <!-- <div class="collapse navbar-collapse pull-left collapse-project-selected">
                <ul class="nav navbar-nav pull-left">
                    <li>
                        <a href="#" id="session_project"></a>
                    </li>
                </ul>
            </div> -->

            <div class="navbar-custom-menu">
                <ul class="nav navbar-nav">
                    <li class="dropdown user user-menu" id="user-menu-navbar">
                        <a href="#" class="dropdown-toggle" data-toggle="dropdown">
                            <img src="@assets/unknown.jpeg" class="user-image" alt="User Image">
                            <!-- <img :src="user_image" class="user-image" alt="User Image" v-if="user_image"> -->
                            <span class="hidden-xs text-lowercase">{{ user.full_name }}</span>
                        </a>
                        <ul class="dropdown-menu">
                            <li class="user-header">
                                <img src="@assets/unknown.jpeg" class="img-circle" alt="User Image">
                                <!-- <img :src="user_image" class="img-circle" alt="User Image" v-if="user_image"> -->
                                <p class="ellipsis">
                                    {{ user.full_name }}
                                    <!--                                    <small class="ellipsis">Member since Nov. 2012</small>-->
                                </p>
                            </li>
                            <!-- <li class="user-body">
                                <div class="row">
                                    <div class="col-xs-4 text-center">
                                        <a href="#">Followers</a>
                                    </div>
                                    <div class="col-xs-4 text-center">
                                        <a href="#">Sales</a>
                                    </div>
                                    <div class="col-xs-4 text-center">
                                        <a href="#">Friends</a>
                                    </div>
                                </div>
                            </li> -->
                            <li class="user-footer">
                                <div class="pull-left">
                                  <!-- <router-link  class="btn btn-default btn-flat" :to="{ name: 'profile', params: {} }">Profile Saya</router-link> -->
                                 </div>
                                <div class="pull-right">
                                    <a href="#" class="btn btn-default btn-flat" @click.prevent="logout">Logout</a>
                                </div>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </nav>
    </header>
</template>

<style>
    .modal-backdrop {
        display:none;
    }
</style>

<script>
    const {console} = window
    export default {
        data() {
            return {
                user: this.$root.$store.state.user || null,
                user_image : null,
                read_by: null,
                me: {},
                role: {}
            }
        },
        async mounted() {

            $("#idfade1", this.$el).modal('hide');
            try {
                this.$root.$on('navbar_full_name', async(data) => {
                    var name = data.name || ""
                    var full_name = name.split(" ")[0]
                    if(full_name.length > 15) {
                        full_name = full_name.substring(0, 14)+"..."
                    }
                    this.user.username = data.name
                    this.user.full_name = full_name
                })
            } catch {

            } finally {

            }

            if(!this.user) {
                let userMe = localStorage.getItem('_USER_ME')
                if(userMe) {
                    let userMeBytes = this.CryptoJS.AES.decrypt(userMe, process.env.VUE_APP_KEY_ENCRYPT_DECRYPT)
                    let userMeData = JSON.parse(userMeBytes.toString(this.CryptoJS.enc.Utf8))
                    if(userMeData) {
                        this.user.id = userMeData.id 
                        this.user.full_name = userMeData.full_name 
                        this.user.username = userMeData.username
                        this.user.avatar = userMeData.avatar
                    }else {
                        await this.getUserMe()
                    }
                }else {
                    await this.getUserMe()
                }
            }


            let temp = localStorage.getItem('_USER_ROLES')
                if(temp) {
                let bytes = this.CryptoJS.AES.decrypt(temp, process.env.VUE_APP_KEY_ENCRYPT_DECRYPT)
                let data = JSON.parse(bytes.toString(this.CryptoJS.enc.Utf8))
                if(data ) {
                    this.read_by = data.user_id
                    this.role.id = data.role_id
                    this.role.name = data.role_name
                }
            }

            let self = this
            $(document).ready(function() {
                $('body').on('click', '#user-menu-navbar', function(e) {
                    e.preventDefault()
                    if (self.isDoubleClicked($(this))) return;
                    let className = $('.user-menu').attr('class')
                    let findStr = 'open'
                    let isFind = className.includes(findStr)
                    if (isFind) {
                        $('#user-menu-navbar').removeClass('open')
                    }else {
                        $('#user-menu-navbar').addClass('open')
                    }

                })
            })
        },
        computed: {
        },
        methods: {
            async logout() {
                let name = 'user'
                if (this.$root.$store.state.user && this.$root.$store.state.user.full_name) {
                    name = this.$root.$store.state.user.full_name
                }

                let params = {
                    activity: name + ' was logout from nuanu cms',
                    feature: 'logout',
                    activity_type: 'logout',
                }

                await this.$root.sendUserLogActivity(params)
                this.Api.logout()
                var name_cookie = 'X-account'
                document.cookie = name_cookie+'=; Max-Age=-99999999;';  
            },
            async getUserMe() {
                Rest.get('/user/me/detail')
                .then( (res) => {
                    if(res && res.data) {
                        this.user.id = res.data.id 
                        this.user.username = res.data.username
                        this.user.full_name = res.data.full_name
                        this.user.avatar = res.avatar
                    }
                })
            },
            isDoubleClicked(element) {
                //if already clicked return TRUE to indicate this click is not allowed
                if (element.data("isclicked")) return true;

                //mark as clicked for 1 second
                element.data("isclicked", true);
                setTimeout(function () {
                    element.removeData("isclicked");
                }, 1000);

                //return FALSE to indicate this click was allowed
                return false;
            },
        },
        
        
    }
</script>
<style scoped>
  .text-lowercase{
    text-transform: lowercase !important;
  }

  .navbar-nav > .notifications-menu > .dropdown-menu,
  .navbar-nav > .messages-menu > .dropdown-menu,
  .navbar-nav > .tasks-menu > .dropdown-menu {
    width: 400px;
    padding: 0 0 0 0;
    margin: 0;
    top: 100%;
}

@media only screen and (max-width: 600px) {
  .navbar-nav > .notifications-menu > .dropdown-menu,
  .navbar-nav > .messages-menu > .dropdown-menu,
  .navbar-nav > .tasks-menu > .dropdown-menu {
    width: 382px;
    padding: 0 0 0 0;
    margin: 0 -60px 0 0 ;
    top: 100%;
  }

}

.navbar-nav > .notifications-menu > .dropdown-menu {
    width: 543px;
}
</style>
