<template>
    <div class="container-input-search">
        <form v-if="iconPosition == 'right' || iconPosition == 'right-button'">
            <input type="search" v-model="search" @input="doInputSearch" :placeholder="placeholder" maxlength="64">
            <button v-if="iconPosition == 'right-button'" type="submit" @click="doButtonSearch">Search</button>
            <div v-else class="icon-search-right">Search</div>
        </form>
        
      <form class="nosubmit" v-if="iconPosition == 'left'">
            <input class="nosubmit" type="search" v-model="search" :placeholder="placeholder">
      </form>
    </div>
</template>

<script>
export default {
    props: {
        iconPosition: {
            type: String,
            default: 'right'
        },
        placeholder: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            search: null
        }
    },
    mounted() {

    },
    methods: {
        async doInputSearch() {
            // console.log('input search', this.search)
            this.$emit('onInputSearch', this.search)
        },
        // doButtonSearch() {
        //     this.$emit('onInputSearch', this.search)
        // },
        // doEmit() {
        //     this.$emit('onInputSearch', this.search)
        // }
    },
    computed: {

    },
    watch: {

    }
}
</script>

<style scoped>
    .container-input-search {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: stretch;
    }

    form {
        color: #555;
        display: flex;
        padding: 0px;
        border: 1px solid currentColor;
        border-radius: 5px;
        margin: 0 0 30px;
    }

    input[type="search"] {
        border: none;
        background: transparent;
        margin: 0;
        padding: 7px 8px;
        font-size: 14px;
        color: inherit;
        border: 1px solid transparent;
        border-radius: inherit;
        width: 100%;
    }

    input[type="search"]::placeholder {
        color: #bbb;
    }

    button[type="submit"],
    .icon-search-right {
        text-indent: -999px;
        overflow: hidden;
        width: 40px;
        padding: 0;
        margin: 0;
        border: 1px solid transparent;
        border-radius: inherit;
        background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E") no-repeat center;
        cursor: pointer;
        opacity: 0.7;
    }

    button[type="submit"]:hover,
    .icon-search-right:hover {
        opacity: 1;
    }

    button[type="submit"]:focus,
    .icon-search-right.focus,
    input[type="search"]:focus {
        box-shadow: 0 0 3px 0 #1183d6;
        border-color: #1183d6;
        outline: none;
    }

    form.nosubmit {
        border: none;
        padding: 0;
    }

    input.nosubmit {
        border: 1px solid #555;
        width: 100%;
        padding: 9px 4px 9px 40px;
        background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E") no-repeat 13px center;
    }
</style>