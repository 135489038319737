<template>
    <div>
        <span class="title-switch-text text-before-switch"> {{ title }}</span>
            <label class="switch" @change="switchButton">
                <input type="checkbox" :class="class_name_input" :checked="value" :disabled="disabled" > 
                <span class="slider round" :style="disabled ? 'cursor: default;' : '' "></span>
            </label>
        <span class="title-switch-text text-after-switch">{{ text_name }}</span>
   
    </div>
    
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: 'Status'
        },
        value: {
            type: [String, Boolean],
            default: false
        },
        name: {
            type: [String],
        },
        disabled: {
            type: [String, Boolean],
            default: false
        },
        is_text_name: {
            type: [String, Boolean],
            default: true,
        }
    },
    data() {
        return {
            text_name: "Not Active",
        }
    },
    computed: {
        class_name_input() {
            let class_name = ''
            let temp = localStorage.getItem('_USER_ME')
            if(temp) {
                let bytes = this.CryptoJS.AES.decrypt(temp, process.env.VUE_APP_KEY_ENCRYPT_DECRYPT)
                let data = JSON.parse(bytes.toString(this.CryptoJS.enc.Utf8))
                if(data) {
                    if(data.company_id) {
                        class_name = 'owner-switch-button'
                    }
                }
            }

            return class_name
        }
    },
    async mounted() {
        var text_name = 'Not Active'
        if(this.value) {
            text_name = 'Active'
        }
        
        if(!this.is_text_name) {
            text_name = ''
        }

        this.text_name = text_name
    },
    methods: {
        switchButton() {
            this.$emit('onClickSwitchButton')
        }
    },
    watch: {
        value: {
            handler(val) {
                // console.log('change val :', val)
                var text_name = 'Not Active'
                if(val) {
                    text_name = 'Active'
                }

                if(!this.is_text_name) {
                    text_name = ''
                }

                this.text_name = text_name
            }
        }
    }
    
}
</script>

<style scoped>
.switch {
  position: relative;
  display: inline-block;
  width: 42px;
  height: 24px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #6147FF;
}

input:focus + .slider {
  box-shadow: 0 0 1px #6147FF;
}

input.owner-switch-button:checked + .slider {
  background-color: #18A891;
}

input.owner-switch-button:focus + .slider {
  box-shadow: 0 0 1px #18A891;
}

input:checked + .slider:before {
  -webkit-transform: translateX(18px);
  -ms-transform: translateX(18px);
  transform: translateX(18px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 24px;
}

.slider.round:before {
  border-radius: 50%;
}

    .title-switch-text{
        /* margin: 0; */
        /* position: absolute; */
        top: 50%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    }

    .text-before-switch {
        margin: 0px 10px 0px 0px ;
    }

    .text-after-switch {
        margin: 0px 0px 0px 10px ;
    }
</style>