<template>
    <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 24 24" :fill="fill">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M12 21.6004C17.302 21.6004 21.6 17.3023 21.6 12.0004C21.6 6.69846 17.302 2.40039 12 2.40039C6.69809 2.40039 2.40002 6.69846 2.40002 12.0004C2.40002 17.3023 6.69809 21.6004 12 21.6004ZM16.4486 10.4489C16.9172 9.98029 16.9172 9.22049 16.4486 8.75186C15.9799 8.28323 15.2201 8.28323 14.7515 8.75186L10.8 12.7033L9.24855 11.1519C8.77992 10.6832 8.02013 10.6832 7.5515 11.1519C7.08287 11.6205 7.08287 12.3803 7.5515 12.8489L9.9515 15.2489C10.4201 15.7175 11.1799 15.7175 11.6486 15.2489L16.4486 10.4489Z" :fill="fill_path"/>
    </svg>
</template>

<script>
export default {
    props: {
        width: {
            type: String,
            default: '24'
        },
        height: {
            type: String,
            default: '24'
        },
        fill: {
            type: String,
            default: 'none'
        },
        fill_path: {
            type: String,
            default: '#18A891'
        }
    },
    data() {
        return {
            
        }
    },
}
</script>

<style scoped>

</style>