<template>
    <div>
        <Error404 v-if="display404"></Error404>
        <div v-else>
            <header-title-button
                :title="id ? disabledForm ?  'Location Detail' : 'Edit Location' : 'Add Location' "
                :isAdd="false">
            </header-title-button>

            <box
                back="master.machine-location"
                title="Location Information"
                :save="!disabledForm"
                :saveText="form.id ? `Edit` : `Save`"
                @onSave="doSaveConfirm"
                :remove="false"
                :isSwitch="true"
                :switchValue="form.is_active"
                :switchDisabled="disabledForm"
                switchTitle="Status"
                :switchText="form.is_active ? 'Active' : 'Not Active' "
                @onSwitchButton="changeBtnSwitch">
                <div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group"  :class="{'has-error': !!errors.name}">
                                <label class="control-label">Name 
                                    <span class="warning-text-required">*</span>
                                </label>
                                <input type="text" class="form-control name"
                                    id="name"
                                    v-model="form.name"
                                    :disabled="disabledForm"
                                    maxlength="20" />

                                <p class="help help-block" v-if="errors.name">{{ form.name ? `Name must be a minimum of three characters and a maximum of twenty characters.` : `Name is required.` }}</p>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group" >
                                <label class="control-label">Description</label>
                                <input type="text" class="form-control description"
                                    id="description"
                                    v-model="form.description"
                                    :disabled="disabledForm" />
                            </div>
                        </div>
                    </div>
                    <hr>
                    <h4>Location</h4>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group" :class="{'has-error': !!errors.longitude}">
                                <label class="control-label">Longitude 
                                    <span class="warning-text-required">*</span>
                                </label>
                                <input type="text" class="form-control longitude"
                                    id="longitude"
                                    name="longitude"
                                    v-model="form.longitude"
                                    :disabled="true"
                                
                                /> 
                                <p class="help help-block" v-if="errors.longitude">{{ errorsText.longitude }}</p>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group" :class="{'has-error': !!errors.latitude}">
                                <label class="control-label">Latitude 
                                    <span class="warning-text-required">*</span>
                                </label>
                                <input type="text" class="form-control latitude"
                                    id="latitude"
                                    name="latitude"
                                    v-model="form.latitude"
                                    :disabled="true"
                                
                                /> 
                                <p class="help help-block" v-if="errors.latitude">{{  errorsText.latitude }}</p>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12">
                            <div class="map-wrapper">
                                <div class="map-box" ref="map"></div>
                                <div class="map-search">
                                    <div class="input-group" id="map-search-result">
                                        <input type="text" 
                                            v-model="geoApiParams.search" 
                                            ref="mapSearch" placeholder="Cari alamat..." 
                                            class="form-control"
                                            :disabled="disabledForm">
                                        <div class="input-group-btn">
                                            <button class="btn btn-default btn-flat" 
                                                ref="mapSearchBtn" 
                                                data-target="#map-search-result" 
                                                data-toggle="dropdown" 
                                                aria-haspopup="true" 
                                                aria-expanded="false"
                                                :disabled="disabledForm">
                                                <i class="fa fa-search"></i>
                                            </button>
                                        </div>
                                        <ul class="dropdown-menu" v-if="mapResults && mapResults.length">
                                            <li v-for="m in mapResults" :key="m.place_id"><a href="#" @click.prevent="onAddressClick(m)">{{ m.display_name }}</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div id="map-markers" class="hide"  :disabled="disabledForm">
                                <img src="~leaflet/dist/images/marker-icon.png" alt="Marker Icon" ref="markerIcon"  :disabled="disabledForm">
                                <img src="~leaflet/dist/images/marker-shadow.png" alt="Marker Shadow" ref="markerShadow"  :disabled="disabledForm"> 
                            </div>
                        </div>
                    </div>
                </div>
            </box>
        </div>

        <modal :title="form.id ? 'Change Confirmation' : 'Add Confirmation' " save="true" remove="false"
            :show="is_confirm"
            :saveText=" form.id ? 'Yes, Confirmation' : 'Save'"
            @hidden="is_confirm = false"
            @onSave="doSave()"
            :loading="is_confirm_loading">
            <span class="span-title">Are you sure you want to modify the location?</span>
        </modal>
    </div>
</template>

<script>
import Error404 from '@pages/Error404.vue'
const {console} = window
export default {
    components: {
        Error404,
    },
    data() {
        return {
            alias: 'machine-location',
            id: this.$route.params.id,
            is_edit: false,
            disabledForm: false,
            display404: false,
            page: {
                title: 'Nuanu',
                subtitle: 'Location'
            },
            form: {
                id: this.$route.params.id,
                name: null,
                description: null,
                latitude: -8.6300489,
                longitude: 115.0965331,
                is_active: true
            },
            errors: {
                name: false,
                latitude: false, 
                longitude: false
            },
            is_confirm: false,
            is_confirm_loading: false,
            map: null,
            marker: null,
            circle: null,
            geoApiUrl: '/maps/geo/address?email={{email}}&accept-language={{language}}&countrycodes={{country}}&q={{search}}&format={{format}}',
            geoApiParams: {
                email: 'burhan@tog.co.id',
                format: 'json',
                country: 'id',
                language: 'id',
                search: '',
            },
            mapResults: [],
        }
    },
    async mounted() {
        this.$root.checkingAccessPage(this.$root._route.name)
        if(this.id)  {
            switch(this.$route.query.is_edit) {
                case true: 
                    this.is_edit = true 
                    this.disabledForm = false
                    break;
                case false: 
                    this.is_edit = false 
                    this.disabledForm = true
                    break;
                case 'true': 
                    this.is_edit = true 
                    this.disabledForm = false
                    break;
                case 'false': 
                    this.is_edit = false 
                    this.disabledForm = true
                    break;
                default: 
                    this.is_edit = false 
                    this.disabledForm = true
                    break;
            } 

            if(!this.is_edit) {
                this.$store.state.title = 'Location Detail'
            }else {
                let permission_edit = await this.$root.checkingButtonsPage(this.alias, 'update')
                if(!permission_edit.validate) {
                    this.disabledForm = true 
                }

            }

            this.init()
        }else {
            let permission_create = await this.$root.checkingButtonsPage(this.alias, 'create')
            if(!permission_create.validate) {
                this.disabledForm = true 
            }
        }


        await this.createMap()
    },
    methods: {
        async init() {
            if(this.id) {
                Rest.get('/location/'+this.id)
                .then( async(res) => {
                    if (res && res.data ) {
                        this.form.name = res.data.name
                        this.form.description = res.data.description
                        this.form.latitude = res.data.latitude
                        this.form.longitude = res.data.longitude
                        this.form.is_active = res.data.is_active
                    }
                })
                .catch( () => {
                    this.display404 = true
                })
            }
        },
        changeBtnSwitch() {
            this.form.is_active = !this.form.is_active
        },
        async doSaveConfirm() {
            let is_success = await this.validationForm()
            if(is_success) {
                if(this.id) {
                    this.is_confirm = true 
                }else {
                    this.doSaveProcess()
                }
                
            }
        },
        async doSave() {
            this.is_confirm_loading = false
            
            this.doSaveProcess()
        },

        async doSaveProcess() {
            let params = {
                name: this.form.name,
                description: this.form.description,
                latitude: this.form.latitude.toString(),
                longitude: this.form.longitude.toString(),
                is_active: this.form.is_active
            }

            let methods = this.id ? 'put' : 'post'
            let url = this.id ? '/location/'+this.id : '/location'
            Rest[methods](url, params)
            .then( async(res) => {
                if(res && res.status == 200) {
                    this.$root.showNotification('Success', 'success', this.id ? "Location modification successful." : "Location addition successful.")
                    if(!this.id) {
                        if(res.data && res.data.id) {
                            this.form.id = res.data.id
                        }
                    }

                    this.userLogActivity()

                    this.$root.$router.push({
                        name: "master.machine-location"
                    })
                }
            })
            .catch( (err) => {
                if(err && err.response && err.response.status) {
                        if(err.response.data && err.response.data.message) {
                            this.$root.showNotification('Failed', 'error', err.response.data.message)        
                        }else {
                            var message = "Location addition failed"
                            if(this.id) {
                                message = "Location modification failed."
                            }
                            this.$root.showNotification('Failed', 'error', message)
                        }
                    }else {
                        this.$root.showNotification('Failed', 'error', 'Sorry, you do not have an internet connection.')
                    }
            })
            .finally( () => {
                this.is_confirm = false 
                this.is_confirm_loading = false 
            })

        },
        async validationForm() {
            let is_success = true 
            this.errors.name = false 
            this.errors.latitude = false 
            this.errors.longitude = false 

            if(!this.form.name) {
                this.errors.name = true 
                is_success = false 
            }else{
                let name = this.form.name.trim()
                if(name.length < 3 || name.length > 20) {
                    this.errors.name = true 
                    is_success = false 
                }
            }

            if(!this.form.longitude) {
                this.errors.longitude = true 
                is_success = false 
            }
        
            if(!this.form.latitude) {
                this.errors.latitude = true 
                is_success = false
            }

            return is_success
        },
        setLatLng(l) {
            this.marker.setLatLng(l)
            this.circle.setLatLng(l)
            this.map.setView(l)
            if (typeof l == 'object' && !l.hasOwnProperty('length')) {
                this.form.latitude = parseFloat(l.lat)
                this.form.longitude = parseFloat(l.lng)
            } else {
                this.form.latitude = parseFloat(l[0])
                this.form.longitude = parseFloat(l[1])
            }
            this.circle.setRadius(this.form.radius || 10)
        },
        async createMap() {
            var self = this 
            var L = this.L

            this.map = L.map(this.$refs.map)
            L.tileLayer("http://{s}.tile.osm.org/{z}/{x}/{y}.png", {
                attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors | <a target="_blank" href="https://monstergroup.co.id">MonsterGroup</a> partners',
            }).addTo(this.map)

            var markerIcon = L.icon({
                iconUrl:      this.$refs.markerIcon.src,
                shadowUrl:    this.$refs.markerShadow.src,
                iconSize:     null,
                shadowSize:   null,
                iconAnchor:   [12, 40],
                shadowAnchor: [12, 40],
                popupAnchor:  [0, 0],
            })
            var target = L.latLng(this.form.latitude, this.form.longitude)
            this.map.setView(target, 17)
            this.marker = L.marker(target, {icon: markerIcon}).addTo(this.map)
            this.circle = L.circle(target, { radius: this.form.radius || 10 }).addTo(this.map)
            this.map.on("click", (e) => {
                if(!self.disabledForm) {
                    this.setLatLng(e.latlng)
                }
            })

            $(this.$refs.mapSearch).off('keypress').on('keypress', (e) => {
                if (e.keyCode == 13 || e.which == 13) {
                    e.preventDefault()
                    this.doSearch()
                }
            })
            $(this.$refs.mapSearchBtn).off('click').on('click', (e) => {
                e.preventDefault()
                this.doSearch()
            })
        },
        doSearch() {
            this.mapResults = []
            if (!this.geoApiParams.search) {
                return
            }
            $('#map-search-result').addClass('open')
            var url = this.geoApiUrl
            for (var i in this.geoApiParams) {
                url = url.replace('{{'+i+'}}', encodeURIComponent(this.geoApiParams[i]))
            }

            Rest.get(url).then(res => {
                this.mapResults = res.data
            })
        },
        onAddressClick(m) {
            this.form.latitude = parseFloat(m.lat)
            this.form.longitude = parseFloat(m.lon)
            this.setLatLng([parseFloat(m.lat), parseFloat(m.lon)])
        },
        async userLogActivity() {
            if(this.$root.$store.state.user && this.$root.$store.state.user.full_name) {
                let route_name = {
                    name: 'master.machine-location.form',
                    params: {
                        id: this.form.id
                    },
                    query: {
                        is_edit: true
                    }
                } 

                let secretKey = process.env.VUE_APP_KEY_ENCRYPT_DECRYPT
                let route_name_encrypt = this.EncryptDecryptAesCbc256.encryptAesCbc256(secretKey, JSON.stringify(route_name))

                let activity_type = 'create'
                let activity = `${this.$root.$store.state.user.full_name} has added a location with the name <a style="cursor: pointer" class="click-detail-log" data-route="${route_name_encrypt}"> ${this.form.name}</a>`
                if(this.id) {
                    activity = `${this.$root.$store.state.user.full_name} has modified the location with the name <a style="cursor: pointer" class="click-detail-log" data-route="${route_name_encrypt}"> ${this.form.name}</a>`
                    activity_type = 'edit'
                }

                let params = {
                    activity: activity,
                    feature: 'Machine Location',
                    activity_type: activity_type,
                    route_name: route_name
                }

                this.$root.sendUserLogActivity(params)
            }
            
        },
    },
    computed: {

    },
    watch: {
        'form.name': {
            handler(val) {
                this.errors.name = false 
                if(!val) {
                    this.errors.name = true 
                }else {
                    let value = val.trim()
                    if (value.length < 3 || value.length > 20) {
                        this.errors.name = true
                    }
                }
            }
        },
        'form.longitude': {
            handler(val) {
                this.errors.longitude = false 
                if(!val) {
                    this.errors.longitude = true 
                }
            }
        },
        'form.latitude': {
            handler(val) {
                this.errors.latitude = false 
                if(!val) {
                    this.errors.latitude = true 
                }
            }
        },
    }
}
</script>

<style scoped>
 .map-wrapper {
        display: block;
        height: 450px;
        width: 100%;
        overflow: hidden;
        /* border-radius: 10px; */
    }
    .map-box {
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        height: 450px;
        left: 15px;
        right: 15px;
        max-width: 100%;
        border-radius: 4px;
        border: 1px solid #d2d6de;
    }

    .map-search {
        position: absolute;
        top: 15px;
        margin-top: 10px;
        right: 25px;
        width: 300px;
        max-width: 100%;
        z-index: 1000;
    }

    .map-search>.input-group>.dropdown-menu {
        left: 0;
        right: 0;
        border-radius: 0;
        margin: 0;
        max-height: 200px;
        overflow-y: auto;
    }
    .map-search>.input-group>.dropdown-menu a {
        overflow: hidden;
        text-overflow: ellipsis;
    }

</style>