<template>
    <svg :width="width" :height="height" viewBox="0 0 24 24" :fill="fill" xmlns="http://www.w3.org/2000/svg">
        <path d="M8 14V17M12 14V17M16 14V17M3 21H21M3 10H21M3 7L12 3L21 7M4 10H20V21H4V10Z" :stroke="stroke" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
</template>

<script>
export default {
    props: {
        width: {
            type: String,
            default: '24'
        },
        height: {
            type: String,
            default: '24'
        },
        fill: {
            type: String,
            default: 'none'
        },
        stroke: {
            type: String,
            default: '#6147FF'
        },
    },
    data() {
        return {
            
        }
    },
}
</script>

<style scoped>

</style>
