<template>
    <div>
        <Error404 v-if="display404"></Error404>
        <div v-else>
            <header-title-button
                title="Sales Detail"
                :isAdd="false"
                :isPrint="true"
                @onClickPrint="doPrint">
                <template v-slot:header-actions v-if="payment_status.is_settlement">
                    <div class="pull-right show-action" v-if="resend.is_resend">
                        <span class="btn btn-md btn-resend-digital-ticket show-action">
                            Resend Digital Ticket &nbsp; | &nbsp; <img src="@/assets/svg/chevron-down.svg"/>
                        </span>
                        <div class="report-sales-detail-dropdown-content show-action" id="dropdown-menu-action">
                            <div class="row click-resend-email dropdown-content-detail show-action" data="${row.id}" v-if="resend.is_resend_email">
                                <div class="col-md-9 show-action">
                                    <span class="show-action">Resend Email</span>    
                                </div>
                                <div class="col-md-3 show-action" >
                                    <span class="pull-right show-action">
                                        <img class="show-action" src="@/assets/svg/mail.svg"/>
                                    </span>
                                </div>
                            </div>
                            <div class="row click-resend-whats-app dropdown-content-detail show-action" data="${row.id}" v-if="resend.is_resend_whatsapp">
                                <div class="col-md-9 show-action">
                                    <span class="show-action">Resend WhatsApp</span>    
                                </div>
                                <div class="col-md-3 show-action" >
                                    <span class="pull-right show-action">
                                        <img class="show-action" src="@/assets/svg/icon-whats-app.svg"/>
                                    </span>
                                </div>
                            </div>
                            <div class="row click-resend-telegram dropdown-content-detail show-action" data="${row.id}" v-if=resend.is_resend_telegram>
                                <div class="col-md-9 show-action">
                                    <span class="show-action color-disabled">Resend Telegram</span>    
                                </div>
                                <div class="col-md-3 show-action" >
                                    <span class="pull-right show-action">
                                        <img class="show-action color-disabled" src="@/assets/svg/icon-telegram.svg"/>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </template>
            </header-title-button>
            <div class="row">
                <div class="col-md-8">
                    <box
                        header="false">

                        <div class="row">
                            <div class="col-md-6 col-xs-6">
                                <h4><b>Order ID : {{ transaction.booking_code }}</b></h4>
                            </div>
                            <div class="col-md-6 col-xs-6">
                                <div class="row">
                                    <div class="col-md-12 col-xs-12">
                                        <button class="btn-xs float-right cursor-text" :class="label_status_trx">{{ transaction.status }}</button>
                                    </div>
                                    <div class="col-md-12 col-xs-12">
                                        <p class="float-right">
                                            <span class="text-label">Total</span>
                                            <span class="text-value font-weight-500"> {{ transaction.total_price | currency }}</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-12 col-xs-12" >
                                <div class="div-border-top"></div>
                            </div>

                        </div>

                        <div class="row">
                            <div class="col-md-12 col-xs-12">
                                <h4><span class="left-vertical-line-company"></span> Customer Detail</h4>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-6 col-xs-6">
                                <p class="text-neutral-70">Customer Name</p>
                                <p class="text-neutral-90">{{ transaction.customer_name }}</p>
                            </div>
                            <div class="col-md-6 col-xs-6">
                                <p class="text-neutral-70">Server</p>
                                <p class="text-neutral-90">{{ server_name }}</p>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-12 col-xs-12" >
                                <div class="div-border-top"></div>
                            </div>

                        </div>

                        <div class="row">
                            <div class="col-md-12 col-xs-12">
                                <h4><span class="left-vertical-line-company"></span> Time Detail</h4>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-4 col-xs-6">
                                <p class="text-neutral-70">Created At</p>
                                <p class="text-neutral-90">{{ formattedDate(transaction.created_at) }}</p>
                            </div>
                            <div class="col-md-4 col-xs-6">
                                <p class="text-neutral-70">Paid At</p>
                                <p class="text-neutral-90">{{ formattedDate(paid_at) }}</p>
                            </div>
                            <div class="col-md-4 col-xs-6">
                                <p class="text-neutral-70">Scanned At</p>
                                <p class="text-neutral-90">{{ scanned_at ? formattedDate(scanned_at) : ''  }}</p>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-12 col-xs-12" >
                                <div class="div-border-top"></div>
                            </div>

                        </div>

                        <div class="row">
                            <div class="col-md-12 col-xs-12">
                                <h4><span class="left-vertical-line-company"></span> Order Information</h4>
                            </div>
                        </div>

                        <br />

                        <div class="row">
                            <div class="col-md-12 col-xs-12">
                                <div class="row">
                                    <div class="col-md-12 col-xs-12" v-if="transaction && transaction.transaction_detail && transaction.transaction_detail.length > 0">
                                        <div class="box-item-list padding-10px">
                                            <div v-for="(data, i) in transaction.transaction_detail" :key="i">
                                                <div class="row" v-if="i > 0">
                                                    <div class="col-md-12 col-xs-12" >
                                                        <div class="div-border-top"></div>
                                                    </div>
                                                </div>

                                                <div class="row">
                                                    <div class="col-md-1 col-xs-2" >
                                                        <div class="image-preview-div" v-if="data.package_image_path">
                                                            <img :id="'image-preview-mechanic-'+i" class="image-preview-img" style="width: 60px !important; height: 60px !important; padding: 5px !important;s" :src="data.package_image_path"/>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-7 col-xs-6 div-order-detail-product">
                                                        <p class="text-neutral-90"> {{ data.package_name }}</p>
                                                        <p class="text-neutral-70"> <span>{{data.quantity }}</span> x {{ data.price_per_unit_after_discount | currency }} </p>
                                                    </div>
                                                    <div class="col-md-4 col-xs-4">
                                                        <p class="text-neutral-90 text-right"><span class=""> {{ data.total_price | currency }}</span></p>
                                                        <p class="text-neutral-70 text-right text-lowercase">
                                                            {{ data.qty }} 
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="row" v-if="data.note">
                                                    <div class="col-md-12 col-xs-12">
                                                        <div class="box-note-description">
                                                            <IconPencil 
                                                            :fill_path_1="fill_path_1" 
                                                            :fill_path_2="fill_path_2"/>
                                                            <span class="secondary-font-color"> Note</span>
                                                            <p>"{{ data.note }}"</p>
                                                        </div>
                                                            
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <br />

                        <div class="row">
                            <div class="col-md-12 col-xs-12" >
                                <div class="div-border-top"></div>
                            </div>

                        </div>

                        <div class="row">
                            <div class="col-md-12 col-xs-12">
                                <h4><span class="left-vertical-line-company"></span> Payment Information</h4>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-12 col-xs-12">
                                <div class="box-item-list padding-10px">
                                    <div class="row">
                                        <div class="col-md-6 col-xs-6">
                                            <p class="text-neutral-90">Order Subtotal</p>
                                        </div>
                                        <div class="col-md-6 col-xs-6"> 
                                            <p class="text-neutral-90 text-right">{{ transaction.total_price | currency }}</p>
                                        </div>
                                    </div>
                    
                                    <div class="row">
                                        <div class="col-md-12 col-xs-12">
                                            <div class="div-border-top"></div>
                                        </div>

                                    </div>

                                    <div class="row">
                                        <div class="col-md-6 col-xs-6">
                                            <p class="text-neutral-90">Total Price</p>
                                        </div>
                                        <div class="col-md-6 col-xs-6">
                                            <p class="text-neutral-90 text-right">{{ transaction.total_price | currency }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </box>
                </div>
                <div class="col-md-4 col-xs-12">
                    <box
                        header="false">
                        <div class="row">
                            <div class="col-md-12 col-xs-12">
                                <h4><span class="left-vertical-line-company"></span> Timeline</h4>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 col-xs-12">
                                <div class="row">
                                    <div class="col-md-12 col-xs-12" v-if="transaction && transaction.transaction_progress && transaction.transaction_progress.length > 0">
                                        <div class="box-item-list lini-masa padding-20px">
                                            <div class="padding-left-right-5px" v-if="['booked', 'approved', 'on-progress'].includes(transaction.transaction_status_code)">
                                                <div class="row">
                                                    <div class="col-md-1 col-xs-2">
                                                        <IconClockDisabled/>
                                                        <h3>
                                                            <span class="left-vertical-line-company-1px-disabled margin-left-10px"></span>
                                                        </h3>
                                                    </div>
                                                    <div class="col-md-10 col-xs-10">
                                                        <p class="text-neutral-70">New information will be displayed here.</p>
                                                    </div>
                                                </div>
                                            </div> 
                                            <div v-else>
                                                <br >
                                            </div>
                                            <div v-for="(data, i) in transaction.transaction_progress" :key="i" class="padding-left-right-5px">
                                                <div class="row">
                                                    <div class="col-md-1 col-xs-2">
                                                        <IconCheckCircleSecondary :fill_path="fill_path" />
                                                        <h3 v-if="i < transaction.transaction_progress.length - 1"> 
                                                            <span class="left-vertical-line-company-1px margin-left-10px" ></span>
                                                        </h3>
                                                    </div>
                                                    <div class="col-md-10 col-xs-10 margin-top--18px">
                                                        <p>{{ formattedDate(data.date) }}</p>
                                                        <p>{{ data.description }}</p>
                                                    <br>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </box>
                </div>
            </div>
        </div>

        <modal :title="'Resend Email Confirmation'" save="true" remove="false"
            :show="is_confirm_resend_email"
            saveText="Yes, Confirmation"
            @hidden="is_confirm_resend_email = false"
            @onSave="doResendEmail()"
            :loading="is_confirm_resend_email_loading">
            <span class="span-title show-action">Are you sure you want to resend email ?</span>
        </modal>

        <modal :title="'Resend Whatsapp Confirmation'" save="true" remove="false"
            :show="is_confirm_resend_whatsapp"
            saveText="Yes, Confirmation"
            @hidden="is_confirm_resend_whatsapp = false"
            @onSave="doResendWhatsapp()"
            :loading="is_confirm_resend_whatsapp_loading">
            <span class="span-title show-action">Are you sure you want to resend whatsapp ?</span>
        </modal>
    </div>
</template>

<script>
import Error404 from '@pages/Error404.vue'
import IconPencil from '@components/svg/IconPencil.vue'
import IconCheckCircleSecondary from '@components/svg/IconCheckCircleSecondary.vue'
import IconClockDisabled from '@components/svg/IconClockDisabled.vue'
import momentTz from 'moment-timezone'
export default {
    components: {
        Error404,
        IconPencil,
        IconCheckCircleSecondary,
        IconClockDisabled
    },
    data() {
        return {
            display404: false,
            id: this.$route.params.id,
            transaction: {
                customer_name: null,
                machine_code: null,
                invoice_number: null,
                created_at: null,
                paid_at: null,
                name: null,
                transaction_code: null,
                status: null,
                transaction_status_code: null,
                sub_total_price: 0,
                service_fee: 0,
                admin_fee: 0,
                total_price: 0,
                name: null,
                transaction_date: null,
                schedule_start: null,
                transaction_detail_employee: {
                    employee_name: null
                },
                transaction_detail: null,
                transaction_progress: null,
                payment_type: 'cash',
                payment_status: 'Unpaid'
            },
            label_status_trx: '',
            fill_path: '#06DCF8',
            fill_path_1: '#18A891',
            fill_path_2: '#18A891',
            paid_at: null,
            scanned_at: null,
            is_confirm_resend_email: false,
            is_confirm_resend_email_loading: false,
            is_confirm_resend_whatsapp: false,
            is_confirm_resend_whatsapp_loading: false,
        }
    },
    async mounted() {
        this.$root.checkingAccessPage(this.$root._route.name)
        this.init()

        let self = this
        $(document).ready(function() {
            $(document).on('click', '.btn-resend-digital-ticket', function(e) {
                $('#dropdown-menu-action').css('display', 'block')
            })

            $(document).on('click', '.click-resend-email', function(e) {
                // e.preventDefault()
                self.is_confirm_resend_email = true 
            })

            $(document).on('click', '.click-resend-whats-app', function(e) {
                self.is_confirm_resend_whatsapp = true
            })

        })

        
        window.onclick = function(event) {
            if (!event.target.matches('.show-action')) {
                self.closeAllDropdowns()
            }
        }

    }, 
    methods: {
        formattedDate(str) {
            if(str) {
                let date = momentTz.parseZone(str).format('YYYY-MM-DD HH:mm:ss')
                return date 
            }

            return ''
        },
        async init()  {
            Rest.get('/transaction/'+this.id)
            .then( async(res) => {
                if(res.status && res.status == 200 && res.data) {
                    res.data.transaction_detail.forEach( (item, i) => {
                        let total_price = item.total_price
                        let quantity = item.quantity
                        let price_per_unit_after_discount = total_price/quantity
                        price_per_unit_after_discount = price_per_unit_after_discount.toFixed(0)
                        item.price_per_unit_after_discount = price_per_unit_after_discount
                        let package_image_path = "" 
                        if(item.package_image_path) {
                            let first_char = item.package_image_path.charAt(0)
                            if(first_char == '/') {
                                package_image_path = process.env.VUE_APP_UPLOAD_URL+item.package_image_path
                            }else {
                                package_image_path = process.env.VUE_APP_UPLOAD_URL+'/'+item.package_image_path
                            }
                            
                            item.package_image_path = package_image_path
        
                        }
                    })

                   

                    let created = null 
                    let paid = null 
                    let book_scanned = null
                    let ticket_scanned = null 
                    let expired = null 
                    let cancel = null 
                    res.data.transaction_progress.forEach( (item) => {
                        switch (item.code) {
                            case 'buy':
                                created = item
                                break;
                            case 'settlement':
                                this.paid_at = item.date
                                paid = item 
                                break;
                            case 'scan-book':
                                book_scanned = item 
                                break;
                            case 'scan-ticket':
                                this.scanned_at = item.date
                                ticket_scanned = item 
                                break;
                            case 'expired':
                                expired = item
                                break;
                            case 'cancel':
                                cancel = item 
                                break;
                            case 'ticket-printed':
                                item.description = item.name 
                                break;
                            case 'ticket-send-email':
                                item.description = item.name 
                                break;
                            case 'ticket-send-whatsapp':
                                item.description = item.name
                                break;
                            case 'ticket-send-telegram':
                                item.description = item.name
                            default:
                                break;
                        }

                    })

                    let transaction_progress = []
                    if (created) {
                        transaction_progress.push(created)
                    }

                    if(paid) {
                        transaction_progress.push(paid)
                    }

                    if(book_scanned) {
                        transaction_progress.push(book_scanned)
                    }

                    if(ticket_scanned) {
                        transaction_progress.push(ticket_scanned)
                    }

                    if(expired) {
                        transaction_progress.push(expired)
                    }

                    if(cancel) {
                        transaction_progress.push(cancel)
                    }
                    
                    // res.data.transaction_progress = transaction_progress
                    this.transaction = res.data
                    let label = 'btn-label-trx-booked'
                    let transaction_status_code = res.data.status ? res.data.status.toLowerCase() : ''
                    switch (transaction_status_code) {
                        case 'pending':
                            label = 'btn-label-trx-booked'
                            break;
                        case 'settlement':
                            label = 'btn-label-trx-on-progress'
                            break;
                        case 'scanned': 
                            label = 'btn-label-trx-finish'
                            break;
                        case 'expired': 
                            label = 'btn-label-trx-reject'
                            break;
                        default:
                            break;
                    }
                    this.label_status_trx = label 
                    
                }else {
                    this.display404 = true
                }
            })
            .catch( (err) => {
                console.log('err', err)
                this.display404 = true
            })
        },
        doPrint() {
            $('.not-print').hide()
            window.print()
            $('.not-print').show()
        },
        isDoubleClicked(element) {
            //if already clicked return TRUE to indicate this click is not allowed
            if (element.data("isclicked")) return true;

            //mark as clicked for 1 second
            element.data("isclicked", true);
            setTimeout(function () {
                element.removeData("isclicked");
            }, 1000);

            //return FALSE to indicate this click was allowed
            return false;
        },
        async closeAllDropdowns() {
            let dropdowns = document.getElementsByClassName('report-sales-detail-dropdown-content');
            for (let i = 0; i < dropdowns.length; i++) {
                dropdowns[i].style.display = 'none';
            }
        },
        async doResendEmail() {
            if (this.id) {
                this.is_confirm_resend_email_loading = true
                let params = {
                    email: null 
                }

                Rest.put('/transaction/send-email/'+this.id+'?is_resend=true', params)
                .then( async() => {
                    this.$root.showNotification('Success', 'success', 'Resend email successfull.')
                })
                .catch((err) => {
                    if(err && err.response && err.response.status) {
                        if(err.response.data && err.response.data.message) {
                            this.$root.showNotification('Failed', 'error', err.response.data.message)        
                        }else {
                            var message = "Failed Resend Email"
                            this.$root.showNotification('Failed', 'error', message)
                        }
                    }else {
                        this.$root.showNotification('Failed', 'error', 'Sorry, you do not have an internet connection.')
                    }
                })
                .finally( (res) => {
                    this.is_confirm_resend_email = false 
                    this.is_confirm_resend_email_loading = false 
                })
            }
        },
        async doResendWhatsapp() {
            if (this.id) {
                this.is_confirm_resend_whatsapp_loading = true
                let params = {
                }

                Rest.put('/transaction/send-whatsapp/'+this.id, params)
                .then( async() => {
                    this.$root.showNotification('Success', 'success', 'Resend whatsapp successfull.')
                })
                .catch((err) => {
                    if(err && err.response && err.response.status) {
                        if(err.response.data && err.response.data.message) {
                            this.$root.showNotification('Failed', 'error', err.response.data.message)        
                        }else {
                            var message = "Failed resend whatsapp"
                            this.$root.showNotification('Failed', 'error', message)
                        }
                    }else {
                        this.$root.showNotification('Failed', 'error', 'Sorry, you do not have an internet connection.')
                    }
                })
                .finally( (res) => {
                    this.is_confirm_resend_whatsapp = false 
                    this.is_confirm_resend_whatsapp_loading = false 
                })
            }
        }
    },
    computed: {
        schedule() {
            let order_date = null 
            let start = null 
            let transaction_date = null 
            let schedule_start = null 
            if(this.transaction) {
                if(this.transaction.transaction_date) {
                    order_date = this.transaction.transaction_date
                    let date = moment(this.transaction.transaction_date)
                    let day = date.format('DD')
                    let month = date.format('MM')
                    let year = date.format("YYYY, HH:mm")
                    let months = this.$root.$store.state.months 
                    var monthName = ""
                    let id = parseInt(month)
                    months.forEach((item) => {
                        if (item.value) {
                            if(item.value == id) {
                                monthName = item.short_name
                            }
                        }
                    })

                    transaction_date = day + " "+monthName+" " + year
                }

                if(this.transaction.schedule_start) {
                    start = this.transaction.schedule_start
                    let date = moment(this.transaction.schedule_start)
                    let day = date.format('DD')
                    let month = date.format('MM')
                    let year = date.format("YYYY, HH:mm")
                    let months = this.$root.$store.state.months 
                    var monthName = ""
                    let id = parseInt(month)
                    months.forEach((item) => {
                        if (item.value) {
                            if(item.value == id) {
                                monthName = item.short_name
                            }
                        }
                    })

                    schedule_start = day + " "+monthName+" " + year
                }
            }

            let res = {
                transaction_date: transaction_date,
                schedule_start: schedule_start,
                order_date: order_date,
                start: start
            }

            return res
        },
        payment_type() {
            let data = 'Tunai'
            if(this.transaction && this.transaction.payment_type) {
                switch (this.transaction.payment_type) {
                    case 'tunai':
                        data = 'Tunai'
                        break;
                    case 'non-tunai':
                        data = 'Non Tunai'
                        break;
                    default:
                        break;
                }
            }

            return data
        },
        payment_status() {
            let is_settlement = false
            let text = 'Unpaid'
            let label = 'btn-label-trx-payment-unpaid'
            if(this.transaction && this.transaction.status) {
                switch (this.transaction.status.toLowerCase()) {
                    case 'unpaid':
                        text = 'Unpaid'
                        label = 'btn-label-trx-payment-unpaid'
                        break;
                    case 'expired':
                        text = 'Expired'
                        label = 'btn-label-trx-payment-expired'
                        break;
                    case 'Menunggu Pembayaran':
                        text = 'Menunggu Pembayaran'
                        label = 'btn-label-trx-payment-waiting'
                        break;
                    case 'settlement':
                        text = 'Settlement'
                        label = 'btn-label-trx-payment-paid'
                        is_settlement = true
                        break;
                    case 'scanned':
                        text = 'Lunas'
                        label = 'btn-label-trx-payment-paid'
                        is_settlement = true
                        break;
                    default:
                        text = this.transaction.status
                        label = 'btn-label-trx-payment-default'
                        break;
                }
            }

            return {
                text: text,
                label: label,
                is_settlement: is_settlement,
            }
        },
        server_name() {
            let name = ''
            if(this.transaction) {
                if(this.transaction.creator_name) {
                    name = this.transaction.creator_name
                }else {
                    if(this.transaction.machine_code) {
                        name = this.transaction.machine_code
                    }
                }
            }

            return name
        },
        resend() {
            let is_resend = false 
            let is_resend_email = true
            let is_resend_whatsapp = false 
            let is_resend_telegram = false 
            if(this.transaction) {

                if(this.transaction.whats_app_mobile_phone_number_code && this.transaction.whats_app_mobile_phone_number) {
                    is_resend_whatsapp = true 
                }

                if(this.transaction.telegram_mobile_phone_number_code && this.transaction.telegram_mobile_phone_number) {
                    is_resend_telegram = true 
                }
            }

            if (is_resend_email || is_resend_whatsapp || is_resend_telegram) {
                is_resend = true 
            }

            return {
                is_resend: is_resend,
                is_resend_email: is_resend_email,
                is_resend_whatsapp: is_resend_whatsapp,
                is_resend_telegram: is_resend_telegram
            }
        }
    },
    watch: {
        
    }
}
</script>

<style scoped>
    .btn-resend-digital-ticket {
        background-color: transparent;
        color: #06DCF8;
        border: 1px solid #06DCF8;
        border-radius: 5px;
        padding: 8px 20px 8px 20px;
        font-weight: 700;
        margin-right: 10px;
        cursor: pointer;
    }

    .btn-resend-digital-ticket:hover {
        color: #06DCF8;
    }

    .report-sales-detail-dropdown-content {
        display: none;
        position: absolute;
        min-width: 230px;
        background-color: #FFFFFF;
        box-shadow: 4px 4px 24px 24px rgba(16, 24, 40, 0.05);
        z-index: 1000;
        right: 100px;
        border-radius: 4px;
        padding-left: 8px;
        padding-right: 8px;
        border-radius: 5px;
    }

    .report-sales-detail-dropdown-content .dropdown-content-detail {
        padding: 12px 16px;
        text-decoration: none;
        display: block;
        cursor: pointer;
    }

    .report-sales-detail-dropdown-content .dropdown-content-detail:hover {
        background-color: rgba(255, 255, 255, 0.3);
    }

    .color-disabled {
        color: #C2C2C2;
    }
</style>