<template>
    <box :loading="loading" :save="true" :saveText="$root.$route.params.id ? 'Ubah' : 'Simpan' " @onSave="doPost">
        <div class="row">
            <div class="col-md-12">
                <InputValidateItem
                    v-model="username"
                    :formLabel="'Username*'"
                    :formPlaceholderName="'Masukkan Username'"
                    :validation="validation.firstError('username')"
                    class="alpha-numeric-other-char"
                    :formDisabled="disabledForm"
                />
            </div>
            <div class="col-md-12">
                <InputValidateItem
                    v-model="telp_no"
                    :formType="'number'"
                    :formLabel="'No. Telp*'"
                    :formPlaceholderName="'Masukkan Nomor Telepon'"
                    :validation="validation.firstError('telp_no')"
                    class="alpha-numeric-other-char"
                    :formDisabled="disabledForm"
                />
            </div>
            <div class="col-md-12">
                <InputValidateItem
                    v-model="email"
                    :formType="'email'"
                    :formLabel="'Email*'"
                    :formPlaceholderName="'Masukkan Email'"
                    :validation="validation.firstError('email')"
                    class="alpha-numeric-other-char"
                    :formDisabled="disabledForm"
                />
            </div>
            <div class="col-md-12">
                <SelectValidateItem
                    v-model="division"
                    :formLabel="'Divisi*'"
                    :formPlaceholderName="'Pilih Divisi'"
                    :validation="validation.firstError('division')"
                    :formItemsSelected="dataDivision"
                    class="alpha-numeric-other-char"
                    :formDisabled="disabledForm"
                />
            </div>
            <div class="col-md-12">
                <SelectValidateItem
                    v-if="division==1"
                    v-model="position"
                    :formLabel="'Jabatan*'"
                    :formPlaceholderName="'Pilih Jabatan'"
                    :validation="validation.firstError('position')"
                    :formItemsSelected="dataPosition"
                    class="alpha-numeric-other-char"
                    :formDisabled="disabledForm"
                />
            </div>
            <div class="col-md-12">
                <SelectValidateItem
                    v-model="project_id"
                    :formLabel="'Projek*'"
                    :formPlaceholderName="'Pilih Projek'"
                    :validation="validation.firstError('project_id')"
                    :formItemsSelected="dataProject"
                    class="alpha-numeric-other-char"
                    :formDisabled="disabledForm"
                />
            </div>
            <div class="col-md-12">
                <MultilineTextValidateItem
                    v-model="additional_information"
                    :formLabel="'Tambahan Informasi'"
                    :formPlaceholderName="'Masukkan Tambahan Informasi'"
                    :validation="validation.firstError('additional_information')"
                    class="alpha-numeric-other-char"
                    :formDisabled="disabledForm"
                />
            </div>

            <div class="col-md-12">
              <div class="form-group" :class="{'has-error': !!errors.description}">
                <label class="control-label" >Keterangan </label>
                <textarea class="form-control alpha-numeric-other-char"
                  rows="7"
                  v-model="description"
                  :disabled="disabledForm"></textarea>
              </div>
              <p class="help help-block" v-if="errors.description">Wajib diisi</p>
            </div>

            <div class="col-md-12">
              <div class="form-group" >
                <label class="control-label" >Percent Existing </label> <br/>
                <input type="number" v-model="percent"
                  step="0.01"
                  min="0"
                  max="100"
                  v-on:keydown="$root.validateDecimal($event)">
              </div>
            </div>

            <div class="col-md-12">
              <div class="form-group" >
                <label class="control-label" >Percent New Component oke</label> <br/>
                <input-number-decimal v-model="percent_2" id="percent-2" min="0" max="200" step="0.001" :disabled="false"> </input-number-decimal>
              </div>
            </div>

            <div class="col-md-12">
                <div class="form-group">
                    <b-form-checkbox
                        id="checkbox-applicant"
                        v-model="applicant"
                        name="checkbox-applicant"
                        :checked-value="true"
                        :unchecked-value="false"
                    >
                        Jadikan Sebagai Pemohon
                    </b-form-checkbox>
                </div>
            </div>
            <div class="col-md-12">
                <p>Resource : <a href="http://simple-vue-validator.magictek.cn" @click.prevent="addHeaderButton">Simple Vue-Validation</a></p>
            </div>
        </div>

        <modal :title="$root.$route.params.id ? 'Konfirmasi Ubah' : 'Konfirmasi Simpan' " save="true" remove="false"
               :show="isModalConfirm"
               :saveText=" $root.$route.params.id ? 'Ubah' : 'Simpan'"
               @hidden="isModalConfirm = false"
               @onSave="doSaveConfirm()">
            <span class="span-title">Apakah anda yakin {{ messageConfirm }}  ?</span>
        </modal>
    </box>
</template>

<script>
    import SimpleVueValidation from 'simple-vue-validator'
    import InputNumberDecimal from '@pages/Components/Form/InputNumberDecimal'
    const Validator = SimpleVueValidation.Validator.create({templates: {
        required: 'Wajib diisi',
        email: 'Mohon masuk kan email yg valid'
    }})
    const {console} = window
    export default {
        props: ['id',],
        components: {
          InputNumberDecimal,
        },
        data () {
          return {
            username: "",
            position: "",
            division: "",
            project_id: "",
            telp_no: "",
            email: "",
            description: "",
            percent: 1,
            percent_2: 2.06,
            errors: {
              description: false
            },
            additional_information: "",
            applicant: false,
            loading: false,
            dataPosition: [{
                value: 1,
                name: 'Position 1'
            }, {
                value: 2,
                name: 'Position 2'
            }],
            dataDivision: [{
                value: 1,
                name: 'Marketing'
            }, {
                value: 2,
                name: 'Sales'
            }, {
                value: 3,
                name: 'General Manager'
            }, {
                value: 4,
                name: 'Direktur'
            }],
            dataProject: [{
                value: 1,
                name: 'Bukit Cimanggu City'
            }, {
                value: 2,
                name: 'Cilegon'
            },],
            dataUser:[],
            showFieldPosition: true,
            messageConfirm: 'menyimpan',
            isModalConfirm: false,
            me: {}, // variable untuk menampung data user saya
            role: {}, // varible untuk menampung data user role saya
            // project = variable untuk pendapatken data project yang sedang dipilih (ada di web navbar atas) , data didapat dari Vuex atau dari localStorage
            project: this.$store.state.project || JSON.parse(localStorage.getItem('_PRJ_SELECT')),
            disabledForm: false, // variable untuk form disabled
            alias: 'form sample standar', // alias name , didefine berdasarkan data yg ada di config/datamenu.json

            // untuk mengecek apakah form create atau update berdasarkan params
            // untuk pengaturan hak akses user ada di menu master file -> pengguna -> manajemen hak akses
            permission_type: this.$route.params.id ? 'update' : 'create',
          }
        },
        validators: {
            username: function (value) {
                return Validator.value(value).required().integer()
            },
            telp_no: function (value) {
                return Validator.value(value).required().maxLength(13, `Maksimal alias harus terdiri dari 13 karakter`)
            },
            email: function (value) {
                return Validator.value(value).required().email()
            },
            position: function(value) {
                return Validator.value(value).required()
            },
            division: function(value) {
                return Validator.value(value).required()
            },
            project_id: function(value) {
                return Validator.value(value).required()
            },
            additional_information: function (value) {
                return Validator.value(value).required().maxLength(115, `Maksimal alias harus terdiri dari 115 karakter`)
            }
        },
        async mounted() {
            console.dir(this.dataDivision)
            // verifikasi apakah route name dari page yang sedang dibuka ini memiliki akses read atau tidak
            // jika memiliki akses read maka page ini (route ini bisa terbuka) jika tidak maka akan di redirect ke dashboard
            // this.$root._route.name = route name dari page ini
            this.$root.checkingAccessPage(this.$root._route.name)

            // // mendapatkan permission ,
            // let permission = await this.$root.checkingButtonsPage(this.alias, this.permission_type)
            // if(!permission.validate) {
            //   this.disabledForm = true // jika tidak
            // }

            // pengecekan jika data project belum ada , ambil data dari localStorage
            if(!this.project.id) {
              this.project = JSON.parse(localStorage.getItem('_PRJ_SELECT'))
            }

            // mendapatkan data user role saya dari localStorage dan di decrypt
            let temp = localStorage.getItem('_USER_ROLES')
            if(temp) {
              let bytes = this.CryptoJS.AES.decrypt(temp, process.env.VUE_APP_KEY_ENCRYPT_DECRYPT)
              let data = JSON.parse(bytes.toString(this.CryptoJS.enc.Utf8))
              if(data ) {
                this.role.id = data.role_id
                this.role.name = data.role_name
              }
            }

            // memanggil function getUserMe() untuk mendapatkan data user saya
            await this.getUserMe()
        },
        methods: {
            redirectToList(){
                this.$router.push({name: 'master.organizational-structure' })
            },
            // untuk handler manual validation , validation form tanpa library SimpleVueValidation
            async manualValidation() {
              let success = true
              this.errors.description = false
              if(!this.description) {
                this.errors.description = true
                success = false
              }
              return success
            },
            async doPost() {
                // this.loading = true
                var validation = await this.manualValidation()
                this.$validate().then(result => {
                  console.log('validate', validation, result)
                    if(result && validation) {
                        this.isModalConfirm = true
                    } else {
                        this.loading = false
                        console.dir("ELSE")
                    }
                })
            },
            doSaveConfirm() {
              this.loading = true
              this.postCreateOrganizationalStructure()
            },
            postCreateOrganizationalStructure(){
              try {
                this.$root.showNotification('Berhasil', 'success', 'Berhasil '+this.messageConfirm)
              } catch {
                this.$root.showNotification('Gagal', 'error', 'Gagal '+this.messageConfirm)
              } finally {
                this.loading = false
                this.isModalConfirm = false
              }
                // Rest.post('/mdasdasd/master/organization/structure', {
                //     user_id: this.username,
                //     organization_position_id: this.position,
                //     project_id: this.project,
                //     telp: this.telp_no,
                //     email: this.email,
                //     applicant: this.applicant,
                //     additional_information: this.additional_information
                // }).then(() => {
                //     this.$root.showNotification('Berhasil', 'success', 'Buat struktur organisasi baru telah berhasil')
                //     this.redirectToList()
                // }).catch(err => {
                //     this.$root.showNotification('Gagal', 'error', err.toString())
                // }).finally(() => {
                //     this.loading = false
                // })
            },
            // mendapatkan data user saya
            async getUserMe() {
              Rest.get('/user/me/deail').then( async(res) => {
                this.me = {
                  id: res.data.id,
                  name: res.data.full_name,
                  username: res.data.username,
                  detail: res.data.detail
                }
              })
            },
            // semua tranksaksi endpoint dengan method POST, PUT , DELETE
            // di semua response success harus menyimpan data log_activities
            async saveLogActivities(type,detail) {
              let message = null
              // route_name  = route redirect dari data log activities
              //  name = bisa ambil data route name dari page yang sedang dibuka ataupun bisa disesuaikan dengan route name dari redirect data log activit
              //  route_name bisa memiliki params atau query , atau bahkan mungkin keduanya , tergantung dari router yang di daftarkan
              let route_name = {
                name: this.$root._route.name, // mengambil data route name dari page yang sedang dibuka
                params: {
                  id: detail.id
                },
                // query: {
                //   id : ''
                // }
              }

              type = type.toLowerCase()
              // contoh message log activities
              switch (type) {
                case 'create':
                  message = "<span>"+this.me.name+" telah menambahkan komplain teknis <a style='cursor: pointer' class='click-detail-log' data-route='"+JSON.stringify(route_name)+"' >"+detail.complaint.no_complaint+"</a> </span>"
                  break;
                case 'update':
                  message = "<span>"+this.me.name+" telah mengubah komplain teknis <a style='cursor: pointer' class='click-detail-log' data-route='"+JSON.stringify(route_name)+"' >"+detail.complaint.no_complaint+"</a> </span>"
                  break;
                case 'delete':
                  message = "<span>"+this.me.name+" telah menghapus komplain teknis <a style='cursor: pointer' class='click-detail-log' data-route='"+JSON.stringify(route_name)+"' >"+detail.complaint.no_complaint+"</a> </span>"
                  break;
                case 'finish':
                  message = "<span>"+this.me.name+" telah menyelesaikan komplain teknis <a style='cursor: pointer' class='click-detail-log' data-route='"+JSON.stringify(route_name)+"' >"+detail.complaint.no_complaint+"</a> </span>"
                  type = 'update'
                  break;
                case 'create early handling':
                  message = "<span>"+this.me.name+" telah menambahkan awal penanganan komplain teknis <a style='cursor: pointer' class='click-detail-log' data-route='"+JSON.stringify(route_name)+"' >"+detail.complaint.no_complaint+"</a> </span>"
                  type = 'create'
                  break;
                case 'update early handling':
                  message =  "<span>"+this.me.name+" telah mengubah awal penanganan komplain teknis <a style='cursor: pointer' class='click-detail-log' data-route='"+JSON.stringify(route_name)+"' >"+detail.complaint.no_complaint+"</a> </span>"
                  type = 'update'
                  break;
                case 'create final handling':
                  message = "<span>"+this.me.name+" telah menambahkan akhir penanganan komplain teknis <a style='cursor: pointer' class='click-detail-log' data-route='"+JSON.stringify(route_name)+"' >"+detail.complaint.no_complaint+"</a></span>"
                  type = 'create'
                  break;
                case 'update final handling':
                  message = "<span>"+this.me.name+" telah mengubah akhir penanganan komplain teknis <a style='cursor: pointer' class='click-detail-log' data-route='"+JSON.stringify(route_name)+"' >"+detail.complaint.no_complaint+"</a></span>"
                  type = 'update'
                  break;
                case 'create customer approval':
                  message = "<span>"+this.me.name+" telah menambahkan hasil pengecekan konsumen pada komplain teknis <a style='cursor: pointer' class='click-detail-log' data-route='"+JSON.stringify(route_name)+"' >"+detail.complaint.no_complaint+"</a> </span>"
                  type = 'create'
                  break;
                case 'update customer approval':
                  message = "<span>"+this.me.name+" telah mengubah hasil pengecekan konsumen pada komplain teknis <a style='cursor: pointer' class='click-detail-log' data-route='"+JSON.stringify(route_name)+"' >"+detail.complaint.no_complaint+"</a> </span>"
                  type = 'update'
                  break;
                case 'create customer reject':
                  message = "<span>"+this.me.name+" telah menambahkan penolakan konsumen terkait penanganan komplain teknis <a style='cursor: pointer' class='click-detail-log' data-route='"+JSON.stringify(route_name)+"' >"+detail.complaint.no_complaint+"</a> </span>"
                  type = 'create'
                  break;
                default:
                  break;
              }

              // log_activities hanya bisa menyimpan field type_activities dengan value create atau update, atau delete (karena type data enum pada table )
              //  sebaiknya type yang selain tiga tersebut harus diubah dan sesuaikan terlebih dahulu seperti yang ada diatas
              let possible_type = ['create', 'update', 'delete']
              if(possible_type.indexOf(type) >= 0) {
                let log_activities = {
                  description: message,
                  menu: 'komplain teknis', // berisi menu atau feature yang bersangkutan
                  user: this.me,
                  detail: detail,
                  type_activities: type,
                  project: this.project,
                  date: moment().format('YYYY-MM-DD HH:mm:ss'),
                  role: this.role
                }
                //  menyimpan data log-activities
                Rest.post('/user/log-activities', log_activities)
              }
            }
        },
        watch:{
          'description': {
            handler(newVal) {
              this.errors.description = false
              if(!newVal) {
                this.errors.description = true
              }
            }
          },
          // 'percent_2': {
          //   handler(newVal, oldVal) {
          //     console.log('change percent_2', {
          //       new: newVal,
          //       old: oldVal
          //     })
          //   }
          // },
          // 'percent': {
          //   handler(newVal, oldVal) {
          //     console.log('change percent =', {
          //       new: newVal,
          //       old: oldVal
          //     })


              
          //   }
          // }
        }
    }
</script>
