// import Dashboard from '@pages/Dashboard.vue'
// Template
import MainPage from '@pages/Page/MainPage.vue'
import MasterFile from '@pages/Page/MasterFile.vue'
import ChartJsExample from '@pages/ChartJsExample.vue'

import Notification from '@pages/Notifikasi/Notification.vue'

import ReportOrder from '@pages/Report/Order/Order.vue'
import ReportOrderDetail from '@pages/Report/Order/OrderDetail.vue'


// Router
import MasterRouter from './MasterfileRoutes'
import BoxSample from '@pages/BoxSample.vue'
import FormSample from '@pages/FormSample.vue'
import FormSampleStandar from '@pages/FormSampleStandar.vue'
import DatatableSample from '@pages/DatatableSample.vue'
import CarouselSlider from '@pages/CarouselSlider.vue'
import Signature from '@pages/Signature.vue'


import ChangePasswordMe from '@pages/Setting/ChangePasswordMe.vue'
import GeneralSetting from '@pages/Setting/GeneralSetting/GlobalSetting.vue'

const router = [

    {
        path: '',
        name: 'dashboard',
        component: MainPage,
        meta: {
            title: "Dashboard"
        }
    },
    {
        path: 'master-file',
        name: 'masterfile',
        component: MasterFile,
        props: {
            page: { title: "Master File" },
            state: [{
                font: 'fa fa-database',
                text: 'Master File',
                name: 'masterfile'
            }]
        },
        children: MasterRouter
    },
    {
        path: 'notifications',
        name: 'notifications',
        component: Notification,
        props: {
            page: { title: "Notifikasi" }
        },
        meta: {
            title: "Notifikasi"
        }
    },
    {
        path: 'chartjsexample',
        name: 'chartjsexample',
        component: ChartJsExample,
        meta: {
            title: 'Chart js example'
        }
    },
    {
        path: 'box-sample',
        name: 'box-sample',
        component: BoxSample,
        meta: {
            title: 'Box Sample',
            subtitle: 'Examples'
        }
    }, {
        path: 'form-sample',
        name: 'form-sample',
        component: FormSample,
        meta: {
            title: 'Form Sample',
            subtitle: 'Examples'
        }
    }, {
        path: 'form-sample-standar/:id?',
        name: 'form-sample-standar',
        component: FormSampleStandar,
        meta: {
            title: 'Form Sample'
        }
    }, {
        path: 'datatable-sample',
        name: 'datatable-sample',
        component: DatatableSample,
        meta: {
            title: 'Datatable Sample',
            subtitle: 'Examples'
        }
    }, {
        path: 'carousel-slider',
        name: 'carousel-slider',
        component: CarouselSlider,
        meta: {
            title: 'Carousel Slider',
            subtitle: 'Examples'
        }
    }, {
        path: 'test-signature',
        name: 'test.signature',
        component: Signature,
        meta: {
            title: 'Signature',
            subtitle: 'Examples'
        }
    },
    {
        path: 'report/order',
        name: 'report.order',
        component: ReportOrder,
        meta: {
            title: 'Sales',
            breadcrumb_tree: [{
                title: 'Report',
                path_name: null
            }]
        }
    },
    {
        path: 'report/order/detail/:id',
        name: 'report.order.detail',
        component: ReportOrderDetail,
        meta: {
            title: 'Sales Detail',
            breadcrumb_tree: [{
                    title: 'Report',
                    path_name: null
                },
                {
                    title: 'Sales',
                    path_name: 'report.order'
                }
            ]
        }
    },
    {
        path: 'setting/change-password-me',
        name: 'setting.change-password-me',
        component: ChangePasswordMe,
        meta: {
            title: 'Change Password',
            breadcrumb_tree: [{
                title: 'Setting',
                path_name: null
            }]
        }
    },
    {
        path: 'setting/general-setting',
        name: 'setting.general-setting',
        component: GeneralSetting,
        props: true,
        meta: {
            title: 'General Setting',
            breadcrumb_tree: [{
                title: 'Setting',
                path_name: null
            }]
        }
    },


]


export default router