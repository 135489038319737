<template>
    <div>
        <div class="row">
            <div class="col-md-8">
                <h3>Machine</h3>
            </div>
            <div class="col-md-4">
                <input-search 
                    iconPosition="right"
                    placeholder="Search a machine..."
                    @onInputSearch="doSearchMachine">
                </input-search>
            </div>
        </div>
        <div class="row" id="data-machine">

            <div v-for="(data, i) in machine" :key="i" class="col-md-3 col-xs-12 data-machine-div">
                <div class="box vue-box data-machine-box" style="padding: 20px">
                    <div class="row" >
                        <div class="col-md-10 col-xs-10" >
                            <h4 class="machine-code">{{ data.code }}</h4>
                        </div>
                        <div class="col-md-2 col-xs-2">
                            <div v-if="data.qr" class="cursor-pointer" @click="machineDetail(data.id)">
                                <IconPencil/>
                            </div>
                        </div>
                    </div>
                    <div class="row" style="min-height: 70px">
                        <div class="col-md-2 col-xs-2">
                            <span v-if="data.location && data.location.name">
                                <LocationMarker/> 
                            </span>
                        </div>
                        <div class="col-md-10 col-xs-10">
                            <span class="machine-location" style="padding-top: -20px" v-if="data.location && data.location.name">{{ data.location.name }}</span>
                        </div>
                    </div>
                    <hr>
                    <div class="row">
                        <div style="padding-left: 10px; padding-right: 10px">
                            <button v-if="data.is_connected" class="btn-label-machine-connected cursor-auto">
                                <span class="machine-status">  <img src="@/assets/svg/bullet-connected.svg"/> Connected </span>
                            </button>
                            <button v-else-if="data.is_active" class="btn-label-machine-active cursor-auto">
                                <span class="machine-status">Active</span>
                            </button>
                            <button v-else type="button" class="btn-label-machine-inactive cursor-auto">
                                <span class="machine-status">Inactive</span>
                            </button>

                            <button v-if="data.qr" 
                                @click="showQrCode(data.id)"
                                type="button" 
                                class="btn-label-machine-configure pull-right">
                                <span> <img src="@/assets/svg/solid-qrcode.svg"/> Show QR </span>
                            </button>
                            <button v-else 
                                @click="machineDetail(data.id)"
                                type="button" 
                                class="btn-label-machine-configure pull-right">
                                <span>Configure 
                                    <img src="@/assets/svg/arrow-sm-right.svg" />
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <modal
            :title="title.title"
            :title_detail="title.title_detail"
            id="modal-show-qr-code"
            :save="true"
            :remove="false"
            :cancel="false"
            :show="is_show_qrcode"
            saveText="OK"
            @hidden="is_show_qrcode = false"
            @onSave="closeModal"
            isDoClose="true"
            @onClose="closeModal">
            <div>
                <div class="row">
                    <div class="col-md-3 col-xs-12"></div>
                    <div class="col-md-6 col-xs-12">
                        <img v-if="qrcode" :src="qrcode"/>
                        <br>
                        <div class="row btn-save-as-copy-link">
                            <div class="col-md-5">
                                <span class="cursor-pointer" @click="saveAsQrCode()">Save As..</span>
                            </div>
                            <div class="col-md-2">
                                | 
                            </div>
                            <div class="col-md-5">
                                <span class="cursor-pointer" @click="copyLinkQrCode()">Copy Link</span>
                            </div>
                                
                            <br />

                        </div>
                    </div>
                    <div class="col-md-3 col-xs-12"></div>
                </div>
                <br/>
                <div class="row">
                    <div class="col-md-12 col-xs-12">
                        <div style="background-color: #F5F5F5; padding: 10px">
                            <p>To connect the machine into the system, follow these steps:</p>
                            <ol>
                                <li>Turn on the machine</li>
                                <li>In the start-up page, click "Activate"</li>
                                <li>Scan the QR with the machine scanner, or</li>
                                <li>Input the special link within the available field in the machine</li>
                            </ol>

                        </div>
                    </div>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
import IconPencil from '@components/svg/IconPencil.vue'
import LocationMarker from '@components/svg/LocationMarker.vue'
import IconArrowSmRight from '@components/svg/IconArrowSmRight.vue'
const {console} = window
export default {
    components: {
        IconPencil,
        LocationMarker,
        IconArrowSmRight
    },
    data() {
        return {
            machine: [],
            machine_selected: null,
            is_show_qrcode: false,

        }
    },
    async mounted() {
        this.$root.checkingAccessPage(this.$root._route.name)
        $("body").removeClass("modal-open-custom")
        $("body").css("overflow-y", "auto")

        this.init()
    },
    methods: {
        async init() {
            Rest.get('/machine?sort=code')
            .then( (res) => {
                if(res && res.data && res.data.items && res.data.items.length > 0) {
                    this.machine = res.data.items
                }
            })
            .catch( () => {

            })
        },
        async machineDetail(id) {
            this.$root.$router.push(
                {
                    name: 'master.machine.form', 
                    params: {
                        id: id
                    },
                    query: {
                        is_edit: true
                    }
                }
            )
        },
        async doSearchMachine(search) {
            let value = search 
            if(value) {
                let regex = new RegExp(value,'i')
                let data = $('#data-machine .data-machine-div')
                data.each(function(a, b) {
                    $(b).hide()
                    let machine = $('div:eq(0) .machine-code', b)
                    let machine_code = machine.text().trim()
                    if(machine_code.match(regex)) {
                        $(b).show()
                    }

                    let machine_loc = $('div:eq(0) .machine-location', b)
                    let machine_location_name = machine_loc.text().trim()
                    if(machine_location_name.match(regex)) {
                        $(b).show()
                    }

                    let machine_status = $('div:eq(0) .machine-status', b)
                    let machine_status_name = machine_status.text().trim()
                    if(machine_status_name.match(regex)) {
                        $(b).show()
                    }
                })

            }else {
                $('#data-machine .data-machine-div').show()
            }
        },
        async showQrCode(machine_id) {
            let machine = null 
            this.machine.forEach( (item) => {
                if(item.id == machine_id) {
                    machine = item
                }
            })

            this.machine_selected = machine
            console.log('machine_selected', this.machine_selected)
            this.is_show_qrcode = true
        },
        closeModal() {
            this.is_show_qrcode = false
            this.machine_selected = null
            $("body").css("overflow-y", "hidden")
            $("body").addClass("modal-open-custom")
        },
        saveAsQrCode() {
            if(this.machine_selected && this.machine_selected.qr) {
                let unix_time = moment().unix()
                let filename = 'qrcode-'+this.machine_selected.code+'-'+unix_time+'.jpg'
                Api.download(this.machine_selected.qr, false, filename)
            }
        },
        copyLinkQrCode() {
            if(this.machine_selected && this.machine_selected.qr) {
                let base_url = process.env.VUE_APP_UPLOAD_URL
                let url = base_url + this.machine_selected.qr 

                var textarea = document.createElement('textarea')
                textarea.value = url

                textarea.style.position = 'fixed'
                textarea.style.left = '-9999px'

                document.body.appendChild(textarea)

                textarea.select()

                try {
                    var successful = document.execCommand('copy')
                    var message = successful ? 'Link copied to clipboard!' : 'Unable to copy link.'
                    console.log(message)
                    this.$root.showNotification('Success', 'success', message)
                } catch (err) {
                    this.$root.showNotification('Failed', 'error', err)
                    console.error('Unable to copy link:', err)
                }

                document.body.removeChild(textarea);


            }
        }
    },
    computed: {
        title() {
            let title = 'Access Code: '
            let title_detail = ''
            if(this.machine_selected && this.machine_selected.location )  {
                if(this.machine_selected.location.name) {
                    title += this.machine_selected.location.name 
                    if(this.machine_selected.location.description) {
                        title_detail = this.machine_selected.location.description
                    }
                }
            }
            return {
                title: title,
                title_detail: title_detail
            }
        },
        qrcode() {
            let url = null
            let image_url = process.env.VUE_APP_UPLOAD_URL
            if(this.machine_selected && this.machine_selected.qr) {
                url = image_url + this.machine_selected.qr
            }

            return url
        },
    },
    watch: {
        filter_status: {
            handler() {
                this.init()
                setInterval(this.tiggerUpdateDropDown, 200)
            }
        },
        is_show_qrcode: {
            handler: function(val) {
                if(!val) {
                    $("body").removeClass("modal-open-custom")
                    $("body").css("overflow-y", "auto")
                }else{
                    $("body").addClass("modal-open-custom")
                    $("body").css("overflow-y", "hidden")
                }
            }
        },
    }
}
</script>

<style scoped>
    .btn-save-as-copy-link {
        padding: 6px 10px 6px 10px;
        border-radius: 8px;
        border: 1px solid #C2C2C2;
        background-color: #F5F5F5;
        color: #404040;
        margin-left: 0px;
        margin-right: 10px;
    }
</style>