<template>
  <div>
    <Error404 v-if="display404"></Error404>
    <div v-else>
        <header-title-button
          :title="id ? disabledForm ? 'User Detail' : 'Edit User' : 'Add User' "
          :isAdd="false">
        </header-title-button>
    
        <box 
            back="master.user" 
            @onSave="save" 
            :save="!disabledForm"
            :remove="false"
            title="User Information"
            :isSwitch="true"
            :switchValue="form.is_active"
            :switchDisabled="disabledForm"
            switchTitle="Status"
            :switchText="form.is_active ? 'Active' : 'Not Active' "
            @onSwitchButton="changeBtnSwitch">
            <div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group" :class="{'has-error': !!errors.name}">
                                    <label class="control-label">Full Name 
                                        <span class="warning-text-required">*</span>
                                    </label>
                                    <input type="text" class="form-control name"
                                        id="name"
                                        v-model="form.name"
                                        :disabled="disabledForm"
                                        maxlength="100"
                                        @input="preventLeadingSpaces($event, 'form.name')" />
                                    
                                    <p class="help help-block" v-if="errors.name"> {{ form.name ? 'Full name must be filled in with a minimum of three characters, maximum one hundred characters.' : 'Full name is required.'}}</p>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group" :class="{'has-error': !!errors.email}">
                                    <label class="control-label">Email 
                                        <span class="warning-text-required">*</span>
                                    </label>
                                    <input type="text" class="form-control email"
                                        id="email"
                                        v-model="form.email"
                                        :disabled="id ? true : disabledForm"
                                        maxlength="60"
                                        @input="preventLeadingSpaces($event, 'form.email')" />
                                    
                                    <p class="help help-block" v-if="errors.email">{{ form.email ? 'Email format is not valid.' : 'Email is required.'}}</p>
                                </div>
                            </div>
                        </div>
                        
                        
                    </div>
                    <div class="col-md-6">
                        <div class="form-group" :class="{'has-error': !!errors.password}">
                            <label class="control-label">Password 
                                <span class="warning-text-required">*</span>
                            </label>
                            <input :type="is_password ? 'password': 'text'" class="form-control password"
                                id="password"
                                v-model="form.password"
                                :disabled="disabledForm" 
                                maxlength="50"
                                autocomplete="new-password"
                                @input="preventLeadingSpaces($event, 'form.password')" />
                                <span  @click="showHidePassword" class="toggle-password">
                                    <i v-if="is_password">
                                        <img src="@/assets/svg/eye.svg"/>
                                    </i>
                                    <i v-else>
                                        <img src="@/assets/svg/eye-off.svg"/>
                                    </i>
                                </span>
                            <p class="help help-block" v-if="errors.password">{{ form.password ? 'Password must contain lowercase letters, uppercase letters, numbers, symbols, and be at least eight characters long and at most fifty characters long..' : 'Password is required.'}}</p>
                        </div>

                        <div class="form-group" :class="{'has-error': !!errors.role_id}">
                            <label class="control-label">Role 
                                <span class="warning-text-required">*</span>
                            </label>
                            <select class="form-control select2 select-role"
                                style="width: 100%"
                                v-model="form.role_id"
                                id="select-role"
                                :disabled="id && is_super_user ? true : disabledForm" >
                                <option value="" disabled>--Select Role--</option>
                                <option v-for="(data, index) in roles" :key="index" :value="data.id">
                                {{ data.name }}
                                </option>
                            </select>
                            <p class="help help-block" v-if="errors.role_id">Role is required.</p>
                        </div>
                    </div>
                </div>
            </div>
        </box>
    </div>
  </div>
</template>

<script>
    import Error404 from '@pages/Error404.vue'
    import IconInfoCircle from '@components/svg/IconInfoCircle.vue'

    const {console} = window
    export default {
      components: {
        Error404,
        IconInfoCircle
      },
        props: {
        },
        data() {
            return {
                id: this.$route.params.id,
                is_edit: this.$route.query.is_edit,
                isSwitch: false,
                page: {
                    title: "Master",
                },
                form: {
                    id: this.id,
                    name: null,
                    role_id: null,
                    email: null,
                    password: null,
                    is_active: true,
                    
                },
                errors: {
                    name: false,
                    role_id: false,
                    email: false,
                    password: false,
                    is_active: false 
                },
                errorsText: {
                    name: 'Name is required.',
                    role_id: 'Role is required.',
                    email: 'Email is required. ',
                    password:  'Password is required.'
                },
                roles: [],
                alias: 'user',
                permission_type: this.id ? 'update' : 'create',
                disabledForm: false,
                display404: false,
                me: {
                    id: null,
                    name: null,
                    email: null,
                    company_id: null
                },
                role: {
                    id: null,
                    name: null,
                    detail: null,
                    company_id: null,
                },
                is_modal: false,
                is_super_user: false,
                is_password: true,
                user: this.$root.$store.state.user || null,
            }
        },
        async mounted() {
            await this.$root.checkingAccessPage(this.$root._route.name)
            let permission = await this.$root.checkingButtonsPage(this.alias, this.permission_type)
            if(!permission.validate) {
              this.disabledForm = true
            }

            this.$root._route.meta.title = 'Add User'
            this.$store.state.title = 'Add User'
            if(this.id) {
                this.$root._route.meta.title = 'Edit User'
                this.$store.state.title = 'Edit User'

                let is_edit = this.is_edit 
                if(typeof is_edit == 'string') {
                    switch (is_edit) {
                        case 'true':
                            this.is_edit = true 
                            break;
                        case 'false':   
                            this.is_edit = false 
                            break;
                        default:
                            this.is_edit = false
                            break;
                    }
                }

                if(!this.is_edit) {
                    this.disabledForm = true

                    this.$root._route.meta.title = 'User Detail'
                    this.$store.state.title = 'User Detail'
                }

                if(!permission.validate) {
                    this.disabledForm = true

                    this.$root._route.meta.title = 'User Detail'
                    this.$store.state.title = 'User Detail'
                }

            }

            let self = this
            $(document).ready(function() {
                $('#select-role').on('select2:select', (e) => {
                    let data = e.params.data
                    self.form.role_id = data.id
                })
            })

            let temp = localStorage.getItem('_USER_ROLES')
            if(temp) {
              let bytes = this.CryptoJS.AES.decrypt(temp, process.env.VUE_APP_KEY_ENCRYPT_DECRYPT)
              let data = JSON.parse(bytes.toString(this.CryptoJS.enc.Utf8))
              if(data ) {
                this.role.id = data.role_id
                this.role.name = data.role_name
              }
            }

            let userMe = localStorage.getItem('_USER_ME')
            if(userMe) {
                let userMeBytes = this.CryptoJS.AES.decrypt(userMe, process.env.VUE_APP_KEY_ENCRYPT_DECRYPT)
                let userMeData = JSON.parse(userMeBytes.toString(this.CryptoJS.enc.Utf8))
                if(userMeData) {
                    this.me.id = userMeData.id 
                    this.me.full_name = userMeData.name 
                    this.me.username = userMeData.username
                }else {
                    await this.getUserMe()
                }
            }else {
                await this.getUserMe()
            }

            this.getRole()

            if(this.id) {
                this.getUser()
            }

            $('.select2').select2()
        },
        methods: {
            getRole() {
                var filter = [["name", "NOT IN", ["ROLE_SUPERUSER", "ROLE_MACHINE", "ROLE_USER", "ROLE_CASHIER" ]]]
                filter = JSON.stringify(filter)
                var encodedFilter = encodeURIComponent(filter)
                Rest.get('/role?page=0&size=1000&filters='+encodedFilter) 
                .then( (res) => {
                    if(res && res.data && res.data && res.data.items) {
                        this.roles = res.data.items
                    }
                })
            },
            changeBtnSwitch() {
                this.form.is_active = !this.form.is_active
            },
            async save() {
                var is_valid = await this.validationForm()
                if(is_valid) {
                    if(this.id) {
                        // confirm save
                        this.doSave()
                    }else {
                        this.doSave()
                    }
                }
                
            },
            async validationForm() {
                var is_valid = true 

                this.errors.name = false 
                this.errors.role_id = false 
                this.errors.email = false 
                this.errors.password = false 
                if(!this.form.name) {
                    this.errors.name = true
                    is_valid = false 
                }else {
                    let value = this.form.name.trim()
                    if(value.length < 3 || value.length > 100) {
                        this.errors.name = true
                        is_valid = false 
                    } 
                }

                if(!this.form.role_id) {
                    this.errors.role_id = true 
                    is_valid = false 
                }

                if(!this.id) {
                    if(!this.form.email) {
                        this.errors.email = true 
                        is_valid = false 
                    }else {
                        if(this.form.email.length > 60) {
                            this.errors.email = true 
                            is_valid = false 
                        }else {
                            let is_valid_email = this.$root.validationInputEmail(this.form.email)
                            if(!is_valid_email) {
                                this.errors.email = true 
                                is_valid = false
                            }
                        }
                    }

                    if(!this.form.password) {
                        this.errors.password = true 
                        this.errorsText.password = "Password is required."
                        is_valid = false 
                    }else {
                        let valid = this.$root.validationInputPassword(this.form.password)
                        if(!valid) {
                            this.errors.password = true 
                            is_valid = false 
                        }
                    }
                }else {
                    if(this.form.password) {
                        let valid = this.$root.validationInputPassword(this.form.password)
                        if(!valid) {
                            this.errors.password = true 
                            is_valid = false 
                        }
                    }
                }

                return is_valid

            },
            async doSave() {
                var params = {
                    username: this.form.email,
                    password: this.form.password,
                    full_name: this.form.name,   
                    is_active: this.form.is_active,
                    roles: [
                        {
                            id: this.form.role_id
                        }
                    ]
                }
                var methods = this.id ? 'put' : 'post'
                var url = this.id ? '/user/'+this.id : '/user'

                Rest[methods](url, params)
                .then( async(res) => {
                    if(res && res.status == 200) {
                        this.$root.showNotification('Success', 'success', this.id ? "User modification successful." : "User addition successful.")
                        this.userLogActivity()
                        
                        if(methods == 'post') {
                            if(res && res.data && res.data.data && res.data.data.id) {
                                this.form.id = res.data.data.id 
                            }
                        }else {
                            if(!this.form.is_active) {
                                this.broadCastInfoNotif(this.form, 'non-active-user')
                            }

                            if(this.form.password) {
                                this.broadCastInfoNotif(this.form, 'change-password')
                            }
                        }

                       
                        this.$root.$router.push({name: 'master.user'})
                    }
                })
                .catch( (err) => {
                    if(err && err.response && err.response.status) {
                        if(err.response.status == 409) {
                            this.$root.showNotification('Failed', 'error', "Email already exists, please input another email.")        
                        }else {
                            var message = "User addition failed"
                            if(this.id) {
                                message = "User modification failed."
                            }
                            this.$root.showNotification('Error', 'error', message)
                        }
                    }else {
                        this.$root.showNotification('Failed', 'error', 'Sorry, you do not have an internet connection.')
                    }
                })
            },
            async userLogActivity() {
                if(this.$root.$store.state.user && this.$root.$store.state.user.full_name) {
                    let route_name = {
                        name: 'master.user.form',
                        params: {
                            id: this.form.id
                        },
                        query: {
                            is_edit: false
                        }
                    } 

                    let secretKey = process.env.VUE_APP_KEY_ENCRYPT_DECRYPT
                    let route_name_encrypt = this.EncryptDecryptAesCbc256.encryptAesCbc256(secretKey, JSON.stringify(route_name))

                    let activity_type = 'create'
                    let activity = `${this.$root.$store.state.user.full_name} has added a user with the name <a style="cursor: pointer" class="click-detail-log" data-route="${route_name_encrypt}"> ${this.form.name}</a>`
                    if(this.id) {
                        activity = `${this.$root.$store.state.user.full_name} has modified the user with the name <a style="cursor: pointer" class="click-detail-log" data-route="${route_name_encrypt}"> ${this.form.name}</a>`
                        activity_type = 'edit'
                    }

                    let params = {
                        activity: activity,
                        feature: 'User',
                        activity_type: activity_type,
                        route_name: route_name
                    }

                    this.$root.sendUserLogActivity(params)
                }
                
            },
            async getUserMe() {
                Rest.get('/user/me/detail')
                .then( (res) => {
                    if(res && res.data) {
                        this.me.id = res.data.id 
                        this.me.username = res.data.username
                        this.me.full_name = res.data.full_name
                    }
                })
            },
            async getUser() {
                Rest.get('/user/'+this.id)
                .then( async(res) => {
                    if(res && res.data && res.data) {
                        this.form.name = res.data.full_name 
                        this.form.email = res.data.username
                        this.form.is_active = res.data.is_active
                        this.form.id = res.data.id
                        if(res.data.roles && res.data.roles.length > 0) {
                            this.form.role_id = res.data.roles[0].id 

                            var role_name = res.data.roles[0].name
                            if(role_name == "ROLE_SUPERUSER") {
                                this.is_super_user = true
                                this.roles.push({
                                    id: this.form.role_id,
                                    name: role_name 
                                })
                            }

                            $('#select-role').val([this.form.role_id]).trigger('change')

                            
                        }
                    }
                })
            },
            showHidePassword() {
                if(!this.disabledForm) {
                    this.is_password = !this.is_password
                }
            },
            async broadCastInfoNotif(data, notif_type) {
                this.$socket.emit('info-notif', {
                    notif_type: notif_type,
                    user_id: data.id,
                    data: data 
                })
            },
            preventLeadingSpaces(event, modelPath, isSpace = true) {
                let input = event.target.value.replace(/^\s+/, '')
                if (!isSpace) {
                    input = event.target.value.replace(/\s+/g, '')
                }

                let keys = modelPath.split('.')
                let obj = this;
                for (let i = 0; i < keys.length - 1; i++) {
                    obj = obj[keys[i]];
                }
                this.$set(obj, keys[keys.length - 1], input);
            }
            
        },
        computed: {
            is_partner() {
                let response = false 
                let temp = localStorage.getItem('_USER_ME')
                if(temp) {
                    let bytes = this.CryptoJS.AES.decrypt(temp, process.env.VUE_APP_KEY_ENCRYPT_DECRYPT)
                    let data = JSON.parse(bytes.toString(this.CryptoJS.enc.Utf8))
                    if(data) {
                        if(data.company_id) {
                            response = true
                        }
                    }
                }

                return response
            }
        },
        watch: {
            'form.name':  {
                handler(val) {
                    this.errors.name = false 
                    if(!val) {
                        this.errors.name = true 
                    }else {
                        let value = val.trim()
                        if(value.length < 3 || value.length > 100) {
                            this.errors.name = true 
                        } 
                    }
                }
            },
            'form.role_id': {
                handler(val) {
                    this.errors.role_id = false 
                    if(!val) {
                        this.errors.role_id = true 
                    }
                }
            },
            'form.email': {
                handler(val) {
                    this.errors.email = false 
                    if(!this.id) {
                        if(!val) {
                            this.errors.email = true 
                        }else {
                            if(val.length > 60) {
                                this.errors.email = true 
                            }else {
                                let is_valid = this.$root.validationInputEmail(val)
                                if(!is_valid) {
                                    this.errors.email = true
                                }
                            }
                        }
                    }
                }
            },
            'form.password': {
                handler(val) {
                    this.errors.password = false
                    if(!this.id) {
                        if(!val) {
                            this.errors.password = true 
                        }else {
                            let is_valid = this.$root.validationInputPassword(val)
                            if(!is_valid) {
                                this.errors.password = true 
                            }
                        }
                    }else {
                        if(val) {
                            let is_valid = this.$root.validationInputPassword(val)
                            if(!is_valid) {
                                this.errors.password = true 
                            }
                        }
                    }
                }
            }
        }
    }
</script>
<style scoped>
    .divider {
        width: 100%;
        height: 0.5px;
        background-color: #c5c5c5;
    }

    .toggle-password {
        cursor: pointer;  
        float: right;
        margin-top: -25px;
        margin-right: 25px;
    }

    .toggle-password img {
        position: absolute;
    }


</style>
