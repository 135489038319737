import 'es6-promise/auto'
import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'
import VueRouter from 'vue-router'
import SimpleVueValidation from 'simple-vue-validator'
import VueCurrencyInput from 'vue-currency-input'
import VueCurrencyFilter from 'vue-currency-filter'
import CryptoJS from 'crypto-js'
// import createCurrencyFormat from 'vue-currency-input/src/utils/createCurrencyFormat'
import createCurrencyFormat from '@components/createCurrencyFormat'
import jQuery from 'jquery'
import moment from 'moment'
import Pace from 'pace-js'
import routerConfig from '@config/router'
import env from '@config/environment'
import Api from '@app/services/Api'
import DataTable from '@components/DataTable'
import Select2 from '@components/Select2'
import Buttons from '@components/Buttons'
import InputValidate from '@components/form/inputFormValidate'
import TextEditorValidate from '@components/form/textEditorFormValidate'
import CurrencyInputValidate from '@components/form/currencyInputFormValidate'
import DateInputValidate from '@components/form/dateFormValidate'
import SelectFormValidate from '@components/form/selectFormValidate'
import MultilineTextFormValidate from '@components/form/multilineTextFormValidate'
import Datepicker from '@components/Datepicker'
import InputCurrency from '@pages/Components/Form/InputCurrency'
import InputNumberDecimal from '@pages/Components/Form/InputNumberDecimal.vue'
import Box from '@components/Box.vue'
import ButtonSwitch from '@components/ButtonSwitch.vue'
import CloseButton from '@components/CloseButton.vue'
import ModalClose from '@components/ModalClose.vue'
import Modal from '@components/Modal.vue'
import HeaderTitleButton from '@components/HeaderTitleButton.vue'
import InputSearch from '@components/InputSearch.vue'
import VueModality from 'vue-modality'
import Notifications from 'vue-notification'
import VueHtmlToPaper from 'vue-html-to-paper'
import * as io from 'socket.io-client'
import VueSocketIO from 'vue-socket.io'
import EncryptDecryptAesCbc256 from '@app/function/encryptDecryptAesCbc256'

// Advanced Use - Hook into Quill's API for Custom Functionality
import { VueEditor, Quill } from "vue2-editor"

/** Custom modules */
import VueSessionStorage from 'vue-sessionstorage'
import VueProgressBar from 'vue-progressbar'
import BootstrapVue, { BVToastPlugin } from 'bootstrap-vue'
import 'bootstrap-datepicker'
import 'bootstrap-datepicker/dist/css/bootstrap-datepicker3.css'
import formatDate from '@app/function/formatdate'

const optionsProgress = {
    color: '#ffb000',
    failedColor: '#874b4b',
    thickness: '5px',
    transition: {
        speed: '0.5s',
        opacity: '0.6s',
        termination: 500
    },
    autoRevert: true,
    inverse: false
}

Vue.use(VueProgressBar, optionsProgress)
Vue.use(SimpleVueValidation)
Vue.use(BootstrapVue)
Vue.use(VueSessionStorage)
Vue.use(MultilineTextFormValidate)
Vue.use(BVToastPlugin)
Vue.use(VueModality)
Vue.use(Notifications)

const WEB_SOCKET_URL = process.env.VUE_APP_WEB_SOCKET_URL || 'http://localhost:3000'
    // const WEB_SOCKET_URL = 'http://gp.monstercode.net/socket.io'

Vue.use(VueSocketIO, io(WEB_SOCKET_URL, {
    transportOptions: {
        polling: {},
        websocket: {}
    }
}), store)

const optionsVueHtmlToPaper = {
    name: '_blank',
    specs: [
        'fullscreen=yes',
        'titlebar=yes',
        'scrollbars=yes'
    ],
    styles: [
        'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
        'https://unpkg.com/kidlat-css/css/kidlat.css'
    ]
}
Vue.use(VueHtmlToPaper, optionsVueHtmlToPaper)

/** End Custom modules */

import bootbox from 'bootbox'
import 'bootstrap/dist/css/bootstrap.css'
import "bootstrap-vue/dist/bootstrap-vue.css"
import 'icheck-bootstrap'
import 'ionicons/dist/css/ionicons.css'
import '@fortawesome/fontawesome-free/css/all.css'
import 'admin-lte/bower_components/font-awesome/css/font-awesome.css'
import 'bootstrap/dist/js/bootstrap.js'
import 'admin-lte/dist/css/AdminLTE.css'
import 'admin-lte/dist/css/skins/_all-skins.min.css'
import 'admin-lte/dist/js/adminlte.js'
import 'icheck'
import 'icheck/skins/all.css'
import 'select2/dist/css/select2.css'
import 'select2-bootstrap-theme/dist/select2-bootstrap.css'
import 'select2/dist/js/select2.full.js'
import 'chart.js/dist/Chart.bundle.js'
import '@assets/css/style.css'
import '@assets/css/skin-template.css'
import "leaflet/dist/leaflet.css"
import LeafLet from "leaflet"

const currencyOptions = {
    globalOptions: {
        currency: 'IDR',
        locale: 'id',
        allowNegative: false
    }
}

const formatOptions = createCurrencyFormat(currencyOptions.globalOptions)
Vue.use(Vuex)
Vue.use(VueRouter)
Vue.use(VueCurrencyInput, currencyOptions)
Vue.use(DataTable)
Vue.use(Select2)
Vue.use(Datepicker)
Vue.use(Buttons)
Vue.use(InputValidate)
Vue.use(TextEditorValidate)
Vue.use(CurrencyInputValidate)
Vue.use(DateInputValidate)
Vue.use(SelectFormValidate)

const filterCurrencyOptions = {
    symbol: formatOptions.prefix,
    thousandsSeparator: formatOptions.groupingSymbol,
    fractionCount: formatOptions.maximumFractionDigits,
    fractionSeparator: formatOptions.decimalSymbol,
    symbolPosition: 'front',
    symbolSpacing: true
}
Vue.use(VueCurrencyFilter, filterCurrencyOptions)
Vue.use(InputCurrency, {
    separator: ',',
    decimalSeparator: '.',
    precision: 2,
    symbol: 'Rp. '
})

const store = new Vuex.Store({
    state: {
        user: {
            id: null,
            username: null,
            full_name: null,
            avatar: null,
        },
        role: {
            id: null,
            name: null,
            permission_detail: null,
        },
        user_image: null,
        data: {},
        title: null,
        breadcrumb_tree: null,
        subtitle: null,
        company: null,
        branch_office: {
            id: null,
            name: null
        },
        months: [
            { value: 1, name: "Januari", short_name: "Jan" },
            { value: 2, name: "Februari", short_name: "Feb" },
            { value: 3, name: "Maret", short_name: "Mar" },
            { value: 4, name: "April", short_name: "Apr" },
            { value: 5, name: "Mei", short_name: "Mei" },
            { value: 6, name: "Juni", short_name: "Jun" },
            { value: 7, name: "Juli", short_name: "Jul" },
            { value: 8, name: "Agustus", short_name: "Agu" },
            { value: 9, name: "September", short_name: "Sep" },
            { value: 10, name: "Oktober", short_name: "Okt" },
            { value: 11, name: "November", short_name: "Nov" },
            { value: 12, name: "Desember", short_name: "Des" }
        ],
        periodQuarterly: [
            { value: 1, name: "January-Maret" },
            { value: 2, name: "April-Juni" },
            { value: 3, name: "Juli-September" },
            { value: 4, name: "Oktober-Desember" }
        ],
        rangeOptions: [
            { name: 'Today', range: [moment(), moment()], start_date: moment().format('YYYY-MM-DD'), end_date: moment().format('YYYY-MM-DD') },
            { name: 'Yesterday', range: [moment().subtract(1, 'days'), moment().subtract(1, 'days')], start_date: moment().subtract(1, 'days').format('YYYY-MM-DD'), end_date: moment().subtract(1, 'days').format('YYYY-MM-DD') },
            { name: 'Last 7 Days', range: [moment().subtract(6, 'days'), moment()], start_date: moment().subtract(6, 'days').format('YYYY-MM-DD'), end_date: moment().format('YYYY-MM-DD') },
            { name: 'Last 30 Days', range: [moment().subtract(29, 'days'), moment()], start_date: moment().subtract(29, 'days').format('YYYY-MM-DD'), end_date: moment().format('YYYY-MM-DD') },
            { name: 'This Month', range: [moment().startOf('month'), moment().endOf('month')], start_date: moment().startOf('month').format('YYYY-MM-DD'), end_date: moment().endOf('month').format('YYYY-MM-DD') },
            { name: 'Last Month', range: [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')], start_date: moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'), end_date: moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD') },
            { name: 'This Year', range: [moment().startOf('year'), moment().endOf('year')], start_date: moment().startOf('year').format('YYYY-MM-DD'), end_date: moment().endOf('year').format('YYYY-MM-DD') },
            { name: 'Last Year', range: [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')], start_date: moment().subtract(1, 'year').startOf('year').format('YYYY-MM-DD'), end_date: moment().subtract(1, 'year').endOf('year').format('YYYY-MM-DD') }

        ],
        transactionStatus: [
            { code: "pending", name: "Pending" },
            { code: "unpaid", name: "Unpaid" },
            { code: "settlement", name: "Settlement" },
            { code: "expired", name: "Expired" },
            { code: "cancel", name: "Cancel" },
        ],
        transactionBookType: [
            { id: 'scheduled', is_check: false, name: 'Jadwal', is_disabled: false },
            { id: 'unscheduled', is_check: false, name: 'Langsung', is_disabled: false }
        ],
        servicePlace: [
            { id: 'base-service', is_check: false, name: 'Kantor', is_disabled: false },
            { id: 'home-service', is_check: false, name: 'Pelanggan (home service)', is_disabled: false }
        ],
        transactionBookTypeMessageInfo: [
            'Tipe order "jadwal", pelanggan order penjadwalan, servis dilakukan dikantor & area pelanggan (home service)',
            'Tipe order "langsung", pelanggan order penjadwalan, tanpa pilih mekanik (mungkin butuh bantuan segera/darurat)'
        ],
        loading: false,
        notifications: [],
    },
    mutations: {
        ADD_NOTIFICATION(state, data) {
            state.notifications.unshift(data)
        },
        SET_NOTIFICATION(state, data) {
            state.notifications = data
        },
        DEC_NOTIFICATION(state, data) {
            // state.notifications = state.notification.filter(function(item) { return item.sp3 != data; });
            var notif = state.notifications.filter(function(item) {
                return item.id != data.id
            })

            state.notifications = notif.sort(function(a, b) {
                return new Date(b.date).getTime() - new Date(a.date).getTime()
            })
        },
    },
    actions: {
        addNotifications({ commit }, data) {
            commit('ADD_NOTIFICATION', data);
        },
        setNotifications({ commit }, data) {
            commit('SET_NOTIFICATION', data);
        },
        decrementNotification({ commit }, sp3) {
            commit('DEC_NOTIFICATION', sp3)
        }
    },

})

const router = new VueRouter(routerConfig)
moment.locale('id')
jQuery.fn.select2.defaults.set('theme', 'bootstrap')
Vue.prototype.currencyOptions = currencyOptions.globalOptions
Vue.config.productionTip = false
Vue.prototype.$ = window.$ = window.jQuery = jQuery
Vue.prototype.moment = window.moment = moment
Vue.prototype.env = window.env = env
Vue.prototype.Pace = window.Pace = Pace
Vue.prototype.bootbox = window.bootbox = bootbox
Vue.prototype.formatDate = window.formatDate = formatDate
Vue.prototype.CryptoJS = window.CryptoJS = CryptoJS
Vue.prototype.L = window.LeafLet = LeafLet
Vue.prototype.EncryptDecryptAesCbc256 = window.EncryptDecryptAesCbc256 = EncryptDecryptAesCbc256

Pace.options = {
    ajax: {
        trackMethods: ['PUT', 'POST', 'PATCH', 'DELETE', 'OPTIONS', 'GET']
    },
    // restartOnPushState: false,
    // document: false
}

Vue.component('box', Box)
Vue.component('close', CloseButton)
Vue.component('close-x', ModalClose)
Vue.component('modal', Modal)
Vue.component('input-number-decimal', InputNumberDecimal)
Vue.component('button-switch', ButtonSwitch)
Vue.component('header-title-button', HeaderTitleButton)
Vue.component('input-search', InputSearch)

window.adminlte = adminlte
Vue.prototype.Api = window.Api = window.Rest = new Api(env, router, ['login', 'forgot.password', 'recover.password'], null)
window.Api.setStore(store)

window.Vue = Vue

if (process.env.NODE_ENV == 'development') {
    require('./assets/css/style.development.css')
} else {
    require('./assets/css/style.production.css')
}

const { console } = window
new Vue({
    router: router,
    store: store,
    render: h => h(App),
    sockets: {
        // 'private-notif': function(response) {
        //     var key = 'id'
        //     if (response && response.length > 0) {
        //         var arrayUniqueByKey = [...new Map(response.map(item => [item[key], item])).values()];
        //         this.$store.dispatch('setNotifications', arrayUniqueByKey)
        //     }
        // },
        'user-connected': function(response) {
            console.log('user-connected', response)
            this.registerUserSocket()
                // var key = 'id'
                // if (response && response.length > 0) {
                //     var arrayUniqueByKey = [...new Map(response.map(item => [item[key], item])).values()];
                //     this.$store.dispatch('setNotifications', arrayUniqueByKey)
                // }
        },
        // 'broadcast-notification': function(response) {
        //     let stringNavigation = JSON.parse(localStorage.getItem('navigations'))
        //     if (stringNavigation) {
        //         // if (stringNavigation.includes("head.sp3") || stringNavigation.includes("head.sp3.form")) {
        //         this.$store.dispatch('addNotifications', response)

        //         let temp = localStorage.getItem('_USER_ROLES')
        //         if (temp) {
        //             let bytes = this.CryptoJS.AES.decrypt(temp, process.env.VUE_APP_KEY_ENCRYPT_DECRYPT)
        //             let data = JSON.parse(bytes.toString(this.CryptoJS.enc.Utf8))
        //             if (data) {
        //                 let user_id = data.user_id
        //                 if (parseInt(user_id) == parseInt(response.notif_to)) {
        //                     if (response.type == 'sp3') {
        //                         this.$root.showNotification('Notifikasi', 'success', 'SP3 ( ' + response.detail.sp3_number + ' ) telah diajukan')
        //                     }
        //                 }
        //             }
        //         }
        //         // } else {
        //         //     this.$store.dispatch('setNotifications', [])
        //         // }
        //     }
        // },
        'broadcast': function(response) {
            console.log('response broadcast', response)
        },
        // 'loadData': function(response) {
        //     this.$store.dispatch('setNotifications', response)
        //         // let stringNavigation = JSON.parse(localStorage.getItem('navigations'))
        //         // if (stringNavigation) {
        //         //     if (stringNavigation.includes("head.sp3") || stringNavigation.includes("head.sp3.form")) {
        //         //         this.$store.dispatch('setNotifications', response)
        //         //     } else {
        //         //         this.$store.dispatch('setNotifications', [])
        //         //     }
        //         // }
        // },
        'user-notification': function(response) {
            console.log('response user-notification', response)
        },
        'broadcast-info-notif': function(response) {
            if (response && response.notif_type) {
                var notif_type = response.notif_type
                switch (notif_type) {
                    case 'change-role':
                        var role_id = response.role_id
                        var data = response.data

                        var temp = localStorage.getItem("_USER_ROLES")
                        if (temp) {
                            var bytes = this.CryptoJS.AES.decrypt(temp, process.env.VUE_APP_KEY_ENCRYPT_DECRYPT)
                            var role = JSON.parse(bytes.toString(this.CryptoJS.enc.Utf8))
                            if (role) {
                                var user_role_id = role.id
                                if (user_role_id == role_id) {
                                    if (data.permission_detail) {
                                        this.$root.showNotification('Notification', 'info', 'Access permission have been updated, sidebar menu will be refreshed.')

                                        role.permission_detail = data.permission_detail
                                        var nav = data.permission_detail.navigations
                                        var navigations = this.CryptoJS.AES.encrypt(JSON.stringify(nav), process.env.VUE_APP_KEY_ENCRYPT_DECRYPT).toString()
                                        var permission = this.CryptoJS.AES.encrypt(JSON.stringify(data.permission_detail.permission), process.env.VUE_APP_KEY_ENCRYPT_DECRYPT).toString()
                                        var roles = this.CryptoJS.AES.encrypt(JSON.stringify(role), process.env.VUE_APP_KEY_ENCRYPT_DECRYPT).toString()

                                        localStorage.setItem('navigations', navigations)
                                        localStorage.setItem('_USER_PERMISSION', permission)
                                        localStorage.setItem('_USER_ROLES', roles)
                                        this.$root.$emit('refresh_sidebar')

                                        var active_page = this.$root._route.name
                                        this.checkingAccessPage(active_page, false)
                                    }

                                }
                            }
                        }
                        break;
                    case 'non-active-user':
                        var user_id = response.user_id
                        var temp = localStorage.getItem("_USER_ME")
                        if (temp && user_id) {
                            var bytes = this.CryptoJS.AES.decrypt(temp, process.env.VUE_APP_KEY_ENCRYPT_DECRYPT)
                            var user = JSON.parse(bytes.toString(this.CryptoJS.enc.Utf8))
                            if (user && user.id && user.id == user_id) {
                                this.showNotification('Notification', 'info', 'Your user access has been deactivated, you will be logged out of the system')
                                setTimeout(() => this.Api.logout(), 5000)
                            }

                        }
                        break;
                    case 'change-password':
                        var user_id = response.user_id
                        var temp = localStorage.getItem("_USER_ME")
                        if (temp && user_id) {
                            var bytes = this.CryptoJS.AES.decrypt(temp, process.env.VUE_APP_KEY_ENCRYPT_DECRYPT)
                            var user = JSON.parse(bytes.toString(this.CryptoJS.enc.Utf8))
                            if (user && user.id && user.id == user_id) {
                                this.showNotification('Notification', 'info', 'Your password has been changed, you will be logged out of the system')
                                setTimeout(() => this.Api.logout(), 5000)
                            }
                        }
                        break;
                    default:
                        break;
                }
            }

        }
    },
    mounted() {
        window.$root = this.$root

        var temp = localStorage.getItem('_USER_ROLES')
        if (temp) {
            var bytes = this.CryptoJS.AES.decrypt(temp, process.env.VUE_APP_KEY_ENCRYPT_DECRYPT)
            var role = JSON.parse(bytes.toString(this.CryptoJS.enc.Utf8))
            if (role && role.user_id) {
                var secretKey = process.env.VUE_APP_KEY_ENCRYPT_DECRYPT;
                var x_account = EncryptDecryptAesCbc256.encryptAesCbc256(secretKey, role.user_id.toString())
                document.cookie = 'X-account=' + x_account + '; path=/';

                this.getUserMe()
            }
        }



        this.$socket.emit('private-notif')
            // if (!this.$store.state.branch_office.id) {
            //     try {
            //         this.$store.state.branch_office = JSON.parse(localStorage.getItem('_PRJ_SELECT'))
            //     } catch (e) {}
            // }

        $(document).ready(function() {
            $(document).on('keydown', '.alpha-numeric-other-char', function($event) {
                var number = [189, 32, 13] // [-_, space]
                if ($event) {
                    let keypressed = $event.which
                        // console.log('disini', keypressed)
                    if ($event.shiftKey && (keypressed >= 48 && keypressed <= 57)) {
                        var specialCharAllow = [50, 57, 48, 49, 52, 53, 55] // @()
                        if (specialCharAllow.indexOf(keypressed) == -1) {
                            $event.preventDefault()
                            return false
                        }
                    }
                    if ((keypressed >= 65 && keypressed <= 90) || (keypressed >= 48 && keypressed <= 57) || (keypressed >= 96 && keypressed <= 105)) {
                        // Digits and numbers are accepted
                        // delay(function() { executeCall() },1800);
                    } else if (keypressed === 8 || keypressed === 27 || keypressed === 46 || (keypressed >= 35 && keypressed <= 40)) {
                        // Accepted but the event it's not propagated because those are the special chars
                    } else if (number.indexOf(keypressed) >= 0) {

                    } else if (keypressed == 188 || keypressed == 190 || keypressed == 191) { // 188 koma 191 /
                        if (($event.shiftKey && keypressed == 188) ||
                            ($event.shiftKey && keypressed == 190)) {
                            $event.preventDefault()
                            return false
                        }

                    } else {
                        // We don't allow anything else
                        $event.preventDefault()
                        return false
                    }
                }
            })
        })
    },
    methods: {
        registerUserSocket() {
            if (this.$root.$store.state.user && this.$root.$store.state.user.id) {
                this.$socket.emit('register', { user_id: this.$root.$store.state.user.id })
            }
        },
        async getUserMe() {
            Rest.get('/user/me/detail')
                .then(async(res) => {
                    if (res && res.data) {
                        var me = {
                            id: res.data.id,
                            username: res.data.username,
                            full_name: res.data.full_name,
                            avatar: res.data.avatar
                        }

                        this.$root.$store.state.user.id = res.data.id
                        this.$root.$store.state.user.username = res.data.username
                        this.$root.$store.state.user.full_name = res.data.full_name
                        this.$root.$store.state.user.avatar = res.data.avatar

                        if (res.data.roles && res.data.roles.length > 0) {
                            var roleRes = res.data.roles[0]
                            var detail = roleRes.permission_detail
                            if (detail && typeof detail == 'string') {
                                detail = JSON.parse(detail)
                            }

                            var role = {
                                permission_detail: detail,
                                id: roleRes.id,
                                name: roleRes.name,
                                user_id: res.data.id,
                            }

                            this.$root.$store.state.role.id = roleRes.id
                            this.$root.$store.state.role.name = roleRes.name
                            this.$root.$store.state.role.user_id = res.data.id
                            this.$root.$store.state.role.detail = detail

                            var navigationsData = []
                            var permissionData = {}
                            if (detail && detail.navigations) {
                                navigationsData = detail.navigations
                            }

                            if (detail && detail.permission) {
                                permissionData = detail.permission
                            }

                            var navigations = this.CryptoJS.AES.encrypt(JSON.stringify(navigationsData), process.env.VUE_APP_KEY_ENCRYPT_DECRYPT).toString()
                            var permission = this.CryptoJS.AES.encrypt(JSON.stringify(permissionData), process.env.VUE_APP_KEY_ENCRYPT_DECRYPT).toString()
                            var role = this.CryptoJS.AES.encrypt(JSON.stringify(role), process.env.VUE_APP_KEY_ENCRYPT_DECRYPT).toString()
                            var user = this.CryptoJS.AES.encrypt(JSON.stringify(me), process.env.VUE_APP_KEY_ENCRYPT_DECRYPT).toString()
                            localStorage.setItem('navigations', navigations)
                            localStorage.setItem('_USER_PERMISSION', permission)
                            localStorage.setItem('_USER_ROLES', role)
                            localStorage.setItem('_USER_ME', user)

                            this.$socket.disconnect();
                            this.$socket.connect();
                            this.$socket.emit('register', { user_id: res.data.id })


                            this.$root.$emit('refresh_sidebar')

                            var active_page = this.$root._route.name
                            await this.checkingAccessPage(active_page)

                            // this.$router.replace({ name: 'dashboard' }
                        }


                    }
                })
                .catch((err) => {
                    if (err && err.response && err.response.status && err.response.status == 403 && err.response.data && err.response.data.status && err.response.data.status == 403) {
                        this.showNotification('Notification', 'success', 'Your user access has been disabled, you will be logged out of the CMS system ')
                        setTimeout(() => this.Api.logout(), 5000)
                    }
                })
        },
        async sendUserLogActivity(params) {
            if (params.activity) {
                params.activity = this.capitalizeFirstLetter(params.activity)
            }

            Rest.post('/user-activity', params)
        },
        showNotification(title, type, text, duration) {
            this.$notify({
                group: 'notification',
                title: title,
                type: type,
                text: text,
                duration: duration,
                closeOnClick: true
            });
        },
        async checkingAccessPage(pageName, isLoadPage = true) {
            if (isLoadPage) {
                $("body").css("overflow-y", "auto")
            }

            let temp = localStorage.getItem('_USER_ROLES')
            let role_name = null
            if (temp) {
                let bytes = this.CryptoJS.AES.decrypt(temp, process.env.VUE_APP_KEY_ENCRYPT_DECRYPT)
                let data = JSON.parse(bytes.toString(this.CryptoJS.enc.Utf8))
                if (data) {
                    role_name = data.name
                }
            }

            let userNavigation = []
            var navigationLocalStorage = localStorage.getItem("navigations")
            if (navigationLocalStorage) {
                let navigationByte = this.CryptoJS.AES.decrypt(navigationLocalStorage, process.env.VUE_APP_KEY_ENCRYPT_DECRYPT)
                let dataNavigation = JSON.parse(navigationByte.toString(this.CryptoJS.enc.Utf8))
                if (dataNavigation) {
                    userNavigation = dataNavigation
                }
            }

            let result = userNavigation.find(data => data == pageName)
            if (!result && role_name != "ROLE_SUPERUSER") {
                this.$root.showNotification('Error', 'warn', 'You do not have access to that page')
                if (userNavigation.indexOf('dashboard') > 0) {
                    this.$root.$router.push({ name: 'dashboard' })
                    if (!isLoadPage) {
                        $("body").css("overflow-y", "auto")
                        $("body").removeClass("modal-open-custom")
                    }
                } else {
                    this.Api.logout()
                }

            }
        },
        async checkingButtonsPage(alias, permissionType) {
            $("body").css("overflow-y", "auto")
            let validate = false
            let role_name = null
            let role_id = null
            let temp = localStorage.getItem('_USER_ROLES')
            if (temp) {
                let bytes = this.CryptoJS.AES.decrypt(temp, process.env.VUE_APP_KEY_ENCRYPT_DECRYPT)
                let data = JSON.parse(bytes.toString(this.CryptoJS.enc.Utf8))
                if (data) {
                    role_name = data.name
                    role_id = data.id
                    if (data.permission_detail) {
                        data.permission_detail.permission.forEach(async(element) => {
                            if (alias) {
                                if (element.alias.toLowerCase() == alias.toLowerCase()) {
                                    if (element.detail.checkbox[permissionType].value) {
                                        validate = true
                                    }
                                }
                            }
                        })
                    }

                }
            }

            if (role_name && role_name == "ROLE_SUPERUSER") {
                validate = true
            }

            return {
                role_id: role_id,
                role_name: role_name,
                validate: validate,
            }
        },
        validateNumberOnly($event) {
            if ($event) {
                if ($.inArray($event.keyCode, [46, 8, 9, 27, 13, 110, 190]) !== -1 ||
                    // Allow: Ctrl+A, Command+A
                    ($event.keyCode === 65 && ($event.ctrlKey === true || $event.metaKey === true)) ||
                    // Allow: home, end, left, right, down, up
                    ($event.keyCode >= 35 && $event.keyCode <= 40)) {
                    // let it happen, don't do anything
                    return;
                }
                // Ensure that it is a number and stop the keypress
                if (($event.shiftKey || ($event.keyCode < 48 || $event.keyCode > 57)) && ($event.keyCode < 96 || $event.keyCode > 105)) {
                    $event.preventDefault();
                }
            }
        },
        validateDecimal($event) {
            let pattern = /^\d*(\.\d{0,1})?$/
            if ($.inArray($event.keyCode, [46, 8, 9, 27, 13, 110, 190]) !== -1 || pattern.test($event.target.value)) {
                return
            } else {
                $event.preventDefault()
            }
        },
        validateAlphaNumericOnly($event) {
            if ($event) {
                let keypressed = $event.which
                    // console.log('disini', keypressed)
                    // alert('keypress', keypressed)
                if ($event.shiftKey && (keypressed >= 48 && keypressed <= 57)) {
                    $event.preventDefault()
                    return false
                }
                if ((keypressed >= 65 && keypressed <= 90) || (keypressed >= 48 && keypressed <= 57) || (keypressed >= 96 && keypressed <= 105)) {
                    // Digits and numbers are accepted
                    // delay(function() { executeCall() },1800);
                } else if (keypressed === 8 || keypressed === 27 || keypressed === 46 || (keypressed >= 35 && keypressed <= 40)) {
                    // Accepted but the event it's not propagated because those are the special chars
                } else {
                    // We don't allow anything else
                    $event.preventDefault()
                    return false
                }
            }
        },
        regexAlphaNumeric(string) {
            return string.replace(/[^a-zA-Z0-9 ]/g, "")
        },
        regexAlphaNumericOtherChar(string) {
            // return string.replace(/[<>;'"`~!@#$%^&*()?]/g, "")
            return string.replace(/[:;|"`'~#^*+={}[?]/g, "").replace(/]/g, "")
        },
        regexObjectData(object) {
            return this.recursiveRegex(object)
        },
        recursiveRegex(object) {
            try {
                var keys = Object.keys(object)
                if (keys.length > 0) {
                    for (var i = 0; i < keys.length; i++) {
                        if (typeof object[keys[i]] == 'string') {
                            var notRegex = ['password', 'password_old', 'password_new']
                            if (notRegex.indexOf(object[keys[i]]) == -1) {
                                if (null != object[keys[i]]) {
                                    object[keys[i]] = this.regexAlphaNumericOtherChar(object[keys[i]])
                                }
                            }
                        } else if (typeof object[keys[i]] == 'object') {
                            if (Array.isArray(object[keys[i]])) {
                                if (object[keys[i]].length > 0) {
                                    if (typeof object[keys[i]][0] == 'string') {
                                        for (var a = 0; a < object[keys[i]].length; a++) {
                                            if (null != object[keys[i]][a]) {
                                                object[keys[i]][a] = this.regexAlphaNumericOtherChar(object[keys[i]][a])
                                            }
                                        }
                                    } else if (typeof object[keys[i]][0] == 'object') {
                                        for (var b = 0; b < object[keys[i]].length; b++) {
                                            this.recursiveRegex(object[keys[i]][b])
                                        }
                                    }
                                }
                            } else {
                                this.recursiveRegex(object[keys[i]])
                            }
                        }
                    }
                }
            } catch {}
            return object
        },
        validationInputEmail(email) {
            return String(email)
                .toLowerCase()
                .match(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                );
        },
        validationInputPassword(password) {
            // return /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*.-_=+?#&])[A-Za-z\d@$!%*.-_=+?#&]{8,50}$/.test(password)
            return /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[@$!%*?#.,_=<>;:{}'"`~&+-/|])[A-Za-z\d@$!%*?#.,_=<>;:{}'"`~&+-/|]{8,50}$/.test(password);
        },
        validationInputUrl(urlString) {
            var urlPattern = new RegExp('^(https?:\\/\\/)?' + // validate protocol
                '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
                '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
                '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
                '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
                '(\\#[-a-z\\d_]*)?$', 'i'); // validate fragment locator
            return !!urlPattern.test(urlString);
        },
        capitalizeFirstLetter(str) {
            if (str && str.length > 0) {
                return str.charAt(0).toUpperCase() + str.slice(1)
            }

            return str
        }
    }
}).$mount('#app')