
const {console} = window
export const timestampToDate = (props) => {
    return moment(props).format('DD-MM-YYYY')
    // const newDate = new Date(props)
    // console.dir(newDate)
    // const dates = newDate.getDate() > 9 ? newDate.getDate() : '0' + newDate.getDate()
    // const months = newDate.getMonth() > 8 ? (newDate.getMonth()+1) : ('0' + (newDate.getMonth() + 1))
    // return dates + "-" + months + "-" + newDate.getFullYear()
}
