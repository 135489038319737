<template>
    <div>
        <datatable 
            :config="config" 
            class="superuser-datatable"
            :settingFilters="settingFilters"
            :defaultFilter="defaultFilter" />
    </div>    
</template>

<script>
const {console} = window
export default {
    components: {
        
    },
    data() {
        return {
            config: {},
            settingFilters: '',
            defaultFilter: null,
            filter_activity_type: '',
            filter_activity_type_temp: '',
            filter_date: {
                start_date: moment().startOf('month').format('YYYY-MM-DD'), 
                end_date: moment().endOf('month').format('YYYY-MM-DD')
            },
            filter_date_temp: null,
            rangeOptions: this.$root.$store.state.rangeOptions,
            activity_type: [
                {id: 'Buy a ticket package', name: 'Buy a ticket package'},
                {id: 'Scan a booking code', name: 'Scan a booking code'},
                {id: 'Ticket printed physically', name: 'Ticket printed physically'},
                {id: 'Ticket send to email', name: 'Ticket send to email'},
                {id: 'Ticket send to whatsapp', name: 'Ticket send to whatsapp'},
                {id: 'Ticket send to telegram', name: 'Ticket send to telegram'},
                {id: 'Scan a ticket', name: 'Scan a ticket'},
                
            ],
            interval: null
        }
    },
    async mounted() {
        this.$root.checkingAccessPage(this.$root._route.name)
        this.destroyed()

        this.setupSettingFilter()

        let self = this 
        $(document).ready(function() {
            $('.select-filter-activity-type').on('select2:select',  function(e) {
                let data = e.params.data
                self.filter_activity_type = data.id
                self.filter_activity_type_temp = data.id
            })

            $(document).on('change', '.select-filter-activity-type', function() {
                let value = $(this).val()
                self.filter_activity_type = value
                self.filter_activity_type_temp = value
            })

            self.loadDateRangePicker()

        })

        setTimeout(self.tiggerUpdateDropDown, 1500);
        
    },
    methods: {
        async init() {
            let defaultFilter = null 

            if(this.filter_activity_type) {
                if(defaultFilter) {
                    defaultFilter.push(["AND"])
                    defaultFilter.push(["activity_type", "=", this.filter_activity_type])
                }else {
                    defaultFilter = [["activity_type", "=", this.filter_activity_type]]
                }
            }

            if(this.filter_date && this.filter_date.start_date && this.filter_date.end_date) {
                let start = this.filter_date.start_date + ' 00:00:00'
                let end = this.filter_date.end_date + ' 23:59:59'
                if(defaultFilter) {
                    defaultFilter.push(["AND"])
                    defaultFilter.push(["activity_date", ">=", start])
                    defaultFilter.push(["AND"])
                    defaultFilter.push(["activity_date",  "<=", end])
                }else {
                    defaultFilter = [["activity_date", ">=", start], ["AND"], ["activity_date",  "<=", end]]
                }
            }

            this.defaultFilter = defaultFilter
            this.config = {
                columns: [{
                    title: 'Visitor',
                    data: 'customer_name'
                }, {
                    title: 'Email',
                    data: 'email'
                },{
                    title: 'Timestamp',
                    data: 'activity_date'
                }, {
                    title: 'Action',
                    data: 'activity'
                }],
                responsive: false,
                scrollX: true,
                url: Api.base+ '/visitor-activity',
                searchPlaceholder: 'Search an visitor activity ...',
            }
        },
        async selectDropdown() {
            $('.select2').select2()

            $('.select-filter-activity-type').select2()
            $('.select-filter-activity-type').val([this.filter_activity_type]).trigger('change')

            this.loadDateRangePicker()
        },
        async setupSettingFilter() {
            let self = this

            let optionAction = ``
            this.activity_type.forEach( (item) => {
                if(item.id && item.name) {
                    optionAction += `<option value="${item.id}">${item.name}</option>`
                }
            })

            this.settingFilters = `<div>
                <span> Filter : &nbsp;  </span>
                <span id="reportrange" class="form-control" style="background: #fff; cursor: pointer; padding: 5px 10px; border: 1px solid #ccc; width: 290px">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M8 7V3M16 7V3M7 11H17M5 21H19C20.1046 21 21 20.1046 21 19V7C21 5.89543 20.1046 5 19 5H5C3.89543 5 3 5.89543 3 7V19C3 20.1046 3.89543 21 5 21Z" stroke="#404040" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    &nbsp;
                    <span class="date-range-text-info" style="position: absolute;margin-top: 2px;"></span> 
                </span>
                <span> &nbsp;  </span>
                <select type="text" class="form-control select2 select-filter-activity-type" >
                    <option value="">All Activity Type</option>
                    ${optionAction}
                </select>
            </div>`


            this.init()

            setTimeout(self.selectDropdown, 1000);

        },
        async tiggerUpdateDropDown() {
            let is_activity = false 
            

            try{
                let data = $(".select-filter-activity-type").select2('data')
                if(data) {
                   
                    if(this.filter_activity_type != this.filter_activity_type_temp) {
                        $('.select-filter-activity-temp').val([this.filter_activity_type]).trigger('change')
                        this.filter_activity_type_temp = this.filter_activity_type
                        is_activity = true 
                    }else if (this.filter_activity_type == this.filter_activity_type_temp){
                        let val = $('.select-filter-activity-type').val()
                        if (val != this.filter_activity_type) {
                            $('.select-filter-activity-type').val([this.filter_activity_type]).trigger('change')
                            this.filter_activity_type_temp = this.filter_activity_type
                        }
                        is_activity = true 
                    }
                }
                
            }catch{
                $('.select-filter-activity-type').select2()
                console.clear()
            }

            let tempDate = $('#reportrange span.date-range-text-info').text()
            if(!tempDate) {
                this.loadDateRangePicker()
            }

            if (is_activity) {
                this.destroyed()
            }
            
        },
        async loadDateRangePicker() {
            let self = this 
            let range_option = {}
            this.rangeOptions.forEach( (item) => {
                if(item && item.name && item.range) {
                    range_option[item.name] = item.range
                }
            })
            $(function() {

                var start = moment(self.filter_date.start_date);
                var end = moment(self.filter_date.end_date);

                var daysInYear = 366

                function cb(start, end) {
                    moment.locale('en')
                    var tempDate = start.format('D MMMM YYYY') + ' - ' + end.format('D MMMM YYYY')
                    self.rangeOptions.forEach( (item) => {
                        if(item && item.name && item.start_date && item.end_date) {
                            if(item.start_date == start.format('YYYY-MM-DD') && item.end_date == end.format('YYYY-MM-DD')) {
                                tempDate = item.name
                            }
                        }
                    })
                    var diff = end.diff(start, 'days') 
                    if(diff > daysInYear) {
                        self.$root.showNotification('Failed', 'error', 'Maximum search range is 1 year.')
                    }else {
                        self.filter_date_temp = tempDate

                        var start_date = start.format('YYYY-MM-DD')
                        var end_date = end.format('YYYY-MM-DD')

                        self.filter_date.start_date = start_date
                        self.filter_date.end_date = end_date
                        $('#reportrange span.date-range-text-info').html(tempDate);
                    }
                   
                }

                $('#reportrange').daterangepicker({
                    opens: 'right',
                    startDate: start,
                    endDate: end,
                    autoApply: true,
                    alwaysShowCalendars: true,
                    showCustomRangeLabel: false,
                    ranges: range_option,
                    locale: {
                        format: "YYYY-MM-DD",
                        separator: " - ",
                        applyLabel: "Apply",
                        cancelLabel: "Cancel",
                        fromLabel: "From",
                        toLabel: "To",
                        customRangeLabel: "Custom",
                        weekLabel: "W",
                        daysOfWeek: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
                        monthNames:  ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Agu', 'Sep', 'Oct', 'Nov', 'Dec'],
                        firstDay: 1
                    },
                }, cb);

                cb(start, end);

            });
        },
        destroyed() {
            clearInterval(this.interval)
        },
        async refreshLoadData() {
            this.filter_role = ''
            this.filter_role_temp = ''
            this.filter_activity_type = ''
            this.filter_activity_type_temp = ''
            this.filter_date = {
                start_date: moment().startOf('month').format('YYYY-MM-DD'), 
                end_date: moment().endOf('month').format('YYYY-MM-DD')
            }
            this.filter_date_temp = null

            this.setupSettingFilter()

        }
    },
    computed: {

    },
    watch: {
        filter_activity_type: {
            handler(val, oldVal) {
                this.setupSettingFilter()
                this.destroyed()
                this.interval = setInterval(this.tiggerUpdateDropDown, 200)
            }
        },
        filter_date_temp: {
            handler() {
                this.setupSettingFilter()
                this.destroyed()
                this.interval = setInterval(this.tiggerUpdateDropDown, 200)
            }
        },
        interval: {
            handler(val, oldVal) {
                console.log('change interval', {
                    val: val,
                    old: oldVal
                })
            }
        },
        unix_time: {
            handler() {
                this.refreshLoadData()
            }
        }
    }
}
</script>

<style scoped>
</style>