<template>
    <aside class="main-sidebar">
        <section class="sidebar">
            <div class="user-panel">
                <!-- <div class="pull-left image">
                    <img src="@assets/unknown.jpeg" v-if="!user_image" class="img-circle" alt="User Image">
                    <img :src="user_image" v-if="user_image" class="img-circle" alt="User Image">
                </div> -->
                <!-- <div class="pull-left info">
                    <p>
                        <router-link :to="{name: 'dashboard'}" class="ellipsis text-lowercase">{{ user.name }}</router-link>
                    </p>
                    <small>
                        <i class="fa fa-circle text-success"></i>
                        Online
                    </small>
                </div> -->
            </div>
            <ul class="sidebar-menu" data-widget="tree">
                <nav-item v-for="(item, index) in items" :key="index" :item="item" :routeName="routeName" :navigation="navigations"></nav-item>
            </ul>
        </section>
        <div class="footer-cms"><p >&copy; {{ year }} Nuanu</p></div>
    </aside>
</template>

<script>
import NavItem from './NavItem.vue'
import SidebarConfig from '@config/sidebar.json'
import dataMenu from '@config/datamenu.json'
const {console} = window


export default {
    components: {
        NavItem
    },
    data() {
        return {
            routeName: 'undefined',
            layout: 'fixed',
            user: {
              name: null
            },
            role: {
              id: null,
              name: null,
              company_id: null,
            },
            user_image: null,
            items: [{
              notChild: []
            }],
            pushMenu: null,
            navigations : [],
            year: new Date().getFullYear(),
        }
    },
    async mounted() {
        if(this.$root.$store.state.user && this.$root.$store.state.user.company_id) {
          $('body').attr('class', 'skin-app-company sidebar-mini')
        }else {
          $('body').attr('class', 'skin-app sidebar-mini')
        }
        // $('body').layout()
        this.routeName = this.$route.name
        this.setTitle(this.$route)
        this.$router.afterHooks = []
        // eslint-disable-next-line
        this.$router.afterEach((nw, old) => {
            $('.modal-backdrop').remove()
            $('body').removeClass('modal-open').css({
                paddingRight: ''
            })
            // if (this.pushMenu) {
            //     this.pushMenu.toggle()
            // }
            this.setTitle(nw)
            this.routeName = nw.name
        })

        let company_id = null
        let role_name = null
        let temp = localStorage.getItem('_USER_ROLES')
        if (temp) {
            let bytes = this.CryptoJS.AES.decrypt(temp, process.env.VUE_APP_KEY_ENCRYPT_DECRYPT)
            let data = JSON.parse(bytes.toString(this.CryptoJS.enc.Utf8))
            if (data) {
              company_id = data.company_id
              role_name = data.name
            }
        }

        var sidebarList = JSON.parse(JSON.stringify(SidebarConfig.admin))
        if(company_id) {
          sidebarList = JSON.parse(JSON.stringify(SidebarConfig.company))
        }

        if(role_name && role_name == 'COMPANY_ROOT') {
          sidebarList = JSON.parse(JSON.stringify(SidebarConfig.company))
        }

        this.items = sidebarList

        if (this.env.environment == 'development') {
            this.items = SidebarConfig.developer.concat(this.items)
        }

        var intv = setInterval(() => {
            var layout = $('body').data('lte.layout')
            if (layout) {
                try {
                    this.pushMenu = $('[data-toggle="push-menu"]').data('lte.pushmenu')
                    this.pushMenu.expandOnHover()
                    layout.activate()
                } catch (e) {}
                clearInterval(intv)
            }
        }, 500)
        $(window).trigger('load')
        $(window).trigger('resize')
        $(document).on('hidden.bs.modal', '.modal', () => {
            $('.modal-backdrop').remove()
            $('body').removeClass('modal-open').css({
                paddingRight: ''
            })
        })

        this.getData
        this.$root.$on('refresh_sidebar', async (data) => {
          this.items = JSON.parse(JSON.stringify(SidebarConfig.admin))
            if(company_id) {
            this.items = JSON.parse(JSON.stringify(SidebarConfig.company))
          }

          if(role_name && role_name == 'COMPANY_ROOT') {
            this.items = JSON.parse(JSON.stringify(SidebarConfig.company))
          }

          if (this.env.environment == 'development') {
              this.items = SidebarConfig.developer.concat(this.items)
          }
          this.getData

        })

        this.getSingleData()

        this.$root.$on('send_full_name', async(data) => {
          var name = data || ""
          var full_name = name.split(" ")[0]
          if(full_name.length > 15) {
            full_name = full_name.substring(0, 14)+"..."
          }
          this.user.full_name = full_name
        })
    },

    computed: {
      getData: function(){
        let self = this
          let origin = window.location.origin

          let userNavigation = []
          var navigationLocalStorage = localStorage.getItem("navigations")
          if(navigationLocalStorage) {
            let navigationByte = this.CryptoJS.AES.decrypt(navigationLocalStorage, process.env.VUE_APP_KEY_ENCRYPT_DECRYPT)
            let dataNavigation = JSON.parse(navigationByte.toString(this.CryptoJS.enc.Utf8))
            if(dataNavigation) {
              userNavigation = eval(dataNavigation)
            }
          }

          let temp = localStorage.getItem('_USER_ROLES')
          let role_name = null
          let role_id = null;
          let company_id = null 
          if (temp) {
              let bytes = this.CryptoJS.AES.decrypt(temp, process.env.VUE_APP_KEY_ENCRYPT_DECRYPT)
              let data = JSON.parse(bytes.toString(this.CryptoJS.enc.Utf8))
              if (data) {
                  role_name = data.name
                  role_id = data.id 
                  company_id = data.company_id
              }
          }

          if(role_name == "ROLE_SUPERUSER") {
            let superUserMenu = dataMenu.admin
            var loopCheckbox = ["approval", "create", "delete", "print", "read", "update"]
            var superUserNav = []
            superUserMenu.forEach( (element) => {
              if(element && element.detail && element.detail.checkbox) {
                for(var i = 0; i < loopCheckbox.length; i++) {
                  var access = loopCheckbox[i]
                  if(element.detail.checkbox[access] && element.detail.checkbox[access]) {
                    if(element.detail.checkbox[access].route && element.detail.checkbox[access].route.name) {
                      superUserNav.push(element.detail.checkbox[access].route.name)
                    }

                    if(element.detail.checkbox[access].selected_on && element.detail.checkbox[access].selected_on.length > 0) {
                      superUserNav = superUserNav.concat(element.detail.checkbox[access].selected_on)
                    }

                  }
                }
              }
            })

            if(superUserNav) {
              userNavigation = [ ...new Set(superUserNav) ]
              self.navigations = [ ...new Set(superUserNav) ]
            }

          }
          
          self.navigations = userNavigation
          this.items.forEach( (element, index) => {
            if(element.title == 'Docs'){
              element.notChild = []
              if(element.items){
                for(let i = 0; i < element.items.length; i++){
                  element.items[i].notChild = []
                  if(element.items[i].items){
                    for(let b = 0; b < element.items[i].items.length; b++){
                      element.items[i].items[b].notChild = []
                    }
                  }
                }
              }
            }else{
              element.notChild = []
              element.totalSelected = [];
              element.refresh = false
              element.is_active = false
              element.is_allow_show = false
              element.childs = []
              element.on_active = false
              if(element.route && this.$route.name == element.route.name){
                element.is_active = true
              }
              if(element.selected_on && element.selected_on.indexOf(this.$route.name) >= 0){
                element.is_active = true
              }

              if(element.route && (userNavigation.indexOf(element.route.name) >= 0 || element.skip_roles == true)){
                element.is_allow_show = true
              }

              if(element.route){
                element.childs.push(element.route.name)
                if(element.childs.indexOf(this.$route.name) >= 0){
                  element.on_active = true
                }
              }
              if(element.selected_on){
                element.childs.concat(element.selected_on)
                if(element.childs.indexOf(this.$route.name) >= 0){
                  element.on_active = true
                }
              }

              if(element.route){
                if(element.route.name){
                  element.href = origin+'#'+this.$router.resolve({name: element.route.name}).route.path
                  // if(arrayFullRefresh.indexOf(element.route.name) >= 0){
                  //   element.refresh = true
                  // }
                }
              }

              if(element.items){
                for(let i = 0 ; i < element.items.length; i ++){
                  element.items[i].notChild = []
                  element.items[i].totalSelected = []
                  element.items[i].refresh = false
                  element.items[i].is_active = false
                  element.items[i].is_allow_show = false
                  element.items[i].childs = []
                  element.on_active = false

                  if(element.items[i].selected_on){
                    if(element.items[i].selected_on && element.items[i].selected_on.indexOf(this.$route.name) >= 0){
                      element.items[i].is_active = true
                    }

                    if(element.items[i].selected_on){
                      element.items[i].childs.concat(element.items[i].selected_on)
                      if(element.items[i].childs.indexOf(this.$route.name) >= 0){
                        element.items[i].on_active = true
                      }
                    }
                    for(let a = 0; a < element.items[i].selected_on.length; a++){
                    element.totalSelected.push(element.items[i].selected_on[a])
                      if(userNavigation.indexOf( element.items[i].selected_on[a]) == -1){
                        element.notChild.push(element.items[i].selected_on[a])
                      }
                    }
                  }
                  if(element.items[i].route){
                    if(element.items[i].route.name){
                      if(element.items[i].route &&  this.$route.name == element.items[i].route.name){
                        element.items[i].is_active = true
                      }

                      if(element.items[i].route && (userNavigation.indexOf(element.items[i].route.name) >= 0 || element.items[i].skip_roles == true)){
                        element.items[i].is_allow_show = true
                      }

                      if(element.items[i].route){
                        element.items[i].childs.push(element.items[i].route.name)
                        if(element.items[i].childs.indexOf(this.$route.name) >= 0){
                          element.items[i].on_active = true
                        }
                      }
                      element.items[i].href = origin+'#'+this.$router.resolve({name: element.items[i].route.name}).route.path
                      // if(arrayFullRefresh.indexOf(element.items[i].route.name) >= 0){
                      //   element.items[i].refresh = true
                      // }
                      element.totalSelected.push(element.items[i].route.name)
                      if(userNavigation.indexOf( element.items[i].route.name) == -1){
                        element.notChild.push(element.items[i].route.name)
                      }
                    }
                  }

                  if(element.items[i].items){
                    for(let b = 0; b < element.items[i].items.length; b++){
                      element.items[i].items[b].notChild = []
                      element.items[i].items[b].totalSelected = []
                      element.items[i].items[b].refresh = false
                      element.items[i].items[b].is_active = false
                      element.items[i].items[b].is_allow_show = false
                      element.items[i].items[b].childs = []
                      element.items[i].items[b].on_active = false

                      if(element.items[i].items[b].selected_on){
                        if(element.items[i].items[b].selected_on && element.items[i].items[b].selected_on.indexOf(this.$route.name) >= 0){
                          element.items[i].items[b].is_active = true
                        }

                        if(element.items[i].items[b].selected_on){
                          element.items[i].items[b].childs.concat(element.items[i].items[b].selected_on)
                          if(element.items[i].items[b].childs.indexOf(this.$route.name) >= 0){
                            element.items[i].items[b].on_active = true
                          }
                        }

                        for(let c = 0; c < element.items[i].items[b].selected_on.length; c++){
                          element.totalSelected.push(element.items[i].items[b].selected_on[c])
                          element.items[i].totalSelected.push(element.items[i].items[b].selected_on[c])
                          if(userNavigation.indexOf(element.items[i].items[b].selected_on[c]) == -1){
                            element.notChild.push(element.items[i].items[b].selected_on[c])
                            element.items[i].notChild.push(element.items[i].items[b].selected_on[c])
                          }
                        }
                      }

                      if(element.items[i].items[b].route){
                        if(element.items[i].items[b].route.name){
                          if(element.items[i].items[b].route && this.$route.name == element.items[i].items[b].route.name){
                            element.items[i].items[b].is_active = true
                          }
                          if(element.items[i].items[b].route && (userNavigation.indexOf(element.items[i].items[b].route.name) >= 0 || element.items[i].items[b].skip_roles == true)){
                            element.items[i].items[b].is_allow_show = true
                          }
                          if(element.items[i].items[b].route){
                            element.items[i].items[b].childs.push(element.items[i].items[b].route.name)
                            if(element.items[i].items[b].childs.indexOf(this.$route.name) >= 0){
                              element.items[i].items[b].on_active = true
                            }
                          }
                          element.items[i].items[b].href = origin+'#'+this.$router.resolve({name: element.items[i].items[b].route.name}).route.path
                          // if(arrayFullRefresh.indexOf(element.items[i].items[b].route.name) >= 0){
                          //   element.items[i].items[b].refresh = true
                          // }
                          element.totalSelected.push(element.items[i].items[b].route.name)
                          element.items[i].totalSelected.push(element.items[i].items[b].route.name)
                          if(userNavigation.indexOf(element.items[i].items[b].route.name) == -1){
                              element.notChild.push(element.items[i].items[b].route.name)
                              element.items[i].notChild.push(element.items[i].items[b].route.name)
                          }
                        }
                      }

                      if(element.items[i].items[b].items) {
                        for(let d = 0; d < element.items[i].items[b].items.length; d++) {
                          element.items[i].items[b].items[d].notChild = []
                          element.items[i].items[b].items[d].totalSelected = []
                          element.items[i].items[b].items[d].refresh = false
                          element.items[i].items[b].items[d].is_active = false
                          element.items[i].items[b].items[d].is_allow_show = false
                          element.items[i].items[b].items[d].childs = []
                          element.items[i].items[b].items[d].on_active = false

                          if(element.items[i].items[b].items[d].selected_on){
                            if(element.items[i].items[b].items[d].selected_on && element.items[i].items[b].items[d].selected_on.indexOf(this.$route.name) >= 0){
                              element.items[i].items[b].items[d].is_active = true
                            }

                            if(element.items[i].items[b].items[d].selected_on){
                              element.items[i].items[b].items[d].childs.concat(element.items[i].items[b].items[d].selected_on)
                              if(element.items[i].items[b].items[d].childs.indexOf(this.$route.name) >= 0){
                                element.items[i].items[b].items[d].on_active = true
                              }
                            }
                          }

                          for(let e = 0; e < element.items[i].items[b].items[d].selected_on.length; e++){
                            element.items[i].totalSelected.push(element.items[i].items[b].items[d].selected_on[e])
                            element.items[i].items[b].totalSelected.push(element.items[i].items[b].items[d].selected_on[e])
                            if(userNavigation.indexOf(element.items[i].items[b].items[d].selected_on[e]) == -1){
                              element.items[i].notChild.push(element.items[i].items[b].items[d].selected_on[e])
                              element.items[i].items[b].notChild.push(element.items[i].items[b].items[d].selected_on[e])
                            }else{
                              element.totalSelected.push(element.items[i].items[b].items[d].selected_on[e])
                            }
                          }

                          if(element.items[i].items[b].items[d].route){
                            if(element.items[i].items[b].items[d].route.name){
                              if(element.items[i].items[b].items[d].route && this.$route.name == element.items[i].items[b].items[d].route.name){
                                element.items[i].items[b].items[d].is_active = true
                              }

                              if(element.items[i].items[b].items[d].route && (userNavigation.indexOf(element.items[i].items[b].items[d].route.name) >= 0 || element.items[i].items[b].items[d].skip_roles == true)){
                                element.items[i].items[b].items[d].is_allow_show = true
                                element.items[i].items[b].is_allow_show = true
                                element.totalSelected.push(element.items[i].items[b].items[d].route.name)
                              }
                              if(element.items[i].items[b].items[d].route){
                                element.items[i].items[b].items[d].childs.push(element.items[i].items[b].items[d].route.name)
                                if(element.items[i].items[b].items[d].childs.indexOf(this.$route.name) >= 0){
                                  element.items[i].items[b].items[d].on_active = true
                                }
                              }
                              element.items[i].items[b].items[d].href = origin+'#'+this.$router.resolve({name: element.items[i].items[b].items[d].route.name}).route.path
                              // if(arrayFullRefresh.indexOf(element.items[i].items[b].items[d].route.name) >= 0){
                              //   element.items[i].items[b].items[d].refresh = true
                              // }
                              element.items[i].totalSelected.push(element.items[i].items[b].items[d].route.name)
                              element.items[i].items[b].totalSelected.push(element.items[i].items[b].items[d].route.name)
                              if(userNavigation.indexOf(element.items[i].items[b].items[d].route.name.trim()) == -1){
                                  element.items[i].notChild.push(element.items[i].items[b].items[d].route.name)
                                  element.items[i].items[b].notChild.push(element.items[i].items[b].items[d].route.name)
                              }
                            }
                          }
                        }
                      }
                      if(element.items[i].items[b].totalSelected.length > element.items[i].items[b].notChild.length) {
                        element.items[i].items[b].notChild = []
                      }
                    }

                  }
                  // console.log('cek disini child', element.title, element.items[i].totalSelected.length, element.items[i].notChild.length)
                  if(element.items[i].totalSelected.length > element.items[i].notChild.length){
                    element.items[i].notChild = []
                  }
                }

                if(element.totalSelected.length > element.notChild.length){
                  element.notChild = []
                }
              }
            }
          })
          return self.items
        },
    },
    methods: {
        getSingleData() {
            Rest.get('/user/me/detail').then( async(res) => {
              if(res && res.data && res.data.content && res.data.content.profile && res.data.content.profile.name) {
                var name = res.data.content.profile.name
                var full_name = name.split(" ")[0]
                if(full_name.length > 15) {
                  full_name = full_name.substring(0, 14)+"..."
                }

                this.user.name = full_name
              }
              
            })
        },
        // eslint-disable-next-line
        setTitle(route) {
            if (this.$route.meta && this.$route.meta.title) {
                this.$store.state.title = this.$route.meta.title
                if (this.$route.meta.subtitle) {
                    this.$store.state.subtitle = this.$route.meta.subtitle
                } else {
                    this.$store.state.subtitle = null
                }
            } else {
                this.$store.state.title = this.$route.name
                this.$store.state.subtitle = null
            }
            
            this.$store.state.breadcrumb_tree = null
            if(route && route.meta && route.meta.breadcrumb_tree && route.meta.breadcrumb_tree.length > 0) {
              this.$store.state.breadcrumb_tree = route.meta.breadcrumb_tree
            }
        },
    },
    watch: {
      '$root.$store.state.user.company_id' : {
        handler(val) {
          if(val) {
            $('body').removeClass('skin-app')
            $('body').addClass('skin-app-company')
          }
        }
      }
    }
}
</script>

<style scoped>
.text-lowercase{
  text-transform: lowercase !important;
}

.footer-cms {
  bottom : 0; 
  padding: 0px 10px 10px 2px; 
  width: 100%; 
  position: absolute; 
  color: #EDEDED;
  font-size: 14px;
  text-align: center;
}
</style>
