<template>
    <div class="row title-header-row not-print">
        <div class="col-md-6">
            <h3 class="title-header">{{ title }}</h3>
        </div>
        <div class="col-md-6" >
            
            <button 
                v-if="isAdd" 
                class="btn btn-md title-header-button" 
                :class="is_owner ? `owner` : ``"
                type="button"  @click="add" style=""> {{ textAdd }} <i><img src="@/assets/svg/add.svg" /></i></button>
            
            <button 
                v-if="isPrint"
                class="btn btn-xs float-right" 
                :class="is_owner ? `btn-print-owner` : `btn-print`"
                type="button"
                @click="print"
                >
                    <span>{{ textPrint }}</span> 
                    <img src="@/assets/svg/printer.svg" /> 
                </button>

            <button 
                v-if="isDownload"
                class="btn btn-md title-header-button float-right" 
                :class="is_owner ? `btn-print-owner` : `btn-print`"
                type="button"
                @click="download"
                >
                    <span>{{ textDownload }}</span> 
                    <img src="@/assets/svg/download.svg" /> 
            </button>
            <slot name="header-actions"></slot>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: ''
        },
        isAdd: {
            type: [String, Boolean],
            default: false
        },
        textAdd: {
            type: String,
            default: 'Add'
        },
        iconAdd: {
            type: String,
            default: 'fa fa-plus'
        },
        isPrint: {
            type: [String, Boolean],
            default: false
        },
        textPrint: {
            type: String,
            default: 'Print'
        },
        isDownload: {
            type: [String, Boolean],
            default: false 
        },
        textDownload: {
            type: String,
            default: 'Download'
        },
        isDownloadDisabled: {
            type: [String, Boolean],
            default: false
        }
    },
    data() {
        return {
            
        }
    },
    computed: {
        is_owner() {
            let response = false
            let temp = localStorage.getItem('_USER_ME')
            if(temp) {
                let bytes = this.CryptoJS.AES.decrypt(temp, process.env.VUE_APP_KEY_ENCRYPT_DECRYPT)
                let data = JSON.parse(bytes.toString(this.CryptoJS.enc.Utf8))
                if(data) {
                    if(data.company_id) {
                        response = true
                    }
                }
            }

            return response
        }
    },
    methods: {
        add() {
            this.$emit('onClickAdd')
        },
        print() {
            this.$emit('onClickPrint')
        },
        download() {
            this.$emit('onClickDownload')
        }
    }
}
</script>

<style scoped>
    .title-header-row {
        margin-top: -15px;
    }

    .title-header {
        margin-top: -5px;
        font-family: 'Inter';
    }

    .title-header-button {
        float: right; 
        margin-top: -5px; 
        margin-bottom: 10px;
        background-color:#06DCF8;
        color: #FFFFFF;

        display: flex;
        padding: 6px 4px 6px 8px;
        justify-content: flex-end;
        align-items: center;
        gap: 8px;
        border-radius: 6px;


        text-align: right;
        font-family: 'Inter';
        font-size: 16px;
        font-style: normal;
        line-height: 24px; /* 150% */
        letter-spacing: -0.176px;
    }
    

    .title-header-button:hover,
    .title-header-button:focus {
        color: #FFFFFF;
        background-color:#06DCF8;
        border-color: #06DCF8;
    }

    .owner.title-header-button {
        background-color: #18A891;
        border: 1px solid #18A891;
    }

    .owner.title-header-button:hover,
    .owner.title-header-button:focus {
        border: 1px solid #18A891;
        color: #FFFFFF;
        background-color: #18A891;
        border-color: #18A891;
    }

    .btn-print {
        display: flex;
        padding: 6px 6px 6px 6px;
        justify-content: flex-end;
        align-items: center;
        gap: 6px;

        border-radius: 6px;
        background: #06DCF8;
    }

    
    .btn-print-owner {
        display: flex;
        padding: 6px 6px 6px 6px;
        justify-content: flex-end;
        align-items: center;
        gap: 6px;

        border-radius: 6px;
        background: #18A891;
    }

    .btn-print-owner span,
    .btn-print span {
        color: #FFFFFF;
        text-align: right;
        font-family: 'Inter';
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        /* line-height: 24px;  */
        letter-spacing: -0.176px;
    }
</style>