<template>
    <div>
        <box header="false"
            :cancel="false"
            :save="false">
            <div>
                <div class="row">
                    <div class="col-md-12">
                        <img src="@/assets/svg/overview/machine.svg" style="margin-top: -5px"/>
                        &nbsp;
                        <span class="overview-title">Server</span>
                    </div>
                </div>
                <br />
                <div class="row">
                    <div class="col-md-6 col-xs-12 margin-bottom-10px">
                        <select type="text" class="form-control select2 select-machine" style="width:100%"
                            id="machine"
                            v-model="machine_id" >
                            <option v-for="(data, i) in machine" :key="i" :value="data.id">{{ data.code }}</option>
                        </select>
                    </div>
                    <div class="col-md-6 col-xs-12 margin-bottom-10px">
                        <span id="report-daterange-ticket-machine-overview" 
                            class="form-control" 
                            style="background: #fff; cursor: pointer; padding: 5px 10px; border: 1px solid #ccc; width: 100%;">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M8 7V3M16 7V3M7 11H17M5 21H19C20.1046 21 21 20.1046 21 19V7C21 5.89543 20.1046 5 19 5H5C3.89543 5 3 5.89543 3 7V19C3 20.1046 3.89543 21 5 21Z" stroke="#404040" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            &nbsp;&nbsp;
                            <span class="date-range-text-info" style="position: absolute;margin-top: 2px;"></span> 
                        </span>
                    </div>
                </div>

                <br />
     
                <div class="row" v-if="is_load_chart_machine">
                    <div class="col-md-1">&nbsp;</div>
                    <div class="col-md-10 col-xs-12">
                        <charts id="chart-machine" :options="data_chart" minHeight="200px"></charts>
                    </div>
                    <div class="col-md-1">&nbsp;</div>
                </div>
                <br />
                <p style="font-family: Inter;
                font-size: 16px;
                font-weight: 500;
                line-height: 28px;
                letter-spacing: -0.014em;
                text-align: left;
                color: #404040;
                ">Ticket Settled/Kiosk</p>
                <hr>
                <div class="row">
                    <div v-for="(data, i) in trx_machine" :key="i">
                        <div class="col-md-6 col-xs-6">
                            <p><span :style="leftBorder(data.color_code)"> &nbsp;{{ data.code }}</span></p>
                        </div>
                        <div class="col-md-6 col-xs-6">
                            <p class="pull-right">{{ data.total }}({{ data.percentage +' %' }})</p>
                        </div>
                    </div>
                </div>
            </div>
        </box>
    </div>
</template>

<script>

import Charts from '@app/components/Charts.js'
export default {
    components: {
        Charts
    },
    data() {
        return {
            machine: [{code: 'All Server', id: null}],
            is_load_chart_machine: false,
            data_chart: {
                header: '',
                string_null: 'Not found',
                series: [],
                chart: {
                    width: '100%',
                    type: 'pie'
                },
                labels: [],
                colors:[],
                noData: {
                    text: 'Not Found'
                },
                theme: {
                    monochrome: {
                        enabled: false
                    }
                },
                plotOptions: {
                    pie: {
                        dataLabels: {
                            offset: -25
                        }
                    }
                },
                title: {
                    align: 'center',
                    margin: 0,
                    text: "",
                    style: {
                        fontSize: "14px",
                        fontWeight: 600
                    }
                },
                dataLabels: {
                    dropShadow: {
                        blur: 0,
                        enabled: false,
                        left: 1,
                        opacity: 0.45,
                        top: 1
                    },
                    style: {
                        colors: ['#FFFFFF'],
                        fontWeight: 400,
                        fontFamily: '',
                        fontSize: '14px'
                    },
                    formatter(val, opts) {
                        // const name = opts.w.globals.labels[opts.seriesIndex]
                        // return [name, val.toFixed(2) + ' %']
                        return ''
                    }
                },
                legend: {
                    show: false
                },
                states: {
                    hover: {
                        filter: {
                            type: 'none'
                        }
                    }
                }
            },
            overview_transaction_machine: [],
            filter_date: {
                start_date: moment().startOf('month').format('YYYY-MM-DD'), 
                end_date: moment().endOf('month').format('YYYY-MM-DD')
            },
            filter_date_temp: null,
            machine_id: null,
            rangeOptions: this.$root.$store.state.rangeOptions,
        }
    },
    async mounted() {
        await this.getDataMachine()

        let self = this 
        $(document).ready(function() {
            self.loadDateRangePicker()

            $('.select-machine').on('select2:select', (e) => {
                e.preventDefault();
                let data = e.params.data
                self.machine_id = data.id
            })

            $('body').on('change', '.select-machine', function() {
                let val = $('.select-machine').val()
                self.machine_id = val
            })
        })

        $('.select2').select2()
        $('.select-machine').select2()

        
    },
    methods: {
        async getDataMachine() {
            Rest.get('/machine?size=100&sort=code')
            .then( async(res) => {
                if(res && res.data && res.data.items ) {
                    res.data.items.forEach( (item) => {
                        item.total = 0
                        item.percentage = 0
                        item.color_code = '#312480'
                    })
                    this.machine = this.machine.concat(res.data.items)
                    await this.getDataCashier(res.data.items)
                    this.init()
                }
            })
        },
        async getDataCashier(machine) {
            let cashier = []
            Rest.get('/cashier')
            .then( async(res) => {
                if(res && res.data && res.data.length > 0) {
                    res.data.forEach( async(item) => {
                        if(item.id && item.full_name) {
                            cashier.push({
                                id: item.id,
                                code: item.full_name,
                                total: 0,
                                percentage: 0,
                            })
                        }
                    })

                    this.machine = this.machine.concat(cashier)

                    
                }
            })
            .catch( () => {

            })
            .finally( () => {
                let overview_transaction_machine = [...machine, ...cashier]
                this.overview_transaction_machine = overview_transaction_machine
            }) 

           
        },
        async loadDateRangePicker() {
            let self = this 
            let range_option = {}
            this.rangeOptions.forEach( (item) => {
                if(item && item.name && item.range) {
                    range_option[item.name] = item.range
                }
            })
            $(function() {

                var start = moment(self.filter_date.start_date);
                var end = moment(self.filter_date.end_date);

                var daysInYear = 366

                function cb(start, end) {
                    moment.locale('en')
                    var tempDate = start.format('D MMMM YYYY') + ' - ' + end.format('D MMMM YYYY')
                    self.rangeOptions.forEach( (item) => {
                        if(item && item.name && item.start_date && item.end_date) {
                            if(item.start_date == start.format('YYYY-MM-DD') && item.end_date == end.format('YYYY-MM-DD')) {
                                tempDate = item.name
                            }
                        }
                    })
                    var diff = end.diff(start, 'days') 
                    if(diff > daysInYear) {
                        self.$root.showNotification('Failed', 'error', 'Maximum search range is 1 year.')
                    }else {
                        self.filter_date_temp = tempDate

                        var start_date = start.format('YYYY-MM-DD')
                        var end_date = end.format('YYYY-MM-DD')

                        self.filter_date.start_date = start_date
                        self.filter_date.end_date = end_date
                        $('#report-daterange-ticket-machine-overview span.date-range-text-info').html(tempDate);
                    }
                   
                }

                $('#report-daterange-ticket-machine-overview').daterangepicker({
                    opens: 'right',
                    startDate: start,
                    endDate: end,
                    autoApply: true,
                    alwaysShowCalendars: true,
                    showCustomRangeLabel: false,
                    ranges: range_option,
                    locale: {
                        format: "YYYY-MM-DD",
                        separator: " - ",
                        applyLabel: "Apply",
                        cancelLabel: "Cancel",
                        fromLabel: "From",
                        toLabel: "To",
                        customRangeLabel: "Custom",
                        weekLabel: "W",
                        daysOfWeek: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
                        monthNames:  ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Agu', 'Sep', 'Oct', 'Nov', 'Dec'],
                        firstDay: 1
                    },
                }, cb);

                cb(start, end);

            });
        },
        async init() {
            let url  = '?start_date='+this.filter_date.start_date+'&end_date='+this.filter_date.end_date
            if(this.machine_id) {
                url += '&server_id='+this.machine_id
            }
            Rest.get('/overview/transaction/machine'+url)
            .then( async(res) => {
                if(res && res.data && res.data.overview_transaction_machine) {
                    let series = []
                    let labels = []
                    let colors = []
                    let is_load_chart_machine = false
                    res.data.overview_transaction_machine.forEach( (item) => {
                        series.push(item.total)
                        labels.push(item.server_name)
                        colors.push(item.color_code)
                        if(item.total > 0) {
                            is_load_chart_machine = true
                        }
                    })

                    this.is_load_chart_machine = is_load_chart_machine
                    this.data_chart.series = series
                    this.data_chart.labels = labels
                    this.data_chart.colors = colors
                    await this.mappingTransactionMachine(res.data.overview_transaction_machine)
                    
                }
            })

        },
        async mappingTransactionMachine(trx) {
            this.overview_transaction_machine.forEach( async(item) => {
                item.total = 0
                item.percentage = 0
                item.color_code = '#312480'
                if(trx && trx.length > 0) {
                    find = await this.findTransactionMachine(trx, item.code)
                    if(find) {
                        item.total = find.total ? find.total : 0
                        item.percentage = find.percentage ? find.percentage : 0
                        item.color_code = find.color_code ? find.color_code : '#312480'
                    }
                }
            })
        },
        async findTransactionMachine(trx, server_name) {
            let machine = null 
            trx.forEach( (item) => {
                if(item.server_name && item.server_name == server_name) {
                    machine = item 
                    
                }
            })

            return machine
        },
        leftBorder(color_code) {
            return {
                fontSize: '14px',
                borderLeft: '3px solid '+color_code,
                marginRight: '5px',
            }
        }
    },
    computed: {
        trx_machine() {
            let machine = []
            if(this.machine_id) {
                this.overview_transaction_machine.forEach( (item) => {
                    if(item.id == this.machine_id) {
                        machine.push(item)
                    }
                })
            }else {
                machine = this.overview_transaction_machine
            }

            return machine
        }
    },
    watch: {
        filter_date_temp: {
            handler() {
                this.init()
            }
        },
        machine_id: {
            handler(){
                this.init()
            }
        }
    }
}
</script>

<style scoped>

</style>