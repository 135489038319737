<template>
    <div>
        <Error404 v-if="display404"></Error404>
        <div v-else>
            <header-title-button
                :title="id ? disabledForm ? 'Cashier Detail' : 'Edit Cashier' : 'Add Cashier' "
                :isAdd="false">
            </header-title-button>
            <box 
            back="master.cashier" 
            @onSave="save" 
            :save="!disabledForm"
            :remove="false"
            title="Cashier Information"
            :isSwitch="true"
            :switchValue="form.is_active"
            :switchDisabled="disabledForm"
            switchTitle="Status"
            :switchText="form.is_active ? 'Active' : 'Not Active' "
            @onSwitchButton="changeBtnSwitch">
            <div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group" :class="{'has-error': !!errors.name}">
                                    <label class="control-label">Full Name 
                                        <span class="warning-text-required">*</span>
                                    </label>
                                    <input type="text" class="form-control name"
                                        id="name"
                                        v-model="form.name"
                                        :disabled="disabledForm"
                                        maxlength="100"
                                        @input="preventLeadingSpaces($event, 'form.name')" />
                                    
                                    <p class="help help-block" v-if="errors.name"> {{ form.name ? 'Full name must be filled in with a minimum of three characters, maximum one hundred characters.s' : 'Full name is required.'}}</p>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group" :class="{'has-error': !!errors.email}">
                                    <label class="control-label">Email 
                                        <span class="warning-text-required">*</span>
                                    </label>
                                    <input type="text" class="form-control email"
                                        id="email"
                                        v-model="form.email"
                                        :disabled="id ? true : disabledForm"
                                        maxlength="60"
                                        @input="preventLeadingSpaces($event, 'form.email')" />
                                    
                                    <p class="help help-block" v-if="errors.email">{{ form.email ? 'Email format is not valid.' : 'Email is required.'}}</p>
                                </div>
                            </div>
                        </div>
                        
                        
                    </div>
                    <div class="col-md-6">
                        <div class="form-group" :class="{'has-error': !!errors.password}">
                            <label class="control-label">Password 
                                <span class="warning-text-required">*</span>
                            </label>
                            <input :type="is_password ? 'password': 'text'" class="form-control password"
                                id="password"
                                v-model="form.password"
                                :disabled="disabledForm" 
                                maxlength="50"
                                autocomplete="new-password"
                                @input="preventLeadingSpaces($event, 'form.password')"/>
                                <span  @click="showHidePassword" class="toggle-password">
                                    <i v-if="is_password">
                                        <img src="@/assets/svg/eye.svg"/>
                                    </i>
                                    <i v-else>
                                        <img src="@/assets/svg/eye-off.svg"/>
                                    </i>
                                </span>
                            <p class="help help-block" v-if="errors.password">{{ form.password ? 'Password must contain lowercase letters, uppercase letters, numbers, symbols, and be at least eight characters long and at most fifty characters long.' : 'Password is required.'}}</p>
                        </div>

                        <div class="form-group" :class="{'has-error': !!errors.code}">
                            <label class="control-label">Code 
                                <span class="warning-text-required">*</span>
                            </label>
                            <input type="text" class="form-control code"
                                id="code"
                                v-model="form.code"
                                :disabled="disabledForm"
                                maxlength="3"
                                @input="preventLeadingSpaces($event, 'form.code', false)" />
                            <p class="help help-block" v-if="errors.code">Code is required.</p>
                        </div>
                    </div>
                </div>
            </div>
        </box>
        </div>

        <modal :title="form.id ? 'Change Confirmation' : 'Add Confirmation' " save="true" remove="false"
            :show="is_confirm"
            :saveText=" form.id ? 'Yes, Confirmation' : 'Save'"
            @hidden="is_confirm = false"
            @onSave="doSave()"
            :loading="is_confirm_loading">
            <span class="span-title">Are you sure you want to modify the cashier ?</span>
        </modal>
    </div>
</template>

<script>
import Error404 from '@pages/Error404.vue'
import IconInfoCircle from '@components/svg/IconInfoCircle.vue'
const {console} = window
export default {
    components: {
        Error404,
        IconInfoCircle
    },
    data() {
        return {
            id: this.$route.params.id,
            is_edit: this.$route.query.is_edit,
            isSwitch: false,
            page: {
                title: "Master",
            },
            form: {
                id: this.id,
                name: null,
                is_cashier: true,
                email: null,
                password: null,
                is_active: true,
                code: null,
            },
            errors: {
                name: false,
                email: false,
                password: false,
                is_active: false,
                code: false
            },
            errorsText: {
                name: 'Name is required.',
                code: 'Code is required.',
                email: 'Email is required. ',
                password:  'Password is required.'
            },
            alias: 'cashier',
            permission_type: this.$route.params.id ? 'update' : 'create',
            disabledForm: false,
            display404: false,
            is_confirm: false,
            is_confirm_loading: false,
            is_password: true,
            user: this.$root.$store.state.user || null,
        }
    },
    async mounted() {
        await this.$root.checkingAccessPage(this.$root._route.name)
        let permission = await this.$root.checkingButtonsPage(this.alias, this.permission_type)
        if(!permission.validate) {
            this.disabledForm = true
        }

        this.$root._route.meta.title = 'Add Cashier'
        this.$store.state.title = 'Add Cashier'
        if(this.id) {
            this.$root._route.meta.title = 'Edit Cashier'
            this.$store.state.title = 'Edit Cashier'

            let is_edit = this.is_edit 
                if(typeof is_edit == 'string') {
                    switch (is_edit) {
                        case 'true':
                            this.is_edit = true 
                            break;
                        case 'false':   
                            this.is_edit = false 
                            break;
                        default:
                            this.is_edit = false
                            break;
                    }
                }

                if(!this.is_edit) {
                    this.disabledForm = true

                    this.$root._route.meta.title = 'Cashier Detail'
                    this.$store.state.title = 'Cashier Detail'
                }

                if(!permission.validate) {
                    this.disabledForm = true

                    this.$root._route.meta.title = 'Cashier Detail'
                    this.$store.state.title = 'Cashier Detail'
                }

            this.init()

        }
    },
    methods: {
        async init() {
            Rest.get('/user/'+this.id)
            .then( async(res) => {
                if(res && res.data && res.data) {
                    this.form.name = res.data.full_name 
                    this.form.email = res.data.username
                    this.form.is_active = res.data.is_active
                    this.form.id = res.data.id
                    this.form.code = res.data.code
                }
            })
        },
        changeBtnSwitch() {
            this.form.is_active = !this.form.is_active
        },
        async save() {
            var is_valid = await this.validationForm()
            if(is_valid) {
                if(this.id) {
                    this.is_confirm = true
                }else {
                    this.doSave()
                }
            }
            
        },
        async validationForm() {
            var is_valid = true 

            this.errors.name = false 
            this.errors.code = false 
            this.errors.email = false 
            this.errors.password = false 
            if(!this.form.name) {
                this.errors.name = true
                is_valid = false 
            }else {
                let value = this.form.name.trim()
                if(value.length < 3 || value.length > 100) {
                    this.errors.name = true
                    is_valid = false 
                } 
            }

            if(!this.form.code) {
                this.errors.code = true 
                is_valid = false 
            }

            if(!this.id) {
                if(!this.form.email) {
                    this.errors.email = true 
                    is_valid = false 
                }else {
                    if(this.form.email.length > 60) {
                        this.errors.email = true 
                        is_valid = false 
                    }else {
                        let is_valid_email = this.$root.validationInputEmail(this.form.email)
                        if(!is_valid_email) {
                            this.errors.email = true 
                            is_valid = false
                        }
                    }
                }

                if(!this.form.password) {
                    this.errors.password = true 
                    this.errorsText.password = "Password is required."
                    is_valid = false 
                }else {
                    let valid = this.$root.validationInputPassword(this.form.password)
                    if(!valid) {
                        this.errors.password = true 
                        is_valid = false 
                    }
                }
            }else {
                if(this.form.password) {
                    let valid = this.$root.validationInputPassword(this.form.password)
                    if(!valid) {
                        this.errors.password = true 
                        is_valid = false 
                    }
                }
            }

            return is_valid

        },
        async doSave() {
            this.is_confirm_loading = true 

            var params = {
                username: this.form.email,
                password: this.form.password,
                full_name: this.form.name,   
                is_active: this.form.is_active,
                is_cashier: true,
                code: this.form.code
            }
            console.log('params:', params)
            var methods = this.id ? 'put' : 'post'
            var url = this.id ? '/user/'+this.id : '/user'

            Rest[methods](url, params)
            .then( async(res) => {
                if(res && res.status == 200) {
                    this.$root.showNotification('Success', 'success', this.id ? "Cashier modification successful." : "Cashier addition successful.")
                    this.userLogActivity()
                    
                    if(methods == 'post') {
                        if(res && res.data && res.data.data && res.data.data.id) {
                            this.form.id = res.data.data.id 
                        }
                    }
                    
                    this.$root.$router.push({name: 'master.cashier'})
                }
            })
            .catch( (err) => {
                if(err && err.response && err.response.status) {
                    if(err.response.status == 409) {
                        var message = "Email already exists, please input another email."
                        if (err.response.data && err.response.data.message) {
                            if (err.response.data.message == "Code already exist") {
                                message = "Code already exist, please input another code."
                            }
                        }
                        this.$root.showNotification('Failed', 'error', message)        
                    }else {
                        var message = "Cashier addition failed"
                        if(this.id) {
                            message = "Cashier modification failed."
                        }
                        this.$root.showNotification('Error', 'error', message)
                    }
                }else {
                    this.$root.showNotification('Failed', 'error', 'Sorry, you do not have an internet connection.')
                }
            })
            .finally( () => {
                this.is_confirm = false
                this.is_confirm_loading = false
            })
        },
        async userLogActivity() {
            if(this.$root.$store.state.user && this.$root.$store.state.user.full_name) {
                let route_name = {
                    name: 'master.cashier.form',
                    params: {
                        id: this.form.id
                    },
                    query: {
                        is_edit: false
                    }
                } 

                let secretKey = process.env.VUE_APP_KEY_ENCRYPT_DECRYPT
                let route_name_encrypt = this.EncryptDecryptAesCbc256.encryptAesCbc256(secretKey, JSON.stringify(route_name))

                let activity_type = 'create'
                let activity = `${this.$root.$store.state.user.full_name} has added a cashier with the name <a style="cursor: pointer" class="click-detail-log" data-route="${route_name_encrypt}"> ${this.form.name}</a>`
                if(this.id) {
                    activity = `${this.$root.$store.state.user.full_name} has modified the cashier with the name <a style="cursor: pointer" class="click-detail-log" data-route="${route_name_encrypt}"> ${this.form.name}</a>`
                    activity_type = 'edit'
                }

                let params = {
                    activity: activity,
                    feature: 'Cashier',
                    activity_type: activity_type,
                    route_name: route_name
                }

                this.$root.sendUserLogActivity(params)
            }
            
        },
        showHidePassword() {
            if(!this.disabledForm) {
                this.is_password = !this.is_password
            }
        },
        preventLeadingSpaces(event, modelPath, isSpace = true) {
            let input = event.target.value.replace(/^\s+/, '')
            if (!isSpace) {
                input = event.target.value.replace(/\s+/g, '')
            }

            let keys = modelPath.split('.')
            let obj = this;
            for (let i = 0; i < keys.length - 1; i++) {
                obj = obj[keys[i]];
            }
            this.$set(obj, keys[keys.length - 1], input);
        }
},
    computed: {

    },
    watch: {
        'form.name':  {
            handler(val) {
                this.errors.name = false 
                if(!val) {
                    this.errors.name = true 
                }else {
                    let value = val.trim()
                    if(value.length < 3 || value.length > 100) {
                        this.errors.name = true 
                    } 
                }
            }
        },
        'form.code': {
            handler(val) {
                this.errors.code = false 
                if(!val) {
                    this.errors.code = true 
                }
            }
        },
        'form.email': {
            handler(val) {
                this.errors.email = false 
                if(!this.id) {
                    if(!val) {
                        this.errors.email = true 
                    }else {
                        if(val.length > 60) {
                            this.errors.email = true 
                        }else {
                            let is_valid = this.$root.validationInputEmail(val)
                            if(!is_valid) {
                                this.errors.email = true
                            }
                        }
                    }
                }
            }
        },
        'form.password': {
            handler(val) {
                this.errors.password = false
                if(!this.id) {
                    if(!val) {
                        this.errors.password = true 
                    }else {
                        let is_valid = this.$root.validationInputPassword(val)
                        if(!is_valid) {
                            this.errors.password = true 
                        }
                    }
                }else {
                    if(val) {
                        let is_valid = this.$root.validationInputPassword(val)
                        if(!is_valid) {
                            this.errors.password = true 
                        }
                    }
                }
            }
        }
    }

}
</script>

<style scoped>

</style>