// https://apexcharts.com/
import Vue from 'vue'
import ApexCharts from 'apexcharts'

const { console } = window
const Charts = {
    name: 'ChartsItem',
    props: {
        id: {
            type: String,
        },
        options: {
            type: Object,
        },
        minHeight: {
            type: String,
            default: "200px"
        },
    },
    template: `<div >
      <div>
        <div :id="id"></div>
      </div>
    </div>
    `,
    data() {
        return {
            style: {
                'min-height': this.minHeight,
            }
        }
    },
    async mounted() {
        var css_text = `
        .text-center{
          text-align: center;
        }
        .font-size-16{
          font-size: 16;
        }
      `
        var css = document.createElement('style')
        css.type = 'text/css'
        css.setAttributeNode(document.createAttribute('scopped'))
        css.appendChild(document.createTextNode(css_text))
        this.$el.appendChild(css)

        this.updateChart()

    },
    methods: {
        async updateChart() {
            // $('#'+this.id).html("")
            if (this.id) {
                let style = $("#chart-example").attr("style")
                $('#' + this.id).replaceWith(`<div id="` + this.id + `" style="` + style + `"></div>`)
                var chart = new ApexCharts(document.querySelector("#" + this.id), this.options)
                try {
                    chart.render()
                        // ApexCharts(document.querySelector("#"+this.id), this.options).render()
                        // ApexCharts.exec(this.id, 'updateSeries', [1,2]);
                } catch (err) { console.log('err', err) }
            }
        }
    },
    watch: {
        'options.series': {
            handler(newVal) {
                this.updateChart()
            }
        },
        'options.labels': {
            handler() {
                this.updateChart()
            }
        },
        'options.title.text': {
            handler() {
                this.updateChart()
            }
        },
        'options.headers': {
            handler() {
                this.updateChart()
            }
        }
    }
}

Vue.component('ChartsItem', Charts)
export default Charts