<template>
  <div>
    <section class="content-header">
      
      <ol class="breadcrumb">
        <li><router-link :to="{ name: 'dashboard' }">Home</router-link></li>
        <li v-for="(data, i) in $root.$store.state.breadcrumb_tree" :key="i">
          <span v-if="!data.path_name">{{ data.title }}</span>
          <router-link v-else :to="{ name: data.path_name}">{{ data.title }}</router-link>
        </li>
        <li :class="isDashboardOwner ? `owner` : `superuser`" class="active">{{ title }}</li>
      </ol>
      <br>
      <hr>
      <!-- <h1>
        {{ title }}
        <small>{{ subtitle }}</small>
      </h1> -->
    </section>
  </div>
    
</template>

<script>
// import { mapState } from 'vuex'

export default {
    data() {
      return {
        user : this.$root.$store.state.user || null,
      }
    },
    mounted() {
      if(!this.user) {
          let temp = localStorage.getItem('_USER_ME')
          if(temp) {
              let bytes = this.CryptoJS.AES.decrypt(temp, process.env.VUE_APP_KEY_ENCRYPT_DECRYPT)
              let data = JSON.parse(bytes.toString(this.CryptoJS.enc.Utf8))
              if(data) {
                  this.user = data 
              }
          }
      }
    },
    computed: {
        title() {
            let title = this.$store.state.title || 'Dashboard'
            window.document.title = [this.env.app_name, title].join(' | ')

            return title
        },
        subtitle() {
            return this.$store.state.subtitle || ''
        },
        isDashboardOwner() {
            let isOwner = false 
            if(this.user && this.user.company_id) {
                isOwner = true 
            }

            return isOwner
        },
    }
}
</script>

<style lang="css" scoped>
  .breadcrumb {
    font-size: 14px !important;
    float: left !important;
    left: 10px !important;
    font-family: 'Inter';
  }
  
  .breadcrumb > .superuser.active {
      color: #6147FF;
  }

  .breadcrumb > .owner.active {
      color: #18A891;
  }

  /* .skin-app .content-header {
    background: #C8C8C8;
  } */


  @media (max-width: 991px) {
    .content-header > .breadcrumb {
      background: transparent !important;
    }
  }
</style>
