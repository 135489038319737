<template>
    <div class="wrapper">
        <notifications
            group="notification"
            position="bottom right"
            :max="5"
            :width="500"
            :duration="15000"
        >
        <template slot="body" slot-scope="props">
            <div class="vue-notification-template vue-notification" :class="props.item.type" >
                <span class="title">
                    <b>{{props.item.title}}</b>
                </span>
                <a class="close icon-close-notif" @click="props.close">
                     <img src="@/assets/svg/outline-x.svg" />
                </a>
                <div v-html="props.item.text">
                </div>
            </div>
        </template>
        </notifications>
        <router-view name="navbar"></router-view>
        <router-view name="sidebar"></router-view>
        <!-- Content Wrapper. Contains page content -->
        <div class="content-wrapper">
            <router-view name="header"></router-view>
            <!-- Main content -->
            <section class="content">
                <router-view></router-view>
            </section>
        </div>
        <!-- /.content-wrapper -->
        <router-view name="footer"></router-view>
        <project-modal/>
    </div>
</template>

<script>
import ProjectModal from './ProjectModal.vue'
export default {
    components: {
        ProjectModal
    },
    mounted() {
        $('body,html').removeAttr('style')
        $('.modal-backdrop').remove()
        $('body').removeClass('modal-open').css({
            paddingRight: ''
        })
    }
}
</script>

<style lang="scss" scoped>
    .vue-notification {
        padding: 10px;
        margin: 0 5px 5px;
        
        font-size: 12px;
        
        color: #ffffff;
        background: #44A4FC;
        border-left: 5px solid #187FE7;

        &.title {
            color: #ffffff;
        }
        
        &.warn {
            background: #ffb648;
            border-left-color: #f48a06;
        }
        
        &.error {
            background: #E54D42;
            border-left-color: #B82E24;
        }
        
        &.success {
            background: #68CD86;
            border-left-color: #42A85F;
        }

        .icon-close-notif {
            opacity: 0.9 !important;
            color: #FFFFFF !important;
        }

        .icon-close-notif:hover,
        .icon-close-notif:focus {
            opacity: 1 !important;
            color: #FFFFFF !important;
        }
    }
</style>
