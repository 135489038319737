<template>
    <div>
        <header-title-button
            title="Item"
            textAdd="Add Item"
            :isAdd="permission_create"
            @onClickAdd="add">
        </header-title-button>
        <datatable 
            id="table-item" 
            :config="config" 
            class="superuser-datatable"
            :settingFilters="settingFilters"
            :defaultFilter="defaultFilter" />

        <modal 
            title="Confirm Deletion" 
            save="true" 
            remove="false"
            :show="is_confirm_delete"
            :loading="is_confirm_delete_loading"
            saveText="Delete"
            @hidden="is_confirm_delete = false"
            @onSave="doDeleteItem()">
            <span class="span-title">Are you sure you want to delete the item <b>{{form.name}} </b> ?</span>
        </modal>

        <modal
            title="Confirm Change Status Item"
            id="modal-confirm-change-status-item"
            :save="true"
            :remove="false"
            :show="is_confirm_change_status"
            :loading="is_confirm_change_status_loading"
            saveText="Save"
            @hidden="is_confirm_change_status = false"
            @onSave="doChangeStatus">
            <span class="span-title">Are you sure you want to change status <b>{{ form && form.status_name_replace ? form.status_name_replace : ``}}</b>  the item named <b>{{form && form.name ? form.name : ``}} </b> ?</span>
        </modal>
    </div>
</template>

<script>
const {console} = window
export default {
    data() {
        return {
            config: {},
            alias: 'item',
            permission_type: 'create',
            permission_create: false,
            permission_edit: false,
            permission_delete: false,
            is_confirm_delete: false,
            is_confirm_delete_loading: false,
            is_confirm_change_status: false,
            is_confirm_change_status_loading: false,
            form: {
                id: null,
                name: null
            },
            user: this.$root.$store.state.user || null,
            settingFilters: '',
            defaultFilter: null,
            filter_status: '',
            filter_status_temp: '',
        }
    },
    async mounted() {
        this.$root.checkingAccessPage(this.$root._route.name)
        let permission = await this.$root.checkingButtonsPage(this.alias, this.permission_type)
        let permissionEdit = await this.$root.checkingButtonsPage(this.alias, 'update')
        let permissionDelete = await this.$root.checkingButtonsPage(this.alias, 'delete')
        if(permission.validate) {
            this.permission_create = true 
        }

        if(permissionEdit.validate) {
            this.permission_edit = true 
        }

        if(permissionDelete.validate) {
            this.permission_delete = true 
        }

        if(!this.user) {
            if(!this.user) {
                let userMe = localStorage.getItem('_USER_ME')
                if(userMe) {
                    let userMeBytes = this.CryptoJS.AES.decrypt(userMe, process.env.VUE_APP_KEY_ENCRYPT_DECRYPT)
                    let userMeData = JSON.parse(userMeBytes.toString(this.CryptoJS.enc.Utf8))
                    if(userMeData) {
                    this.user = userMeData
                    this.$root.$store.state.user = userMeData
                    }
                }
            }
        }

        // this.init()
        this.setupSettingFilter()


        let self = this
        $(document).ready(function() {
            $(document).on('click', '.click-view-item', function(e) {
                e.preventDefault();
                if (self.isDoubleClicked($(this))) return;
                let data = $(this).attr('data')
                self.redirectToForm(data, false)
            })
            
            $(document).on('click', '.click-edit-item', function(e) {
                e.preventDefault();
                if (self.isDoubleClicked($(this))) return;
                let data = $(this).attr('data')
                self.redirectToForm(data, true)
            })

            $(document).on('click', '.click-delete-item', function(e) {
                e.preventDefault();
                let data = $(this).attr('data')
                let name = $(this).attr('data-name')
                if(self.permission_delete) {
                    self.form.id = data 
                    self.form.name = name 

                    self.is_confirm_delete = true
                    this.is_confirm_delete_loading = false
                }
                
            })

            $(document).on('change', '.select-filter-status', function(e) {
                let val = $('.select-filter-status').val()
                self.filter_status = val
            })

            $('.select-filter-status').on('select2:select', (e) => {
                let data = e.params.data
                self.filter_status = data.id
            })

            $(document).on('click', '.click-change-status', function(e) {
                e.preventDefault()
                self.form = {
                    id: null,
                    name: null,
                }

                let data = $(this).attr('data')

                let secretKey = process.env.VUE_APP_KEY_ENCRYPT_DECRYPT
                let decrypt = self.EncryptDecryptAesCbc256.decryptAesCbc256(secretKey, data)
                if(decrypt) {
                    let res = JSON.parse(decrypt)
                    self.form = res
                    self.is_confirm_change_status = true
                }
                
            })
        })

        setTimeout(self.selectDropdown, 1500);
    },
    methods: {
        selectDropdown() {
            $('.select2').select2()

            $('.select-filter-status').select2()
            $('.select-filter-status').val([this.filter_status]).trigger('change')
        },
        tiggerUpdateDropDown() {
            try{
                let data = $(".select-filter-status").select2('data')
                if(data) {
                    if(this.filter_status != this.filter_status_temp) {
                        $('.select-filter-status').val([this.filter_status]).trigger('change')
                        this.filter_status_temp = this.filter_status
                    }
                }
            }catch{
                $('.select-filter-status').select2()
                console.clear()
            }
        },
        async setupSettingFilter() {
            this.settingFilters = `<div>
                <span> Filter : &nbsp;  </span>
                <select type="text" class="form-control select2 select-filter-status" >
                    <option value="">All Status</option>
                    <option value="true">Active</option>
                    <option value="false">Not Active</option>
                </select>
            </div>`

            this.init()
            setTimeout(self.selectDropdown, 500);
        },
        async init() {
            let urlLink = '/item'
            let defaultFilter = null 
            if(this.filter_status) {
                var boolString = this.filter_status; 
                var boolValue = (boolString === "true"); 
                defaultFilter = [["is_active", "=", boolValue]]
            }

            this.defaultFilter = defaultFilter

            let self = this 
            this.config = {
                columns: [{
                    title: 'Name',
                    data: 'name'
                }, {
                    title: 'Category',
                    data: 'category'
                }, {
                    title: 'Description',
                    data: 'description'
                },{
                    title: 'Status',
                    data: 'status_name',
                    render(value, type, row) {
                        if(self.permission_edit) {
                            return self.renderStatus(row)
                        }

                        return row.status_name
                    }
                },{
                    title: 'Action',
                    data: 'id',
                    searchable: false,
                    orderable: false,
                    render(value, type, row) {
                        return self.renderAction(row)
                    }
                }],
                pageStartAt: 0,
                responsive: false,
                scrollX: true,
                searchPlaceholder: 'Search an item ...',
                url: Api.base + urlLink
            }
        },
        add() {
            this.$root.$router.push({name: 'master.item.form.create'})
        },
        click(row) {
            if(row.data()) {
                this.$root.$router.push({name: 'master.item.form', params: row.data()})
            }
        },
        renderStatus(row) {
            let toogle_active = `
            <svg style="margin-bottom: -2px" width="28" height="16" viewBox="0 0 28 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="28" height="16" rx="8" fill="#6147FF"/>
                <circle cx="20" cy="8" r="6" fill="white"/>
            </svg>
            `

            let toogle_inactive = `
            <svg style="margin-bottom: -2px" width="28" height="16" viewBox="0 0 28 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="28" height="16" rx="8" fill="#9E9E9E"/>
                <circle cx="8" cy="8" r="6" fill="white"/>
            </svg>
            `

            let is_active = row.is_active ? row.is_active : false
            let status_name_replace = row.is_active ? `not active` : `active`
            let data = row 
            data.status_name_replace = status_name_replace
            data.is_active = is_active

            let data_str = JSON.stringify(data)
            let secretKey = process.env.VUE_APP_KEY_ENCRYPT_DECRYPT
            let encrypt = this.EncryptDecryptAesCbc256.encryptAesCbc256(secretKey, data_str)

            let button = `<div>`
            button += `<span class="click-change-status" data="${encrypt}">`
            if(is_active) {
                button += `<span>`+toogle_active+`</span>`
            }else {
                button += `<span>`+toogle_inactive+`</span>`
            }
            button += ` <span>`+row.status_name+'</span>'
            button += `</span>`
            button += `</div>`
            
            return button
        },
        renderAction(row) {
            let viewButton = `<span style="margin: 0px 15px 0px 0px" class="click-view-item" data="${row.id}"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path d="M12.5 10.0002C12.5 11.3809 11.3807 12.5002 10 12.5002C8.6193 12.5002 7.50001 11.3809 7.50001 10.0002C7.50001 8.61949 8.6193 7.5002 10 7.5002C11.3807 7.5002 12.5 8.61949 12.5 10.0002Z" stroke="#757575" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M2.04855 10.0002C3.11045 6.61927 6.26902 4.16687 10.0004 4.16687C13.7318 4.16687 16.8903 6.6193 17.9522 10.0002C16.8903 13.3811 13.7318 15.8335 10.0004 15.8335C6.26902 15.8335 3.11043 13.3811 2.04855 10.0002Z" stroke="#757575" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg></span>`

            let editButton = `<span style="margin: 0px 15px 0px 0px" class="click-edit-item" data="${row.id}"><svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                    <path d="M13.1221 4.36019L16.0684 7.30647M14.3721 3.11019C15.1857 2.2966 16.5048 2.2966 17.3184 3.11019C18.132 3.92379 18.132 5.24288 17.3184 6.05647L5.8453 17.5296H2.92863V14.5537L14.3721 3.11019Z" stroke="#404040" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg></span>`
            let deleteButton = `<span class="click-delete-item" data="${row.id}" data-name="${row.name}"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M19 7L18.1327 19.1425C18.0579 20.1891 17.187 21 16.1378 21H7.86224C6.81296 21 5.94208 20.1891 5.86732 19.1425L5 7M10 11V17M14 11V17M15 7V4C15 3.44772 14.5523 3 14 3H10C9.44772 3 9 3.44772 9 4V7M4 7H20" stroke="#404040" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg></span>`
            let show = viewButton
            if(this.permission_edit) {
                show += editButton
            }

            if(this.permission_delete) {
                show += deleteButton
            }

            let button = `<div>`
            button += show
            button += `</div>`

            return button
        },
        redirectToForm(id, is_edit) {
            if(id) {
                this.$root.$router.push(
                    {
                        name: 'master.item.form', 
                        params: {
                            id: id
                        },
                        query: {
                            is_edit: is_edit
                        }
                    }
                )
            }
            
        },
        isDoubleClicked(element) {
            //if already clicked return TRUE to indicate this click is not allowed
            if (element.data("isclicked")) return true;

            //mark as clicked for 1 second
            element.data("isclicked", true);
            setTimeout(function () {
                element.removeData("isclicked");
            }, 1000);

            //return FALSE to indicate this click was allowed
            return false;
        },
        async doDeleteItem() {
            if (this.permission_delete && this.form.id) {
                this.is_confirm_delete_loading = true
                Rest.delete('/item/'+this.form.id)
                .then( async(res) => {
                    if (res && res.status && res.status == 200) {
                        this.$root.showNotification('Success', 'success', 'Item deletion successful.')

                        this.userLogActivity('delete')
                        this.init()
                    }else {
                        this.$root.showNotification('Success', 'error', 'Item deletion failed')
                    }
                })
                .catch( (err) => {
                    if(err && err.response && err.response.status) {
                        if(err.response.data && err.response.data.message) {
                            this.$root.showNotification('Failed', 'error', err.response.data.message)        
                        }else {
                            var message = "Item deletion failed"
                            this.$root.showNotification('Failed', 'error', message)
                        }
                    }else {
                        this.$root.showNotification('Failed', 'error', 'Sorry, you do not have an internet connection.')
                    }
                })
                .finally( () => {
                    this.is_confirm_delete_loading = false 
                    this.is_confirm_delete = false
                })
            }
            
        },
        async userLogActivity(activity_type) {
            if(this.$root.$store.state.user && this.$root.$store.state.user.full_name) {
                let activity = `${this.$root.$store.state.user.full_name} has been remove item with the name ${this.form.name} `
                if(activity_type == 'edit') {
                    activity = `${this.$root.$store.state.user.full_name} has been  modified status item with the name ${this.form.name} `
                }

                let params = {
                    activity: activity,
                    feature: 'Item',
                    activity_type: activity_type,
                }

                this.$root.sendUserLogActivity(params)
            }
            
        },  
        async doChangeStatus() {
            if(this.form && this.form.id) {
                this.is_confirm_change_status_loading = true
                let params = this.form
                params.is_active = !this.form.is_active
                Rest.put('/item/'+this.form.id, params)
                .then( async(res) => {
                    let message_success = 'Item change status successful.'
                    this.$root.showNotification('Success', 'success', message_success)
                    this.userLogActivity('edit')
                    this.setupSettingFilter()
                    this.form = {
                        id: null,
                        name: null
                    }
                })
                .catch( async(err) => {
                    if(err && err.response && err.response.status) {
                        if(err.response.data && err.response.data.message) {
                            this.$root.showNotification('Failed', 'error', err.response.data.message)        
                        }else {
                            var message = "Item change status failed"
                            this.$root.showNotification('Failed', 'error', message)
                        }
                    }else {
                        this.$root.showNotification('Failed', 'error', 'Sorry, you do not have an internet connection.')
                    }
                })
                .finally( () => {
                    this.is_confirm_change_status = false 
                    this.is_confirm_change_status_loading = false 
                })
            }
        } 
    },
    computed: {

    },
    watch: {
        filter_status: {
            handler() {
                this.init()
                setInterval(this.tiggerUpdateDropDown, 200)
            }
        },
    }
}
</script>

<style scoped>

</style>