<template>
    <div>
        <div class="modal fade" id="modal-project" aria-hidden="true" data-backdrop="static" data-keyboard="false">
            <div class="modal-dialog" style="width: 70%">
                <div class="modal-content">
                    <div class="modal-header">
                        <close-x/>
                        <h4 class="modal-title">Pilih Proyek</h4>
                    </div>
                    <div class="modal-body" >
                        <datatable :config="config" @click="clickRow"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";

const {console} = window
export default {
    name: 'project-modal',
    data() {
        return {
            config: {},
            // branch_office_id : [],
            branch_office: {
              name: ""
            },
            me: {},
            role: {},
            last_branch_office_name: "",
            is_modal: false,
        }
    },
    async created() {
      this.last_branch_office_name = this.branch_office.name
      let temp = localStorage.getItem('_USER_ROLES')
        if(temp) {
          let bytes = this.CryptoJS.AES.decrypt(temp, process.env.VUE_APP_KEY_ENCRYPT_DECRYPT)
          let data = JSON.parse(bytes.toString(this.CryptoJS.enc.Utf8))
          if(data ) {
            this.role.id = data.role_id
            this.role.name = data.role_name
          }
        }
    },
    mounted() {
      let self = this 
      $( document ).ready(function() {
        $(document).on('click', '#modal-project button[data-dismiss="modal"]', function() {
          self.is_modal = false 
          $("body").css("overflow-y", "hidden")
          $("body").addClass("modal-open-custom")
        })
      })
        
      //   $(document).on('click', '#session_project', (e) => {
      //       e.preventDefault()
      //       this.branch_office = this.$store.state.branch_office || JSON.parse(localStorage.getItem('_PRJ_SELECT'))
      //       this.last_branch_office_name = this.branch_office.name
      //       $('#modal-project', this.$el).modal('show')
      //       self.is_modal = true 
            
       
      // })
        this.$root.$on('send_branch_office', (data) => {
          this.branch_office_id = data
          console.log('send branch office list', data, this.branch_office_id)
        })
    },
    methods: {
        async clickRow(row) {
            this.$root.$emit('refresh_sidebar')
            $('#modal-project', this.$el).modal('hide')
            this.is_modal = false
            // await this.saveLogActivities('update', row.data())

            Rest.get('/role/me')
            .then( res => {
              if(res.data.data.role_detail.navigations) {
                localStorage.setItem('navigations', JSON.stringify(JSON.stringify(res.data.data.role_detail.navigations)))
                var permission = this.CryptoJS.AES.encrypt(JSON.stringify(res.data.data.role_detail.permission), process.env.VUE_APP_KEY_ENCRYPT_DECRYPT).toString()
                var role = this.CryptoJS.AES.encrypt(JSON.stringify(res.data.data), process.env.VUE_APP_KEY_ENCRYPT_DECRYPT).toString()
                localStorage.setItem('_USER_PERMISSION', permission)
                localStorage.setItem('_USER_ROLES', role)
              }

              $('#session_project').text(this.$store.state.project.name)
              // if(project_id != data.id) {
                window.location.reload()
              // }
            })
            .catch( () => {
              $('#session_project').text(this.$store.state.project.name)
              // if(project_id != data.id) {
                window.location.reload()
              // }

            })

        },
        async saveLogActivities(type,detail) {
          let message = null
          let route_name = {
            name: this.$root._route.name
          }

          type = type.toLowerCase()
          switch (type) {
            case 'update':
              message = this.me.name+" telah berpindah proyek dari "+ this.branch_office.name + " ke "+detail.name+ " di ERP Gapuraprima"
              break;
            default:
              break;
        }
        let possible_type = ['update']
          if(possible_type.indexOf(type) >= 0) {
            let log_activities = {
              description: message,
              menu: 'Project Modal',
              user: this.me,
              detail: detail,
              route_name: route_name,
              type_activities: type ,
              project: this.project,
              date: moment().format('YYYY-MM-DD HH:mm:ss'),
              role: this.role
            }

            Rest.post('/user/log-activities', log_activities)
          }
      },
    },
    watch: {
      is_modal: {
        handler: function(val) {
          if(!val) {
            $("body").removeClass("modal-open-custom")
            $("body").css("overflow-y", "auto")
          }else{
            $("body").addClass("modal-open-custom")
            $("body").css("overflow-y", "hidden")
          }
        }
      }
    }
}
</script>
