<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.06031 4.86021C9.34071 1.5798 14.6593 1.5798 17.9397 4.86021C21.2201 8.14061 21.2201 13.4592 17.9397 16.7396L12 22.6793L6.06031 16.7396C2.77991 13.4592 2.77991 8.14061 6.06031 4.86021ZM12 13.1999C13.3255 13.1999 14.4 12.1254 14.4 10.7999C14.4 9.47442 13.3255 8.3999 12 8.3999C10.6745 8.3999 9.60001 9.47442 9.60001 10.7999C9.60001 12.1254 10.6745 13.1999 12 13.1999Z" fill="#616161"/>
    </svg>
</template>

<script>
export default {
    props: {
        width: {
            type: String,
            default: '20'
        },
        height: {
            type: String,
            default: '20'
        },
        fill: {
            type: String,
            default: 'none'
        },
        fill_path_1: {
            type: String,
            default: 'white'
        },
        fill_path_2: {
            type: String,
            default: 'white'
        }
    },
    data() {
        return {
            
        }
    },
}
</script>

<style scoped>

</style>
