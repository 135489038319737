<template>
  <div>
    <box
      :save="true"
      @onSave="doSave"
    >
      <div class="row">
        <div class="col-md-6">
          <div class="form-group" :class="{'has-error': !!errors.name}">
            <label class="control-label">Nama Lengkap *</label>
            <input type="text" class="form-control"
              v-model="user.name"
              />
            <p class="help help-block" v-if="errors.name">Wajib diisi</p>
          </div>
          <div class="form-group" :class="{'has-error': !!errors.detail.profile_data.phone_number}">
            <label class="control-label">No HP *</label>
            <input class="form-control" v-model="user.detail.profile_data.phone_number"
              @keydown="$root.validateNumberOnly($event)" />
            <p class="help help-block" v-if="errors.detail.profile_data.phone_number">Wajib diisi, minimal 10 karakter , maksimal 13 karakter</p>
          </div>
          <div class="form-group" :class="{'has-error': !!errors.detail.profile_data.id_card}">
            <label class="control-label">No KTP *</label>
            <input class="form-control" v-model="user.detail.profile_data.id_card"
              @keydown="$root.validateNumberOnly($event)" />
            <p class="help help-block" v-if="errors.detail.profile_data.id_card">Wajib diisi 16 karakter</p>
          </div>
          <div class="form-group" :class="{'has-error': !!errors.detail.profile_data.date_of_birth}">
            <label class="control-label">Tanggal Lahir *</label>
            <datepicker  v-model="user.detail.profile_data.date_of_birth"
              :config="dateConfig"
            />
            <p class="help help-block" v-if="errors.detail.profile_data.date_of_birth">Wajib diisi</p>
          </div>
          <div class="form-group ">
            <label>Tanda Tangan</label>
            <div class="border-solid-1-black align-items-center" style="width:300px; height:324px; display: grid;">
              <div class="btn-edit-signature">
              <router-link class="fa fa-edit fa-2x"
                :to="{ name: 'profile.change-signature', params: {} }">
              
              </router-link>
              </div>
              <div class="frame"
                v-if="signatureUrl == '#' || !signatureUrl"
                >
              </div>
               <div class="frame" v-else>
              <img 
                :src="signatureUrl" class="img_signature"/>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label class="control-label">Email</label>
            <div class="input-group">
              <input type="text" class="form-control"
                v-model="user.username"
                :disabled="true"
                />
              <div class="input-group-addon btn-change">
                <router-link :to="{ name: 'profile.change-email', params: {} }">Ubah Email</router-link>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label class="control-label">Password</label>
            <div class="input-group">
              <input type="password" class="form-control"
                v-model="user.password"
                :disabled="true"
                />
              <div class="input-group-addon btn-change">
                <router-link :to="{ name: 'profile.change-password', params: {} }">Ubah Password</router-link>
              </div>
            </div>
          </div>
          <div class="form-group" >
            <label>Gaji Karyawan</label>
            <currency-input class="form-control"
             v-model="user.detail.profile_data.salary"
             :disabled="true"
            />
          </div>
          <div class="form-group">
             <label>Tipe Karyawan *</label>
             <div class="form-group" >
                 <div class="icheck-primary">
                     <input type="radio" id="r1"
                      name="customRadio"
                      value="Tetap"
                      :disabled="true"
                      v-model="user.detail.profile_data.employee_type" />
                     <label for="r1">Tetap</label>
                 </div>
                 <div class="icheck-primary"
                  style="margin-top: -28px !important;margin-left: 100px; padding-bottom: 5px">
                     <input type="radio" id="r2"
                      name="customRadio"
                      value="Kontrak"
                      :disabled="true"
                      v-model="user.detail.profile_data.employee_type" />
                     <label for="r2">Kontrak</label>
                 </div>
             </div>
         </div>
         <div class="form-group">
             <label>Entitas *</label>
             <div class="form-group" >
                 <div class="icheck-primary">
                     <input type="radio" id="r1"
                      name="customRadio2"
                      value="Pusat"
                      :disabled="true"
                      v-model="user.detail.profile_data.entity_type" />
                     <label for="r1">Pusat</label>
                 </div>
                 <div class="icheck-primary"
                  style="margin-top: -28px !important;margin-left: 100px; padding-bottom: 5px">
                     <input type="radio" id="r2"
                      name="customRadio2"
                      value="Cabang"
                      :disabled="true"
                      v-model="user.detail.profile_data.entity_type" />
                     <label for="r2">Cabang</label>
                 </div>
             </div>
         </div>
         <div class="form-group" :class="{'has-error': !!errors.detail.profile_data.address}">
           <label class="control-label">Alamat</label>
           <textarea class="form-control"
            placeholder="Masukkan Alamat"
            v-model="user.detail.profile_data.address"
            rows="7">
           </textarea>
           <p class="help help-block" v-if="errors.detail.profile_data.address">Wajib diisi</p>
         </div>
        </div>
      </div>
    </box>
  </div>
</template>

<script>
const {console} = window
export default {
    data() {
      return {
        user: {
          name: null,
          username: null,
          detail: {
            profile_data: {
              address: null,
              employee_type: null,
              salary: 0,
            }
          }
        },
        errors: {
          name: false,
          username: false,
          detail: {
            profile_data: {
              id_card: false,
              date_of_birth: false,
              phone_number: false,
              address: false
            }
          }
        },
        dateConfig: {
          locale: {
            format: 'DD-MM-YYYY'
          }
        },
        customStyle: {
          border: 'black 1px solid'
        },
        fileUrl: process.env.VUE_APP_UPLOAD_URL,
        signatureUrl: '#',
        me: {},
        role: {},
        project: this.$store.state.project || JSON.parse(localStorage.getItem('_PRJ_SELECT'))
      }
    },
    async created() {
      let temp = localStorage.getItem('_USER_ROLES')
        if(temp) {
          let bytes = this.CryptoJS.AES.decrypt(temp, process.env.VUE_APP_KEY_ENCRYPT_DECRYPT)
          let data = JSON.parse(bytes.toString(this.CryptoJS.enc.Utf8))
          if(data ) {
            this.role.id = data.role_id
            this.role.name = data.role_name
          }
        }
      await this.getUserMe()
    },
    async mounted() {
      await this.init()
    },
    methods: {
      async init() {
        Rest.get('/user/me/detail')
        .then( async(res) => {
          if(res.data) {
            if(res.data.detail) {
              if(res.data.detail.profile_data) {
                if(res.data.detail.profile_data.date_of_birth) {
                  try {
                    res.data.detail.profile_data.date_of_birth = moment(res.data.detail.profile_data.date_of_birth, 'YYYY-MM-DD').format('DD-MM-YYYY')
                    this.dateConfig = {
                      locale: {
                        format: 'DD-MM-YYYY'
                      }
                    }
                  } catch {}
                }

                if(!res.data.detail.profile_data.employee_type) {
                  res.data.detail.profile_data.employee_type = ''
                }
                if(!res.data.detail.profile_data.salary) {
                  res.data.detail.profile_data.salary = 0
                }

                if(res.data.detail.profile_data.signature) {
                  this.signatureUrl = this.fileUrl+res.data.detail.profile_data.signature
                }

              }
            }
          }
          this.user = res.data
        })
        .catch( (err) => {
            console.log('error', err.response)
        })
      },
      async manualValidation() {
        this.errors.name = false
        this.errors.detail.profile_data.phone_number = false
        this.errors.detail.profile_data.id_card = false
        this.errors.detail.profile_data.date_of_birth = false
        this.errors.detail.profile_data.address = false
        let success = true

        if(!this.user.name) {
          this.errors.name = true
          success = false
        }

        if(!this.user.detail.profile_data.phone_number) {
          this.errors.detail.profile_data.phone_number = true
          success = false
        }else{
          let length = this.user.detail.profile_data.phone_number.toString().length
          if (length < 10 || length > 13) {
             this.errors.detail.profile_data.phone_number = true
             success = false
          }
        }

        if (!this.user.detail.profile_data.id_card) {
            this.errors.detail.profile_data.id_card = true
            success = false
        }else{
            let length = this.user.detail.profile_data.id_card.toString().length
            if (length != 16) {
              this.errors.detail.profile_data.id_card = true
              success = false
            }
        }

        if(!this.user.detail.profile_data.date_of_birth) {
          this.errors.detail.profile_data.date_of_birth = true
          success = false
        }

        if(!this.user.detail.profile_data.address) {
          this.errors.detail.profile_data.address = true
          success = false
        }

        return success
      },
      async doSave() {
        let validate = await this.manualValidation()
        if(validate ){
          this.user.detail.profile_data.date_of_birth = moment(this.user.detail.profile_data.date_of_birth, 'DD-MM-YYYY').format('YYYY-MM-DD')
          Rest.put('/user/me/change-profile', this.user)
          .then( (res) => {
            if(res.status == 200) {
              this.$root.$emit('send_full_name', this.user.name)
              this.$root.$emit('navbar_full_name', {
                name: this.user.name,
                username: this.user.username
              })
              this.saveLogActivities('update', this.user.detail)
              this.$root.showNotification('Berhasil', 'success', 'Sukses ubah profile')
            }else{
              this.$root.showNotification('Gagal', 'error', 'Gagal ubah profile')
            }
          })
          .catch( (err) => {
            this.$root.showNotification('Gagal', 'error', err.response.data.message)

          })
          .finally( () => {
            console.log('finally save', this.user.detail.profile_data.date_of_birth)
            this.user.detail.profile_data.date_of_birth = moment(this.user.detail.profile_data.date_of_birth, 'YYYY-MM-DD').format('DD-MM-YYYY')
          })
        }
      },
      async saveLogActivities(type,detail) {
          let message = null
          let route_name = {
            name: this.$root._route.name
          }

          type = type.toLowerCase()
          switch (type) {
            case 'update':
              message = this.me.name+" telah mengubah profile"
              break;
            default:
              break;
        }
        let possible_type = ['update']
          if(possible_type.indexOf(type) >= 0) {
            let log_activities = {
              description: message,
              menu: 'Change Profile',
              user: this.me,
              detail: detail,
              route_name: route_name,
              type_activities: type ,
              project: this.project,
              date: moment().format('YYYY-MM-DD HH:mm:ss'),
              role: this.role
            }

            Rest.post('/user/log-activities', log_activities)
          }
      },
      async getUserMe() {
          Rest.get('/user/me/detail').then( async(res) => {
            this.me = {
              id: res.data.id,
              name: res.data.name,
              username: res.data.username,
              detail: res.data.detail
            }
          })
        },
    },
    watch: {
      'user.name': {
        handler(val) {
          this.errors.name = false
          if(!val) {
            this.errors.name = true
          }
        }
      },
      'user.detail.profile_data.phone_number' : {
        handler(val) {
          this.errors.detail.profile_data.phone_number = false
          if(!val) {
            this.errors.detail.profile_data.phone_number = true
          }else{
            let length = this.user.detail.profile_data.phone_number.toString().length
            if (length < 10 || length > 13) {
               this.errors.detail.profile_data.phone_number = true
            }
          }
        }
      },
      'user.detail.profile_data.id_card' : {
        handler(val) {
          this.errors.detail.profile_data.id_card = false
          if(!val) {
            this.errors.detail.profile_data.id_card = true
          }else{
            let length = this.user.detail.profile_data.id_card.toString().length
            if(length != 16) {
              this.errors.detail.profile_data.id_card = true
            }
          }
        }
      },
      'user.detail.profile_data.date_of_birth' : {
        handler(val) {
          this.errors.detail.profile_data.date_of_birth = false
          if(!val) {
            this.errors.detail.profile_data.date_of_birth = true
          }
        }
      },
      'user.detail.profile_data.address' : {
        handler(val) {
          this.errors.detail.profile_data.address = false
          if(!val) {
            this.errors.detail.profile_data.address = true
          }
        }
      }
    }
  }
</script>

<style scoped>
  .border-solid-1-black{
    border: 1px solid #000;
    border-radius: 5px;
  }
  .btn-change{
    cursor: pointer;
    background: #ccc !important;
  }
  .btn-change a{
    cursor: pointer;
    color: #000;
  }
  .btn-edit-signature{
    /* position: absolute; */
    cursor: pointer;
    color: #3c8dbc;
    height: 24px; 
    text-align-last: right;
    /* top: 10px; */
  }
  .fa-2x {
    height: 20px;
    margin-top: 2px;
    margin-right: 2px;
  }
  .img_signature {
  max-height: 98%;
  max-width: 98%;
  width: auto;
  height: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.frame {
  height: 300px; /*can be anything*/
  width: 300px; /*can be anything*/
  display: inline-block;
  vertical-align: top; /*not required*/
  position: relative;
}
</style>
