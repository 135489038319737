<template>
    <div>
        <header-title-button
            title="Log"
            :isAdd="false">
        </header-title-button>

        <div class="row">
            <div class="col-md-6 col-xs-12">
                <div class="notification-switch">
                    <span class="notification-type-switch-btn"
                        :class="is_tabs_user_log ? 'notification-type-switch-btn-active' : ''"
                        @click="showTabsUserLog">
                        <icon-user
                            :stroke="is_tabs_user_log ? enable_color : disable_color">
                        </icon-user>
                        <span class="summary-head-title" :class="is_tabs_user_log ? 'active' : '' ">User</span>
                    </span>

                    <span class="notification-type-switch-btn"
                        :class="is_tabs_visitor_log ? 'notification-type-switch-btn-active' : ''"
                        @click="showTabsVisitorLog">
                        <icon-users
                            :stroke="is_tabs_visitor_log ? enable_color : disable_color">
                        </icon-users>
                        <span class="summary-head-title" :class="is_tabs_visitor_log ? 'active' : '' ">Visitor</span>
                    </span>
                </div>
            </div>
        </div>

        <br>
        <div v-if="is_tabs_user_log">
            <user-log :unix_time="unix_time"></user-log>
        </div>
        <div v-if="is_tabs_visitor_log">
            <visitor-log :unix_time="unix_time"></visitor-log>
        </div>

        <!-- <div class="row">
            <div class="col-md-12">
                <b-card no-body>
                    <b-tabs  class="workshop-tabs standar-tabs" card>
                        <b-tab class="text-black"
                            @click="tabsUserLog"
                            title="User"
                            :active="is_tabs_user_log"
                            v-if="is_show_user_log"
                        >
                            <template slot="title">
                                <span>User</span>
                            </template>

                            <b-card-text>
                                <div sty></div>
                            </b-card-text>

                        </b-tab>
                    </b-tabs>
                </b-card>
            </div>
        </div> -->
    </div>
</template>

<script>
const {console} = window
import UserLog from '@pages/MasterFile/Log/UserLog.vue'
import VisitorLog from '@pages/MasterFile/Log/VisitorLog.vue'
import IconUser from '@components/svg/user/IconUser.vue'
import IconUsers from '@components/svg/user/IconUsers.vue'
export default {
    components: {
        UserLog,
        VisitorLog,
        IconUser,
        IconUsers
    },
    data() {
        return {
            alias: 'log',
            is_tabs_user_log: true,
            is_tabs_visitor_log: false,
            is_show_user_log: true,
            is_show_visitor_log: true,
            unix_time: moment().unix(),
            enable_color: '#6147FF',
            disable_color: '#9E9E9E',
        }
    },
    async mounted() {
        this.$root.checkingAccessPage(this.$root._route.name)
        
    },
    methods: {
        showTabsUserLog(){
            if(!this.is_tabs_user_log) {
                this.is_tabs_user_log = true 
                this.is_tabs_visitor_log = false 
                this.unix_time = moment().unix()
            }
        },
        showTabsVisitorLog() {  
            if(!this.is_tabs_visitor_log) {
                this.is_tabs_user_log = false 
                this.is_tabs_visitor_log = true 
                this.unix_time = moment().unix()
            }
        }
    },
    computed: {

    },
    watch: {

    }
}
</script>

<style scoped>
.notification-switch {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 2px;
        gap: 2px;
        align-self: stretch;
        border-radius: 12px;
        border: 1px solid #E0E0E0;
        background: #F5F5F5;
    }

    .notification-type-switch-btn {
        display: flex; padding: 8px 0px;
        justify-content: center;
        align-items: center;
        gap: 2px;
        border-radius: 10px;
        width: 100%;
        cursor: pointer;
    }

    .notification-type-switch-btn-active {
        background: #FFF;
        box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.10);
    }

    .summary-head-title.active {
        color: #6147FF;
        font-weight: 700;
    }

    .div-editor-dinamis_variable {
        padding: 10px; 
        background-color: #E0E0E0;
    }
</style>