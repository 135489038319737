import Vue from 'vue'
const { console } = window

const DateInputValidate = {
    name: 'DateInputValidateItem',
    props: ['formId', 'formName', 'value', 'validation', 'formPlaceholderName', 'formLabel', 'formType', 'fromIcon', 'formDisabled', 'formMinDate', 'formMaxDate' ],
    template:
        `<div :class="validation ? 'form-group has-error' : 'form-group'">
            <label>{{formLabel}}</label>
            <div class="input-group ">
                <div class="input-group-addon">
                    <i class="fa fa-calendar"></i>
                </div>
                <input
                    v-bind:id="formId"
                    v-bind:name="formName"
                    type="date"
                    class="form-control pull-right"
                    v-bind:value="value"
                    v-bind:min="formMinDate"
                    v-bind:max="formMaxDate"
                    v-on:input="$emit('input', $event.target.value)"
                    v-bind:placeholder="formPlaceholderName"
                    v-bind:disabled="formDisabled"
                />
            </div>
            <div class="help-block">{{ validation }}</div>
        </div>`,
    data() {
        return {
        }
    },
    mounted(){
    },
    methods: {
    }
}
Vue.component('DateInputValidateItem', DateInputValidate)
export default DateInputValidate
