<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.303 4.30304C17.2402 3.36578 18.7598 3.36578 19.6971 4.30304C20.6343 5.2403 20.6343 6.7599 19.6971 7.69715L18.7456 8.64863L15.3515 5.25451L16.303 4.30304Z" fill="#757575"/>
        <path d="M13.6544 6.95157L3.60001 17.006V20.4001H6.99412L17.0485 10.3457L13.6544 6.95157Z" fill="#757575"/>
    </svg>
</template>

<script>
export default {
    props: {
        width: {
            type: String,
            default: '20'
        },
        height: {
            type: String,
            default: '20'
        },
        fill: {
            type: String,
            default: 'none'
        },
        fill_path_1: {
            type: String,
            default: 'white'
        },
        fill_path_2: {
            type: String,
            default: 'white'
        }
    },
    data() {
        return {
            
        }
    },
}
</script>

<style scoped>

</style>