<template>
    <li :class="{'treeview': item.items, 'menu-open active': this.childs.indexOf(routeName) >= 0}"
      v-if="item.notChild == undefined || item.notChild.length < 1">
        <a v-if="!item.route && !item.items && item.title" :target="item.target" :href="item.href || '#'">
            <span style="display: flex; align-items: center;gap: 5px" v-if="item.icon_svg">
              <span v-html="item.icon_svg" style="padding-top: 2px;"></span>
              <span v-html="item.title"></span>
            </span>
            <span v-else>
              <i class="nav-icon" :class="item.icon" v-if="item.icon"></i>&nbsp;
              <span v-html="item.title"></span>
            </span>
            <span class="pull-right-container" v-if="item.icon_right">
                <span class="pull-right" :id="item.id_icon_right"
                  :class="item.icon_right">{{item.icon_right_text}}
                </span>
            </span>
        </a>
        <!-- v-if="(item.route && this.navigations.indexOf(item.route.name) != -1) && !item.items && (this.navigations.indexOf(item.route.name) != -1 || item.skip_roles == true)" -->
        <router-link :to="item.route" v-if="isAllowToShow(item) && item.title" :class="{'active': isActive(item) }">
            <span style="display: flex; align-items: center;gap: 5px" v-if="item.icon_svg">
              <span v-html="item.icon_svg" style="padding-top: 2px;"></span>
              <span v-html="item.title"></span>
            </span>
            <span v-else>
              <i class="nav-icon" :class="item.icon" v-if="item.icon"></i>&nbsp;
              <span v-html="item.title"></span>
            </span>
            
            <span class="pull-right-container">
                <span class="pull-right"
                  :id="item.id_icon_right"
                  :class="item.icon_right"
                  v-if="item.icon_right">
                  {{item.icon_right_text}}
                </span>
            </span>
        </router-link>
        <!-- <a :href="item.href"  v-if="isAllowToShow(item) && item.refresh" :class="{'active': isActive(item) }">
          <i class="nav-icon" :class="item.icon" v-if="item.icon"></i>
          <span v-html="item.title"></span>
          <span class="pull-right-container">
              <span class="pull-right" :class="item.icon_right" v-if="item.icon_right">{{item.icon_right_text}}</span>
          </span>
        </a> -->
        <a href="#" v-if="item.items && item.title" :class="{'active': this.childs.indexOf(routeName) >= 0}">
            <span style="display: flex; align-items: center;gap: 5px" v-if="item.icon_svg">
              <span v-html="item.icon_svg" style="padding-top: 2px;"></span>
              <span v-html="item.title"></span>
            </span>
            <span v-else>
              <i class="nav-icon" :class="item.icon" v-if="item.icon"></i>&nbsp;
              <span v-html="item.title"></span>
            </span>
            <span class="pull-right-container">
                <i class="fas fa-angle-left pull-right"></i>
                <span class="pull-right"
                  :id="item.id_icon_right"
                  :class="item.icon_right"
                  v-if="item.icon_right">
                  {{item.icon_right_text}}
                </span>
            </span>
        </a>
        <ul class="treeview-menu" v-if="item.items" v-show="this.childs.indexOf(routeName) >= 0">
            <nav-item v-for="(it, index) in item.items"
              :key="index"
              @routeAdd="routeAdd"
              :item="it"
              :routeName="routeName"
              :navigation="navigations">
            </nav-item>
        </ul>
    </li>
</template>

<script>
import NavItem from './NavItem.vue'
import dataMenu from '@config/datamenu.json'
const {console} = window
export default {
    name: 'nav-item',
    props: ['item', 'routeName', 'navigation'],
    data: () => ({
        childs: [],
        navigations : [],
        selected_on: []
    }),
    async mounted() {
        this.selected_on = this.item.selectedOn || this.item.selected_on || []
        if (this.selected_on) {
            this.childs = this.childs.concat(this.selected_on)
        }
        if (this.item.route) {
            this.childs.push(this.item.route.name)
        }

        for (let c in this.childs) {
            this.routeAdd(this.childs[c])
        }



        let userNavigation = []
        var navigationLocalStorage = localStorage.getItem("navigations")
        if(navigationLocalStorage) {
          let navigationByte = this.CryptoJS.AES.decrypt(navigationLocalStorage, process.env.VUE_APP_KEY_ENCRYPT_DECRYPT)
          let dataNavigation = JSON.parse(navigationByte.toString(this.CryptoJS.enc.Utf8))
          if(dataNavigation) {
            userNavigation = dataNavigation
          }
        }
        
        let temp = localStorage.getItem('_USER_ROLES')
          let role_name = null
          if (temp) {
              let bytes = this.CryptoJS.AES.decrypt(temp, process.env.VUE_APP_KEY_ENCRYPT_DECRYPT)
              let data = JSON.parse(bytes.toString(this.CryptoJS.enc.Utf8))
              if (data) {
                  role_name = data.name
              }
          }

        if(role_name == "ROLE_SUPERUSER") {
            var superUserMenu = dataMenu.admin
            var loopCheckbox = ["approval", "create", "delete", "print", "read", "update"]
            var superUserNav = []
            superUserMenu.forEach( (element) => {
              if(element && element.detail && element.detail.checkbox) {
                for(var i = 0; i < loopCheckbox.length; i++) {
                  var access = loopCheckbox[i]
                  if(element.detail.checkbox[access] && element.detail.checkbox[access]) {
                    if(element.detail.checkbox[access].route && element.detail.checkbox[access].route.name) {
                      superUserNav.push(element.detail.checkbox[access].route.name)
                    }

                    if(element.detail.checkbox[access].selected_on && element.detail.checkbox[access].selected_on.length > 0) {
                      superUserNav = superUserNav.concat(element.detail.checkbox[access].selected_on)
                    }

                  }
                }
              }
            })

            if(superUserNav) {
              userNavigation = [ ...new Set(superUserNav) ]
              this.navigations = [ ...new Set(superUserNav) ]
            }

        }
        this.navigations = userNavigation
    },
    components: {
        NavItem
    },
    methods: {
        routeAdd(name) {
            this.$emit('routeAdd', name)
            if (typeof this.item.items != 'undefined' && this.childs.indexOf(name) < 0) {
                this.childs.push(name);
            }
        },
        isActive(item) {
            if (item.route && this.routeName == item.route.name) {
                return true
            }

            if (this.selected_on && this.selected_on.indexOf(this.routeName) >= 0) {
                return true
            }

            return false
        },
        isAllowToShow(item) {
            if (item.items) {
                return false
            }

            if (item.route && (this.navigations.indexOf(item.route.name) >= 0 || item.skip_roles == true)) {
                return true
            }

            return false
        }
    },
    watch: {
      'navigation': function(val) {
        this.navigations = val
      }
    }
}
</script>
