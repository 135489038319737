<template>
    <div>
        <header-title-button
            title="Sales"
            :isAdd="false"
            :isDownload="true"
            :isDownloadDisabled="isDownloadDisabled"
            textDownload="Download (.xlsx)"
            @onClickDownload="doDownload">
        </header-title-button>
        <datatable 
            :config="config" 
            :class="datatable_class"
            :settingFilters="settingFilters"
            :defaultFilter="defaultFilter" />

        <modal :title="'Resend Email Confirmation'" save="true" remove="false"
            :show="is_confirm_resend_email"
            saveText="Yes, Confirmation"
            @hidden="is_confirm_resend_email = false"
            @onSave="doResendEmail()"
            :loading="is_confirm_resend_email_loading">
            <span class="span-title show-action">Are you sure you want to resend email ?</span>
        </modal>

        <modal :title="'Resend Whatsapp Confirmation'" save="true" remove="false"
            :show="is_confirm_resend_whatsapp"
            saveText="Yes, Confirmation"
            @hidden="is_confirm_resend_whatsapp = false"
            @onSave="doResendWhatsapp()"
            :loading="is_confirm_resend_whatsapp_loading">
            <span class="span-title show-action">Are you sure you want to resend whatsapp ?</span>
        </modal>
    </div>
</template>

<script>
import {numberToCurrency} from '@app/function'
import moment from 'moment'
import * as XLSX from 'xlsx'
import { saveAs } from 'file-saver'
import momentTz from 'moment-timezone'
const {console} = window
export default {
    data() {
        return {
            config: {},
            user: this.$root.$store.state.user || null,
            transactionStatus: this.$root.$store.state.transactionStatus,
            settingFilters: '',
            defaultFilter: null,
            filter_server: '',
            filter_server_temp: '',
            filter_status: '',
            filter_status_temp: '',
            filter_date: {
                start_date: moment().startOf('month').format('YYYY-MM-DD'), 
                end_date: moment().endOf('month').format('YYYY-MM-DD')
            },
            filter_date_temp: null,
            rangeOptions: this.$root.$store.state.rangeOptions,
            interval: null,
            machine: [],
            isDownloadDisabled: false,
            is_confirm_resend_email: false,
            is_confirm_resend_email_loading: false,
            is_confirm_resend_whatsapp: false,
            is_confirm_resend_whatsapp_loading: false,
            form: {
                id: null
            }
        }
    },
    async mounted() {
        this.$root.checkingAccessPage(this.$root._route.name)
        this.destroyed()

        await this.getDataMachine()

        if(!this.user) {
            let userMe = localStorage.getItem('_USER_ME')
            if(userMe) {
                let userMeBytes = this.CryptoJS.AES.decrypt(userMe, process.env.VUE_APP_KEY_ENCRYPT_DECRYPT)
                let userMeData = JSON.parse(userMeBytes.toString(this.CryptoJS.enc.Utf8))
                if(userMeData) {
                   this.user = userMeData
                   this.$root.$store.state.user = userMeData
                }
            }
        }

        console.log("filter_status_temp", this.filter_status_temp)

        if(this.$route.query.start_date && this.$route.query.end_date) {
            this.filter_date.start_date = moment(this.$route.query.start_date).format('YYYY-MM-DD')
            this.filter_date.end_date = moment(this.$route.query.end_date).format('YYYY-MM-DD')
        } 

        console.log('this.rooute.query', this.$root.$route.query)

        if(this.$route.query.transaction_status) {
            this.filter_status = this.$route.query.transaction_status
            this.filter_status_temp = this.$route.query.transaction_status
        }

        

        let self = this 
        $(document).ready(function() {
            $(document).on('click', '.click-show-action-menu', function(e) {
                let data = $(this).attr('data')
                

                let dropdowns = document.getElementsByClassName('report-sales-dropdown-content');
                for (let i = 0; i < dropdowns.length; i++) {
                    dropdowns[i].style.display = 'none';
                }

                let id = '#dropdown-menu-'+data
                $(id).css('display', 'block')
            })

            $(document).on('click', '.click-resend-email', function(e) {
                let data = $(this).attr('data')
                self.form.id = data
                self.is_confirm_resend_email = true 
            })

            $(document).on('click', '.click-resend-whats-app', function(e) {
                let data = $(this).attr('data')
                self.form.id = data
                self.is_confirm_resend_whatsapp = true 
            })

            $(document).on('click', '.click-view-order-detail', function(e) {
                e.preventDefault();
                if(self.isDoubleClicked($(this))) return
                let data = $(this).attr('data')
                self.redirectToOrderDetail(data)
            })

            $(document).on('change', '.select-filter-trx-status', function(e) {
                let val = $('.select-filter-trx-status').val()
                self.filter_status = val
                self.filter_status_temp = val 
            })

            $('.select-filter-trx-status').on('select2:select', (e) => {
                let data = e.params.data
                self.filter_status = data.id
                self.filter_status_temp = data.id
            })

            $(document).on('change', '.select-filter-trx-server', function(e) {
                let val = $('.select-filter-trx-server').val()
                self.filter_server = val
                self.filter_server_temp = val 
            })

            $('.select-filter-trx-server').on('select2:select', (e) => {
                let data = e.params.data
                self.filter_server = data.id
                self.filter_server_temp = data.id
            })

            self.loadDateRangePicker()
            
        }) 

        setTimeout(self.selectDropdown, 1500);

        window.onclick = function(event) {
            if (!event.target.matches('.show-action')) {
                self.closeAllDropdowns()
            }
        }
    },
    methods: {
        async getDataMachine() {
            Rest.get('/machine?size=100&sort=code')
            .then( async(res) => {
                if(res && res.data && res.data.items ) {
                    this.machine = this.machine.concat(res.data.items)
                    await this.getDataCashier()
                    
                }
            })
        },
        async getDataCashier() {
            let cashier = []
            Rest.get('/cashier')
            .then( async(res) => {
                if(res && res.data && res.data.length > 0) {
                    res.data.forEach( async(item) => {
                        if(item.id && item.full_name) {
                            cashier.push({
                                id: item.id,
                                code: item.full_name,
                            })
                        }
                    })

                    this.machine = this.machine.concat(cashier)

                    
                }
            })
            .finally( () => {
                this.setupSettingFilter()
            })
        },
        selectDropdown() {
            $('.select2').select2()

            $('.select-filter-trx-status').select2()
            $('.select-filter-trx-status').val([this.filter_status]).trigger('change')

            $('.select-filter-trx-server').select2()
            $('.select-filter-trx-server').val([this.filter_server]).trigger('change')

            this.loadDateRangePicker()

            $('#select-server').select2({width: '230px'})
        },
        tiggerUpdateDropDown() {
            try{
                let data = $(".select-filter-trx-status").select2('data')
                if(data) {
                   
                    if(this.filter_status != this.filter_status_temp) {
                        $('.select-filter-trx-status').val([this.filter_status]).trigger('change')
                        this.filter_status_temp = this.filter_status
                    }
                }
                
            }catch{
                $('.select-filter-trx-status').select2()
                console.clear()
            }

            try{
                let data = $(".select-filter-trx-server").select2('data')
                if(data) {
                   
                    if(this.filter_server != this.filter_server_temp) {
                        $('.select-filter-trx-server').val([this.filter_server]).trigger('change')
                        this.filter_server_temp = this.filter_server
                    }
                }
                
            }catch{
                $('.select-filter-trx-server').select2()
                console.clear()
            }



            let tempDate = $('#reportrange span.date-range-text-info').text()
            if(!tempDate) {
                this.loadDateRangePicker()
            }

            
        },
        async init() {
            let urlLink = '/transaction'
            let self = this 

            let defaultFilter = null 
            if(this.filter_status) {
                defaultFilter = [["status", "=", this.filter_status]]
            }

            if(this.filter_date && this.filter_date.start_date && this.filter_date.end_date) {
                let start = this.filter_date.start_date + ' 00:00:00'
                let end = this.filter_date.end_date + ' 23:59:59'

                start = moment(start).subtract(1, 'hours').format('YYYY-MM-DD HH:mm:ss')
                end = moment(end).subtract(1, 'hours').format('YYYY-MM-DD HH:mm:ss')
                if(defaultFilter) {
                    defaultFilter.push(["AND"])
                    defaultFilter.push(["created_at", ">=", start])
                    defaultFilter.push(["AND"])
                    defaultFilter.push(["created_at",  "<=", end])
                }else {
                    defaultFilter = [["created_at", ">=", start], ["AND"], ["created_at",  "<=", end]]
                }
            }

            if(this.filter_server) {
                if(defaultFilter) {
                    defaultFilter.push(["AND"])
                    defaultFilter.push(["server_id", "=", this.filter_server])
                }else {
                    defaultFilter = [["server_id", "=", this.filter_server]]
                }
            }

            this.defaultFilter = defaultFilter

            let columns = [{
                title: 'Customer Name',
                data: 'customer_name'
            },{
                title: 'Server',
                data: 'server_name'
            }, {
                title: 'Created At',
                data: 'created_at',
                searchable: false,
                render(value, type, row) {
                    let date = momentTz.parseZone(row.created_at)
                    return date.format('D MMMM YYYY, HH:mm')
                }
            },{
                title: 'Order',
                data: 'id',
                searchable: false,
                orderable: false,
                render(value, type, row) {
                    return self.renderOrderDetail(row)
                }
            }, {
                title: 'Total',
                data: 'total_price',
                render(value, type, row) {
                    var total = row.total_price || 0 
                    return numberToCurrency(total, 'Rp')
                }
            },  {
                title: 'Status',
                data: 'status',
                render(value, type, row) {
                    return self.renderStatus(row)
                }
            }, {
                title: 'Aksi',
                data: 'id',
                orderable: false,
                render(value, type, row) {
                    return self.renderAction(row)
                }
            }]

            this.config = {
                columns: columns,
                pageStartAt: 0,
                responsive: false,
                scrollX: true,
                order: [
                    [2, 'desc']
                ],
                searchPlaceholder: 'Search a sales ...',
                url: Api.base + urlLink,
            }

            this.$router.replace({ 
                query : {
                    start_date : this.filter_date.start_date, 
                    end_date: this.filter_date.end_date,
                    transaction_status: this.filter_status
                } 
            })
            .catch(() => {})
        },
        async setupSettingFilter() {
            let self = this

            let optionServer = ``
            this.machine.forEach( (item) => {
                if(item.code && item.id) {
                    if(self.filter_server == item.id) {
                        optionServer += `<option selected value=${item.id}>${item.code}</option>`
                    }else {
                        optionServer += `<option value=${item.id}>${item.code}</option>`
                    }
                }
            })

            let optionStatus = ``
            this.transactionStatus.forEach ( (item) => {
                if(item.name) {
                    if(self.filter_status == item.name) {
                        optionStatus += `<option selected value="${item.name}">${item.name}</option>`
                    }else {
                        optionStatus += `<option value="${item.name}">${item.name}</option>`
                    }
                }
            })

            this.settingFilters = `<div>
                <span> Filter : &nbsp;  </span>
                <select type="text" class="form-control select2 select-filter-trx-server" id="select-server" style="width: 230px;">
                    <option value="">All Server</option>
                    ${optionServer}
                </select>
                <span id="reportrange" class="form-control" style="background: #fff; cursor: pointer; margin-left: 8px; padding: 5px 10px; border: 1px solid #ccc; width: 290px">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M8 7V3M16 7V3M7 11H17M5 21H19C20.1046 21 21 20.1046 21 19V7C21 5.89543 20.1046 5 19 5H5C3.89543 5 3 5.89543 3 7V19C3 20.1046 3.89543 21 5 21Z" stroke="#404040" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    &nbsp;
                    <span class="date-range-text-info" style="position: absolute;margin-top: 2px;"></span> 
                </span>
                <span> &nbsp;  </span>
                <select type="text" class="form-control select2 select-filter-trx-status" >
                    <option value="">All Status</option>
                    ${optionStatus}
                </select>
            </div>`


            this.init()

            setTimeout(self.selectDropdown, 1000);

        },
        renderOrderDetail(row) {
            let name = ''
            let total = row.transaction_detail.length - 1
            row.transaction_detail.forEach( (item ) => {
                if (item.package_name ) {
                    name = item.package_name 
                }
            })

            if(total > 0) {
                return name+ ` <button type="button" class="btn-xs cursor-text btn-label-trx-default">+${total}</button>`            
            }

            return name
        },
        renderStatus(row) {
            let code = row.status
            let classname = 'btn-label-trx-default'
            switch (code.toLowerCase()) {
                case 'pending':
                    classname = 'btn-label-trx-booked'
                    break;
                case 'settlement': 
                    classname = 'btn-label-trx-on-progress'
                    break;
                case 'scanned': 
                    classname = 'btn-label-trx-finish'
                    break;   
                case 'expired':
                    classname = 'btn-label-trx-reject'
                    break;
                default:
                    classname = 'btn-label-trx-default'
                    break;
            }

            let button = `<button type="button" class="btn-xs cursor-text ${classname}" >${row.status}</button>`
            return button 
        },
        renderAction(row) {
            let is_resend_email = true 
            let is_resend_whatsapp = false
            let is_resend_telegram = false  

            if(row.whats_app_mobile_phone_number_code && row.whats_app_mobile_phone_number) {
                is_resend_whatsapp = true 
            }

            if(row.telegram_mobile_phone_number_code && row.telegram_mobile_phone_number) {
                is_resend_telegram = true 
            }

            let  viewButton = `
            <div class="datatable-action-menu show-action">
                <span class="click-show-action-menu show-action" data="${row.id}">
                    <svg class="show-action" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16 6.66732L16 6.68065M16 16.0007L16 16.014M16 25.334L16 25.3473M16 8.00065C15.2636 8.00065 14.6666 7.4037 14.6666 6.66732C14.6666 5.93094 15.2636 5.33398 16 5.33398C16.7364 5.33398 17.3333 5.93094 17.3333 6.66732C17.3333 7.4037 16.7364 8.00065 16 8.00065ZM16 17.334C15.2636 17.334 14.6666 16.737 14.6666 16.0007C14.6666 15.2643 15.2636 14.6673 16 14.6673C16.7364 14.6673 17.3333 15.2643 17.3333 16.0007C17.3333 16.737 16.7364 17.334 16 17.334ZM16 26.6673C15.2636 26.6673 14.6666 26.0704 14.6666 25.334C14.6666 24.5976 15.2636 24.0006 16 24.0006C16.7364 24.0006 17.3333 24.5976 17.3333 25.334C17.3333 26.0704 16.7364 26.6673 16 26.6673Z" stroke="#404040" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </span>
                <div class="report-sales-dropdown-content show-action" id="dropdown-menu-${row.id}">
                   <div class="row click-view-order-detail dropdown-content-detail show-action" data="${row.id}">
                        <div class="col-md-9 show-action">
                            <span class="show-action">View Detail</span>    
                        </div>
                        <div class="col-md-3 show-action" >
                            <span class="pull-right show-action">
                                <svg class="show-action" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                    <path d="M12.5 10.0002C12.5 11.3809 11.3807 12.5002 10 12.5002C8.6193 12.5002 7.50001 11.3809 7.50001 10.0002C7.50001 8.61949 8.6193 7.5002 10 7.5002C11.3807 7.5002 12.5 8.61949 12.5 10.0002Z" stroke="#757575" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M2.04855 10.0002C3.11045 6.61927 6.26902 4.16687 10.0004 4.16687C13.7318 4.16687 16.8903 6.6193 17.9522 10.0002C16.8903 13.3811 13.7318 15.8335 10.0004 15.8335C6.26902 15.8335 3.11043 13.3811 2.04855 10.0002Z" stroke="#757575" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </span>
                        </div>
                    </div>`

                    if(row.status && row.status.toLowerCase() == 'settlement') {
                        
                        if(is_resend_email) {
                            viewButton += `
                            <div class="row click-resend-email dropdown-content-detail show-action" data="${row.id}">
                                <div class="col-md-9 show-action">
                                    <span class="show-action">Resend Email</span>    
                                </div>
                                <div class="col-md-3 show-action" >
                                    <span class="pull-right show-action">
                                        <svg class="show-action" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M2 5.33398L7.2604 8.84092C7.70827 9.13949 8.29173 9.13949 8.7396 8.84092L14 5.33398V4.66732C14 3.93094 13.403 3.33398 12.6667 3.33398H3.33333C2.59695 3.33398 2 3.93094 2 4.66732V11.334C2 12.0704 2.59695 12.6673 3.33333 12.6673H6" stroke="#757575" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            <g clip-path="url(#clip0_2296_25890)">
                                                <path d="M15 11.332H11.6667C10.1939 11.332 9 12.5259 9 13.9987V14.6654M15 11.332L13 13.332M15 11.332L13 9.33203" stroke="#757575" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            </g>
                                            <defs>
                                            <clipPath id="clip0_2296_25890">
                                            <rect width="8" height="8" fill="white" transform="matrix(-1 0 0 1 16 8)"/>
                                            </clipPath>
                                            </defs>
                                        </svg>
                                    </span>
                                </div>
                            </div>`
                        }

                        if(is_resend_whatsapp) {
                            viewButton += `
                            <div class="row click-resend-whats-app dropdown-content-detail show-action" data="${row.id}">
                                <div class="col-md-9 show-action">
                                    <span class="show-action">Resend WhatsApp</span>    
                                </div>
                                <div class="col-md-3 show-action" >
                                    <span class="pull-right show-action">
                                        <svg class="show-action" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip0_2296_25928)">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M13.306 2.67206C11.9026 1.26709 10.0363 0.493011 8.04808 0.492188C3.9511 0.492188 0.616745 3.82645 0.615097 7.92453C0.614548 9.23456 0.956772 10.5134 1.60725 11.6406L0.55275 15.4922L4.49309 14.4586C5.57881 15.0508 6.80113 15.3629 8.04506 15.3633H8.04817C12.1447 15.3633 15.4794 12.0287 15.481 7.93048C15.4818 5.94434 14.7094 4.07693 13.306 2.67206ZM8.04808 14.108H8.04552C6.937 14.1075 5.84981 13.8096 4.90114 13.2469L4.67564 13.1129L2.33739 13.7263L2.9615 11.4466L2.81456 11.2128C2.19612 10.2292 1.86955 9.09229 1.8701 7.92499C1.87138 4.51877 4.64287 1.74756 8.05055 1.74756C9.7007 1.74811 11.2519 2.39154 12.4183 3.5593C13.5846 4.72705 14.2266 6.27924 14.2261 7.93002C14.2246 11.3365 11.4533 14.108 8.04808 14.108ZM11.4368 9.48102C11.2511 9.388 10.338 8.93884 10.1677 8.87677C9.9976 8.81479 9.87364 8.78394 9.74995 8.96979C9.62608 9.15564 9.27022 9.57404 9.16182 9.69791C9.05342 9.82187 8.94521 9.83743 8.75945 9.74442C8.57368 9.65149 7.9753 9.45529 7.26585 8.82257C6.71379 8.33011 6.34108 7.72192 6.23268 7.53607C6.12447 7.35004 6.23177 7.25922 6.31416 7.15704C6.51521 6.90738 6.71654 6.64563 6.77843 6.52176C6.84041 6.3978 6.80937 6.28931 6.76286 6.19638C6.71654 6.10345 6.34511 5.1893 6.19038 4.81732C6.03951 4.45532 5.88652 4.50421 5.77245 4.49854C5.66423 4.49313 5.54036 4.49203 5.41649 4.49203C5.29271 4.49203 5.09148 4.53845 4.92119 4.72449C4.75099 4.91043 4.27126 5.35968 4.27126 6.27383C4.27126 7.18799 4.93675 8.07111 5.02959 8.19507C5.12242 8.31903 6.33925 10.1949 8.20226 10.9993C8.64537 11.1909 8.99126 11.305 9.26106 11.3906C9.70601 11.532 10.1108 11.512 10.4308 11.4642C10.7877 11.4109 11.5296 11.0149 11.6845 10.5811C11.8392 10.1472 11.8392 9.77545 11.7927 9.69791C11.7464 9.62045 11.6225 9.57404 11.4368 9.48102Z" fill="#757575"/>
                                            </g>
                                            <defs>
                                            <clipPath id="clip0_2296_25928">
                                            <rect width="16" height="16" fill="white"/>
                                            </clipPath>
                                            </defs>
                                        </svg>
                                    </span>
                                </div>
                            </div>`
                        }

                        if (is_resend_telegram) {
                            viewButton += `
                            <div class="row click-resend-telegram dropdown-content-detail show-action" data="${row.id}">
                                <div class="col-md-9 show-action">
                                    <span class="show-action" style="color: #C2C2C2">Resend Telegram</span>    
                                </div>
                                <div class="col-md-3 show-action" >
                                    <span class="pull-right show-action">
                                        <svg class="show-action" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M15.6867 1.3083C15.4293 1.07246 15.0634 0.993862 14.7319 1.10314L0.663476 5.74067C0.28007 5.86705 0.0200696 6.2093 0.00110086 6.61252C-0.0178366 7.01577 0.208882 7.38092 0.578695 7.54277L4.05791 9.06502C4.07488 9.14377 5.07366 13.7761 5.10763 13.9336C5.15123 14.1359 5.25388 14.316 5.47135 14.3714C5.69132 14.4275 5.84673 14.3072 6.01085 14.1844C6.1022 14.116 8.59273 12.2511 8.59273 12.2511L11.6111 14.721C11.7869 14.865 12.0018 14.94 12.2209 14.94C12.3252 14.94 12.4305 14.923 12.533 14.8883C12.8507 14.7805 13.0862 14.5213 13.1629 14.1947L15.9744 2.24155C16.0543 1.90171 15.9441 1.54414 15.6867 1.3083ZM6.23576 9.9373C6.23404 9.94136 6.23235 9.94596 6.23076 9.95146L5.64876 11.9848L4.99926 8.97249L9.46541 6.49108L6.34288 9.75602C6.2937 9.80746 6.25751 9.86964 6.23576 9.9373ZM6.42048 12.7037L6.68482 11.7801L6.93766 10.8967L7.84363 11.6381L6.42048 12.7037ZM15.0596 2.02636L12.2482 13.9795C12.2468 13.9855 12.2449 13.9937 12.2312 13.9983C12.2176 14.003 12.2109 13.9977 12.2063 13.9938L8.90341 11.291C8.90332 11.291 8.90326 11.2909 8.90316 11.2908L7.37288 10.0386L12.2892 4.89802C12.4498 4.73011 12.4635 4.47008 12.3216 4.28617C12.1795 4.10224 11.9245 4.04971 11.7214 4.16258L4.44173 8.20724L0.955382 6.68183C0.944757 6.67721 0.938913 6.67461 0.939757 6.65664C0.940601 6.63874 0.946663 6.6367 0.957663 6.63308L15.026 1.99558C15.0329 1.99336 15.0405 1.9908 15.0519 2.00111C15.0632 2.01149 15.0613 2.01936 15.0596 2.02636Z" fill="#C2C2C2"/>
                                        </svg>
                                    </span>
                                </div>
                            </div>`
                        }
                    }

            viewButton += `
                </div>
            </div>
                `
            return viewButton
        },
        isDoubleClicked(element) {
            //if already clicked return TRUE to indicate this click is not allowed
            if (element.data("isclicked")) return true;

            //mark as clicked for 1 second
            element.data("isclicked", true);
            setTimeout(function () {
                element.removeData("isclicked");
            }, 1000);

            //return FALSE to indicate this click was allowed
            return false;
        },
        redirectToOrderDetail(id) {
            this.$root.$router.push({
                name: 'report.order.detail',
                params: {
                    id: id
                }
            })
        },
        async filterMonthsByMonth(months, id) {
            var res
            id = parseInt(id)
            months.forEach((item) => {
                if (item.value) {
                    if(item.value == id) {
                        res = item
                    }
                }
            })

            return res
        },
        async loadDateRangePicker() {
            let self = this 
            let range_option = {}
            this.rangeOptions.forEach( (item) => {
                if(item && item.name && item.range) {
                    range_option[item.name] = item.range
                }
            })
            $(function() {

                var start = moment(self.filter_date.start_date);
                var end = moment(self.filter_date.end_date);

                var daysInYear = 366

                function cb(start, end) {
                    moment.locale('en')
                    var tempDate = start.format('D MMMM YYYY') + ' - ' + end.format('D MMMM YYYY')
                    self.rangeOptions.forEach( (item) => {
                        if(item && item.name && item.start_date && item.end_date) {
                            if(item.start_date == start.format('YYYY-MM-DD') && item.end_date == end.format('YYYY-MM-DD')) {
                                tempDate = item.name
                            }
                        }
                    })
                    var diff = end.diff(start, 'days') 
                    if(diff > daysInYear) {
                        console.log('error disini')
                        self.$root.showNotification('Failed', 'error', 'Maximum search range is 1 year.')
                    }else {
                        self.filter_date_temp = tempDate

                        var start_date = start.format('YYYY-MM-DD')
                        var end_date = end.format('YYYY-MM-DD')

                        self.filter_date.start_date = start_date
                        self.filter_date.end_date = end_date
                        $('#reportrange span.date-range-text-info').html(tempDate);
                    }
                   
                }

                $('#reportrange').daterangepicker({
                    opens: 'right',
                    startDate: start,
                    endDate: end,
                    autoApply: true,
                    alwaysShowCalendars: true,
                    showCustomRangeLabel: false,
                    ranges: range_option,
                    locale: {
                        format: "YYYY-MM-DD",
                        separator: " - ",
                        applyLabel: "Apply",
                        cancelLabel: "Cancel",
                        fromLabel: "From",
                        toLabel: "To",
                        customRangeLabel: "Custom",
                        weekLabel: "W",
                        daysOfWeek: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
                        monthNames:  ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Agu', 'Sep', 'Oct', 'Nov', 'Dec'],
                        firstDay: 1
                    },
                }, cb);

                cb(start, end);

            });
        },
        destroyed() {
            clearInterval(this.interval)
        },
        async doDownload() {
            this.isDownloadDisabled = true
            var fileName = 'report-sales'
            if(this.filter_date && this.filter_date.start_date && this.filter_date.end_date) {
                var sheets = [
                    {
                        sheetName: 'Sheet1',
                        data: [],
                        headers: [
                            { key: 'booking_code', label: 'Booking Code' },
                            { key: 'customer_name', label: 'Customer Name' },
                            { key: 'server_name', label: 'Server' },
                            { key: 'created_at', label: 'Created At' },
                            { key: 'ticket_package_name', label: 'Ticket Package Name'},
                            { key: 'qty', label: 'Qty'},
                            { key: 'total_price', label: 'Total Price'},
                            { key: 'status', label: 'Status'},
                            
                        ]
                    },
                    
                ]

                fileName += '-'+this.filter_date.start_date+'_'+this.filter_date.end_date+'_'+moment().unix()

                Rest.get('/transaction/order/export?start_date='+this.filter_date.start_date+'&end_date='+this.filter_date.end_date)
                .then( async(res) => {
                    if( res && res.data && res.data) {
                        var data = []
                        res.data.forEach( async(item) => {
                            var server_name = item.creator_name ? item.creator_name : item.machine_code
                            if(item.modifier_name) {
                                server_name = item.modifier_name
                            }

                            var date = moment(item.created_at)
                            var created_at = date.format('D MMMM YYYY, HH:mm')
                            if(item.transaction_detail && item.transaction_detail.length > 0) {
                                item.transaction_detail.forEach( (val, i) => {
                                    if(i ==0) {
                                        data.push({
                                            customer_name: item.customer_name,
                                            server_name: server_name,
                                            created_at: created_at,
                                            total_price: item.total_price,
                                            status: item.status,
                                            booking_code: item.booking_code,
                                            ticket_package_name: val.package_name,
                                            qty: val.quantity
                                        })
                                    }else {
                                        data.push({
                                            ticket_package_name: val.package_name,
                                            qty: val.quantity
                                        })
                                    }
                                })
                            }
                            
                        })

                        // if(data && data.length > 0) {
                            sheets[0].data = data

                            const wb = XLSX.utils.book_new()

                            sheets.forEach(sheet => {
                                const { data, headers, sheetName } = sheet;

                                // Format data with headers
                                const formattedData = data.map(item => {
                                    const formattedItem = {};
                                    headers.forEach(header => {
                                        formattedItem[header.label] = item[header.key];
                                    });
                                    return formattedItem;
                                });

                                // Convert JSON data to a worksheet with headers
                                const ws = XLSX.utils.json_to_sheet(formattedData, { header: headers.map(header => header.label) });

                                // Append the worksheet to the workbook
                                XLSX.utils.book_append_sheet(wb, ws, sheetName);
                            });

                            // Generate XLSX file
                            const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

                            // Save the file using FileSaver
                            saveAs(new Blob([wbout], { type: 'application/octet-stream' }), `${fileName}.xlsx`);

                            this.isDownloadDisabled = false
                            this.$root.showNotification('Success', 'success', 'Download report sales successfull.')
                        // }
                    }
                })
                .catch( (err) => {
                    this.isDownloadDisabled = false
                    if(err && err.response && err.response.status) {
                        if(err.response.data && err.response.data.message) {
                            this.$root.showNotification('Failed', 'error', err.response.data.message)        
                        }else {
                            var message = "Failed Download report sales"
                            this.$root.showNotification('Failed', 'error', message)
                        }
                    }else {
                        this.$root.showNotification('Failed', 'error', 'Sorry, you do not have an internet connection.')
                    }
                })
                .finally( () => {
                    
                })
                
            }else {
                this.isDownloadDisabled = false
            }
        },
        async closeAllDropdowns() {
            let dropdowns = document.getElementsByClassName('report-sales-dropdown-content');
            for (let i = 0; i < dropdowns.length; i++) {
                dropdowns[i].style.display = 'none';
            }
        },
        async doResendEmail() {
            if (this.form.id) {
                this.is_confirm_resend_email_loading = true
                let params = {
                    email: null 
                }

                Rest.put('/transaction/send-email/'+this.form.id+'?is_resend=true', params)
                .then( async() => {
                    this.$root.showNotification('Success', 'success', 'Resend email successfull.')
                })
                .catch((err) => {
                    if(err && err.response && err.response.status) {
                        if(err.response.data && err.response.data.message) {
                            this.$root.showNotification('Failed', 'error', err.response.data.message)        
                        }else {
                            var message = "Failed resend email"
                            this.$root.showNotification('Failed', 'error', message)
                        }
                    }else {
                        this.$root.showNotification('Failed', 'error', 'Sorry, you do not have an internet connection.')
                    }
                })
                .finally( (res) => {
                    this.is_confirm_resend_email = false 
                    this.is_confirm_resend_email_loading = false 
                })
            }
        },
        async doResendWhatsapp() {
            if (this.form.id) {
                this.is_confirm_resend_whatsapp_loading = true
                let params = {
                }

                Rest.put('/transaction/send-whatsapp/'+this.form.id, params)
                .then( async() => {
                    this.$root.showNotification('Success', 'success', 'Resend whatsapp successfull.')
                })
                .catch((err) => {
                    if(err && err.response && err.response.status) {
                        if(err.response.data && err.response.data.message) {
                            this.$root.showNotification('Failed', 'error', err.response.data.message)        
                        }else {
                            var message = "Failed resend whatsapp"
                            this.$root.showNotification('Failed', 'error', message)
                        }
                    }else {
                        this.$root.showNotification('Failed', 'error', 'Sorry, you do not have an internet connection.')
                    }
                })
                .finally( (res) => {
                    this.is_confirm_resend_whatsapp = false 
                    this.is_confirm_resend_whatsapp_loading = false 
                })
            }
        }
    },
    computed: {
        datatable_class() {
            let class_name = 'superuser-datatable'
            if(this.user) {
                if(this.user.company_id) {
                    class_name = 'owner-datatable'
                }
            }

            return class_name
        }
    },
    watch: {
        filter_status: {
            handler(val, oldVal) {
                console.log('disini', {
                    val: val,
                    oldVal: oldVal
                })
                this.setupSettingFilter()
                // this.destroyed()
                setInterval(this.tiggerUpdateDropDown, 200)
            }
        },
        filter_server: {
            handler() {
                this.setupSettingFilter()
                // this.destroyed()
                setInterval(this.tiggerUpdateDropDown, 200)
            }
        },
        filter_date_temp: {
            handler() {
                this.setupSettingFilter()
                // this.destroyed()
                setInterval(this.tiggerUpdateDropDown, 200)
            }
        },
        interval: {
            handler(val, oldVal) {
                console.log('change interval', {
                    val: val,
                    old: oldVal
                })
            }
        }
    }
}
</script>

<style scoped>


</style>
