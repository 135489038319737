<template>
    <div>
        <box header="false"
            :cancel="false"
            :save="permission_edit"
            @onSave="doSaveConfirm">
            <div>
                <div class="row">
                    <div class="col-md-6 col-xs-12">
                        <div class="notification-switch">
                            <span class="notification-type-switch-btn" 
                                :class="is_tabs_notification_email ? 'notification-type-switch-btn-active' : ''" 
                                @click="showNotificationEmail">
                                <icon-email
                                    :stroke="is_tabs_notification_email ? enable_color : disable_color">
                                </icon-email>
                                <span class="summary-head-title" :class="is_tabs_notification_email ? 'active' : '' ">Email</span>
                            </span>
                            
                            <span class="notification-type-switch-btn" 
                                :class="is_tabs_notification_whats_app ? 'notification-type-switch-btn-active' : ''" 
                                @click="showNotificationWhatsApp">
                                <icon-whats-app
                                    :fill_path="is_tabs_notification_whats_app ? enable_color : disable_color">
                                </icon-whats-app>
                                <span class="summary-head-title" :class="is_tabs_notification_whats_app ? 'active' : '' ">Whats App</span>
                              
                            </span>
                            <span class="notification-type-switch-btn" 
                                :class="is_tabs_notification_telegram ? 'notification-type-switch-btn-active' : ''" 
                                @click="showNotificationTelegram">
                                <icon-telegram
                                    :fill_path="is_tabs_notification_telegram ? enable_color : disable_color">

                                </icon-telegram>
                                <span class="summary-head-title" :class="is_tabs_notification_telegram ? 'active' : '' ">Telegram</span>
                            </span>
                        </div>
                    </div>
                </div>

                <br>

                <div v-if="is_tabs_notification_email"> 
                    <div class="row" >
                        <div class="col-md-6 col-xs-12">
                            <div class="form-group" :class="{'has-error': !!errors.form_email_description}">
                                <label class="control-label">
                                    Subject 
                                    <span class="warning-text-required">*</span>
                                </label>
                                <input type="text" class="form-control subject-email"
                                    id="subject-email"
                                    v-model="form_email.description"
                                    :disabled="disabledForm || !permission_edit"
                                    autocomplete="off"
                                    maxlength="100" />

                                    <p class="help help-block" v-if="errors.form_email_description">Subject is required.</p>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group" :class="{'has-error': !!errors.form_email_value}">
                                <label class="control-label">
                                    Body 
                                    <span class="warning-text-required">*</span>
                                </label>
                                <vue-editor
                                    v-model="form_email.value"
                                    id="form-email-value"
                                    ref="editor_email"
                                    class="form-email-value"
                                    :editor-toolbar="customToolbar"
                                    :style="disabledForm ? 'background-color: #eeeeee; opacity: 1;': ''"
                                    :disabled="disabledForm || !permission_edit" >
                                </vue-editor>
                                <div class="div-editor-dinamis_variable">
                                    <span 
                                        class="btn btn-md btn-label-select-option-editor" 
                                        @click="emailClickVariableVisitorName">
                                        [VISITOR_NAME] 
                                        <img src="@/assets/svg/plus-circle-outline.svg"/>
                                    </span>
                                    <span 
                                        class="btn btn-md btn-label-select-option-editor" 
                                        @click="emailClickVariableBookingId">
                                        [BOOKING_ID] 
                                        <img src="@/assets/svg/plus-circle-outline.svg"/>
                                    </span>
                                    <span 
                                        class="btn btn-md btn-label-select-option-editor" 
                                        @click="emailClickVariableOrderDate">
                                        [ORDER_DATE] 
                                        <img src="@/assets/svg/plus-circle-outline.svg"/>
                                    </span>
                                    <span
                                        class="btn btn-md btn-label-select-option-editor"
                                        @click="emailClickVariableSettlementDate">
                                        [SETTLEMENT_DATE]
                                        <img src="@/assets/svg/plus-circle-outline.svg"/>
                                    </span>
                                    <span 
                                        class="btn btn-md btn-label-select-option-editor" 
                                        @click="emailClickVariableExpiredDate">
                                        [EXPIRED_DATE] 
                                        <img src="@/assets/svg/plus-circle-outline.svg"/>
                                    </span>
                                    <span 
                                        class="btn btn-md btn-label-select-option-editor" 
                                        @click="emailClickVariableKioskCode">
                                        [KIOSK_CODE] 
                                        <img src="@/assets/svg/plus-circle-outline.svg"/>
                                    </span>
                                    <span 
                                        class="btn btn-md btn-label-select-option-editor" 
                                        @click="emailClickVariableCashierCode">
                                        [CASHIER_CODE] 
                                        <img src="@/assets/svg/plus-circle-outline.svg"/>
                                    </span>
                                    <span 
                                        class="btn btn-md btn-label-select-option-editor" 
                                        @click="emailClickVariablePaymentMethod">
                                        [PAYMENT_METHOD] 
                                        <img src="@/assets/svg/plus-circle-outline.svg"/>
                                    </span>
                                </div>
                                <!-- <p>{{ calculate_form_email_value }}/144</p> -->
                                <p class="help help-block" v-if="errors.form_email_value">Body is required.</p>
                                <!-- <p class="help help-block" v-if="errors.form_email_value">{{  form_email.value ? `Body must be at least three characters, maximum one hundred forty-four characters.` : `Body is required.` }}</p> -->
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="is_tabs_notification_whats_app">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group" :class="{'has-error': !!errors.form_whats_app_value}">
                                <label class="control-label">
                                    Body 
                                    <span class="warning-text-required">*</span>
                                </label>
                                <vue-editor
                                    v-model="form_whats_app.value"
                                    id="form-whats-app-value"
                                    ref="editor_whats_app"
                                    class="form-whats-app-value"
                                    :editor-toolbar="customToolbarWhatsapp"
                                    :style="disabledForm ? 'background-color: #eeeeee; opacity: 1;': ''"
                                    :disabled="disabledForm || !permission_edit" >
                                </vue-editor>
                                <div class="div-editor-dinamis_variable">
                                    <span 
                                        class="btn btn-md btn-label-select-option-editor" 
                                        @click="whatsAppClickVariableVisitorName">
                                        [VISITOR_NAME] 
                                        <img src="@/assets/svg/plus-circle-outline.svg"/>
                                    </span>
                                    <span 
                                        class="btn btn-md btn-label-select-option-editor" 
                                        @click="whatsAppClickVariableBookingId">
                                        [BOOKING_ID] 
                                        <img src="@/assets/svg/plus-circle-outline.svg"/>
                                    </span>
                                    <span 
                                        class="btn btn-md btn-label-select-option-editor" 
                                        @click="whatsAppClickVariableOrderDate">
                                        [ORDER_DATE] 
                                        <img src="@/assets/svg/plus-circle-outline.svg"/>
                                    </span>
                                    <span
                                        class="btn btn-md btn-label-select-option-editor"
                                        @click="whatAppsClickVariableSettlementDate">
                                        [SETTLEMENT_DATE]
                                        <img src="@/assets/svg/plus-circle-outline.svg"/>
                                    </span>
                                    <span 
                                        class="btn btn-md btn-label-select-option-editor" 
                                        @click="whatsAppClickVariableExpiredDate">
                                        [EXPIRED_DATE] 
                                        <img src="@/assets/svg/plus-circle-outline.svg"/>
                                    </span>
                                    <span 
                                        class="btn btn-md btn-label-select-option-editor" 
                                        @click="whatsAppClickVariableKioskCode">
                                        [KIOSK_CODE] 
                                        <img src="@/assets/svg/plus-circle-outline.svg"/>
                                    </span>
                                    <span 
                                        class="btn btn-md btn-label-select-option-editor" 
                                        @click="whatsAppClickVariableCashierCode">
                                        [CASHIER_CODE] 
                                        <img src="@/assets/svg/plus-circle-outline.svg"/>
                                    </span>
                                    <span 
                                        class="btn btn-md btn-label-select-option-editor" 
                                        @click="whatsAppClickVariablePaymentMethod">
                                        [PAYMENT_METHOD] 
                                        <img src="@/assets/svg/plus-circle-outline.svg"/>
                                    </span>
                                </div>
                                <!-- <p>{{ calculate_form_email_value }}/144</p> -->
                                <p class="help help-block" v-if="errors.form_whats_app_value">Body is required.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="is_tabs_notification_telegram">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group" :class="{'has-error': !!errors.form_telegram_value}">
                                <label class="control-label">
                                    Body 
                                    <span class="warning-text-required">*</span>
                                </label>
                                <vue-editor
                                    v-model="form_telegram.value"
                                    id="form-telegram-value"
                                    ref="editor_telegram"
                                    class="form-telegram-value"
                                    :editor-toolbar="customToolbarWhatsapp"
                                    :style="disabledForm ? 'background-color: #eeeeee; opacity: 1;': ''"
                                    :disabled="disabledForm || !permission_edit" >
                                </vue-editor>
                                <div class="div-editor-dinamis_variable">
                                    <span 
                                        class="btn btn-md btn-label-select-option-editor" 
                                        @click="telegramClickVariableVisitorName">
                                        [VISITOR_NAME] 
                                        <img src="@/assets/svg/plus-circle-outline.svg"/>
                                    </span>
                                    <span 
                                        class="btn btn-md btn-label-select-option-editor" 
                                        @click="telegramClickVariableBookingId">
                                        [BOOKING_ID] 
                                        <img src="@/assets/svg/plus-circle-outline.svg"/>
                                    </span>
                                    <span 
                                        class="btn btn-md btn-label-select-option-editor" 
                                        @click="telegramClickVariableOrderDate">
                                        [ORDER_DATE] 
                                        <img src="@/assets/svg/plus-circle-outline.svg"/>
                                    </span>
                                    <span
                                        class="btn btn-md btn-label-select-option-editor"
                                        @click="telegramClickVariableSettlementDate">
                                        [SETTLEMENT_DATE]
                                        <img src="@/assets/svg/plus-circle-outline.svg"/>
                                    </span>
                                    <span 
                                        class="btn btn-md btn-label-select-option-editor" 
                                        @click="telegramClickVariableExpiredDate">
                                        [EXPIRED_DATE] 
                                        <img src="@/assets/svg/plus-circle-outline.svg"/>
                                    </span>
                                    <span 
                                        class="btn btn-md btn-label-select-option-editor" 
                                        @click="telegramClickVariableKioskCode">
                                        [KIOSK_CODE] 
                                        <img src="@/assets/svg/plus-circle-outline.svg"/>
                                    </span>
                                    <span 
                                        class="btn btn-md btn-label-select-option-editor" 
                                        @click="telegramClickVariableCashierCode">
                                        [CASHIER_CODE] 
                                        <img src="@/assets/svg/plus-circle-outline.svg"/>
                                    </span>
                                    <span 
                                        class="btn btn-md btn-label-select-option-editor" 
                                        @click="telegramClickVariablePaymentMethod">
                                        [PAYMENT_METHOD] 
                                        <img src="@/assets/svg/plus-circle-outline.svg"/>
                                    </span>
                                </div>
                                <!-- <p>{{ calculate_form_email_value }}/144</p> -->
                                <p class="help help-block" v-if="errors.form_telegram_value">Body is required.</p>
                            </div>
                        </div>
                    </div>
                </div>

                
            </div>
        </box>

        <modal :title="'Change Confirmation'" save="true" remove="false"
            :show="is_confirm"
            saveText="Yes, Confirmation"
            @hidden="is_confirm = false"
            @onSave="doSave"
            :loading="is_confirm_loading">
            <span class="span-title">Are you sure you want to modify <b>{{ confirm_text }}</b> ?</span>
        </modal>
    </div>
</template>

<script>
import IconEmail from '@components/svg/notification/IconEmail.vue'
import IconWhatsApp from '@components/svg/notification/IconWhatsApp.vue'
import IconTelegram from '@components/svg/notification/IconTelegram.vue'
import { VueEditor } from "vue2-editor"
const {console} = window
export default {
    components: {
        IconEmail,
        IconWhatsApp,
        IconTelegram,
        VueEditor
    },
    props: {
        notification: {
            type: Array
        },
        unix_time: {
            type: Number
        }
    },
    data() {
        return {
            alias: 'general-setting',
            permission_edit: false,
            form_email: {
                id: null,
                code: 'SEND-EMAIL',
                name: null,
                value: null,
                description: null,
            },
            form_whats_app: {
                id: null,
                code: 'SEND-WHATS',
                name: null,
                value: null,
                description: null,
            },
            form_telegram: {
                id: null,
                code: 'SEND-TLGRM',
                name: null,
                value: null,
                description: null,
            },
            form_temp: null,
            errors: {
                form_email_value: false,
                form_email_description: false,
                form_whats_app_value: false,
                form_telegram_value: false,
            },
            customToolbar: [
                ["bold", "italic", "underline"],
                [
                    { align: "" },
                    { align: "center" },
                    { align: "right" },
                    { align: "justify" }
                ],
                [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
            ],
            customToolbarWhatsapp: [
                ["bold", "italic"],
            ],
            customToolbarNone: {
                modules: {
                toolbar: false,
                }
            },
            is_tabs_notification_email: true,
            is_tabs_notification_whats_app: false,
            is_tabs_notification_telegram: false,
            enable_color: '#6147FF',
            disable_color: '#9E9E9E',
            disabledForm: false,
            is_confirm: false,
            is_confirm_loading: false,
            is_first_load_subject: false,
            is_first_load_body: false,
        }
    },
    async mounted() {
        this.defineNotificationData(this.notification)
    },
    methods: {
        async defineNotificationData(data) {
            let query = this.$route.query.tabs
            switch (query) {
                case 'notification-email':
                    this.is_tabs_notification_email = true
                    this.is_tabs_notification_whats_app = false
                    this.is_tabs_notification_telegram = false
                    break;
                case 'notification-whats-app':
                    this.is_tabs_notification_email = false
                    this.is_tabs_notification_whats_app = true 
                    this.is_tabs_notification_telegram = false
                    break;
                case 'notification-telegram':
                    this.is_tabs_notification_email = false 
                    this.is_tabs_notification_whats_app = false
                    this.is_tabs_notification_telegram = true
                    break;
                default:
                    this.is_tabs_notification_email = true
                    this.is_tabs_notification_whats_app = false
                    this.is_tabs_notification_telegram = false
                    break;
            }
            
            let form_email = null 
            let form_whats_app = null 
            let form_telegram = null 
            if(data && data.length > 0) {
                data.forEach( (item) => {
                    switch (item.code) {
                        case 'SEND-EMAIL':
                            form_email = JSON.parse(JSON.stringify(item))  
                            this.form_email.id = item.id 
                            this.form_email.code = item.code 
                            this.form_email.name = item.name
                            this.form_email.value = item.value
                            this.form_email.description = item.description
                            break;
                        case 'SEND-WHATS':
                            form_whats_app = JSON.parse(JSON.stringify(item))  
                            this.form_whats_app.id = item.id 
                            this.form_whats_app.code = item.code
                            this.form_whats_app.name = item.name
                            this.form_whats_app.value = item.value
                            this.form_whats_app.description = item.description
                            break;
                        case 'SEND-TLGRM':
                            form_telegram = JSON.parse(JSON.stringify(item)) 
                            this.form_telegram.id = item.id 
                            this.form_telegram.code = item.code
                            this.form_telegram.name = item.name
                            this.form_telegram.value = item.value
                            this.form_telegram.description = item.description
                            break;
                        default:
                            break;
                    }
                })
            } 

            let temp = {
                form_email: form_email,
                form_whats_app: form_whats_app,
                form_telegram: form_telegram
            }

            this.form_temp = JSON.parse(JSON.stringify(temp))

            let permission_edit = await this.$root.checkingButtonsPage(this.alias, 'update')
            if (permission_edit.validate) {
                this.permission_edit = true
            } 
        },
        async showNotificationEmail() {
            if(!this.is_tabs_notification_email) {
                this.is_tabs_notification_email = true 
                this.is_tabs_notification_whats_app = false 
                this.is_tabs_notification_telegram = false 

                this.errors.form_email_value = false
                this.errors.form_email_description = false

                let temp = this.form_temp
                if(temp &&temp.form_email) {
                    this.form_email = JSON.parse(JSON.stringify(temp.form_email))
                }

                try {
                    this.$router.replace({ query : {tabs : 'notification-email'} })
                } catch {
                    
                } finally{
                    console.clear()
                }
            }
            
        },
        async showNotificationWhatsApp() {   
            if(!this.is_tabs_notification_whats_app) { 
                this.is_tabs_notification_email = false 
                this.is_tabs_notification_whats_app = true 
                this.is_tabs_notification_telegram = false 

                this.errors.form_whats_app_value = false

                let temp = this.form_temp
                if(temp && temp.form_whats_app) {
                    this.form_whats_app = JSON.parse(JSON.stringify(temp.form_whats_app))
                }

                try{
                    this.$router.replace({ query : {tabs : 'notification-whats-app'} })
                }catch{}
                finally{
                    console.clear()
                }
            }
        },
        async showNotificationTelegram() {
            if(!this.is_tabs_notification_telegram){
                this.is_tabs_notification_email = false 
                this.is_tabs_notification_whats_app = false
                this.is_tabs_notification_telegram = true

                this.errors.form_telegram_value = false

                let temp = this.form_temp
                if(temp &&temp.form_telegram) {
                    this.form_telegram = JSON.parse(JSON.stringify(temp.form_telegram))
                }

                try {
                    this.$router.replace({ query : {tabs : 'notification-telegram'} })
                } catch {
                    
                } finally{
                    console.clear()
                }
            }
        },
        async validationForm()  {
            let is_success = true 
            this.errors.form_email_value = false
            this.errors.form_email_description = false 
            this.errors.form_whats_app_value = false 
            this.errors.form_telegram_value = false 
            if(this.is_tabs_notification_email) {
                if(!this.form_email.value) {
                    this.errors.form_email_value = true 
                    is_success = false 
                }

                if(!this.form_email.description) {
                    this.errors.form_email_description = true 
                    is_success = false
                }
            }

            if(this.is_tabs_notification_whats_app) {
                if(!this.form_whats_app.value) {
                    this.errors.form_whats_app_value = true 
                    is_success = false 
                }
            }

            if(this.is_tabs_notification_telegram) {
                if(!this.form_telegram.value) {
                    this.errors.form_telegram_value = true 
                    is_success = false 
                }
            }
            return is_success
        },  
        async doSaveConfirm() {
            let is_valid = await this.validationForm()
            if(is_valid) {
                this.is_confirm = true 
            }
        },
        async doSave() {
            this.is_confirm_loading = true 
            let params = {}
            let name = null
            if(this.is_tabs_notification_email) {
                params.code = this.form_email.code 
                params.name = this.form_email.name
                params.value = this.form_email.value
                params.description = this.form_email.description

                name = 'Notification body email'
            }

            if(this.is_tabs_notification_whats_app) {
                params.code = this.form_whats_app.code 
                params.name = this.form_whats_app.name
                params.value = this.form_whats_app.value 
                params.description = this.form_whats_app.description

                name = 'Notification body whats app'
            }

            if(this.is_tabs_notification_telegram) {
                params.code = this.form_telegram.code 
                params.name = this.form_telegram.name
                params.value = this.form_telegram.value
                params.description = this.form_telegram.description

                name = 'Notification body telegram'
            }

            Rest.put('/setting/'+params.code, params)
            .then( async(res) => {
                if(res && res.status == 200) {
                    this.$root.showNotification('Success', 'success',name+" modification successful.")
                    this.userLogActivity()
                    this.onRefreshNotification()
                }
            })
            .catch( async(err) => {
                if(err && err.response && err.response.status) {
                    if(err.response.data && err.response.data.message) {
                        this.$root.showNotification('Failed', 'error', err.response.data.message)        
                    }else {
                           let message = name+" modification failed."
                            this.$root.showNotification('Failed', 'error', message)
                    }
                }else {
                    this.$root.showNotification('Failed', 'error', 'Sorry, you do not have an internet connection.')
                }
            })
            .finally( () => {
                this.is_confirm = false
                this.is_confirm_loading = false 
            })
        },
        onRefreshNotification() {
            let data = []
            data.push(this.form_email)
            data.push(this.form_whats_app)
            data.push(this.form_telegram)

            this.form_temp = JSON.parse(JSON.stringify(data))
            this.$emit('onRefreshNotification', data)
        },
        emailClickVariableTicketId() {
            let content = "[TICKET_ID]"
            let editor = this.$refs.editor_email.quill
            let selection = editor.getSelection()
            if(selection && selection.index >= 0) {
                let cursor_position = selection.index
                editor.clipboard.dangerouslyPasteHTML(cursor_position, content)
            }

            
        },
        emailClickVariablePackageName() {
            let content = "[PACKAGE_NAME]"
            let editor = this.$refs.editor_email.quill
            let selection = editor.getSelection()
            if(selection && selection.index >= 0) {
                let cursor_position = selection.index
                editor.clipboard.dangerouslyPasteHTML(cursor_position, content)
            }
        },
        emailClickVariableQty() {
            let content = "[QTY]"
            let editor = this.$refs.editor_email.quill
            let selection = editor.getSelection()
            if(selection && selection.index >= 0) {
                let cursor_position = selection.index
                editor.clipboard.dangerouslyPasteHTML(cursor_position, content)
            }
        },
        emailClickVariableVisitorName() {
            let content = "[VISITOR_NAME]"
            let editor = this.$refs.editor_email.quill
            let selection = editor.getSelection()
            if(selection && selection.index >= 0) {
                let cursor_position = selection.index
                editor.clipboard.dangerouslyPasteHTML(cursor_position, content)
            }
        },
        emailClickVariableBookingId() {
            let content = "[BOOKING_ID]"
            let editor = this.$refs.editor_email.quill
            let selection = editor.getSelection()
            if(selection && selection.index >= 0) {
                let cursor_position = selection.index
                editor.clipboard.dangerouslyPasteHTML(cursor_position, content)
            }
        },
        emailClickVariablePaymentMethod() {
            let content = "[PAYMENT_METHOD]"
            let editor = this.$refs.editor_email.quill
            let selection = editor.getSelection()
            if(selection && selection.index >= 0) {
                let cursor_position = selection.index
                editor.clipboard.dangerouslyPasteHTML(cursor_position, content)
            }
        },
        emailClickVariableOrderDate() {
            let content = "[ORDER_DATE]"
            let editor = this.$refs.editor_email.quill
            let selection = editor.getSelection()
            if(selection && selection.index >= 0) {
                let cursor_position = selection.index
                editor.clipboard.dangerouslyPasteHTML(cursor_position, content)
            }
        },
        emailClickVariableSettlementDate() {
            let content = "[SETTLEMENT_DATE]"
            let editor = this.$refs.editor_email.quill
            let selection = editor.getSelection()
            if(selection && selection.index >= 0) {
                let cursor_position = selection.index
                editor.clipboard.dangerouslyPasteHTML(cursor_position, content)
            }
        },
        emailClickVariableExpiredDate() {
            let content = "[EXPIRED_DATE]"
            let editor = this.$refs.editor_email.quill
            let selection = editor.getSelection()
            if(selection && selection.index >= 0) {
                let cursor_position = selection.index
                editor.clipboard.dangerouslyPasteHTML(cursor_position, content)
            }
        },
        emailClickVariableCashierCode() {
            let content = "[CASHIER_CODE]"
            let editor = this.$refs.editor_email.quill
            let selection = editor.getSelection()
            if(selection && selection.index >= 0) {
                let cursor_position = selection.index
                editor.clipboard.dangerouslyPasteHTML(cursor_position, content)
            }
        },
        emailClickVariableKioskCode() {
            let content = "[KIOSK_CODE]"
            let editor = this.$refs.editor_email.quill
            let selection = editor.getSelection()
            if(selection && selection.index >= 0) {
                let cursor_position = selection.index
                editor.clipboard.dangerouslyPasteHTML(cursor_position, content)
            }
        },
        whatsAppClickVariableTicketId() {
            let content = "[TICKET_ID]"
            let editor = this.$refs.editor_whats_app.quill
            let selection = editor.getSelection()
            if(selection && selection.index >= 0) {
                let cursor_position = selection.index
                editor.clipboard.dangerouslyPasteHTML(cursor_position, content)
            }
        },
        whatsAppClickVariablePackageName() {
            let content = "[PACKAGE_NAME]"
            let editor = this.$refs.editor_whats_app.quill
            let selection = editor.getSelection()
            if(selection && selection.index >= 0) {
                let cursor_position = selection.index
                editor.clipboard.dangerouslyPasteHTML(cursor_position, content)
            }
        },
        whatsAppClickVariableQty() {
            let content = "[QTY]"
            let editor = this.$refs.editor_whats_app.quill
            let selection = editor.getSelection()
            if(selection && selection.index >= 0) {
                let cursor_position = selection.index
                editor.clipboard.dangerouslyPasteHTML(cursor_position, content)
            }
        },
        whatsAppClickVariableVisitorName() {
            let content = "[VISITOR_NAME]"
            let editor = this.$refs.editor_whats_app.quill
            let selection = editor.getSelection()
            if(selection && selection.index >= 0) {
                let cursor_position = selection.index
                editor.clipboard.dangerouslyPasteHTML(cursor_position, content)
            }
        },
        whatsAppClickVariableBookingId() {
            let content = "[BOOKING_ID]"
            let editor = this.$refs.editor_whats_app.quill
            let selection = editor.getSelection()
            if(selection && selection.index >= 0) {
                let cursor_position = selection.index
                editor.clipboard.dangerouslyPasteHTML(cursor_position, content)
            }
        },
        whatsAppClickVariablePaymentMethod() {
            let content = "[PAYMENT_METHOD]"
            let editor = this.$refs.editor_whats_app.quill
            let selection = editor.getSelection()
            if(selection && selection.index >= 0) {
                let cursor_position = selection.index
                editor.clipboard.dangerouslyPasteHTML(cursor_position, content)
            }
        },
        whatsAppClickVariableOrderDate() {
            let content = "[ORDER_DATE]"
            let editor = this.$refs.editor_whats_app.quill
            let selection = editor.getSelection()
            if(selection && selection.index >= 0) {
                let cursor_position = selection.index
                editor.clipboard.dangerouslyPasteHTML(cursor_position, content)
            }
        },
        whatAppsClickVariableSettlementDate() {
            let content = "[SETTLEMENT_DATE]"
            let editor = this.$refs.editor_whats_app.quill
            let selection = editor.getSelection()
            if(selection && selection.index >= 0) {
                let cursor_position = selection.index
                editor.clipboard.dangerouslyPasteHTML(cursor_position, content)
            }
        },
        whatsAppClickVariableExpiredDate() {
            let content = "[EXPIRED_DATE]"
            let editor = this.$refs.editor_whats_app.quill
            let selection = editor.getSelection()
            if(selection && selection.index >= 0) {
                let cursor_position = selection.index
                editor.clipboard.dangerouslyPasteHTML(cursor_position, content)
            }
        },
        whatsAppClickVariableCashierCode() {
            let content = "[CASHIER_CODE]"
            let editor = this.$refs.editor_whats_app.quill
            let selection = editor.getSelection()
            if(selection && selection.index >= 0) {
                let cursor_position = selection.index
                editor.clipboard.dangerouslyPasteHTML(cursor_position, content)
            }
        },
        whatsAppClickVariableKioskCode() {
            let content = "[KIOSK_CODE]"
            let editor = this.$refs.editor_whats_app.quill
            let selection = editor.getSelection()
            if(selection && selection.index >= 0) {
                let cursor_position = selection.index
                editor.clipboard.dangerouslyPasteHTML(cursor_position, content)
            }
        },
        telegramClickVariableTicketId() {
            let content = "[TICKET_ID]"
            let editor = this.$refs.editor_telegram.quill
            let selection = editor.getSelection()
            if(selection && selection.index >= 0) {
                let cursor_position = selection.index
                editor.clipboard.dangerouslyPasteHTML(cursor_position, content)
            }

            
        },
        telegramClickVariablePackageName() {
            let content = "[PACKAGE_NAME]"
            let editor = this.$refs.editor_telegram.quill
            let selection = editor.getSelection()
            if(selection && selection.index >= 0) {
                let cursor_position = selection.index
                editor.clipboard.dangerouslyPasteHTML(cursor_position, content)
            }
        },
        telegramClickVariableQty() {
            let content = "[QTY]"
            let editor = this.$refs.editor_telegram.quill
            let selection = editor.getSelection()
            if(selection && selection.index >= 0) {
                let cursor_position = selection.index
                editor.clipboard.dangerouslyPasteHTML(cursor_position, content)
            }
        },
        telegramClickVariableVisitorName() {
            let content = "[VISITOR_NAME]"
            let editor = this.$refs.editor_telegram.quill
            let selection = editor.getSelection()
            if(selection && selection.index >= 0) {
                let cursor_position = selection.index
                editor.clipboard.dangerouslyPasteHTML(cursor_position, content)
            }
        },
        telegramClickVariableBookingId() {
            let content = "[BOOKING_ID]"
            let editor = this.$refs.editor_telegram.quill
            let selection = editor.getSelection()
            if(selection && selection.index >= 0) {
                let cursor_position = selection.index
                editor.clipboard.dangerouslyPasteHTML(cursor_position, content)
            }
        },
        telegramClickVariablePaymentMethod() {
            let content = "[PAYMENT_METHOD]"
            let editor = this.$refs.editor_telegram.quill
            let selection = editor.getSelection()
            if(selection && selection.index >= 0) {
                let cursor_position = selection.index
                editor.clipboard.dangerouslyPasteHTML(cursor_position, content)
            }
        },
        telegramClickVariableOrderDate() {
            let content = "[ORDER_DATE]"
            let editor = this.$refs.editor_telegram.quill
            let selection = editor.getSelection()
            if(selection && selection.index >= 0) {
                let cursor_position = selection.index
                editor.clipboard.dangerouslyPasteHTML(cursor_position, content)
            }
        },
        telegramClickVariableSettlementDate() {
            let content = "[SETTLEMENT_DATE]"
            let editor = this.$refs.editor_telegram.quill
            let selection = editor.getSelection()
            if(selection && selection.index >= 0) {
                let cursor_position = selection.index
                editor.clipboard.dangerouslyPasteHTML(cursor_position, content)
            }
        },
        telegramClickVariableExpiredDate() {
            let content = "[EXPIRED_DATE]"
            let editor = this.$refs.editor_telegram.quill
            let selection = editor.getSelection()
            if(selection && selection.index >= 0) {
                let cursor_position = selection.index
                editor.clipboard.dangerouslyPasteHTML(cursor_position, content)
            }
        },
        telegramClickVariableCashierCode() {
            let content = "[CASHIER_CODE]"
            let editor = this.$refs.editor_telegram.quill
            let selection = editor.getSelection()
            if(selection && selection.index >= 0) {
                let cursor_position = selection.index
                editor.clipboard.dangerouslyPasteHTML(cursor_position, content)
            }
        },
        telegramClickVariableKioskCode() {
            let content = "[KIOSK_CODE]"
            let editor = this.$refs.editor_telegram.quill
            let selection = editor.getSelection()
            if(selection && selection.index >= 0) {
                let cursor_position = selection.index
                editor.clipboard.dangerouslyPasteHTML(cursor_position, content)
            }
        },
        async userLogActivity() {
            let tabs = null 
            if(this.is_tabs_notification_email) {
                tabs = 'notification-email'
            }

            if(this.is_tabs_notification_whats_app) {
                tabs = 'notification-whats-app'
            }

            if(this.is_tabs_notification_telegram) {
                tabs = 'notification-telegram'
            }

            if(this.$root.$store.state.user && this.$root.$store.state.user.full_name) {
                let route_name = {
                    name: 'setting.general-setting',
                    query: {
                        is_edit: false,
                        tabs: tabs
                    }
                } 

                let secretKey = process.env.VUE_APP_KEY_ENCRYPT_DECRYPT
                let route_name_encrypt = this.EncryptDecryptAesCbc256.encryptAesCbc256(secretKey, JSON.stringify(route_name))

                let activity_type = 'edit'
                let activity = `${this.$root.$store.state.user.full_name} has modified the <a style="cursor: pointer" class="click-detail-log" data-route="${route_name_encrypt}"> general setting notification </a>`
               

                let params = {
                    activity: activity,
                    feature: 'General Setting Nofification',
                    activity_type: activity_type,
                    route_name: route_name
                }

                this.$root.sendUserLogActivity(params)
            }
            
        },   

    },
    computed: {
        calculate_form_email_value() {
            let length = 0

            return length
        },
        calculate_form_whats_app_value() {
            let length = 0

            return length
        },
        calculate_form_telegram_value() {
            let length = 0

            return length
        },
        confirm_text() {
            let txt = ''
            if(this.is_tabs_notification_email) {
                txt = 'notification body email'
            }

            if(this.is_tabs_notification_whats_app) {
                txt = 'notification body whats app'
            }

            if(this.is_tabs_notification_telegram) {
                txt = 'notification body telegram'
            }   

            return txt 
        }
    },
    watch: {
        notification: {
            handler(val) {
                this.defineNotificationData(val)
            }
        },
        unix_time: {
            handler() {
                this.defineNotificationData(this.notification)
            }
        },
        'form_email.value': {
            handler(val) {
                this.errors.form_email_value = false
                if(this.is_tabs_notification_email) {
                    if(!val) {
                        this.errors.form_email_value = true 
                    }
                }
            }
        },
        'form_email.description': {
            handler(val) {
                this.errors.form_email_description = false 
                if(this.is_tabs_notification_email) {
                    if(!val) {
                        this.errors.form_email_description = true 
                    }
                }
            }
        },
        'form_whats_app.value': {
            handler(val) {
                this.errors.form_whats_app_value = false 
                if(this.is_tabs_notification_whats_app) {
                    if(!val) {
                        this.errors.form_whats_app_value = true 
                    }
                }
            }
        },
        'form_telegram.value': {
            handler(val) {
                this.errors.form_telegram_value = false
                if(this.is_tabs_notification_telegram) {
                    if(!val) {
                        this.errors.form_telegram_value = true 
                    }
                }
            }
        }
    }
}
</script>

<style scoped>
.notification-switch {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 2px;
        gap: 2px;
        align-self: stretch;
        border-radius: 12px;
        border: 1px solid #E0E0E0;
        background: #F5F5F5;
    }

    .notification-type-switch-btn {
        display: flex; padding: 8px 0px;
        justify-content: center;
        align-items: center;
        gap: 2px;
        border-radius: 10px;
        width: 100%;
        cursor: pointer;
    }

    .notification-type-switch-btn-active {
        background: #FFF;
        box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.10);
    }

    .summary-head-title.active {
        color: #6147FF;
        font-weight: 700;
    }

    .div-editor-dinamis_variable {
        padding: 10px; 
        background-color: #E0E0E0;
    }
</style>