<template>
    <div>
        <box header="false"
            :cancel="false"
            :save="false">
            <div>
                <div class="row">
                    <div class="col-md-8 col-xs-12 margin-bottom-10px">
                        <img src="@/assets/svg/overview/icon-overview.svg" style="margin-top: -5px"/>
                        &nbsp;
                        <span class="overview-title">Ticket Overview</span>
                    </div>
                    <div class="col-md-4 col-xs-12 margin-bottom-10px">
                        <span id="report-daterange-ticket-overview" 
                            class="form-control" 
                            style="background: #fff; cursor: pointer; padding: 5px 10px; border: 1px solid #ccc; width: 100%;">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M8 7V3M16 7V3M7 11H17M5 21H19C20.1046 21 21 20.1046 21 19V7C21 5.89543 20.1046 5 19 5H5C3.89543 5 3 5.89543 3 7V19C3 20.1046 3.89543 21 5 21Z" stroke="#404040" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            &nbsp;&nbsp;
                            <span class="date-range-text-info" style="position: absolute;margin-top: 2px;"></span> 
                        </span>
                    </div>
                </div>
                <br/>
                <div class="row">
                    <div class="col-md-3 col-xs-12 margin-bottom-10px">
                        <div class="summary-box">
                            <div class="summary-content">
                                <p><span class="left-vertical-line-settlement"></span> 
                                    <span class="settlement-text">Settlement</span>
                                </p>

                                <p class="summary-total">{{ total_settlement >= 1000 ? formatNumberLocalString(total_settlement) : total_settlement }}</p>

                            </div>
                           
                            <div class="summary-redirect" v-if="is_permission_order">
                                <p class="summary-redirect-text" @click="redirectOrder('Settlement')">See Details
                                    <span class="pull-right">
                                        <img src="@/assets/svg/arrow-sm-right.svg"/>
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3 col-xs-12 margin-bottom-10px">
                        <div class="summary-box">
                            <div class="summary-content">
                                <p><span class="left-vertical-line-pending"></span> 
                                    <span class="pending-text">Pending</span>
                                </p>
                                <p class="summary-total">{{ total_pending >= 1000 ? formatNumberLocalString(total_pending) : total_pending }}</p>
                            </div>

                            <div class="summary-redirect" v-if="is_permission_order">
                                <p class="summary-redirect-text" @click="redirectOrder('Pending')">See Details
                                    <span class="pull-right">
                                        <img src="@/assets/svg/arrow-sm-right.svg"/>
                                    </span>
                                </p>
                            </div>
                            
                        </div>
                    </div>
                    <div class="col-md-3 col-xs-12 margin-bottom-10px">
                        <div class="summary-box">
                            <div class="summary-content">
                                <p><span class="left-vertical-line-unpaid"></span> 
                                    <span class="unpaid-text">Unpaid</span>
                                </p>

                                <p class="summary-total">{{ total_unpaid >= 1000 ? formatNumberLocalString(total_unpaid) : total_unpaid }}</p>
                            </div>
                            <div class="summary-redirect" v-if="is_permission_order">
                                <p class="summary-redirect-text" @click="redirectOrder('Unpaid')">See Details
                                    <span class="pull-right">
                                        <img src="@/assets/svg/arrow-sm-right.svg"/>
                                    </span>
                                </p>
                            </div>

                        </div>
                    </div>
                    <div class="col-md-3 col-xs-12 margin-bottom-10px">
                        <div class="summary-box">
                            <div class="summary-content">
                                <p><span class="left-vertical-line-expired"></span> 
                                    <span class="expired-text">Expired</span>
                                </p>

                                <p class="summary-total">{{ total_expired >= 1000 ? formatNumberLocalString(total_expired) : total_expired }}</p>

                            </div>
                            <div class="summary-redirect" v-if="is_permission_order">
                                <p class="summary-redirect-text" @click="redirectOrder('Expired')">See Details
                                    <span class="pull-right">
                                        <img src="@/assets/svg/arrow-sm-right.svg"/>
                                    </span>
                                </p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </box>
    </div>
</template>

<script>
import moment from 'moment'
export default {
    data() {
        return {
            is_permission_order: false,
            total_settlement: 0,
            total_pending: 0,
            total_unpaid: 0,
            total_expired: 0,
            filter_date: {
                start_date: moment().startOf('month').format('YYYY-MM-DD'), 
                end_date: moment().endOf('month').format('YYYY-MM-DD')
            },
            filter_date_temp: null,
            rangeOptions: this.$root.$store.state.rangeOptions,
        }
    },
    async mounted() {
        let permission_read = await this.$root.checkingButtonsPage('report.order', 'read')
        if (permission_read.validate) {
            this.is_permission_order = true
        }

        let self = this 
        $(document).ready(function() {
            self.loadDateRangePicker()
        })

        this.init()
    },
    methods: {
        redirectSetting() {
            this.$root.$router.push({
                name: 'report.order'
            })
        },
        formatNumberLocalString(number) {
            return number.toLocaleString('id-ID');
        },
        async loadDateRangePicker() {
            let self = this 
            let range_option = {}
            this.rangeOptions.forEach( (item) => {
                if(item && item.name && item.range) {
                    range_option[item.name] = item.range
                }
            })
            $(function() {

                var start = moment(self.filter_date.start_date);
                var end = moment(self.filter_date.end_date);

                var daysInYear = 366

                function cb(start, end) {
                    moment.locale('en')
                    var tempDate = start.format('D MMMM YYYY') + ' - ' + end.format('D MMMM YYYY')
                    self.rangeOptions.forEach( (item) => {
                        if(item && item.name && item.start_date && item.end_date) {
                            if(item.start_date == start.format('YYYY-MM-DD') && item.end_date == end.format('YYYY-MM-DD')) {
                                tempDate = item.name
                            }
                        }
                    })
                    var diff = end.diff(start, 'days') 
                    if(diff > daysInYear) {
                        console.log('error disini')
                        self.$root.showNotification('Failed', 'error', 'Maximum search range is 1 year.')
                    }else {
                        self.filter_date_temp = tempDate

                        var start_date = start.format('YYYY-MM-DD')
                        var end_date = end.format('YYYY-MM-DD')

                        self.filter_date.start_date = start_date
                        self.filter_date.end_date = end_date
                        $('#report-daterange-ticket-overview span.date-range-text-info').html(tempDate);
                    }
                   
                }

                $('#report-daterange-ticket-overview').daterangepicker({
                    opens: 'right',
                    startDate: start,
                    endDate: end,
                    autoApply: true,
                    alwaysShowCalendars: true,
                    showCustomRangeLabel: false,
                    ranges: range_option,
                    locale: {
                        format: "YYYY-MM-DD",
                        separator: " - ",
                        applyLabel: "Apply",
                        cancelLabel: "Cancel",
                        fromLabel: "From",
                        toLabel: "To",
                        customRangeLabel: "Custom",
                        weekLabel: "W",
                        daysOfWeek: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
                        monthNames:  ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Agu', 'Sep', 'Oct', 'Nov', 'Dec'],
                        firstDay: 1
                    },
                }, cb);

                cb(start, end);

            });
        },
        async init() {
            Rest.get('/overview/transaction?start_date='+this.filter_date.start_date+'&end_date='+this.filter_date.end_date)
            .then( (res) => {
                if(res && res.data) {
                    this.total_settlement = res.data.total_settlement
                    this.total_pending = res.data.total_pending
                    this.total_unpaid = res.data.total_unpaid
                    this.total_expired = res.data.total_expired
                }
            })

        },
        redirectOrder(status) {
            this.$root.$router.push({
                name: 'report.order',
                query: {
                    start_date: this.filter_date.start_date,
                    end_date: this.filter_date.end_date,
                    transaction_status: status,
                }
            })
        }
    },
    computed: {

    },
    watch: {
        filter_date_temp: {
            handler() {
                this.init()
            }
        },
    }
}
</script>

<style scoped>
.summary-box {
    /*  */
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 14px;
    align-self: stretch;
    background: #F5F5F5;
    box-shadow: 4px 4px 24px 24px rgba(16, 24, 40, 0.05);
}

.summary-content {
    padding: 20px;
    background: #FFFFFF;
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
}
.summary-total {
    font-family: Inter;
    font-size: 36px;
    font-weight: 500;
    line-height: 44px;
    letter-spacing: -0.022em;
    text-align: left;
    color : #404040;
}

.summary-redirect {
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.006em;
    text-align: left;
    color: #404040;

}

.summary-redirect-text {
    padding: 10px 20px 5px 20px; 
    cursor: pointer;
}

.left-vertical-line-settlement {
    font-size: 20px;
    border-left: 3px solid #43936C;
    margin-right: 5px;
}

.settlement-text {
    color: #43936C;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.006em;
    text-align: left;

}

.left-vertical-line-pending {
    font-size: 20px;
    border-left: 3px solid #E3BC32;
    margin-right: 5px;
}

.pending-text {
    color: #E3BC32;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.006em;
    text-align: left;

}

.left-vertical-line-unpaid {
    font-size: 20px;
    border-left: 3px solid #BF6919;
    margin-right: 5px;
}

.unpaid-text {
    color: #BF6919;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.006em;
    text-align: left;

}

.left-vertical-line-expired {
    font-size: 20px;
    border-left: 3px solid #CB3A31;
    margin-right: 5px;
}

.expired-text {
    color: #CB3A31;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.006em;
    text-align: left;

}

</style>