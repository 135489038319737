<template>
    <div>
        <h3>PaymentMethod Form Under Construction</h3>
    </div>
</template>

<script>
const {console} = window
export default {
    data() {
        return {

        }
    },
    async mounted() {

    },
    methods: {
        
    },
    computed: {

    },
    watch: {

    }
}
</script>

<style scoped>

</style>