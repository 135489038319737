<template>
    <div style="margin-top: -30px">
        <box header="false"
            :cancel="false"
            :save="false">
            <div>
                <div class="row">
                    <div class="col-md-8 col-xs-12 margin-bottom-10px">
                        <img src="@/assets/svg/overview/ticket.svg" style="margin-top: -5px" />
                        &nbsp;
                        <span class="overview-title">Ticket Sold Trends</span>
                    </div>
                    <div class="col-md-4 col-xs-12 margin-bottom-10px">
                        <span id="report-daterange-ticket-trend-overview" 
                            class="form-control" 
                            style="background: #fff; cursor: pointer; padding: 5px 10px; border: 1px solid #ccc; width: 100%;">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M8 7V3M16 7V3M7 11H17M5 21H19C20.1046 21 21 20.1046 21 19V7C21 5.89543 20.1046 5 19 5H5C3.89543 5 3 5.89543 3 7V19C3 20.1046 3.89543 21 5 21Z" stroke="#404040" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            &nbsp;&nbsp;
                            <span class="date-range-text-info" style="position: absolute;margin-top: 2px;"></span> 
                        </span>
                    </div>
                </div>

                <br />
                <div class="row">
                    <div class="col-md-12 col-xs-12">
                        <charts id="chart-trend" :options="data_chart" minHeight="200px"></charts>
                    </div>
                </div>
            </div>
        </box>
    </div>
</template>

<script>
import Charts from '@app/components/Charts.js'
export default {
    components: {
        Charts
    },
    data() {
        return {
            overview_transaction_trend: [],
            filter_date: {
                start_date: moment().startOf('month').format('YYYY-MM-DD'), 
                end_date: moment().endOf('month').format('YYYY-MM-DD')
            },
            filter_date_temp: null,
            rangeOptions: this.$root.$store.state.rangeOptions,
            data_chart: {
                header: '',
                string_null: 'Not found',
                colors:["#6147FF"],
                labels :  [],
                series: [{
                    data: [],
                    name: 'Ticket Sold'
                    
                }],
                chart: {
                    height: '400px',
                    width: '100%',
                    type: 'bar',
                    toolbar: {
                        show: false
                    }
                },
               
                noData: {
                    text: 'Not Found'
                },
                theme: {
                    monochrome: {
                        enabled: false
                    }
                },
                plotOptions: {
                    bar: {
                        dataLabels: {
                            offset: -25
                        }
                    }
                },
                title: {
                    align: 'center',
                    margin: 0,
                    text: "",
                    style: {
                        fontSize: "14px",
                        fontWeight: 400
                    }
                },
                dataLabels: {
                    dropShadow: {
                        blur: 1,
                        enabled: false,
                        left: 1,
                        opacity: 0.45,
                        top: 1
                    },
                    style: {
                        colors: ['#FFFFFF'],
                        fontWeight: 400,
                        fontFamily: '',
                        fontSize: '14px'
                    },
                    formatter(val, opts) {
                        // const name = opts.w.globals.labels[opts.seriesIndex]
                        // return [name, val.toFixed(2) + ' %']
                        return ''
                    }
                },
                legend: {
                    show: false
                },
                yaxis: {
                    labels: {
                        show: false
                    }
                },
                tooltip: {
                    y: {
                        formatter: function (val) {
                            if (val < 10) {
                                return val.toFixed(0); 
                            } else {
                                return val; 
                            }
                        }
                    }
                }
            },
        }
    },
    async mounted() {
        let self = this 
        $(document).ready(function() {
            self.loadDateRangePicker()
        })

        this.init()
    },
    methods: {
        async loadDateRangePicker() {
            let self = this 
            let range_option = {}
            this.rangeOptions.forEach( (item) => {
                if(item && item.name && item.range) {
                    range_option[item.name] = item.range
                }
            })
            $(function() {

                var start = moment(self.filter_date.start_date);
                var end = moment(self.filter_date.end_date);

                var daysInYear = 366

                function cb(start, end) {
                    moment.locale('en')
                    var tempDate = start.format('D MMMM YYYY') + ' - ' + end.format('D MMMM YYYY')
                    self.rangeOptions.forEach( (item) => {
                        if(item && item.name && item.start_date && item.end_date) {
                            if(item.start_date == start.format('YYYY-MM-DD') && item.end_date == end.format('YYYY-MM-DD')) {
                                tempDate = item.name
                            }
                        }
                    })
                    var diff = end.diff(start, 'days') 
                    if(diff > daysInYear) {
                        console.log('error disini')
                        self.$root.showNotification('Failed', 'error', 'Maximum search range is 1 year.')
                    }else {
                        self.filter_date_temp = tempDate

                        var start_date = start.format('YYYY-MM-DD')
                        var end_date = end.format('YYYY-MM-DD')

                        self.filter_date.start_date = start_date
                        self.filter_date.end_date = end_date
                        $('#report-daterange-ticket-trend-overview span.date-range-text-info').html(tempDate);
                    }
                   
                }

                $('#report-daterange-ticket-trend-overview').daterangepicker({
                    opens: 'right',
                    startDate: start,
                    endDate: end,
                    autoApply: true,
                    alwaysShowCalendars: true,
                    showCustomRangeLabel: false,
                    ranges: range_option,
                    locale: {
                        format: "YYYY-MM-DD",
                        separator: " - ",
                        applyLabel: "Apply",
                        cancelLabel: "Cancel",
                        fromLabel: "From",
                        toLabel: "To",
                        customRangeLabel: "Custom",
                        weekLabel: "W",
                        daysOfWeek: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
                        monthNames:  ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Agu', 'Sep', 'Oct', 'Nov', 'Dec'],
                        firstDay: 1
                    },
                }, cb);

                cb(start, end);

            });
        },
        async init() {
            Rest.get('/overview/transaction/trend?start_date='+this.filter_date.start_date+'&end_date='+this.filter_date.end_date)
            .then( (res) => {
                if(res && res.data && res.data.overview_transaction_trend) {
                    this.overview_transaction_trend = res.data.overview_transaction_trend
                    let series = []
                    let labels = []
                    res.data.overview_transaction_trend.forEach( (item, i) => {
                        series.push(item.total)

                        let year = item.year.toString() 
                        let short_year = year.substring(2, 4)

                        let name = item.month_short_name+' '+short_year
                        labels.push(name)
                    })

                    this.data_chart.labels = labels
                    this.data_chart.series[0].data = series
                }
            })

        },
    },
    computed: {

    },
    watch: {
        filter_date_temp: {
            handler() {
                this.init()
            }
        },
    }
}
</script>

<style scoped>
    
</style>