<template>
    <div>
        <header-title-button
            title="General Setting"
            :isAdd="false" >
        </header-title-button>

        <div class="row">
            <div class="col-md-12">
                <b-card no-body>
                    <b-tabs class="workshop-tabs standar-tabs" card>
                        <b-tab class="text-black" 
                            @click="tabsKioskSession" 
                            title="Kiosk Session" 
                            :active="is_tabs_kiosk_session" 
                            v-if="is_show_kiosk_session">
                            <template slot="title">
                                <span>KiosK Session</span>
                            </template>

                            <b-card-text>
                                <div style="padding-top: 10px">
                                    
                                </div>
                                <kios-k-session
                                    :kiosk_session="kiosk_session"
                                    @onRefreshKiosKSession="onRefreshKiosKSession">
                                </kios-k-session>
                            </b-card-text>
                        </b-tab>
                        <b-tab class="text-black" 
                            @click="tabsTickeetLayout" 
                            title="Ticket Layout" 
                            :active="is_tabs_ticket_layout" 
                            v-if="is_show_ticket_layout">
                            <template slot="title">
                                <span>Ticket Layout</span>
                            </template>

                            <b-card-text>
                                <div style="padding-top: 10px">
                                    
                                </div>
                                <ticket-layout 
                                    :ticket_layout="ticket_layout"
                                    :unix_time="unix_time"
                                    @onRefreshTicketLayout="onRefreshTicketLayout">
                                </ticket-layout>
                            </b-card-text>
                        </b-tab>
                        <b-tab class="text-black" 
                            @click="tabsNotification" 
                            title="Notification" 
                            :active="is_tabs_notification" 
                            v-if="is_show_notification">
                            <template slot="title">
                                <span>Notification</span>
                            </template>

                            <b-card-text>
                                <div style="padding-top: 10px">
                                    
                                </div>
                                <notification
                                    :notification="notification"
                                    :unix_time="unix_time"
                                    @onRefreshNotification="onRefreshNotification">
                                </notification>
                            </b-card-text>
                        </b-tab>
                        <b-tab class="text-black" 
                            @click="tabsCheckInTimeout" 
                            title="Check In Timeout" 
                            :active="is_tabs_check_in_timeout" 
                            v-if="is_show_check_in_timeout">
                            <template slot="title">
                                <span>Check In Timeout</span>
                            </template>

                            <b-card-text>
                                <div style="padding-top: 10px">
                                    
                                </div>
                                <check-in-timeout 
                                    :check_in_timeout="check_in_timeout"
                                    :unix_time="unix_time"
                                    @onRefreshCheckInTimeout="onRefreshCheckInTimeout">
                                </check-in-timeout>
                            </b-card-text>
                        </b-tab>
                        <b-tab class="text-black" 
                            @click="tabsLegal" 
                            title="Legal" 
                            :active="is_tabs_legal" 
                            v-if="is_show_legal">
                            <template slot="title">
                                <span>Legal</span>
                            </template>

                            <b-card-text>
                                <div style="padding-top: 10px">
                                    
                                </div>
                                <legal 
                                    :legal="legal"
                                    :unix_time="unix_time"
                                    @onRefreshLegal="onRefreshLegal">
                                </legal>
                            </b-card-text>
                        </b-tab>
                        <b-tab class="text-black" 
                            @click="tabsOfficialContact" 
                            title="Offical Contact" 
                            :active="is_tabs_official_contact" 
                            v-if="is_show_official_contact">
                            <template slot="title">
                                <span>Official Contact</span>
                            </template>

                            <b-card-text>
                                <div style="padding-top: 10px">
                                    
                                </div>
                                <official-contact
                                    :official_contact="official_contact"
                                    :unix_time="unix_time"
                                    @onRefreshOfficialContact="onRefreshOfficialContact">
                                </official-contact>
                            </b-card-text>
                        </b-tab>
                    </b-tabs>
                </b-card>
            </div>
        </div>
    </div>
</template>

<script>
const {console} = window
import KiosKSession from '@pages/Setting/GeneralSetting/KiosKSession.vue'
import TicketLayout from '@pages/Setting/GeneralSetting/TicketLayout.vue'
import Notification from '@pages/Setting/GeneralSetting/Notification.vue'
import CheckInTimeout from '@pages/Setting/GeneralSetting/CheckInTimeout.vue'
import Legal from '@pages/Setting/GeneralSetting/Legal.vue'
import OfficialContact from '@pages/Setting/GeneralSetting/OfficialContact.vue'
import moment from 'moment'
export default {
    components: {
        KiosKSession,
        TicketLayout,
        Notification,
        CheckInTimeout,
        Legal,
        OfficialContact
    },
    data() {
        return {
            alias: 'general-setting',
            disabledForm: false,
            page: {
                title: 'Nuanu',
                subtitle: 'General Setting'
            },
            is_tabs_kiosk_session: true,
            is_tabs_ticket_layout: false,
            is_tabs_notification: false,
            is_tabs_check_in_timeout: false,
            is_tabs_legal: false,
            is_tabs_official_contact: false,
            is_show_kiosk_session: true,
            is_show_ticket_layout: true,
            is_show_notification: true,
            is_show_check_in_timeout: true,
            is_show_legal: true,
            is_show_official_contact: true,
            kiosk_session: [
                {
                    code: 'SESS-TIME',
                    name: null,
                    value: null,
                    description: null,
                },  {
                    code: 'IDLE-TIME',
                    name: null,
                    value: null,
                    description: null
                },
                {
                    code: 'EXP-PYMENT',
                    name: null,
                    value: null,
                    description: null
                },
                {
                    code: 'EXP-CASH',
                    name: null,
                    value: null,
                    description: null
                }
            ],
            ticket_layout: [
                {
                    code: 'TCK-HEAD',
                    name: null,
                    value: null,
                    description: null
                },
                {
                    code: 'TCK-WORD',
                    name: null,
                    value: null,
                    description: null
                },
                { 
                    code: 'PRINT-NOTE',
                    name: null,
                    value: null,
                    description: null,
                }
            ],
            notification: [
                {   code: 'SEND-EMAIL',
                    name: null,
                    value: null,
                    description: null
                },
                {
                    code: 'SEND-WHATS',
                    name: null,
                    value: null,
                    description: null
                },
                {
                    code: 'SEND-TLGRM',
                    name: null,
                    value: null,
                    description: null
                }
            ],
            check_in_timeout: {code: 'CHECK-IN', name: null, value: null, description: null},
            legal: [
                {code: 'PRIVACY', name: null, value: null, description: null},
                {code: 'TERM-COND', name: null, value: null, description: null}
            ],
            official_contact: [
                {code: 'SET-EMAIL', name: null, value: null, description: null},
                {code: 'WHATS-APP', name: null, value: null, description: null},
                {code: 'TELE-GRAM', name: null, value: null, description: null}
            ],
            max_package: {code: 'MAX-PCKAGE', name: null, value: null, description: null},
            max_accumulation_ticket: {code: 'MAX-TICKET', name: null, value: null, description: null},
            expired_payment: {code: 'EXP-PYMENT', name: null, value: null, description: null},
            expired_cash_payment: {code: 'EXP-CASH', name: null, value: null, description: null},
            form_temp: null,
            unix_time: moment().unix(),
        }
    },
    async mounted() {
        this.$root.checkingAccessPage(this.$root._route.name)
        let permission_edit = await this.$root.checkingButtonsPage(this.alias, 'update')
        if(permission_edit.validate) {
            this.disabledForm = true 
        }

        this.init()
        
    },
    methods: {
        async init() {
            let query = this.$route.query.tabs
            switch (query) {
                case 'kiosk-session':
                    this.is_tabs_kiosk_session = true
                    this.is_tabs_ticket_layout = false
                    this.is_tabs_notification = false
                    this.is_tabs_check_in_timeout = false
                    this.is_tabs_legal = false
                    this.is_tabs_official_contact = false
                    break;
                case 'ticket-layout':
                    this.is_tabs_kiosk_session = false
                    this.is_tabs_ticket_layout = true
                    this.is_tabs_notification = false
                    this.is_tabs_check_in_timeout = false
                    this.is_tabs_legal = false
                    this.is_tabs_official_contact = false
                    break;
                case 'notification-email':
                    this.is_tabs_kiosk_session = false 
                    this.is_tabs_ticket_layout = false
                    this.is_tabs_notification = true 
                    this.is_tabs_check_in_timeout = false
                    this.is_tabs_legal = false
                    this.is_tabs_official_contact = false
                    break;
                case 'notification-whats-app':
                    this.is_tabs_kiosk_session = false 
                    this.is_tabs_ticket_layout = false
                    this.is_tabs_notification = true 
                    this.is_tabs_check_in_timeout = false
                    this.is_tabs_legal = false
                    this.is_tabs_official_contact = false
                    break;
                case 'notification-telegram':
                    this.is_tabs_kiosk_session = false 
                    this.is_tabs_ticket_layout = false
                    this.is_tabs_notification = true 
                    this.is_tabs_check_in_timeout = false
                    this.is_tabs_legal = false
                    this.is_tabs_official_contact = false
                    break;
                case 'check-in-timeout':
                    this.is_tabs_kiosk_session = false 
                    this.is_tabs_ticket_layout = false
                    this.is_tabs_notification = false
                    this.is_tabs_check_in_timeout = true 
                    this.is_tabs_legal = false
                    this.is_tabs_official_contact = false
                    break;
                case 'legal-privacy-policy':
                    this.is_tabs_kiosk_session = false 
                    this.is_tabs_ticket_layout = false
                    this.is_tabs_notification = false
                    this.is_tabs_check_in_timeout = false
                    this.is_tabs_legal = true 
                    this.is_tabs_official_contact = false
                    break;
                case 'legal-term-and-condition':
                    this.is_tabs_kiosk_session = false 
                    this.is_tabs_ticket_layout = false
                    this.is_tabs_notification = false
                    this.is_tabs_check_in_timeout = false
                    this.is_tabs_legal = true 
                    this.is_tabs_official_contact = false
                    break;
                case 'official-contact':
                    this.is_tabs_kiosk_session = false 
                    this.is_tabs_ticket_layout = false
                    this.is_tabs_notification = false
                    this.is_tabs_check_in_timeout = false
                    this.is_tabs_legal = false
                    this.is_tabs_official_contact = true
                    break;
                default:
                    break;
            }
            Rest.get('/setting?size=30')
            .then(async(res) => {
                if( res && res.data && res.data.items && res.data.items.length) {
                    let kiosk_session = []
                    let ticket_layout = []
                    let notification = []
                    let check_in_timeout = this.check_in_timeout
                    let legal = []
                    let official_contact = []
                    let max_package = this.max_package
                    let max_accumulation_ticket = this.max_accumulation_ticket
                    
                    res.data.items.forEach((item) => {
                        switch (item.code) {
                            case 'SESS-TIME':
                                kiosk_session.push({
                                    id: item.id,
                                    code: item.code,
                                    name: item.name ? item.name : null,
                                    value: item.value ? item.value : null,
                                    description: item.description ? item.description : null
                                })
                                break;
                            case 'IDLE-TIME':
                                kiosk_session.push({
                                    id: item.id,
                                    code: item.code,
                                    name: item.name ? item.name : null,
                                    value: item.value ? item.value : null,
                                    description: item.description ? item.description : null
                                })
                                break;
                            case 'TCK-HEAD':
                                ticket_layout.push({
                                    id: item.id,
                                    code: item.code,
                                    name: item.name ? item.name : null,
                                    value: item.value ? item.value : null,
                                    description: item.description ? item.description : null
                                })
                                break;
                            case 'TCK-WORD':
                                ticket_layout.push({
                                    id: item.id,
                                    code: item.code,
                                    name: item.name ? item.name : null,
                                    value: item.value ? item.value : null,
                                    description: item.description ? item.description : null
                                })
                                break;
                            case 'PRINT-NOTE':
                                ticket_layout.push({
                                    id: item.id,
                                    code: item.code,
                                    name: item.name ? item.name : null,
                                    value: item.value ? item.value : null,
                                    description: item.description ? item.description : null
                                })
                                break;
                            case 'SEND-EMAIL':
                                notification.push({
                                    id: item.id,
                                    code: item.code,
                                    name: item.name ? item.name : null,
                                    value: item.value ? item.value : null,
                                    description: item.description ? item.description : null
                                })
                                break;
                            case 'SEND-WHATS':
                                notification.push({
                                    id: item.id,
                                    code: item.code,
                                    name: item.name ? item.name : null,
                                    value: item.value ? item.value : null,
                                    description: item.description ? item.description : null
                                })
                                break;
                            case 'SEND-TLGRM':
                                notification.push({
                                    id: item.id,
                                    code: item.code,
                                    name: item.name ? item.name : null,
                                    value: item.value ? item.value : null,
                                    description: item.description ? item.description : null
                                })
                                break;
                            case 'CHECK-IN':
                                check_in_timeout = {
                                    id: item.id,
                                    code: item.code,
                                    name: item.name ? item.name : null,
                                    value: item.value ? item.value : null,
                                    description: item.description ? item.description : null
                                }
                                break;
                            case 'PRIVACY':
                                legal.push({
                                    id: item.id,
                                    code: item.code,
                                    name: item.name ? item.name : null,
                                    value: item.value ? item.value : null,
                                    description: item.description ? item.description : null
                                })                            
                                break;
                            case 'TERM-COND':
                                legal.push({
                                    id: item.id,
                                    code: item.code,
                                    name: item.name ? item.name : null,
                                    value: item.value ? item.value : null,
                                    description: item.description ? item.description : null
                                })
                                break;
                            case 'SET-EMAIL':
                                official_contact.push({
                                    id: item.id,
                                    code: item.code,
                                    name: item.name ? item.name : null,
                                    value: item.value ? item.value : null,
                                    description: item.description ? item.description : null
                                })
                                break;
                            case 'WHATS-APP':   
                                official_contact.push({
                                    id: item.id,
                                    code: item.code,
                                    name: item.name ? item.name : null,
                                    value: item.value ? item.value : null,
                                    description: item.description ? item.description : null
                                })
                                break;
                            case 'TELE-GRAM':
                                official_contact.push({
                                    id: item.id,
                                    code: item.code,
                                    name: item.name ? item.name : null,
                                    value: item.value ? item.value : null,
                                    description: item.description ? item.description : null
                                })
                                break;
                            case 'MAX-PCKAGE':
                                max_package = {
                                    id: item.id,
                                    code: item.code,
                                    name: item.name ? item.name : null,
                                    value: item.value ? item.value : null,
                                    description: item.description ? item.description : null
                                }
                                break;
                            case 'MAX-TICKET':
                                max_accumulation_ticket = {
                                    id: item.id,
                                    code: item.code,
                                    name: item.name ? item.name : null,
                                    value: item.value ? item.value : null,
                                    description: item.description ? item.description : null
                                }
                                break;
                            case 'EXP-PYMENT':
                                kiosk_session.push({
                                    id: item.id,
                                    code: item.code,
                                    name: item.name ? item.name : null,
                                    value: item.value ? item.value : null,
                                    description: item.description ? item.description : null
                                })
                                break;
                            case 'EXP-CASH': 
                                kiosk_session.push({
                                    id: item.id,
                                    code: item.code,
                                    name: item.name ? item.name : null,
                                    value: item.value ? item.value : null,
                                    description: item.description ? item.description : null
                                })
                                break;
                            default:
                                break;
                        }
                    })

                    if(kiosk_session && kiosk_session.length > 0) {
                        this.kiosk_session = kiosk_session
                    }

                    if(ticket_layout && ticket_layout.length > 0) {
                        this.ticket_layout = ticket_layout
                    }

                    if(notification && notification.length > 0) {
                        this.notification = notification
                    }

                    if(check_in_timeout.id) {
                        this.check_in_timeout = check_in_timeout
                    }

                    if(legal && legal.length > 0) {
                        this.legal = legal
                    }

                    if(official_contact && official_contact.length > 0) {
                        this.official_contact = official_contact
                    }

                    if(max_package.id) {
                        this.max_package = max_package
                    }

                    if(max_accumulation_ticket.id) {
                        this.max_accumulation_ticket = max_accumulation_ticket
                    }

                    let temp = {
                        kiosk_session: this.kiosk_session,
                        ticket_layout: this.ticket_layout,
                        notification: this.notification,
                        check_in_timeout: this.check_in_timeout,
                        legal: this.legal,
                        official_contact: this.official_contactcheck_in_timeout,
                        max_package: this.max_package,
                        max_accumulation_ticket: this.max_accumulation_ticket
                    }

                    this.form_temp = JSON.parse(JSON.stringify(temp))
                }
            })
        },
        async tabsKioskSession() {
            if(!this.is_tabs_kiosk_session) {
                this.unix_time = moment().unix()
                this.is_tabs_kiosk_session = true
                this.is_tabs_ticket_layout = false
                this.is_tabs_notification = false
                this.is_tabs_check_in_timeout = false
                this.is_tabs_legal = false
                this.is_tabs_official_contact = false

                let temp = this.form_temp
                if(temp && temp.kiosk_session) {
                    this.kiosk_session = temp.kiosk_session
                }

                this.$root.$router.replace({ query : {tabs : 'kiosk-session'} })
                console.clear()
            }
        },
        async tabsTickeetLayout(){
            if(!this.is_tabs_ticket_layout) {
                this.unix_time = moment().unix()
                this.is_tabs_kiosk_session = false
                this.is_tabs_ticket_layout = true
                this.is_tabs_notification = false
                this.is_tabs_check_in_timeout = false
                this.is_tabs_legal = false
                this.is_tabs_official_contact = false

                let temp = this.form_temp
                if(temp && temp.ticket_layout) {
                    this.ticket_layout = temp.ticket_layout
                }

                this.$root.$router.replace({ query : {tabs : 'ticket-layout'} })
                console.clear()
            }
        },
        async tabsNotification(){
            if(!this.is_tabs_notification) {
                this.unix_time = moment().unix()
                this.is_tabs_kiosk_session = false 
                this.is_tabs_ticket_layout = false
                this.is_tabs_notification = true 
                this.is_tabs_check_in_timeout = false
                this.is_tabs_legal = false
                this.is_tabs_official_contact = false

                let temp = this.form_temp
                if(temp && temp.notification) {
                    this.notification = temp.notification
                }

                this.$root.$router.replace({ query : {tabs : 'notification-email'} })
                console.clear()
            }
        },
        async tabsCheckInTimeout() {
            if(!this.is_tabs_check_in_timeout) {
                this.unix_time = moment().unix()
                this.is_tabs_kiosk_session = false 
                this.is_tabs_ticket_layout = false
                this.is_tabs_notification = false
                this.is_tabs_check_in_timeout = true 
                this.is_tabs_legal = false
                this.is_tabs_official_contact = false

                let temp = this.form_temp
                if(temp && temp.check_in_timeout) {
                    this.check_in_timeout = temp.check_in_timeout
                }

                this.$root.$router.replace({ query : {tabs : 'check-in-timeout'} })
                // console.clear()
            }
        },
        async tabsLegal() {
            if(!this.is_tabs_legal) {
                this.unix_time = moment().unix()
                this.is_tabs_kiosk_session = false
                this.is_tabs_ticket_layout = false
                this.is_tabs_notification = false
                this.is_tabs_check_in_timeout = false
                this.is_tabs_legal = true 
                this.is_tabs_official_contact = false

                let temp = this.form_temp
                if(temp && temp.legal) {
                    this.legal = temp.legal
                }

                this.$root.$router.replace({ query : {tabs : 'legal-privacy-policy'} })
                console.clear()
            }
        },
        async tabsOfficialContact() {
            if(!this.is_tabs_official_contact) {
                this.unix_time = moment().unix()
                this.is_tabs_kiosk_session = false
                this.is_tabs_ticket_layout = false
                this.is_tabs_notification = false
                this.is_tabs_check_in_timeout = false
                this.is_tabs_legal = false
                this.is_tabs_official_contact = true

                let temp = this.form_temp
                if(temp && temp.official_contact) {
                    this.official_contact = temp.official_contact
                }

                this.$root.$router.replace({ query : {tabs : 'official-contact'} })
                console.clear()
            }
        },
        async onRefreshKiosKSession(data) {
            this.kiosk_session = data 

            let temp = this.form_temp
            if(temp) {
                temp.kiosk_session = data 
                this.form_temp = JSON.parse(JSON.stringify(temp))
            }
        },
        async onRefreshTicketLayout(data) {
            this.ticket_layout = data 

            let temp = this.form_temp
            if(temp) {
                temp.ticket_layout = data 
                this.form_temp = JSON.parse(JSON.stringify(temp))
            }
        },
        async onRefreshNotification(data) {
            this.notification = data 

            let temp = this.form_temp
            if(temp) {
                temp.notification = data 
                this.form_temp = JSON.parse(JSON.stringify(temp))
            }
        },
        async onRefreshCheckInTimeout(data) {
            this.check_in_timeout = data 

            let temp = this.form_temp
            if(temp) {
                temp.check_in_timeout = data
                this.form_temp = JSON.parse(JSON.stringify(temp))
            }
        },
        async onRefreshLegal(data) {
            console.log('update onRefreshLegal')
            this.legal = data

            let temp = this.form_temp
            if(temp) {
                temp.legal = data 
                this.form_temp = JSON.parse(JSON.stringify(temp))
            }
        },
        async onRefreshOfficialContact(data) {
            this.official_contact = data

            let temp = this.form_temp
            if(temp) {
                temp.official_contact = data 
                this.form_temp = JSON.parse(JSON.stringify(temp))
            }
        }
    },
    computed: {

    },
    watch: {

    }
}
</script>

<style scoped>
</style>