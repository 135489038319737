<template>
    <div>

        <pre>View Code: src/app/pages/DatatableSample.vue</pre>

        <datatable title="Less config table" icon="fa-th" :columns="[{title:'Name',data:'name'}]" :data="[{name:'Tony'},{name:'Nami'}]"/>

        <datatable :config="config"/>

        <datatable title="Each config table" icon="fa-th" :columns="config.columns" :data="config.data"/>

        <datatable title="Dynamic data (5 seconds update)" icon="fa-th" :columns="config.columns" :data="longData"/>

        <datatable :config="clickableRow" @click="rowClick"/>

        <datatable :config="configButtons"/>

        <datatable :config="configCustomDom"/>

        <datatable :config="configSimpleAjax"/>

        <datatable :config="configPrepare"/>

        <datatable :config="configNotFix"/>

        <datatable :config="configNestedTable" @click="openChild"/>

    </div>
</template>

<script>
export default {
    data() {
        return {

            /**
             * Starter config
             */
            config: {
                title: 'Single config table',
                icon: 'fa-th',
                columns: [{
                    title: 'Name',
                    data: 'name'
                }],
                data: [{
                    name: 'Franky'
                }]
            },
            longData: [],

            /**
             * Clickable row config
             */
            clickableRow: {
                title: 'Clickable row',
                icon: 'fa-th',
                columns: [{
                    title: 'Name',
                    data: 'name'
                }],
                data: [{
                    name: 'Franky'
                }]
            },

            /**
             * Config with buttons
             */
            configButtons: {
                title: 'With buttons',
                icon: 'fa-th',
                buttons: [{
                    text: '<i class="fa fa-info-circle"></i> Say hello',
                    className: 'btn-primary',
                    action: () => bootbox.alert('Hello!')
                }, {
                    text: '<i class="fa fa-smile"></i> Say hello again',
                    className: 'btn-info',
                    action: () => bootbox.alert('Hello again!')
                }],
                columns: [{
                    title: 'Name',
                    data: 'name'
                }],
                data: [{
                    name: 'Franky'
                }]
            },

            /**
             * Custom com
             */
            configCustomDom: {
                title: 'Custom dom',
                icon: 'fa-th',
                dom: "<'box'<'box-header table-title'>t>",
                columns: [{
                    title: 'Name',
                    data: 'name'
                }],
                data: [{
                    name: 'Franky'
                }]
            },

            /**
             * Simple ajax
             */
            configSimpleAjax: {
                title: 'Simple ajax with paging, shorting, limit and filtering support',
                icon: 'fa-th',
                columns: [{
                    title: 'Cluster',
                    data: 'cluster_name'
                }, {
                    title: 'Project',
                    data: 'project_name'
                }, {
                    title: 'Cluster ID',
                    data: 'cluster_id'
                }],
                url: Api.base + '/stock/cluster/0'
            },

            /**
             * Init after mount
             */
            configPrepare: {},

            /**
             * Disable fixed header
             */
            configNotFix: {
                fixedHeader: false,
                title: 'Disable fixed header',
                icon: 'fa-th',
                columns: [{
                    title: 'No Fixed Header',
                    data: 'cluster_name'
                }],
                url: Api.base + '/stock/cluster/0'
            },

            /**
             * Nested tables
             */
            configNestedTable: {
                fixedHeader: false,
                title: 'Nested tables',
                icon: 'fa-th',
                columns: [{
                    title: 'Name',
                    data: 'cluster_name'
                }],
                url: Api.base + '/stock/cluster/0'
            }
        }
    },
    mounted() {
        this.init()

        setTimeout(() => {
            this.configPrepare = {
                title: 'Delayed config (Created after 5 seconds mounted)',
                icon: 'fa-th',
                columns: [{
                    title: 'Cluster',
                    data: 'cluster_name'
                }, {
                    title: 'Project',
                    data: 'project_name'
                }, {
                    title: 'Cluster ID',
                    data: 'cluster_id'
                }],
                url: Api.base + '/stock/cluster/0'
            }
        }, 5000)
    },
    methods: {
        rowClick(row) {
            let item = row.data()
            bootbox.alert('Hello '+item.name)
        },
        openChild(row) {
            if (!row.data()) {
                return
            }
            var item = row.table().row(row.selector.rows)
            if (item.child.isShown()) {
                item.child.hide()
            } else {
                this.fetchRow(row, item)
            }
        },
        fetchRow(row, item) {
            var data = row.data()
            var table = $('<table class="table table-sm table-bordered"></table>')
            item.child(table).show()
            var dt = table.DataTable({
                destroy: true,
                fixedHeader: false,
                serverSide: true,
                processing: true,
                dom: "<'table-form'<'box'<'box-body'ftrp>>>",
                columns: [{
                    title: "House number",
                    data: "house_name"
                }],
                ajax: {
                    url: Api.base + '/stock/house/' + data.cluster_id
                }
            })

            table.off('click', 'tbody tr').on('click', 'tbody tr', function() {
                var data = dt.row(this).data()
                bootbox.alert('Clicked house number ' + data.house_name)
            })
        },
        init() {
            this.longData = []
            let x = setInterval(() => {
                this.longData.push({name: 'User '+(new Date().getTime())})
                if (this.longData.length > 50) {
                    clearInterval(x)
                    this.init()
                }
            }, 5000)
        }
    }
}
</script>
