<template>
    <div>
        <header-title-button
          title="Change Password"
          :isAdd="false">
        </header-title-button>

        <box 
            header="false"
            :cancel="false"
            :save="true"
            saveText="Simpan"
            @onSave="doSaveConfirm">
            <div class="row">
                <div class="col-md-12">
                    <div class="box-info-warning-primary">
                        <icon-info-circle :fill="'#0083FF'"> </icon-info-circle>
                        <span :class="`box-info-warning-primary-span`">
                            Ensure the password is at least 8 characters long, contains symbols, and is a combination of uppercase and lowercase letters..
                        </span>
                    </div>
                </div>
            </div>
            <br />
            <div class="row">
                <div class="col-md-4 col-xs-12">
                    <div class="form-group"  :class="{'has-error': !!errors.old_password}">
                        <label class="control-label">Old Password 
                            <span class="warning-text-required">*</span>
                        </label>
                        <input :type="is_old_password ? 'password' : 'text'" class="form-control old_password"
                            id="old_password"
                            name="old_password"
                            v-model="form.old_password"
                            @input="inputChange"
                            maxlength="50"
                            autocomplete="new-password"
                        /> 
                        <span  @click="showHideOldPassword" class="toggle-password">
                            <i v-if="is_old_password">
                                <img src="@/assets/svg/eye.svg"/>
                            </i>
                            <i v-else>
                                <img src="@/assets/svg/eye-off.svg"/>
                            </i>
                        </span>
                        <p class="help help-block" v-if="errors.old_password">Old password is required.</p>
                    </div>
                </div>
                <div class="col-md-4 col-xs-12">
                    <div class="form-group"  :class="{'has-error': !!errors.password}">
                        <label class="control-label">New Password 
                            <span class="warning-text-required">*</span>
                        </label>
                        <input :type="is_password ? 'password' : 'text'" class="form-control password"
                            id="password"
                            name="password"
                            v-model="form.password"
                            @input="inputChange"
                            maxlength="50"
                            autocomplete="new-password"
                        /> 
                        <span  @click="showHidePassword" class="toggle-password">
                            <i v-if="is_password">
                                <img src="@/assets/svg/eye.svg"/>
                            </i>
                            <i v-else>
                                <img src="@/assets/svg/eye-off.svg"/>
                            </i>
                        </span>
                        <p class="help help-block" v-if="errors.password">{{ form.password ? `The new password must contain lowercase letters, uppercase letters, numbers, symbols, and be at least eight characters long and at most fifty characters long.` : `New password is required.` }}</p>
                    </div>
                </div>
                <div class="col-md-4 col-xs-12">
                    <div class="form-group"  :class="{'has-error': !!errors.password_confirm}">
                        <label class="control-label">Confirm New password 
                            <span class="warning-text-required">*</span>
                        </label>
                        <input :type="is_password_confirm ? 'password' : 'text'" class="form-control password_confirm"
                            id="password_confirm"
                            name="password_confirm"
                            v-model="form.password_confirm"
                            @input="inputChange"
                            maxlength="50"
                            autocomplete="new-password"
                        /> 
                        <span  @click="showHidePasswordConfirm" class="toggle-password">
                            <i v-if="is_password_confirm">
                                <img src="@/assets/svg/eye.svg"/>
                            </i>
                            <i v-else>
                                <img src="@/assets/svg/eye-off.svg"/>
                            </i>
                        </span>
                        <p class="help help-block" v-if="errors.password_confirm">{{ form.password_confirm ? `The confirm new password must contain lowercase letters, uppercase letters, numbers, symbols, and be at least eight characters long and at most fifty characters long.` : `confirm new password is required.` }}</p>
                    </div>
                </div>
            </div>
        </box>

        <modal
            title="Change Confirmation"
            save="true"
            :show="is_confirm"
            saveText="Ya, Confirmation"
            @hidden="is_confirm = false"
            @onSave="doSaveProcess()"
            :loading="is_loading_confirm">
            <span class="span-title">AAre you sure you want to change password ?</span>
        </modal>
    </div>
</template>

<script>
import IconInfoCircle from '@components/svg/IconInfoCircle.vue'
export default {
    components: {
        IconInfoCircle
    },
    data() {
        return {
            form: {
                old_password: null,
                password: null,
                password_confirm: null
            },
            errors: {
                old_password: false,
                password: false,
                password_confirm: false
            },
            is_confirm: false,
            is_loading_confirm: false,
            is_old_password: true,
            is_password: true,
            is_password_confirm: true,
            is_change: false,
        }
    },
    async mounted() {
        await this.$root.checkingAccessPage(this.$root._route.name)
    },
    methods: {
        async doSaveConfirm() {
            let is_valid = await this.valdiationForm()
            if(is_valid) {
                this.is_confirm = true
            }
        },
        async doSaveProcess() {
            this.is_loading_confirm = true 

           
            if(this.form.old_password == this.form.password) {
                let message = 'There is no difference between the old password and the new password.'
                this.$root.showNotification('Failed', 'error', message)
                this.is_loading_confirm = false 
                this.is_confirm = false
            }else {
            
                Rest.put('/user/change/password', this.form)
                .then( async(res) => {
                    if(res && res.status && res.status == 200) {
                    
                        this.is_confirm = false 
                        this.form.old_password = null 
                        this.form.password = null 
                        this.form.password_confirm = null

                        this.is_change = false

                        this.errors.old_password = false 
                        this.errors.password = false 
                        this.errors.password_confirm = false 
                        this.$root.showNotification('Success', 'success', 'Change password successful')
                        this.userLogActivity()

                        let message = 'Password change successful, the system will log out, please log in with the new password.'
                        this.$root.showNotification('Notification', 'success', message)

                        this.broadCastInfoNotif()

                        setTimeout(() => {
                            Api.logout() 
                        }, 3000);
                        
                    }
                })
                .catch (async(err) => {
                    if(err && err.response && err.response.status) {
                        if(err.response.data && err.response.data.message) {
                            this.$root.showNotification('Failed', 'error', err.response.data.message)        
                        }else {
                            let message = "Official contact modification failed."
                                this.$root.showNotification('Failed', 'error', message)
                        }
                    }else {
                        this.$root.showNotification('Failed', 'error', 'Sorry, you do not have an internet connection.')
                    }
                })
                .finally( () => {
                    this.is_loading_confirm = false 
                    this.is_confirm = false 
                })
            }
        },
        async valdiationForm() {
            let is_success = true 
            this.errors.old_password = false 
            this.errors.password = false 
            this.errors.password_confirm = false 

            if(!this.form.old_password) {
                this.errors.old_password = true 
                is_success = false 
            }

            if(!this.form.password) {
                this.errors.password = true 
                is_success = false 
            }else {
                let is_valid = this.$root.validationInputPassword(this.form.password)
                if(!is_valid) {
                    this.errors.password = true 
                    is_success = false 
                }
            }

            if(!this.form.password_confirm) {
                this.errors.password_confirm = true 
                is_success = false 
            }else {
                let is_valid = this.$root.validationInputPassword(this.form.password_confirm)
                if(!is_valid) {
                    this.errors.password_confirm = true 
                    is_success = false 
                }
            }

            return is_success
        },
        showHidePassword() {
            this.is_password = !this.is_password
        },
        showHideOldPassword() {
            this.is_old_password = !this.is_old_password
        },
        showHidePasswordConfirm() {
            this.is_password_confirm = !this.is_password_confirm
        },
        inputChange() {
            this.is_change = true
        },
        async userLogActivity() {
            if(this.$root.$store.state.user && this.$root.$store.state.user.name) {
                let params = {
                    activity: this.$root.$store.state.user.name + ' has been change password',
                    feature: 'Change Password',
                    activity_type: 'edit'
                }

                this.$root.sendUserLogActivity(params)
            }
            
        },
        async broadCastInfoNotif() {
            this.$socket.emit('info-notif', {
                notif_type: 'change-password',
                user_id: this.$root.$store.state.user.id,
                data: {
                    user_id: this.$root.$store.state.user.id
                }
            })
        }
    },
    computed: {
        is_partner() {
            let response = false 
            let temp = localStorage.getItem('_USER_ME')
            if(temp) {
                let bytes = this.CryptoJS.AES.decrypt(temp, process.env.VUE_APP_KEY_ENCRYPT_DECRYPT)
                let data = JSON.parse(bytes.toString(this.CryptoJS.enc.Utf8))
                if(data) {
                    if(data.company_id) {
                        response = true
                    }
                }
            }

            return response
        }
    },
    watch: {
        'form.old_password': {
            handler(val) {
                this.errors.old_password = false 
                if(this.is_change) {
                    if(!val) {
                        this.errors.old_password = true 
                    }
                }
            }
        },
        'form.password': {
            handler(val) {
                this.errors.password = false 
                if(this.is_change) {
                    if(!val) {
                        this.errors.password = true 
                    }else {
                        let is_valid = this.$root.validationInputPassword(val)
                        if(!is_valid) {
                            this.errors.password = true 
                        }
                    }
                }
            }
        },
        'form.password_confirm': {
            handler(val) {
                this.errors.password_confirm = false 
                if(this.is_change) {
                    if(!val) {
                        this.errors.password_confirm = true 
                    }else {
                        let is_valid = this.$root.validationInputPassword(val)
                        if(!is_valid) {
                            this.errors.password_confirm = true 
                        }
                    }
                }
            }
        }
    }
}
</script>

<style scoped>
    .toggle-password {
        cursor: pointer;  
        float: right;
        margin-top: -25px;
        margin-right: 25px;
    }

    .toggle-password img {
        position: absolute;
    }
</style>