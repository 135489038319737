<template>
    <div>
        <div class="div-box-image">
                          
            <div id="video-preview" v-if="file_extension == 'mp4' || file_extension == 'ogg' ">
                <video width="320" height="210" controls>
                    <source :src="image_path" type="video/mp4">
                </video>

            </div >
            <div v-else>
                <img v-if="image_path"  id="image-preview" :src="image_path" />
            </div>
                                
            <div class="box-button-edit-delete-image">
                <span class="button-edit-delete-image-info">
                    <span class="cursor-pointer" @click="uploadImage()">
                        <img src="@/assets/svg/solid-pencil.svg" /> Edit
                    </span>
                    <span class="edit-delete-image-divider"> | </span>
                    <span class="cursor-pointer" @click="deleteImage()">
                        <img src="@/assets/svg/solid-trash.svg" /> Delete
                    </span>
                </span>
            </div>
        </div>

        <input 
            type="file" 
            id="upload-image" 
            class="upload-image"
            name="upload-image" 
            :accept="format_file" 
            @change="uploadImageChange()"/>
        
        <div class="box-upload-info">
            <!-- <p class="upload-info-text">Maks. 1MB, Format .jpg/.png</p> -->
            <div class="box-info-warning-primary">
                <IconInfoCircle :fill="'#0083FF'"/>
                <span :class="`box-info-warning-primary-span`">
                    Max. {{max_size_mb}}MB, Format {{ format_file }}
                </span>
            </div>
        </div>
        
    </div>
</template>

<script>
import IconInfoCircle from '@components/svg/IconInfoCircle.vue'
export default {
    props: {
        image: {
            type: String,
            default: ""
        },
        disabled: {
            type: Boolean,
            default: false
        },
        scale: {
            type: String,
            default: "1:2"
        },
        is_scale: {
            type: [String, Boolean],
            default: false
        },
        max_size: {
            type: Number,
            default: 5000000
        },
        format_file: {
            type: String,
            default: "image/png, image/jpg, image/jpeg"
        }
    },
    components: {
        IconInfoCircle
    },
    data() {
        return {
            image_path: "",
            scale_height: 2,
            scale_width: 1,
            file_extension: null,
        }
    },
    async mounted() {
        if(this.image && this.image != "") {
            let first_char = this.image.charAt(0)
            if(first_char == '/') {
                this.image_path = process.env.VUE_APP_UPLOAD_URL + this.image
                let split = this.image_path.split('.')
                if(split.length > 1) {
                    this.file_extension = split[split.length - 1]
                }
            }else {
                let sub = this.image_path.substring(0, 4)
                if(sub != 'blob') {
                    this.image_path = process.env.VUE_APP_UPLOAD_URL +'/'+ this.image
                }
                
            }
        }

    },
    methods: {
        mouseEnter() {
            console.log('enter')
            $('.box-button-edit-delete-image').addClass('visibility-visible')
        },
        mouseOver() {
            console.log('over')
            $('.box-button-edit-delete-image').addClass('visibility-visible')
        },
        mouseLeave() {
            console.log('leave')
            $('.box-button-edit-delete-image').removeClass('visibility-visible')
        },
        uploadImage() {
            if(!this.disabled) {
                $('#upload-image').click()
            }
        },
        uploadImageChange() {
            if(!this.disabled) {
                let possible_type = this.possible_format_file //['image/png', 'image/jpg', 'image/jpeg']
                let input =  document.getElementById('upload-image');
                let file = input.files[0]
                if(file.size <= this.max_size) {
                    if(possible_type.includes(file.type)) {
                        
                        let imageUrl = window.URL.createObjectURL(file) 
                        this.image_path = imageUrl
                        if(file.type == "video/mp4") {
                            this.file_extension = "mp4"
                            
                        }else {
                            this.file_extension = null
                        }
                       

                        let data = {
                            file: file,
                        }

                        console.log('data', data)

                        input.value = null
                        this.$emit('onChangeUploadImage', data)
                    }else {
                        this.$root.showNotification('Failed', 'error', 'Invalid format file extension, possible upload using format file extension '+this.format_file)
                        input.value = null
                    }
                }else {
                    let your_file = file.size/1000000
                    if(parseFloat(your_file) % 1 == 0) {
                        your_file = parseFloat(your_file).toFixed(0)
                    }
                    
                    this.$root.showNotification('Failed', 'error', 'Your file exceeds '+your_file+', the maximum file is '+this.max_size_mb+'MB')
                    input.value = null
                }
            }
        },
        deleteImage() {
            if(!this.disabled) {
                this.image_path = ''
                this.file_extension = null

                let input =  document.getElementById('upload-image');
                input.value = null
                this.$emit('onChangeDeleteImage')
            }
        },
        isNumber(value) {
            return !isNaN(parseFloat(value)) && isFinite(value);
        },
        async setScaleHeightWidth(data) {
            let width = 1
            let height = 2 
            if(data && data.length == 2) {
                let w = data[0]
                let h = data[1]
                if(this.isNumber(w)  && this.isNumber(h)) {
                    width = parseFloat(w)
                    height = parseFloat(h)
                }
            }

            this.scale_width = width
            this.scale_height = height
        }

    },
    computed: {
        max_size_mb() {
            let max = 0
            if(this.max_size) {
                max = this.max_size/1000000
            }

            let max_file = max.toFixed(1)
            if(parseFloat(max_file) % 1 == 0) {
                max_file = parseFloat(max_file).toFixed(0)
            }
            return max_file
        },
        possible_format_file() {
            let possible_type = []
            if(this.format_file) {
                let format_file = this.format_file
                format_file = format_file.replaceAll(" ", "")
                possible_type = format_file.split(",")
            }
            return possible_type
        }
    },
    watch: {
        image: {
            handler(val) {
                if(val) {
                    if(!this.image_path != val) {
                        let first_char = val.charAt(0)
                        if(first_char == '/') {
                            this.image_path = process.env.VUE_APP_UPLOAD_URL +val
                            let split = this.image_path.split('.')
                            if(split.length > 1) {
                                this.file_extension = split[split.length - 1]
                            }

                        }else {
                            let sub = val.substring(0, 4)
                            if(sub != 'blob') {
                                this.image_path = process.env.VUE_APP_UPLOAD_URL +'/'+val
                            }else {
                                this.image_path = val
                            }
                        }
                    }
                }
            }
        },
        scale: {
            handler(val) {
                
                if(val) {
                    let split = val.split(":")
                    if(split.lengh == 2){
                        this.setScaleHeightWidth(split)
                    }
                }
            }
        }
    }
}
</script>

<style scoped>
    .div-box-image {
        display: flex;
        height: 210px;
        width: 322px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        align-self: stretch;
        border-radius: 8px;
        border: 1px dashed  #9E9E9E
    }

    .upload-image {
        display: none;
    }

    #image-preview {
        width: 320px !important; 
        height: 210px !important; 
        padding: 2px !important; 
        border-radius: 8px !important
    }

    .box-button-edit-delete-image {
        display: flex;
        width: 320px;
        padding: 12px 16px;
        justify-content: center;
        align-items: center;
        gap: 24px;
        position: absolute;
        bottom: 0px;
        border-radius: 0px 0px 6px 6px;
        background: rgba(64, 64, 64, 0.50);
        backdrop-filter: blur(2px);
    }

    .button-edit-delete-image-info {
        color:  #FFF;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
    }

    .edit-delete-image-divider {
        width: 1px;
        height: 40px; 
        padding: 0px 15px;
    }

    .box-upload-info {
        width: 320px;
        position: absolute; 
        padding-top: 20px;
    }
    
    .upload-info-text {
        color:#757575;
        text-align: center;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
    }
</style>