import LoadingButton from './LoadingButton'
const RemoveButton = {
    name: 'remove-button',
    props: ['loading', 'saveText', 'removeText', 'loadingText', 'click', 'click.prevent', 'iconRemove'],
    components: {
        LoadingButton
    },
    template: '<loading-button :loading="loading" :saveText="removeText || \'Yes, delete\'" :loadingText="loadingText" class="btn btn-md btn-danger show-action" :icon="iconRemove" @click="onClick"/>',
    methods: {
        onClick(e) {
            this.$emit('click', e)
        }
    }
}
export default RemoveButton