<template>
    <div>
        <div class="row">
            <div class="col-md-5" style="background-color: #FFFFFF; height: calc(100vh);">
                <div style="position: absolute; width: 65%; left: 30%; top: 50%; -webkit-transform: translate(-25%, -50%); transform: translate(-25%, -50%);">
                    <div>
                        <p>
                            <span>
                                <img src="@/assets/svg/logo.svg" style="margin-top: -8px"/> 
                            </span>
                            <span style="
                                color:  #06DCF8;
                                text-align: center;
                                font-family: 'Red Hat Display';
                                font-size: 22px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                letter-spacing: 0.3px;
                                padding-left: 10px;">CMS</span>
                        </p>

                    </div>


                    <br /> 

                    <p style="color: #0A0A0A;
                        font-family: 'Inter';
                        font-size: 24px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 44px; 
                        letter-spacing: -0.704px;">Welcome!</p>
                    <p style="color: #757575;
                        font-family: 'Inter';
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 20px; 
                        letter-spacing: -0.084px;">Sign-in to your account.</p>
                    <br />
                    
                    <form @submit.prevent="submit">
                        <div class="form-group has-feedback" id="login-email" style="margin-top: -15px" :class="{'has-error': !!errors.username}">
                            <input type="text" class="form-control email" 
                                id="email"
                                v-model="form.username"
                                maxlength="60" 
                                autocomplete="off"
                                :disabled="loading" />
                                <p class="help help-block" v-if="errors.username">{{ form.username ? 'Email format is not valid.' : 'Email is required.'}}</p>
                        </div>
                        <div class="form-group" :class="{'has-error': !!errors.password}">
                                <input  :type="isBeText ? 'text' : 'password'" 
                                autocomplete="off" 
                                v-model="form.password" 
                                :disabled="loading"
                                class="form-control" 
                                placeholder="Password"
                                maxlength="50" >
                                <span @click.prevent="showPwd()" id="toggle-password">
                                    <i v-if="isBeText">
                                        <img src="@/assets/svg/eye-off.svg"/>
                                    </i>
                                    <i v-else>
                                        <img src="@/assets/svg/eye.svg"/>
                                    </i>
                                </span>
                                <p class="help help-block" v-if="errors.password">{{ form.password ? 'Password must be at least eight characters long and at most fifty characters long.' : 'Password is required.'}}</p>
                        </div>
                        <div class="row">
                            <div class="col-xs-6">
                                <div class="icheck-primary">
                                    <input type="checkbox" id="someCheckboxId" v-model="form.remember" :disabled="loading"/>
                                    <label for="someCheckboxId">Remember Me</label>
                                </div>
                            </div>
                            <div class="col-xs-6">
                                <div class="float-right">
                                    <router-link :to="{name: 'forgot.password'}">Forgot Password</router-link>
                                </div>
                            </div>
                            
                        </div>
                        <div class="row">
                            <div class="col-xs-6">
                                <save-button :disabled="loading || (errors.username || !form.password)" :loading="loading" type="submit"
                                    class="btn-block btn-flat" saveText="Login" loadingText="Login..."/>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="col-md-7" style="background-color: #F2F3F7; height: calc(100vh);">
                <img width="100%" height="100%" src="@/assets/cms.png"/>
                
            </div>
        </div>
        
        <notifications
            group="notification"
            position="bottom right"
            :max="5"
            :width="500"
            :duration="15000"
        >
            <template slot="body" slot-scope="props">
                <div class="vue-notification-template vue-notification" :class="props.item.type" >
                    <span class="title">
                        <b>{{props.item.title}}</b>
                    </span>
                    <a class="close icon-close-notif" @click="props.close">
                        <img src="@/assets/svg/outline-x.svg" />
                    </a>
                    <div v-html="props.item.text">
                    </div>
                </div>
            </template>
        </notifications>
    </div>
</template>

<script>
// import encryptDecryptAesCbc256 from '@app/function/encryptDecryptAesCbc256'
import IconVericalLinearGradient from '@components/svg/IconVericalLinearGradient.vue'
// import CryptoJS from 'crypto-js'
const {console} = window
export default {
    components: {
        IconVericalLinearGradient
    },
    data() {
        return {
            form: {
                remember: true,
                username : null,
                password: null,
                detail: {
                    profile_data: {
                        date_of_birth: "",
                        id_card: "",
                        phone_number: "",
                        address: "",
                        email: ""
                    },
                    project_id: []
                }
            },
            errors: {
                username: false,
                password: false
            },
            error: undefined,
            loading: false,
            checkConnection :null,
            isBeText: false,
            isRemember: true,
            role: {
                detail: [],
                id: null,
                name: null,
                user_id: null,

            },
            project: {},
            me: {
                id: null,
                username: null,
                full_name: null,
                role_type: null,
            },
            icon_width: 841,
            icon_height: 836,
            style_position_vertical_gradient: 'style:position: fixed'
        }
    },
    computed: {
        
    },
    mounted() {
        if (Api.getToken()) {
            this.$router.replace({name: 'dashboard'})
        }
        $('body').attr('class', 'hold-transition login-page')
        $('body.vue').removeAttr('style')
        $('body').layout()
        $('[type="email"]', this.$el).eq(0).focus()

        let screen = window.screen
        console.log('screen :', screen)
        if(screen) {
            if(screen.width && screen.height) {  
                this.icon_height = screen.height
                // if(screen.width <= 1526) {
                //     this.icon_height = screen.height - (15/100*screen.height)
                // }
                if(screen.width == 1920) {
                    this.icon_height = screen.height - (15.9/100*screen.height)
                    // this.icon_width = this.icon_width + (100/100*this.icon_width)
                }

                if (screen <= 1280) {
                    // this.style_position_vertical_gradient = 'style: positio: relative'
                }

                
            }

            
        }

        // let browser  = (function() {
        //     var test = function(regexp) {
        //         return regexp.test(window.navigator.userAgent);
        //     }
        //     switch (true) {
        //         case test(/edg/i): return "edge";
        //         case test(/opr/i) && (!!window.opr || !!window.opera): return "opera";
        //         case test(/chrome/i) && !!window.chrome: return "chrome";
        //         case test(/trident/i): return "ie";
        //         case test(/firefox/i): return "firefox";
        //         case test(/safari/i): return "safari";
        //         default: return "other";
        //     }
        // })();
        // if(browser.toLowerCase() != 'chrome'){
        //   // var win = window.open("","_self");
        //   // win.close();
        //   this.$root.showNotification('Gagal', 'info', 'Dimohon menggunakan browser google chrome untuk membuka aplikasi ini, jika belum install silahkan install terlebih google chrome')
        //   setTimeout(function(){
        //     window.open('https://www.google.com/chrome', '_self')
        //   }, 5000);
        // }

    },
    methods: {
        toggleDisabled(loading) {
            var emailInput = $('#login-email').find('input')
            if (loading) {
                emailInput.attr('disabled', 'disabled')
            } else {
                setTimeout(() => {
                    emailInput.removeAttr('disabled')
                }, 1000)
            }
        },
        async validationForm() {
            var is_success = true 
            this.errors.username = false
            this.errors.password = false 
            if(!this.form.username) {
                this.is_success = false 
                this.errors.username = true 
            }else {
                if(this.form.username.trim().length > 60) {
                    is_success = false 
                    this.errors.username = true 
                }else {
                    let is_valid = this.$root.validationInputEmail(this.form.username.trim())
                    if(!is_valid) {
                        is_success = false 
                        this.errors.username = true
                    }
                }
            }

            this.errors.password = false
            if(!this.form.password) {
                is_success = false
                this.errors.password = true 
            }else {
                if(this.form.password.length < 8 || this.form.password.length > 50) {
                    is_success = false
                    this.errors.password = true 
                }
            }

            return is_success
        },
        async submit() {
            var is_success = await this.validationForm()
            if(is_success) {
                this.checkConnection= null;
                this.loading = true
                this.isRemember = this.form.remember
                Api.login(this.form.username, this.form.password)
                    .then( async(res) => {
                        Api.setToken(res, this.form.remember)
                        Rest.get('/user/me/detail')
                        .then( async(res) => {
                            if(res && res.data) {
                                this.me = {
                                    id: res.data.id,
                                    username: res.data.username,
                                    full_name: res.data.full_name,
                                    avatar: res.data.avatar
                                } 

                                this.$root.$store.state.user.id = res.data.id
                                this.$root.$store.state.user.username = res.data.username
                                this.$root.$store.state.user.full_name = res.data.full_name
                                this.$root.$store.state.user.avatar = res.data.avatar

                                if(res.data.roles && res.data.roles.length > 0) {
                                    var roleRes = res.data.roles[0]
                                    if(roleRes && roleRes.name) {
                                        var roleNameProtect = ["ROLE_USER", "ROLE_MACHINE", "ROLE_GATE_SCAN", "ROLE_CASHIER"]
                                        if (roleNameProtect.indexOf(roleRes.name) >= 0) {
                                            this.loading = false
                                            this.$root.showNotification('Error', 'error', roleRes.Name+" do not have access to log in to the CMS (Content Management System).")
                                            localStorage.clear()
                                            console.clear()
                                            return
                                        }
                                    }   

                                    var detail = roleRes.permission_detail 
                                    if (detail && typeof detail == 'string') {
                                        detail = JSON.parse(detail)
                                    }

                                    this.role = {
                                        permission_detail: detail,
                                        id: roleRes.id,
                                        name: roleRes.name,
                                        user_id: res.data.id,
                                    }

                                    this.$root.$store.state.role.id = roleRes.id
                                    this.$root.$store.state.role.name = roleRes.name
                                    this.$root.$store.state.role.user_id =res.data.id
                                    this.$root.$store.state.role.detail = detail

                                    var navigationsData = []
                                    var permissionData = {}
                                    if(detail && detail.navigations) {
                                        navigationsData = detail.navigations
                                    }

                                    if(detail && detail.permission) {
                                        permissionData = detail.permission
                                    }

                                    console.log('this.me', this.me)
                                    
                                    var navigations = this.CryptoJS.AES.encrypt(JSON.stringify(navigationsData), process.env.VUE_APP_KEY_ENCRYPT_DECRYPT).toString()
                                    var permission = this.CryptoJS.AES.encrypt(JSON.stringify(permissionData), process.env.VUE_APP_KEY_ENCRYPT_DECRYPT).toString()
                                    var role = this.CryptoJS.AES.encrypt(JSON.stringify(this.role), process.env.VUE_APP_KEY_ENCRYPT_DECRYPT).toString()
                                    var user = this.CryptoJS.AES.encrypt(JSON.stringify(this.me), process.env.VUE_APP_KEY_ENCRYPT_DECRYPT).toString()
                                    localStorage.setItem('navigations', navigations)
                                    localStorage.setItem('_USER_PERMISSION', permission)
                                    localStorage.setItem('_USER_ROLES', role)
                                    localStorage.setItem('_USER_ME', user)

                                    this.$socket.disconnect(); 
                                    this.$socket.connect();
                                    this.$socket.emit('register', { user_id: res.data.id })
                                    
                                    this.sendLogActivities()

                                    if(this.$root._route.name != 'dashboard') {
                                        this.$router.push({name: 'dashboard'})
                                    }

                                }
                            }
                        })
                        .catch( () => {
                            this.loading = false
                        })

                        
                    }).catch( async(err) => {
                        if(err && err.response && err.response.status) {
                            if(err.response.data && err.response.data.message) {
                                this.$root.showNotification('Failed', 'error', err.response.data.message)        
                            }else {
                                var message = "Login failed"
                                this.$root.showNotification('Failed', 'error', message)
                            }
                        }else {
                            this.$root.showNotification('Failed', 'error', 'Sorry, you do not have an internet connection.')
                        }

                })
                .finally( () => {
                    this.loading = false
                }) 
            }
        },
        showPwd() {
            this.isBeText = !this.isBeText
        },
        async sendLogActivities() {
            let message = this.isRemember ? " using 'remember me' " : ""
            let params = {
                activity: this.$root.$store.state.user.full_name+" was login to system nuanu cms"+message,
                route_name: {name: 'login'},
                activity_type: 'login',
                feature: 'login',
            }
            
            this.$root.sendUserLogActivity(params)
            this.$router.replace({name: 'dashboard'})
        },
    },
    watch: {
        'form.username': {
            handler(val) {
                this.errors.username = false 
                if(!this.id) {
                    if(!val) {
                        this.errors.username = true 
                    }else {
                        if(val.trim().length > 60) {
                            this.errors.username = true 
                        }else {
                            let is_valid = this.$root.validationInputEmail(val.trim())
                            if(!is_valid) {
                                this.errors.username = true
                            }
                        }
                    }
                }
            }
        },
        'form.password': {
            handler(val) {
                this.errors.password = false
                if(!val) {
                    this.errors.password = true 
                }else {
                    if(val.length < 8 || val.length > 50) {
                        this.errors.password = true 
                    }
                }
                
            }
        }
    }
}
</script>

<style lang="css" scoped>
@import url('https://fonts.googleapis.com/css?family=Inter');
@import url('https://fonts.googleapis.com/css?family=Red+Hat+Display');
    #toggle-password {
        cursor: pointer;  
        float: right;
        margin-top: -28px;
        margin-right: 25px;
    }

    #toggle-password img {
        position: absolute;
    }

 
</style>


<style lang="scss" scoped>
    .vue-notification {
        padding: 10px;
        margin: 0 5px 5px;
        
        font-size: 12px;
        
        color: #ffffff;
        background: #44A4FC;
        border-left: 5px solid #187FE7;

        &.title {
            color: #ffffff;
        }
        
        &.warn {
            background: #ffb648;
            border-left-color: #f48a06;
        }
        
        &.error {
            background: #E54D42;
            border-left-color: #B82E24;
        }
        
        &.success {
            background: #68CD86;
            border-left-color: #42A85F;
        }

        .icon-close-notif {
            opacity: 0.9 !important;
            color: #FFFFFF !important;
        }

        .icon-close-notif:hover,
        .icon-close-notif:focus {
            opacity: 1 !important;
            color: #FFFFFF !important;
        }
    }
</style>
