<template>
    <div>
        <box back="dashboard" :save="true" :print="true" :remove="true" @onSave="onSave" @onRemove="onRemove" @onPrint="onPrint">

            <pre>View Code: src/app/pages/BoxSample.vue</pre>

            <h4 v-if="action">action: {{ action }}</h4>

            <div class="btn-group">
                <a href="#" class="btn btn-info" @click.prevent="makeRequest">Make request</a>
                <a href="#modal-box-sample" class="btn btn-info" data-toggle="modal">Show modal from HTML</a>
                <a href="#" class="btn btn-info" @click.prevent="showModal()">Show modal from JS</a>
                <a href="#" class="btn btn-info" @click.prevent="show = true">Show modal from JS 2</a>
            </div>

            <hr>

            <div class="btn-group">
                <a href="#" class="btn btn-info" @click.prevent="addHeaderButton">Add header button</a>
                <a href="#" class="btn btn-info" @click.prevent="removeHeaderButton">Remove header button</a>
                <a href="#" class="btn btn-info" @click.prevent="addFooterButton">Add footer button</a>
                <a href="#" class="btn btn-info" @click.prevent="removeFooterButton">Remove footer button</a>
            </div>
            <hr>

            <div class="btn-group">
                <a href="#" class="btn btn-success" @click.prevent="showAlert('success')">Show Success Notification</a>
                <a href="#" class="btn btn-warning" @click.prevent="showAlert('warn')">Show Warning Notification</a>
                <a href="#" class="btn btn-danger" @click.prevent="showAlert('error')">Show Error Notification</a>
                <p>Resource : <a href="https://www.npmjs.com/package/vue-notification" @click.prevent="addHeaderButton">Vue-Notification</a></p>
            </div>

            <modal id="modal-box-sample" title="Hello world" icon="fa-info-circle" save="true" remove="true"
                @onRemove="onRemove"
                @onSave="onSave">
                <h4>Sample modal</h4>
            </modal>

            <modal title="Hello world from js" save="true" remove="true"
                :show="show"
                @hidden="show = false"
                @onRemove="onRemove"
                @onSave="onSave">
                <h4>Sample modal JS</h4>
            </modal>

            <hr>

            <datatable title="Table inside box" icon="fa-th" class="table-form" :columns="[{title:'Name',data:'name'}]" :data="[{name:'Zoro'},{name:'Sanji'}]"/>

            <template v-slot:header-actions>
                <div class="form-inline">
                    <datepicker/>
                    <div class="input-group">
                        <input type="search" class="form-control" placeholder="Cari" v-model="search">
                        <div class="input-group-btn">
                            <button class="btn btn-default" @click.prevent="search = ''"><i class="fa fa-search"></i></button>
                        </div>
                    </div>
                </div>
            </template>

            <template v-slot:footer-buttons>
                <button class="btn btn-info" v-for="btn in footerButtons" :key="btn">Button {{ btn }}</button>
            </template>

            <template v-slot:header-buttons>
                <button class="btn btn-info" v-for="btn in headerButtons" :key="btn">Button {{ btn }}</button>
            </template>

        </box>
        <box :save="false" :cancel="false">
            <img src="@/assets/nuanu-logo.jpg"/>
            <br />
            <br />
            &nbsp;
            <img src="@/assets/how-to-enter.png"/>
        </box>
    </div>
</template>
<script>
const {console} = window
export default {
    data() {
        return {
            action: null,
            show: false,
            search: '',
            footerButtons: [],
            headerButtons: [],
        }
    },
    watch: {
        '$store.state.loading': function(val) {
            if (!val)
                this.show = val
        }
    },
    methods: {
        makeRequest() {
            Api.get('/user')
        },
        showModal() {
            this.show = true
        },
        showAlert(type) {
            this.$root.showNotification('Gagal', type, 'Anda tidak punya akses untuk page tersebut')
        },
        onSave() {
            this.action = 'save'
            this.makeRequest()
        },
        onRemove() {
            this.action = 'remove'
            this.makeRequest()
        },
        onPrint() {
            this.action = 'print'
            window.print()
        },
        addHeaderButton() {
            this.headerButtons.push(this.headerButtons.length + 1)
        },
        removeHeaderButton() {
            try {
                this.headerButtons.splice(this.headerButtons.length - 1, 1)
            } catch (e) {}
        },
        addFooterButton() {
            this.footerButtons.push(this.footerButtons.length + 1)
        },
        removeFooterButton() {
            try {
                this.footerButtons.splice(this.footerButtons.length - 1, 1)
            } catch (e) {}
        }
    }
}
</script>
