<template>
    <div>
        <box header="false"
            :cancel="false"
            :save="permission_edit"
            @onSave="doSaveConfirm">
            <div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group" :class="{'has-error': !!errors.form_email_value}">
                            <label class="control-label">
                                Email 
                                <span class="warning-text-required">*</span>
                            </label>
                            <input type="text" class="form-control email"
                                id="email"
                                v-model="form_email.value"
                                :disabled="disabledForm || !permission_edit"
                                autocomplete="off"
                                maxlength="60" />

                                <p class="help help-block" v-if="errors.form_email_value">{{ text_error_email }}</p>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group" >
                            <label class="control-label">
                                WhatsApp Official Number 
                                <span class="warning-text-required"> </span>
                            </label>
                            <input type="text" class="form-control whats-app"
                                id="whats-app"
                                v-model="form_whats_app.value"
                                :disabled="true"
                                autocomplete="off" />
                            
                                <div class="box-info-warning-primary" style="margin-top: 3px">
                                    <icon-info-circle :fill="'#0083FF'"> </icon-info-circle>
                                    <span :class="`box-info-warning-primary-span`">
                                        To change WhatsApp official number, please contact us.
                                    </span>
                                </div>

                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group" >
                            <label class="control-label">
                                Telegram Official Number 
                                <span class="warning-text-required"></span>
                            </label>
                            <input type="text" class="form-control telegram"
                                id="telegram"
                                v-model="form_telegram.value"
                                :disabled="true"
                                autocomplete="off" />

                            <div class="box-info-warning-primary" style="margin-top: 3px">
                                <icon-info-circle :fill="'#0083FF'"> </icon-info-circle>
                                <span :class="`box-info-warning-primary-span`">
                                    To change Telegram official number, please contact us.
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </box>

        <modal :title="'Change Confirmation'" save="true" remove="false"
            :show="is_confirm"
            saveText="Yes, Confirmation"
            @hidden="is_confirm = false"
            @onSave="doSave"
            :loading="is_confirm_loading">
            <span class="span-title">Are you sure you want to modify official contact ?</span>
        </modal>
    </div>
</template>

<script>
import IconInfoCircle from '@components/svg/IconInfoCircle.vue'
const {console} = window
export default {
    components: {
        IconInfoCircle
    },
    props: {
        official_contact: {
            type: Array,
        },
        unix_time: {
            type: Number
        }
    },
    data() {
        return {
            alias: 'general-setting',
            permission_edit: false,
            disabledForm: false,
            form_email: {
                id: null,
                code: 'SET-EMAIL',
                name: null,
                value: null,
                description: null,
            },
            form_whats_app: {
                id: null,
                code: 'WHATS-APP',
                name: null,
                value: null,
                description: null,
            },
            form_telegram: {
                id: null,
                code: 'TELE-GRAM',
                name: null,
                value: null,
                description: null,
            },
            form_temp: null,
            errors: {
                form_email_value: false 
            },
            is_confirm: false,
            is_confirm_loading: false,
            is_valid_email: true,
        }
    },
    async mounted() {
        this.defineOfficalContact(this.official_contact)
    },
    methods: {
        async defineOfficalContact(data) {
            let form_email = null 
            let form_whats_app = null 
            let form_telegram = null 
            if(data && data.length > 0) {
                data.forEach( (item) => {
                    switch (item.code) {
                        case 'SET-EMAIL':
                            form_email = JSON.parse(JSON.stringify(item))
                            this.form_email.id = item.id 
                            this.form_email.code = item.code
                            this.form_email.name = item.name 
                            this.form_email.value = item.value
                            this.form_email.description = item.description
                            break;
                        case 'WHATS-APP':
                            form_whats_app = JSON.parse(JSON.stringify(item))
                            this.form_whats_app.id = item.id 
                            this.form_whats_app.code = item.code 
                            this.form_whats_app.name = item.name
                            this.form_whats_app.value = item.value
                            this.form_whats_app.description = item.description
                            break;
                        case 'TELE-GRAM':
                            form_telegram = JSON.parse(JSON.stringify(item))
                            this.form_telegram.id  = item.id 
                            this.form_telegram.code = item.code
                            this.form_telegram.name = item.name
                            this.form_telegram.value = item.value
                            this.form_telegram.description = item.description
                            break;
                        default:
                            break;
                    }
                })
            }

            let temp = {
                form_email: form_email,
                form_whats_app: form_whats_app,
                form_telegram: form_telegram
            }

            this.form_temp = JSON.parse(JSON.stringify(temp))

            let permission_edit = await this.$root.checkingButtonsPage(this.alias, 'update')
            if (permission_edit.validate) {
                this.permission_edit = true
            } 
        },
        async validationForm() {
            let is_success = true 
            this.errors.form_email_value = false 
            this.is_valid_email = true 
            if(!this.form_email.value) {
                this.errors.form_email_value = true 
                is_success = false 
            }else {
                let is_valid_email = this.$root.validationInputEmail(this.form_email.value)
                if(!is_valid_email) {
                    this.errors.form_email_value = true 
                    this.is_valid_email = false
                    is_success = false 
                }else {
                    let length = this.form_email.value.trim().length
                    if(length < 7 || length > 60) {
                        this.errors.form_email_value = true 
                        is_success = false
                    }
                }
            }
            return is_success
        },
        async doSaveConfirm() {
            let is_valid = await this.validationForm()
            if(is_valid) {
                this.is_confirm = true 
            }
        },
        async doSave() {
            this.is_confirm_loading = true 
            let params = {
                code: this.form_email.code,
                name: this.form_email.name,
                value: this.form_email.value,
                description: this.form_email.description
            }

            Rest.put('/setting/'+params.code, params)
            .then( async(res) => {
                if(res && res.status == 200) {
                    this.$root.showNotification('Success', 'success', "Official contact modification successful.")
                    this.userLogActivity()
                    this.onRefreshOfficialContact()
                }
            })
            .catch( async(err) => {
                if(err && err.response && err.response.status) {
                    if(err.response.data && err.response.data.message) {
                        this.$root.showNotification('Failed', 'error', err.response.data.message)        
                    }else {
                           let message = "Official contact modification failed."
                            this.$root.showNotification('Failed', 'error', message)
                    }
                }else {
                    this.$root.showNotification('Failed', 'error', 'Sorry, you do not have an internet connection.')
                }
            })
            .finally( () => {
                this.is_confirm = false 
                this.is_confirm_loading = false 
            })
        },
        onRefreshOfficialContact() {
            let data = []
            data.push(this.form_email)
            data.push(this.form_whats_app)
            data.push(this.form_telegram)

            this.form_temp = JSON.parse(JSON.stringify(data))
            this.$emit('onRefreshOfficialContact', data)
        },
        async userLogActivity() {

            if(this.$root.$store.state.user && this.$root.$store.state.user.full_name) {
                let route_name = {
                    name: 'setting.general-setting',
                    query: {
                        is_edit: false,
                        tabs: 'official-contact'
                    }
                } 

                let secretKey = process.env.VUE_APP_KEY_ENCRYPT_DECRYPT
                let route_name_encrypt = this.EncryptDecryptAesCbc256.encryptAesCbc256(secretKey, JSON.stringify(route_name))

                let activity_type = 'edit'
                let activity = `${this.$root.$store.state.user.full_name} has modified the <a style="cursor: pointer" class="click-detail-log" data-route="${route_name_encrypt}"> general setting official contact </a>`

                let params = {
                    activity: activity,
                    feature: 'General Setting Official Contact',
                    activity_type: activity_type,
                    route_name: route_name
                }

                this.$root.sendUserLogActivity(params)
            }
            
        },   
    },
    computed: {
        text_error_email() {
            let txt = ''
            if(!this.is_valid_email) {
                txt = 'Email format is not valid.'
            }else {
                if(this.errors.form_email_value) {
                    let length = this.form_email.value.trim().length
                    if(length == 0) {
                        txt = 'Email is required.'
                    }else {
                        if(length < 7 || length > 60) {
                            txt = 'Email must be at least seven characters, maximum sixty characters.'
                        }
                    }
                }
            }

            return txt 
        }
    },
    watch: {
        official_contact: {
            handler(val) {
                this.defineOfficalContact(val)
            }
        },
        unix_time: {
            handler() {
                this.defineOfficalContact(this.official_contact)
            }
        },
        'form_email.value': {
            handler(val) {
                this.errors.form_email_value = false 
                this.is_valid_email = true 
                if(!val) {
                    this.errors.form_email_value = true 
                }else {
                    let is_valid_email = this.$root.validationInputEmail(val)
                    if(!is_valid_email) {
                        this.errors.form_email_value = true
                        this.is_valid_email = false 
                    }else {
                        let length = val.trim().length
                        if(length < 7 || length > 60) {
                            this.errors.form_email_value = true 
                        }
                    }
                }
            }
        }
    }
}
</script>

<style scoped>

</style>