<template>
  <box :back="back" v-if="back"
    >

    <div class="col-md-6">
        <div class="form-group">
        </div>
      <VueSignaturePad
        width="500px"
        height="500px"
        ref="signaturePad"
        :customStyle="customStyle"
        :options="{ onBegin, onEnd }"
      />
      <p align="center" style="width: 100%; margin-top: 5px;">Silahkan buat tanda tangan digital didalam kotak,
        atau gunakan tombol unggah untuk menggugah tanda tangan yang sudah ada !</p>
    </div>
    <template v-slot:footer-actions>
      <div class="btn-group">
        <button class="btn btn-primary" @click="save" v-if="!isEmpty">Simpan</button>
        <button class="btn btn-info" @click="upload">Unggah</button>
        <button class="btn btn-warning" @click="clear" v-if="!isEmpty">Bersihkan</button>
      </div>
    </template>

    <div class="modal" id="upload-signature" aria-hidden="true" data-backdrop="static" data-keyboard="false" style="z-index: 1051 !important;">
      <div class="modal-dialog" style="width:50%;">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Unggah Tanda Tangan</h4>
          </div>
          <div class="modal-body">
            <div class="col-md-12" style="text-align: -webkit-center;">
              <cropper
              ref="cropper"
              class="cropper"
              :src="img"
              :stencil-props="{ aspectRatio: 12/12}"
              @change="change"
              />
            </div>
          </div>
          <div class="modal-footer">
             <button type="button" @click="$refs.file.click()" class="btn btn-success pull-left"><span class="fa fa-file-image-o"/>
              <input
                type="file"
                ref="file"
                @change="changeAttachment($event)"
                accept="image/*"
                style="display:none"
              />
              Masukkan File
             </button>
              <button type="button" class="btn btn-primary pull-left" @click="uploadFile" :disabled="!imageUrl"><span class="fa fa-upload"/> Unggah Lampiran</button>
              <button type="button" class="btn btn-default" @click="closeModalUpload">Tutup</button>
          </div>
        </div>
      </div>
    </div>

  </box>
</template>

<script>
import axios from 'axios'
import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
// const {console} = window
export default {
  components: {
		Cropper,
	},
    data() {
      return {
        user: {
          id: null,
          name: null,
          username: null ,
          detail: {
            profile_data: {
              address: null,
              date_of_birth: null,
              email: null,
              employee_type: null,
              id_card: null,
              phone_number: null,
              salary: null,
              signature: ""
            },
            project_id: [],
            navigation: null
          }

        },
        back: null,
        customStyle: {
          border: 'black 1px solid',
          'border-radius': '5px'
        },
        isEmpty: true,
        loading: false,
        fileUrl: process.env.VUE_APP_UPLOAD_URL,
        signatureUrl: '#',

        attachmentFile: [],
        hasUploadAttachment: false,
        me: {},
        role: {},
        project: this.$store.state.project || JSON.parse(localStorage.getItem('_PRJ_SELECT')),
        image: '',
        img:'',
        imageUrl: false,
        fileImages: '',
        filename : '',
 
      }
    },
    async created() {
      let temp = localStorage.getItem('_USER_ROLES')
        if(temp) {
          let bytes = this.CryptoJS.AES.decrypt(temp, process.env.VUE_APP_KEY_ENCRYPT_DECRYPT)
          let data = JSON.parse(bytes.toString(this.CryptoJS.enc.Utf8))
          if(data ) {
            this.role.id = data.role_id
            this.role.name = data.role_name
          }
        }
    },
    async mounted() {
      await this.init()
      let self = this
      $(document).ready(function() {
        $(document).on('click', '#upload-signature button[data-dismiss="modal"]', function() {
          self.closeModalUpload()
        })
        $(document).on('click', '#upload-signature button[class="close"]', function() {
          self.closeModalUpload()
        })
      })
    },
    methods: {
      change({canvas}) {
        let data = canvas.toDataURL()
        let times = new Date().getTime()
        let filename = 'signature-'+times+'.png'
        this.fileImages = this.dataURLtoFile(data, filename)
      },
      async saveLogActivities(type,detail) {
          let message = null
          let route_name = {
            name: this.$root._route.name
          }
          type = type.toLowerCase()
          switch (type) {
            case 'update':
              message = this.me.name+" telah mengubah tanda tangan digital"
              break;
            default:
              break;
        }
        let possible_type = ['update']
          if(possible_type.indexOf(type) >= 0) {
            let log_activities = {
              description: message,
              menu: 'Change Signature',
              user: this.me,
              detail: detail,
              route_name: route_name,
              type_activities: type ,
              project: this.project,
              date: moment().format('YYYY-MM-DD HH:mm:ss'),
              role: this.role
            }

            Rest.post('/user/log-activities', log_activities)
          }
      },
      async init() {
        Rest.get('/user/me/detail')
        .then( async(res) => {
          if(res.data) {
            if(res.data.detail) {
              if(res.data.detail.profile_data) {
                if(res.data.detail.profile_data.date_of_birth) {
                  try {
                    res.data.detail.profile_data.date_of_birth = moment(res.data.detail.profile_data.date_of_birth, 'YYYY-MM-DD').format('DD-MM-YYYY')
                    this.dateConfig = {
                      locale: {
                        format: 'DD-MM-YYYY'
                      }
                    }
                  } catch {}
                }

                if(!res.data.detail.profile_data.employee_type) {
                  res.data.detail.profile_data.employee_type = ''
                }
                if(!res.data.detail.profile_data.salary) {
                  res.data.detail.profile_data.salary = 0
                }

                if(res.data.detail.profile_data.signature) {
                  this.signatureUrl = this.fileUrl+res.data.detail.profile_data.signature
                }

              }
            }
          }
          this.me = {
              id: res.data.id,
              name: res.data.name,
              username: res.data.username,
              detail: res.data.detail
            }
          this.user = res.data
          this.back = {
            name: 'profile',
            params: {
              id: this.user.id
            }
          }
        })
        .catch( (err) => {

        })
      },
      changeCanvas() {
        this.isEmpty = this.$refs.signaturePad.isEmpty()
      },
      onBegin() {
        this.changeCanvas()
      },
      onEnd() {
        this.changeCanvas()
      },
      undo() {
        this.$refs.signaturePad.undoSignature();
        this.changeCanvas()
      },
      async save() {
        this.changeCanvas()
        const { isEmpty, data } = this.$refs.signaturePad.saveSignature()

        if(data){
          let times = new Date().getTime()
          let filename = 'signature-'+times+'.png'
          let files = this.dataURLtoFile(data, filename)

          let params = new FormData()
          params.append('file', files)
          params.append('type', 'signature/user')
          Rest.post('/md/file-upload', params)
          .then( async(res) => {
            if(res.status == 200) {
              if(res.data) {
                if(res.data.path) {
                  this.user.detail.profile_data.signature = res.data.path
                  this.signatureUrl = this.fileUrl+res.data.path
                  await this.saveSignature()
                  this.saveLogActivities('update', this.user.detail)
                }
              }
              this.$root.showNotification('Berhasil', 'success', 'Sukses simpan tanda tangan')
            }
          })
          .catch( (err) => {
          })
          .finally( () => {

          })
        }else{
          this.$root.showNotification('Gagal', 'error', 'Anda belum tanda tangan')
        }
      },
      dataURLtoFile(dataurl, filename) {
        let arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, {type:mime});
      },
      clear() {
        this.$refs.signaturePad.clearSignature()
        this.changeCanvas()
      },
      async saveSignature() {
        Rest.put('/user/me/change-profile', this.user)
        .then( async(res) => {
          if(res.status == 200) {
            if(res.data.code == 200 ){
              this.$root.$router.push({
                name: 'profile'
              })
            }
          }
        })
      },
      upload() {
        $('#upload-signature').modal({
          backdrop: 'static',
          keyboard: false
        })
      },
      changeAttachment(e) {
        var reader, files = e.target.files
        this.filename = files[0].name
        let type = files[0].type
        let imageType = ['image/png', 'image/jpg', 'image/jpeg']
        if(files[0].size > 2000000){
            this.closeUploadFilePreview()
            this.$root.showNotification('Gagal', 'error', 'Maksimal file 5MB')
        }else
        if(imageType.indexOf(type) < 0){
            this.closeUploadFilePreview()
            this.$root.showNotification('Gagal', 'error', 'Format file harus .png, .jpg atau .jpeg')
        } else {
          reader = new FileReader()
          reader.onload = (e) => {
            this.image = e.target.result
            this.img = e.target.result
            this.imageUrl = true
          }
          reader.readAsDataURL(files[0])
        }
      },
      closeUploadFilePreview() {
        this.hasUploadAttachment = false
        this.attachmentFile = []
        $('button[class="filepond--file-action-button filepond--action-remove-item"]').click()
      },
      async uploadFile() {
        await this.uploadImage({
          file: this.fileImages,
          type: 'signature/user'
        })
      },
      async uploadImage(params) {
        var data = new FormData()
        data.append('file', params.file)
        data.append('type', params.type)
        axios({
          url: Rest.base+'/md/file-upload',
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data'
          },
          data: data
          
        })
        .then( async(res) => {
          if((res && res.data && res.data.success) || (res && res.status == 200)) {
            this.user.detail.profile_data.signature = res.data.path
            await this.saveSignature()
            this.$root.showNotification('Berhasil', 'success', 'Sukses unggah tanda tangan')
            this.closeModalUpload()
          }
        })
        .catch( (err) => {
          this.$root.showNotification('Gagal', 'errror', err.response.data.message)
        })
        .finally ( () => {

        })
      },
      closeModalUpload() {
        $('#upload-signature').modal('hide')
        this.hasUploadAttachment = false
        this.attachmentFile = []
        if (this.image) {
         this.image = ''
        }
        this.filename = ''
        this.fileImages = ''
        this.imageUrl = false
      }
    }
  }
</script>
<style scoped>
.cropper {
	/* height: 600px;
	width: 600px; */
	background: #DDD;
  margin-bottom: 10px;
  /* margin-top: 20px; */
}
</style>
