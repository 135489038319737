<template>
    <svg width="14" height="18" viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.82807 2.2403C4.015 0.0533638 7.56073 0.0533634 9.74766 2.2403C11.9346 4.42724 11.9346 7.97296 9.74766 10.1599L5.78787 14.1197L1.82807 10.1599C-0.358868 7.97296 -0.358868 4.42724 1.82807 2.2403ZM5.78787 7.8001C6.67152 7.8001 7.38787 7.08375 7.38787 6.2001C7.38787 5.31644 6.67152 4.6001 5.78787 4.6001C4.90421 4.6001 4.18787 5.31644 4.18787 6.2001C4.18787 7.08375 4.90421 7.8001 5.78787 7.8001Z" fill="#757575"/>
    </svg>
</template>

<script>
export default {
    props: {
        width: {
            type: [String, Number],
            default: '24'
        },
        height: {
            type: [String, Number],
            default: '24'
        },
        fill: {
            type: String,
            default: '#18A891'
        },
    },
    data() {
        return {
            viewBox: '0 0 24 24'
        }
    },
    mounted() {
        this.viewBox = '0 0 '+this.width+ ' '+this.height
    },
    watch: {
        'width': {
            handler(val) {
                if(val) {
                    this.viewBox = '0 0 '+val+ ' '+this.height
                }
            }
        },
        'height': {
            handler(val) {
                if(val) {
                    this.viewBox = '0 0 '+this.width+' '+val
                }
            }
        }
    }
}
</script>

<style scoped>

</style>