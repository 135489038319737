import LoadingButton from './LoadingButton'

const SaveButton = {
    name: 'save-button',
    props: ['loading', 'saveText', 'loadingText', 'click', 'click.prevent', 'classBtn'],
    components: {
        LoadingButton
    },
    template: '<loading-button :loading="loading" :saveText="saveText || \'Save\'" :loadingText="loadingText" class="show-action" :classBtn="classBtn" @click="onClick"/>',
    methods: {
        onClick(e) {
            this.$emit('click', e)
        }
    }
}
export default SaveButton