import Vue from 'vue'
const { console } = window

const CurrencyInputValidate = {
    name: 'CurrencyInputValidateItem',
    props: ['formId', 'formName', 'value', 'validation', 'formPlaceholderName', 'formLabel', 'formType', 'fromIcon', 'formDisabled' ],
    template:
        `<div :class="validation ? 'form-group has-error' : 'form-group'">
            <label v-if="formLabel">{{formLabel}}</label>
            <currency-input
                v-bind:id="formId"
                v-bind:name="formName"
                v-bind:type="formType"
                class="form-control"
                v-bind:value="value"
                v-on:input="$emit('input', $event.target.value)"
                v-bind:placeholder="formPlaceholderName"
                v-bind:disabled="formDisabled"
            />
            <div class="help-block">{{ validation }}</div>
        </div>`,
    data() {
        return {
        }
    },
    mounted(){
    },
    methods: {
    }
}
Vue.component('CurrencyInputValidateItem', CurrencyInputValidate)
export default CurrencyInputValidate
