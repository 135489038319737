<template>
    <div>
        <box header="false"
            :cancel="false"
            :save="false">
            <div>
                <div class="row">
                    <div class="col-md-9">
                        <div class="form-group">
                            <label class="control-label">Check-In Timeout</label>
                            <p>Set up when the check-in time is expired.</p>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="pull-right">
                            <select type="text" class="form-control select2 select-check-in-timeout"
                                id="select-check-in-timeout"
                                v-model="select_check_in_timeout"
                                :disabled="disabledForm || !permission_edit" 
                                style="width: 200px;">
                                <option v-for="(data, i) in check_in_timeout_option" :key="i" :value="data.name">{{ data.name }}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </box>

        <modal :title="'Change Confirmation'" save="true" remove="false"
            :show="is_confirm"
            saveText="Yes, Confirmation"
            @hidden="is_confirm = false"
            @onSave="doSave()"
            :loading="is_confirm_loading">
            <span class="span-title">Are you sure you want to modify check in timeout <b>{{ select_check_in_timeout_temp }}</b> ?</span>
        </modal>
    </div>
</template>

<script>
const {console} = window
export default {
    components: {

    },
    props: {
        check_in_timeout: {
            type: Object,
        },
        unix_time: {
            type: Number,
        }
    },
    data() {
        return {
            alias: 'general-setting',
            permission_edit: false,
            disabledForm: false,
            form: {
                id: null,
                code: 'CHECK-IN',
                name: null,
                value: null,
                description: null
            },
            is_confirm: false,
            is_confirm_loading: false,
            select_check_in_timeout: null,
            select_check_in_timeout_temp: null,
            select_check_in_timeout_default: null,
            check_in_timeout_option: [
                {name: '1 days', value: '1', description: 'days'},
                {name: '1 week', value: '1', description: 'week'},
                {name: '1 month', value: '1', description: 'month'}
            ]
        }
    },
    async mounted() {
        await this.defineFormCheckInTimeout(this.check_in_timeout)
        

        let self = this
        $(document).ready(function(e) {
            $('.select-check-in-timeout').on('select2:select', (e) => {
                e.preventDefault();
                let data = e.params.data
                self.select_check_in_timeout_temp = data.id 
                self.is_confirm = true

                self.select_check_in_timeout = self.select_check_in_timeout_default
                $('.select-check-in-timeout').val([self.select_check_in_timeout_default]).trigger('change')
            })
        })

        $('.select2').select2()
        $('.select-check-in-timeout').select2()

    },
    methods: {
        async defineFormCheckInTimeout(check_in_timeout) {
            this.form.name = check_in_timeout.name 
            this.form.value = check_in_timeout.value 
            this.form.id = check_in_timeout.id 
            this.form.description = check_in_timeout.description

            if(check_in_timeout.value && check_in_timeout.description) {
                this.check_in_timeout_option.forEach( (item) => {
                    if(item.name && item.value && item.description) {
                        if(item.value == check_in_timeout.value && item.description == check_in_timeout.description) {
                            this.select_check_in_timeout = item.name
                            this.select_check_in_timeout_default = item.name
                            $('#select-check-in-timeout').val([this.select_check_in_timeout]).trigger('change')
                        }
                    }
                })
            }

            let permission_edit = await this.$root.checkingButtonsPage(this.alias, 'update')
            if (permission_edit.validate) {
                this.permission_edit = true
            } 
        },
        async selectCheckInTimeout(name) {
            this.select_check_in_timeout = name 
            this.check_in_timeout_option.forEach((item) => {
                if(item.value && item.name && item.description) {
                    if(item.name == name) {
                        this.form.value = item.value
                        this.form.description = item.description
                    }
                } 
            })
        },
        async doSave() {
            this.is_confirm_loading = true 
            let name = this.select_check_in_timeout_temp
            await this.selectCheckInTimeout(name)

            let params = {
                code: this.form.code,
                name: this.form.name,
                value: this.form.value,
                description: this.form.description
            }

            Rest.put('/setting/'+params.code, params)
            .then( async(res) => {
                if(res && res.status == 200) {
                    this.$root.showNotification('Success', 'success',"Check in timeout modification successful.")
                    $('#select-check-in-timeout').val([this.select_check_in_timeout]).trigger('change')
                    this.userLogActivity()
                    this.onRefreshCheckInTimeout()
                }
            })
            .catch( async(err) => {
                if(err && err.response && err.response.status) {
                    if(err.response.data && err.response.data.message) {
                        this.$root.showNotification('Failed', 'error', err.response.data.message)        
                    }else {
                        message = "Check in timeout modification failed."
                        this.$root.showNotification('Failed', 'error', message)
                    }
                }else {
                    this.$root.showNotification('Failed', 'error', 'Sorry, you do not have an internet connection.')
                }
            })
            .finally( () => {
                this.is_confirm = false 
                this.is_confirm_loading = false
            })
        },
        onRefreshCheckInTimeout() {
            let data = this.form
            this.$emit('onRefreshCheckInTimeout', data)
        },
        async userLogActivity() {
            if(this.$root.$store.state.user && this.$root.$store.state.user.full_name) {
                let route_name = {
                    name: 'setting.general-setting',
                    query: {
                        is_edit: false,
                        tabs: 'check-in-timeout'
                    }
                } 

                let secretKey = process.env.VUE_APP_KEY_ENCRYPT_DECRYPT
                let route_name_encrypt = this.EncryptDecryptAesCbc256.encryptAesCbc256(secretKey, JSON.stringify(route_name))

                let activity_type = 'edit'
                let activity = `${this.$root.$store.state.user.full_name} has modified the <a style="cursor: pointer" class="click-detail-log" data-route="${route_name_encrypt}"> general setting check in timeout </a>`
               

                let params = {
                    activity: activity,
                    feature: 'General Setting Check in Timeout',
                    activity_type: activity_type,
                    route_name: route_name
                }

                this.$root.sendUserLogActivity(params)
            }
        },   
    },
    computed: {

    },
    watch: {
        unix_time: {
            handler(val) {
                this.defineFormCheckInTimeout(this.check_in_timeout)
            }
        },
        check_in_timeout: {
            handler(val) {
                this.defineFormCheckInTimeout(val)
            }
        }
    }
}
</script>

<style scoped>

</style>