<template>
    <div>
        <header-title-button
            title="Payment Method">
        </header-title-button>

        <datatable 
            :config="config" 
            class="superuser-datatable" 
            :settingFilters="settingFilters"
            :defaultFilter="defaultFilter" />

        <modal
            title="Confirm Change Status Payment Method"
            id="modal-confirm-change-status-payment-method"
            :save="true"
            :remove="false"
            :show="is_confirm_change_status"
            :loading="is_confirm_change_status_loading"
            saveText="Save"
            @hidden="is_confirm_change_status = false"
            @onSave="doChangeStatus">
            <span class="span-title">Are you sure you want to change status <b>{{ form && form.status_name_replace ? form.status_name_replace : ``}}</b>  the payment method named <b>{{form && form.name ? form.name : ``}} </b> ?</span>
        </modal>

    </div>
</template>

<script>
const {console} = window
export default {
    data() {
        return {
            config: {},
            alias: 'payment-method',
            is_created_permission: false,
            is_deleted_permission: false,
            is_updated_permission: false,
            settingFilters: '',
            defaultFilter: null,
            filter_status: '',
            filter_status_temp: '',
            form: {
                id: null,
                name: null 
            },
            status: [
                {"is_active": '', name: 'All Status'},
                {"is_active": 'true', name: 'Active'},
                {"is_active": 'false', name: "Not Active"}
            ],
            form: null,
            is_confirm_change_status: false,
            is_confirm_change_status_loading: false,
        }
    },
    async mounted() {
        this.$root.checkingAccessPage(this.$root._route.name)
        let permission_create = await this.$root.checkingButtonsPage(this.alias, 'create')
        let permission_delete = await this.$root.checkingButtonsPage(this.alias, 'delete')
        let permission_update = await this.$root.checkingButtonsPage(this.alias, 'update')
        if(permission_create.validate) {
            this.is_created_permission = true
        }

        if(permission_delete.validate) {
            this.is_deleted_permission = true 
        }

        if(permission_update.validate) {
            this.is_updated_permission = true
        }

        this.setupSettingFilter()

        let self = this
        $(document).ready(function() {
            $(document).on('change', '.select-filter-status', function(e) {
                let val = $('.select-filter-status').val()
                self.filter_status = val
                self.filter_status_temp = val 
            })

            $('.select-filter-status').on('select2:select', (e) => {
                e.preventDefault();
                let data = e.params.data
                self.filter_status = data.id
                self.filter_status_temp = data.id
            })

            $(document).on('click', '.click-change-status', function(e) {
                e.preventDefault()
                self.form = null
                let data = $(this).attr('data')

                let secretKey = process.env.VUE_APP_KEY_ENCRYPT_DECRYPT
                let decrypt = self.EncryptDecryptAesCbc256.decryptAesCbc256(secretKey, data)
                if(decrypt) {
                    let res = JSON.parse(decrypt)
                    self.form = res
                    self.is_confirm_change_status = true
                }
                
            })
        })

        setTimeout(self.selectDropdown, 1500);

    },
    methods: {
        async init() {
            let self = this 
            let defaultFilter = null 

            if(this.filter_status != '') {
                let status = true 
                switch (this.filter_status) {
                    case 'true':
                        status = true 
                        break;
                    case 'false':
                        status = false 
                        break;
                    default:
                        status = this.filter_status
                        break;
                }
                if(defaultFilter) {
                    defaultFilter.push(["AND"])
                    defaultFilter.push(["is_active", "=", status])
                }else {
                    defaultFilter = [["is_active", "=", status]]
                }
            }

            this.defaultFilter = defaultFilter

            this.config = {
                columns: [{
                    title: 'Name',
                    data: 'name'
                }, {
                    title: 'Description',
                    data: 'description'
                },  {
                    title: 'Status',
                    data: 'status_name',
                    render(value, type, row) {
                        if(self.is_updated_permission) {
                            return self.renderStatus(row)
                        }

                        return row.status_name
                    }
                }],
                responsive: false,
                scrollX: true,
                searchPlaceholder: 'Search a payment method ...',
                url: Api.base+ '/payment-method'
            }
        },
        renderStatus(row) { 
            let toogle_active = `
            <svg style="margin-bottom: -2px" width="28" height="16" viewBox="0 0 28 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="28" height="16" rx="8" fill="#6147FF"/>
                <circle cx="20" cy="8" r="6" fill="white"/>
            </svg>
            `

            let toogle_inactive = `
            <svg style="margin-bottom: -2px" width="28" height="16" viewBox="0 0 28 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="28" height="16" rx="8" fill="#9E9E9E"/>
                <circle cx="8" cy="8" r="6" fill="white"/>
            </svg>
            `

            let is_active = row.is_active ? row.is_active : false
            let status_name_replace = row.is_active ? `not active` : `active`

            let data = row 
            data.status_name_replace = status_name_replace
            data.is_active = is_active

            let data_str = JSON.stringify(data)
            let secretKey = process.env.VUE_APP_KEY_ENCRYPT_DECRYPT
            let encrypt = this.EncryptDecryptAesCbc256.encryptAesCbc256(secretKey, data_str)

            let button = `<div>`
            button += `<span class="click-change-status" data="${encrypt}">`
            if(is_active) {
                button += `<span>`+toogle_active+`</span>`
            }else {
                button += `<span>`+toogle_inactive+`</span>`
            }
            button += ` <span>`+row.status_name+'</span>'
            button += `</span>`
            button += `</div>`
            
            return button


        },
        async setupSettingFilter() {
            let optionStatus = ``
            this.status.forEach( (item) => {
                if(item.name) {
                    if(this.filter_status == item.is_active) {
                        optionStatus  += `<option selected value="${item.is_active}">${item.name}</option>`
                    }else {
                        optionStatus += `<option value="${item.is_active}"">${item.name}</option>`
                    }
                }
            })

            this.settingFilters = `<div>
                <span> Filter : &nbsp;  </span>
                    <select type="text" class="form-control select2 select-filter-status">
                    ${optionStatus}
                    </select>
                </div>`

            this.init()

            setTimeout(self.selectDropdown, 1000);
        },
        isDoubleClicked(element) {
            //if already clicked return TRUE to indicate this click is not allowed
            if (element.data("isclicked")) return true;

            //mark as clicked for 1 second
            element.data("isclicked", true);
            setTimeout(function () {
                element.removeData("isclicked");
            }, 1000);

            //return FALSE to indicate this click was allowed
            return false;
        },
        selectDropdown() {
            $('.select2').select2()

            $('.select-filter-status').select2()
            let status =  $('.select-filter-status').val()

            if(this.filter_status != status && this.filter_status != '') {
                $('.select-filter-status').val([this.filter_status]).trigger('change')
            }

        },
        tiggerUpdateDropDown() {
            try{
                let data = $(".select-filter-status").select2('data')
                if(data) {
                    if(this.filter_status != this.filter_status_temp) {
                        $('.select-filter-status').val([this.filter_status]).trigger('change')
                        this.filter_status_temp = this.filter_status
                    }
                }
            }catch{
                $('.select-filter-status').select2()
                console.clear()
            }
        },
        async userLogActivity() {
            if(this.$root.$store.state.user && this.$root.$store.state.user.full_name && this.form && this.form.name) {
                let activity_type = 'edit'
                let activity = `${this.$root.$store.state.user.full_name} has been modified status payment method with the name ${this.form.name}`
                let params = {
                    activity: activity,
                    feature: 'Payment Method',
                    activity_type: activity_type,
                }

                this.$root.sendUserLogActivity(params)
            }
        },
        async doChangeStatus() {
            if(this.form && this.form.id) {
                this.is_confirm_change_status_loading = true
                let params = this.form
                params.is_active = !this.form.is_active
                Rest.put('/payment-method/'+this.form.id, params)
                .then( async(res) => {
                    let message_success = 'Payment method change status successful.'
                    this.$root.showNotification('Success', 'success', message_success)
                    this.userLogActivity()
                    this.setupSettingFilter()
                    this.form = null
                })
                .catch( async(err) => {
                    if(err && err.response && err.response.status) {
                        if(err.response.data && err.response.data.message) {
                            this.$root.showNotification('Failed', 'error', err.response.data.message)        
                        }else {
                            var message = "Payment method change status failed"
                            this.$root.showNotification('Failed', 'error', message)
                        }
                    }else {
                        this.$root.showNotification('Failed', 'error', 'Sorry, you do not have an internet connection.')
                    }
                })
                .finally( () => {
                    this.is_confirm_change_status = false 
                    this.is_confirm_change_status_loading = false 
                })
            }
        }
    },
    computed: {

    },
    watch: {
        filter_status: {
            handler() {
                this.setupSettingFilter()
                setInterval(this.tiggerUpdateDropDown, 200)
            }
        },
    }
}
</script>

<style scoped>

</style>