import Vue from 'vue'
import { VueEditor } from "vue2-editor"

const TextEditorValidate = {
    components: {
        VueEditor
    },
    name: 'TextEditorValidateItem',
    props: ['formId', 'formName', 'value', 'validation', 'formPlaceholderName', 'formLabel', 'formDisabled'],
    template:
        `<div :class="validation ? 'form-group has-error' : 'form-group'">
            <label>{{formLabel}}</label>
            <vue-editor
                v-bind:id="formId"
                v-bind:name="formName"
                v-bind:value="value"
                v-on:input="$emit('input', $event.target.value)"
                v-bind:placeholder="formPlaceholderName"
                v-bind:disabled="formDisabled"
                rows="8"
            />
            <div class="help-block">{{ validation }}</div>
        </div>`,
    data() {
        return {
        }
    },
    mounted(){
    },
    methods: {
    }
}
Vue.component('TextEditorValidateItem', TextEditorValidate)
export default TextEditorValidate
