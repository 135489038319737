<template>
    <div>
        <router-view></router-view>
    </div>
</template>
<script>
 const {console} = window
export default {
    mounted() {
        this.$root.state = [
            {
                font: 'fa fa-database',
                text: 'Master File',
                name: 'masterfile'
            }
        ]
    }
 }

</script>