<template>
    <div>
        <box header="false"
            :cancel="is_modify"
            @onCancel="doCancelConfirm"
            :save="is_modify"
            @onSave="doSaveConfirm">
            <div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="control-label">Session Timeout</label>
                            <p>Set up when the kiosk to automatically enter idle mode when it's out of session.</p>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="form-group">
                            <label>&nbsp;</label>
                            <button-switch
                                title="Custom"
                                :value="is_custom_session"
                                :disabled="disabledForm || !permission_edit"
                                :is_text_name="false"
                                @onClickSwitchButton="changeBtnSwitchSessionTimeout"></button-switch>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="form-group">
                            <label>&nbsp;</label>
                            <input-number-decimal 
                                v-model="form_session.value" 
                                id="session-timeout" 
                                min="1" 
                                max="60"
                                step="1" 
                                :disabled="disabledForm || !permission_edit"
                                @onChange="changeSessionTimeoutUnit"> 
                            </input-number-decimal>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="form-group">
                            <label>&nbsp;</label>
                            <select class="form-control select2 select-session"
                                id="select-session"
                                v-model="form_session.description"
                                :disabled="!is_custom_session"
                                style="width: 150px;" >
                                <option v-for="(data, i) in expired_unit" :key="i" :value="data.value">{{ data.name }}</option>
                            </select>
                        </div>
                    </div>
                </div>

                <hr>

                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="control-label">Redirect to Idle Screen</label>
                            <p>Redirect screen to idle when usage in active screen is no longer detected.</p>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="form-group">
                            <label>&nbsp;</label>
                            <button-switch
                                title="Custom"
                                :value="is_custom_idle"
                                :disabled="disabledForm || !permission_edit"
                                :is_text_name="false"
                                @onClickSwitchButton="changeBtnSwitchIdleTimeout"></button-switch>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="form-group">
                            <label>&nbsp;</label>
                            <input-number-decimal 
                                v-model="form_idle.value" 
                                id="idle-timeout" 
                                min="1" 
                                max="60"
                                step="1" 
                                :disabled="disabledForm || !permission_edit"
                                @onChange="changeIdleTimeoutUnit"> 
                            </input-number-decimal>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="form-group">
                            <label>&nbsp;</label>
                            <select class="form-control select2 select-idle"
                                id="select-idle"
                                v-model="form_idle.description"
                                :disabled="!is_custom_idle"
                                style="width: 150px;" >
                                <option v-for="(data, i) in expired_unit" :key="i" :value="data.value">{{ data.name }}</option>
                            </select>
                        </div>
                    </div>
                </div>

                <hr>

                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="control-label">Payment Timeout (Cashless)</label>
                            <p>Define the inactivity period after which a payment session is considered expired.</p>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="form-group">
                            <label>&nbsp;</label>
                            <button-switch
                                title="Custom"
                                :value="is_custom_expired_payment"
                                :disabled="disabledForm || !permission_edit"
                                :is_text_name="false"
                                @onClickSwitchButton="changeBtnSwitchCustomExpPayment"></button-switch>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="form-group">
                            <label class="control-label">&nbsp;</label>
                            <input-number-decimal 
                                v-model="form_expired_payment.value" 
                                id="expired-payment-cashless" 
                                min="1" 
                                max="60"
                                step="1" 
                                :disabled="disabledForm || !permission_edit"
                                @onChange="changeExpiredPayment"> 
                            </input-number-decimal>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="form-group">
                            <label>&nbsp;</label>
                            <select class="form-control select2 select-expired-payment"
                                id="select-expired-payment"
                                v-model="form_expired_payment.description"
                                :disabled="!is_custom_expired_payment"
                                style="width: 150px;" >
                                <option v-for="(data, i) in expired_unit" :key="i" :value="data.value">{{ data.name }}</option>
                            </select>
                        </div>
                    </div>
                </div>

                <hr>

                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="control-label">Payment Timeout (Cash)</label>
                            <p>Define the inactivity period after which a payment session is considered expired.</p>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="form-group">
                            <label>&nbsp;</label>
                            <button-switch
                                title="Custom"
                                :value="is_custom_expired_cash_payment"
                                :disabled="disabledForm || !permission_edit"
                                :is_text_name="false"
                                @onClickSwitchButton="changeBtnSwitchCustomExpCashPayment"></button-switch>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="form-group">
                            <label>&nbsp;</label>
                            <input-number-decimal 
                                v-model="form_expired_cash_payment.value" 
                                id="expired-cash-payment" 
                                min="1" 
                                max="60"
                                step="1" 
                                :disabled="disabledForm || !permission_edit"
                                @onChange="changeExpiredCashPayment"> 
                            </input-number-decimal>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="form-group">
                            <label>&nbsp;</label>
                            <select class="form-control select2 select-expired-cash-payment"
                                id="select-expired-cash-payment"
                                v-model="form_expired_cash_payment.description"
                                :disabled="!is_custom_expired_cash_payment"
                                style="width: 150px;" >
                                <option v-for="(data, i) in expired_unit" :key="i" :value="data.value">{{ data.name }}</option>
                            </select>
                        </div>
                    </div>
                </div>

            </div>
        </box>

        <modal :title="'Change Confirmation'" save="true" remove="false"
            :show="is_confirm"
            saveText="Yes, Confirmation"
            @hidden="is_confirm = false"
            @onSave="doSave()"
            :loading="is_confirm_loading">
            <div>
                <p><b>Change Summary : </b></p>
                <table class="table table-bordered table-striped">
                    <thead>
                        <tr>
                            <th>Session</th>
                            <th>Old Data</th>
                            <th>New Data</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Session Timeout</td>
                            <td>{{ select_session_value }} {{ select_session_description }} (s)</td>
                            <td>{{ form_session.value }} {{ form_session.description }} (s)</td>
                        </tr>
                        <tr>
                            <td>Redirect to Idle Screen</td>
                            <td>{{ select_idle_value }} {{ select_idle_description }} (s)</td>
                            <td>{{ form_idle.value }} {{ form_idle.description }} (s)</td>
                        </tr>
                        <tr>
                            <td>Payment Timeout (Cashless)</td>
                            <td>{{ select_expired_payment_value }} {{ select_expired_payment_description }} (s)</td>
                            <td>{{ form_expired_payment.value }} {{ form_expired_payment.description }} (s)</td>
                        </tr>
                        <tr>
                            <td>Payment Timeout (Cash)</td>
                            <td>{{ select_expired_cash_payment_value }} {{ select_expired_cash_payment_description }} (s)</td>
                            <td>{{ form_expired_cash_payment.value }} {{ form_expired_cash_payment.description }} (s)</td>
                        </tr>
                    </tbody>
                </table>

                <span class="span-title">Are you sure you want to modify kiosk session ? </span>
            </div>
        </modal>

        <modal :title="'Cancel Confirmation'" save="true" remove="false"
            :show="is_confirm_cancel"
            saveText="Yes, Cancel"
            @hidden="is_confirm_cancel = false"
            @onSave="doCancel()"
            :loading="is_confirm_cancel_loading"
            closeText="Close">
            <span class="span-title">Are you sure you want to cancel all changes ?</span>
        </modal>
    </div>
</template>

<script>
import ButtonSwitch from '@app/components/ButtonSwitch.vue'
import InputNumberDecimal from '@pages/Components/Form/InputNumberDecimal.vue'
const {console} = window
export default {
    components: {
        ButtonSwitch,
        InputNumberDecimal
    },
    props: {
        kiosk_session: {
            type: Array,
        }
    },
    data() {
        return {
            alias: 'general-setting',
            permission_edit: false,
            disabledForm: false,
            form_session: {
                id: null,
                code: 'SESS-TIME',
                name: null,
                value: null,
                description: null,
            },
            form_idle: {
                id: null,
                code: 'IDLE-TIME',
                name: null,
                value: null,
                description: null,
            },
            form_expired_payment: {
                id: null,
                code: 'EXP-PYMENT',
                name: null,
                value: null,
                description: null,
            },
            form_expired_cash_payment: {
                id: null,
                code: 'EXP-CASH',
                name: null,
                value: null,
                description: null,
            },
            is_confirm: false,
            is_confirm_loading: false,
            is_close_confirm: false,
            is_confirm_session: false,
            is_confirm_session_loading: false,
            is_confirm_cancel: false,
            is_confirm_cancel_loading: false,
            // is_confirm_idle: false,
            // is_confirm_idle_loading: false ,
            // is_confirm_expired_payment: false,
            // is_confirm_expired_payment_loading: false,
            // is_confirm_expired_cash_payment: false,
            // is_confirm_expired_cash_payment_loading: false,
            // is_close_confirm_expired_payment: false,
            // is_close_confirm_expired_cash_payment: false,
            // is_close_confirm_session: false,
            // is_close_confirm_idle: false,
            expired_unit: [
                {name: 'Minute (s)', value: 'minutes'}, 
                {name: 'Hour (s)', value: 'hours'},
                {name: 'Day (s)', value: 'days'},
                // {name: 'Week (s)', value: 'weeks'},
                // {name: 'Month (s)', value: 'months'}
            ],
            is_custom_session: false,
            is_custom_idle: false,
            is_custom_expired_payment: false,
            is_custom_expired_cash_payment: false,
            select_session_value: null,
            select_session_description: null,
            select_idle_value: null,
            select_idle_description: null,
            select_expired_payment_value: null,
            select_expired_payment_description: null, 
            select_expired_cash_payment_value: null,
            select_expired_cash_payment_description: null, 
        }
    },
    async mounted() {
        this.defineFormSessionFormIdle(this.kiosk_session)
        let self = this
        $(document).ready(function(e) {
            $('.select-session').on('select2:select', (e) => {
                e.preventDefault();
                let data = e.params.data
                self.form_session.description = data.id 
            })
        })

        $(document).ready(function(e) {
            $('.select-idle').on('select2:select', (e) => {
                e.preventDefault();
                let data = e.params.data
                self.form_idle.description = data.id 
            })

            $('.select-expired-payment').on('select2:select', (e) => {
                e.preventDefault();
                let data = e.params.data
                self.form_expired_payment.description = data.id 
            })

            $('.select-expired-cash-payment').on('select2:select', (e) => {
                e.preventDefault();
                let data = e.params.data
                self.form_expired_cash_payment.description = data.id 

            })
        })

        $('.select2').select2()
        $('.select-session').select2()
        $('.select-idle').select2()
        $('.select-expired-payment').select2()
        $('.select-expired-cash-payment').select2()
    },
    methods: {
        async defineFormSessionFormIdle(data) {
            this.is_close_confirm_session = true  
            this.is_close_confirm_idle = true
            this.is_close_confirm_expired_payment = true 
            this.is_close_confirm_expired_cash_payment = true
            if(data && data.length > 0) {
                data.forEach( (item) => {
                    var unit = 'minutes'
                    if( ['minutes', 'minute'].indexOf(item.description) >= 0) {
                        unit = 'minutes'
                    }

                    if( ['hours', 'hour'].indexOf(item.description) >= 0) {
                        unit = 'hours'
                    }

                    if( ['days', 'day'].indexOf(item.description) >= 0) {
                        unit = 'days'
                    }


                    switch (item.code) {
                        case 'SESS-TIME':
                            this.form_session.id = item.id 
                            this.form_session.code = item.code 
                            this.form_session.name = item.name
                            this.form_session.value = item.value
                            this.form_session.description = unit 

                            $('#select-session').val([unit]).trigger('change')

                            this.select_session_value = item.value 
                            this.select_session_description = unit 
                            break;
                        case 'IDLE-TIME':
                            this.form_idle.id = item.id 
                            this.form_idle.code = item.code
                            this.form_idle.name = item.name
                            this.form_idle.value = item.value
                            this.form_idle.description = unit 
                            $('#select-idle').val([unit]).trigger('change')

                            this.select_idle_value = item.value 
                            this.select_idle_description = unit 
                            break;
                        case 'EXP-PYMENT':
                            this.form_expired_payment.id = item.id 
                            this.form_expired_payment.name = item.name 
                            this.form_expired_payment.value = item.value
                            this.form_expired_payment.description = unit 
                            $('#select-expired-payment').val([unit]).trigger('change')

                            this.select_expired_payment_value = item.value
                            this.select_expired_payment_description = unit
                            break;
                        case'EXP-CASH':
                            this.form_expired_cash_payment.id = item.id 
                            this.form_expired_cash_payment.name = item.name 
                            this.form_expired_cash_payment.value = item.value
                            this.form_expired_cash_payment.description = unit 
                            $('.select-expired-cash-payment').val([unit]).trigger('change')

                            this.select_expired_cash_payment_value = item.value
                            this.select_expired_cash_payment_description = unit
                            break;
                        default:
                            break;
                    }
                })
            } 

            let permission_edit = await this.$root.checkingButtonsPage(this.alias, 'update')
            if (permission_edit.validate) {
                this.permission_edit = true
            } 

            this.is_close_confirm_session = false 
            this.is_close_confirm_idle = false
            this.is_close_confirm_expired_payment = false 
            this.is_close_confirm_expired_cash_payment = false 
        },
        changeBtnSwitchSessionTimeout() {
            this.is_custom_session = !this.is_custom_session
        },
        async changeSessionTimeoutUnit() {
        },
        changeBtnSwitchIdleTimeout() {
            this.is_custom_idle = !this.is_custom_idle
        },
        async changeIdleTimeoutUnit() {
        },
        async onRefreshKiosKSession() {
            let data = []
            data.push(this.form_session)
            data.push(this.form_idle)
            data.push(this.form_expired_payment)
            data.push(this.form_expired_cash_payment)
            this.$emit('onRefreshKiosKSession', data)
        },
        async userLogActivity() {
            if(this.$root.$store.state.user && this.$root.$store.state.user.full_name) {
                let route_name = {
                    name: 'setting.general-setting',
                    query: {
                        is_edit: false,
                        tabs: 'kiosk-session'
                    }
                } 

                let secretKey = process.env.VUE_APP_KEY_ENCRYPT_DECRYPT
                let route_name_encrypt = this.EncryptDecryptAesCbc256.encryptAesCbc256(secretKey, JSON.stringify(route_name))

                let activity_type = 'edit'
                let activity = `${this.$root.$store.state.user.full_name} has modified the <a style="cursor: pointer" class="click-detail-log" data-route="${route_name_encrypt}"> general setting kiosk session </a>`
               

                let params = {
                    activity: activity,
                    feature: 'General Setting Kiosk Session',
                    activity_type: activity_type,
                    route_name: route_name
                }

                this.$root.sendUserLogActivity(params)
            }
            
        },   
        changeBtnSwitchCustomExpPayment() {
            this.is_custom_expired_payment = !this.is_custom_expired_payment
        },
        async changeExpiredPayment() {
        },
        changeBtnSwitchCustomExpCashPayment() {
            this.is_custom_expired_cash_payment = !this.is_custom_expired_cash_payment
        },
        async changeExpiredCashPayment() {
        },
        doSaveConfirm() {
            this.is_confirm = true 
            this.is_confirm_loading = false 
        },
        async doSave() {
            this.is_confirm_loading = true 

            let params = []
            params.push(this.form_session)
            params.push(this.form_idle)
            params.push(this.form_expired_payment)
            params.push(this.form_expired_cash_payment)
            params.forEach( (item) => {
                item.value = item.value ? item.value.toString() : ''
            })

            Rest.put('/setting', params)
            .then( async(res) => {
                if(res && res.status == 200) {
                    this.$root.showNotification('Success', 'success',"Kiosk session modification successful.")
                    this.userLogActivity()
                    this.onRefreshKiosKSession()
                }
            })
            .catch( async(err) => {
                if(err && err.response && err.response.status) {
                    if(err.response.data && err.response.data.message) {
                        this.$root.showNotification('Failed', 'error', err.response.data.message)        
                    }else {
                        message = "Kiosk session modification failed."
                        this.$root.showNotification('Failed', 'error', message)
                    }
                }else {
                    this.$root.showNotification('Failed', 'error', 'Sorry, you do not have an internet connection.')
                }

                this.doClose()
            })
            .finally( () => {
                this.is_confirm = false
                this.is_confirm_loading = false 
            })   
        },
        async doClose() {
            this.is_confirm = false 
            this.is_confirm_loading = false 
            this.is_close_confirm = true 

            this.form_session.value = this.select_session_value
            this.form_session.description = this.select_session_description

            this.form_idle.value = this.select_idle_value
            this.form_idle.description = this.select_idle_description

            this.form_expired_payment.value = this.select_expired_payment_value
            this.form_expired_payment.description = this.select_expired_payment_description

            this.form_expired_cash_payment.value = this.select_expired_cash_payment_value
            this.form_expired_cash_payment.description = this.select_expired_cash_payment_description

            $('#select-session').val([this.select_session_description]).trigger('change')
            $('#select-idle').val([this.select_idle_description]).trigger('change')
            $('#select-expired-payment').val([this.select_expired_payment_description]).trigger('change')
            $('#select-expired-cash-payment').val([this.select_expired_cash_payment_description]).trigger('change')
            this.is_close_confirm = false 
        },
        async doCancelConfirm() {
            this.is_confirm_cancel = true 
            this.is_confirm_cancel_loading = false

        },
        async doCancel() {
            this.is_confirm_cancel_loading = true
            this.doClose()
            this.is_confirm_cancel = false 
            this.is_confirm_cancel_loading = false 

            this.$root.showNotification('Success', 'info', 'Cancel all changes successfull.')

        }
    },
    computed: {
        is_modify() {
            let is_change = false 
            if(this.form_session.value != this.select_session_value) {
                is_change = true 
            }

            if(this.form_session.description != this.select_session_description) {
                is_change = true 
            }

            if(this.form_idle.value != this.select_idle_value) {
                is_change = true 
            }

            if(this.form_idle.description != this.select_idle_description) {
                is_change = true 
            }

            if(this.form_expired_payment.value != this.select_expired_payment_value) {
                is_change = true 
            }

            if(this.form_expired_payment.description != this.select_expired_payment_description) {
                is_change = true 
            }

            if(this.form_expired_cash_payment.value != this.select_expired_cash_payment_value) {
                is_change = true 
            }

            if(this.form_expired_cash_payment.description != this.select_expired_cash_payment_description) {
                is_change = true 
            }

            return is_change
        }
    },
    watch: {
        kiosk_session: {
            handler(val) {
                this.defineFormSessionFormIdle(val)
            }
        }
    }
}
</script>

<style scoped>

</style>