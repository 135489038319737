<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M12 21.5994C17.302 21.5994 21.6 17.3013 21.6 11.9994C21.6 6.69748 17.302 2.39941 12 2.39941C6.69809 2.39941 2.40002 6.69748 2.40002 11.9994C2.40002 17.3013 6.69809 21.5994 12 21.5994ZM13.2 7.19941C13.2 6.53667 12.6628 5.99941 12 5.99941C11.3373 5.99941 10.8 6.53667 10.8 7.19941V11.9994C10.8 12.3177 10.9265 12.6229 11.1515 12.8479L14.5456 16.2421C15.0142 16.7107 15.774 16.7107 16.2427 16.2421C16.7113 15.7734 16.7113 15.0136 16.2427 14.545L13.2 11.5024V7.19941Z" :fill="fill_path"/>
    </svg>
</template>

<script>
export default {
    props: {
        width: {
            type: String,
            default: '24'
        },
        height: {
            type: String,
            default: '24'
        },
        fill: {
            type: String,
            default: 'none'
        },
        fill_path: {
            type: String,
            default: '#9E9E9E'
        }
    },
    data() {
        return {
            
        }
    },
}
</script>

<style scoped>

</style>