<template>
    <div>
        <br />
        <dashboard-info></dashboard-info>
        <div class="row" >
            <div class="col-md-8" style="margin-top: -30px">
                <dashboard-ticket-overview></dashboard-ticket-overview>
                <dashboard-ticket-sold-trend-overview></dashboard-ticket-sold-trend-overview>
            </div>
            <div class="col-md-4" style="margin-top: -30px">
                <dashboard-server-overview></dashboard-server-overview>
            </div>
        </div>
        <!-- <div class="text-center" style="margin-top:15%;">
            <div style="font-size: 45px;font-weight: normal;"> Dashboard CMS </div>
            <div style="font-size: 42px;font-weight: bold;"> {{ env.app_name }} </div>
        </div> -->
    </div>
</template>
<script>
const {console} = window
import encryptDecryptAesCbc256 from '@app/function/encryptDecryptAesCbc256'
import DashboardInfo from '@app/pages/Dashboard/DashboardInfo.vue'
import DashboardTicketOverview from '@app/pages/Dashboard/DashboardTicketOverview.vue'
import DashboardServerOverview from '@app/pages/Dashboard/DashboardServerOverview.vue'
import DashboardTicketSoldTrendOverview from '@app/pages/Dashboard/DashboardTicketSoldTrendOverview.vue'
export default {
    components: {
        DashboardInfo,
        DashboardTicketOverview, 
        DashboardServerOverview,
        DashboardTicketSoldTrendOverview
    },
    data() {
        return {
            user : this.$root.$store.state.user || null,
        }
    },
    async mounted() {
        if(!this.user) {
            let temp = localStorage.getItem('_USER_ME')
            if(temp) {
                let bytes = this.CryptoJS.AES.decrypt(temp, process.env.VUE_APP_KEY_ENCRYPT_DECRYPT)
                let data = JSON.parse(bytes.toString(this.CryptoJS.enc.Utf8))
                if(data) {
                    this.user = data 
                }
            }
        }

        var temp = localStorage.getItem('_USER_ROLES')
        if (temp) {
            var bytes = this.CryptoJS.AES.decrypt(temp, process.env.VUE_APP_KEY_ENCRYPT_DECRYPT)
            var role = JSON.parse(bytes.toString(this.CryptoJS.enc.Utf8))
            if (role && role.user_id) {
                var secretKey = process.env.VUE_APP_KEY_ENCRYPT_DECRYPT;
                var x_account = encryptDecryptAesCbc256.encryptAesCbc256(secretKey, role.user_id.toString())
                document.cookie = 'X-account=' + x_account + '; path=/';

                this.$socket.disconnect(); 
                this.$socket.connect();
                this.$socket.emit('register', { user_id: x_account })
                
            }
        }
    },
    methods: {

    },
    computed: {
        
    },
    watch: {
        '$root.$store.state.user': {
            handler(val) {
               console.log('disini change state user', val)
            }
        },
        'user': {
            handler(val) {
                console.log('apakah change user : ', val)
            }
        }
    }
}
</script>
