<template>
    <div>
        <box header="false"
            :cancel="false"
            :save="permission_edit"
            @onSave="doSaveConfirm">
            <div>
                <div class="row">
                    <div class="col-md-6 col-xs-12">
                        <div class="notification-switch">
                            <span class="notification-type-switch-btn" 
                                :class="is_tabs_legal_privacy_policy ? 'notification-type-switch-btn-active' : ''" 
                                @click="showLegalPrivacyPolicy">
                                <icon-library
                                    :stroke="is_tabs_legal_privacy_policy ? enable_color : disable_color">
                                </icon-library>
                                <span class="summary-head-title" :class="is_tabs_legal_privacy_policy ? 'active' : '' ">Privacy Policy</span>
                            </span>
                            
                            <span class="notification-type-switch-btn" 
                                :class="is_tabs_legal_term_condition ? 'notification-type-switch-btn-active' : ''" 
                                @click="showLegalTermCondition">
                                <icon-support
                                    :fill_path="is_tabs_legal_term_condition ? enable_color : disable_color">
                                </icon-support>
                                <span class="summary-head-title" :class="is_tabs_legal_term_condition ? 'active' : '' ">Term and Condition</span>
                              
                            </span>
                        </div>
                    </div>
                </div>

                <br>

                <div v-if="is_tabs_legal_privacy_policy">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group" :class="{'has-error': !!errors.form_privacy_policy_value}">
                                <label class="control-label">
                                    Privacy & Policy  
                                    <span class="warning-text-required">*</span>
                                </label>
                                <vue-editor
                                    v-model="form_privacy_policy.value"
                                    id="form-privacy-policy-value"
                                    ref="editor_privacy_policy"
                                    class="form-privacy-policy-value"
                                    :editor-toolbar="customToolbar"
                                    :style="disabledForm ? 'background-color: #eeeeee; opacity: 1;': ''"
                                    :disabled="disabledForm || !permission_edit" >
                                </vue-editor>

                                <p class="help help-block" v-if="errors.form_privacy_policy_value">Privacy & Policy is required.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="is_tabs_legal_term_condition">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group" :class="{'has-error': !!errors.form_term_condition_value}">
                                <label class="control-label">
                                    Terms and Condition 
                                    <span class="warning-text-required">*</span>
                                </label>
                                <vue-editor
                                    v-model="form_term_condition.value"
                                    id="form-term-condition-value"
                                    ref="editor_term_condition"
                                    class="alpha-numeric-other-char form-term-condition-value"
                                    :editor-toolbar="customToolbar"
                                    :style="disabledForm ? 'background-color: #eeeeee; opacity: 1;': ''"
                                    :disabled="disabledForm || !permission_edit" >
                                </vue-editor>

                                <p class="help help-block" v-if="errors.form_term_condition_value">Terms and Condition is required.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </box>

        <modal :title="'Change Confirmation'" save="true" remove="false"
            :show="is_confirm"
            saveText="Yes, Confirmation"
            @hidden="is_confirm = false"
            @onSave="doSave"
            :loading="is_confirm_loading">
            <span class="span-title">Are you sure you want to modify <b>{{ confirm_text }}</b> ?</span>
        </modal>
    </div>
</template>

<script>
import IconLibrary from '@components/svg/legal/library.vue'
import IconSupport from '@components/svg/legal/support.vue'
import { VueEditor } from 'vue2-editor'
const {console} = window
export default {
    components: {
        IconLibrary,
        IconSupport,
        VueEditor,
    },
    props: {
        legal: {
            type: Array
        },
        unix_time: {
            type: Number
        }
    },
    data() {
        return {
            alias: 'general-setting',
            permission_edit: false,
            form_privacy_policy: {
                id: null,
                code: 'PRIVACY',
                name: null,
                value: null,
                description: null 
            },
            form_term_condition: {
                id: null,
                code: 'TERM-COND',
                name: null,
                value: null,
                description: null 
            },
            form_temp: null,
            errors: {
                form_privacy_policy_value: false,
                form_term_condition_value: false,
            },
            customToolbar: [
                ["bold", "italic", "underline"],
                [
                    { align: "" },
                    { align: "center" },
                    { align: "right" },
                    { align: "justify" }
                ],
                [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
            ],
            is_tabs_legal_privacy_policy: true,
            is_tabs_legal_term_condition: false,
            enable_color: '#6147FF',
            disable_color: '#9E9E9E',
            disabledForm: false,
            is_confirm: false,
            is_confirm_loading: false,
            is_first_load_privacy_policy: true,
            is_first_load_term_condition : true,

        }
    },
    async mounted() {
        this.defineLegal(this.legal)
    },
    methods: {
        async defineLegal(data) {
            let query = this.$route.query.tabs
            switch (query) {
                case 'legal-privacy-policy':
                    this.is_tabs_legal_privacy_policy = true 
                    this.is_tabs_legal_term_condition = false 

                    this.errors.form_privacy_policy_value = false 
                    break;
                case 'legal-term-and-condition':
                    this.is_tabs_legal_privacy_policy = false 
                    this.is_tabs_legal_term_condition = true

                    this.errors.form_term_condition_value = false 
                    break;
                default:
                    this.is_tabs_legal_privacy_policy = true 
                    this.is_tabs_legal_term_condition = false 

                    this.errors.form_privacy_policy_value = false
                    break;
            }

            let form_privacy_policy = null 
            let form_term_condition = null 
            if(data && data.length > 0) {
                data.forEach( (item) => {
                    switch (item.code) {
                        case 'PRIVACY':
                            form_privacy_policy = JSON.parse(JSON.stringify(item))
                            this.form_privacy_policy.id = item.id 
                            this.form_privacy_policy.name = item.name
                            this.form_privacy_policy.code = item.code
                            this.form_privacy_policy.description = item.description
                            if(item.value) {
                                this.form_privacy_policy.value = item.value
                            }
                            break;
                        case 'TERM-COND':
                            form_term_condition = JSON.parse(JSON.stringify(item))
                            this.form_term_condition.id = item.id
                            this.form_term_condition.name = item.name 
                            this.form_term_condition.code = item.code 
                            this.form_term_condition.description = item.description
                            if(item.value) {
                                this.form_term_condition.value = item.value
                            }
                            
                            break;
                        default:
                            break;
                    }
                })
            }

            let temp = {
                form_privacy_policy: form_privacy_policy,
                form_term_condition: form_term_condition
            }

            this.form_temp = JSON.parse(JSON.stringify(temp))

            const delay = ms => new Promise(res => setTimeout(res, ms))
            await delay(0.001)
            this.errors.form_privacy_policy_value = false
            this.errors.form_term_condition_value = false 

            this.is_first_load_privacy_policy = false 
            this.is_first_load_term_condition = false

            let permission_edit = await this.$root.checkingButtonsPage(this.alias, 'update')
            if (permission_edit.validate) {
                this.permission_edit = true
            } 

        },
        async showLegalPrivacyPolicy() {
            if(!this.is_tabs_legal_privacy_policy) {
                this.is_tabs_legal_privacy_policy = true 
                this.is_tabs_legal_term_condition = false 

                this.errors.form_privacy_policy_value = false 

                let temp = this.form_temp
                if(temp && temp.form_privacy_policy) {
                    this.form_privacy_policy = JSON.parse(JSON.stringify(temp.form_privacy_policy))
                }

                try {
                    this.$router.replace({ query : {tabs : 'legal-privacy-policy'} })
                } catch {
                    
                } finally{
                    console.clear()
                }   
            }
        },
        async showLegalTermCondition() {
            if(!this.is_tabs_legal_term_condition) {
                this.is_tabs_legal_privacy_policy = false 
                this.is_tabs_legal_term_condition = true

                this.errors.form_term_condition_value = false 

                let temp = this.form_temp
                if(temp && temp.form_term_condition) {
                    this.form_term_condition = JSON.parse(JSON.stringify(temp.form_term_condition))
                }

                try {
                    this.$router.replace({ query : {tabs : 'legal-term-and-condition'} })
                } catch {
                    
                } finally{
                    console.clear()
                }
            }
        },
        async validationForm() {
            let is_success = true 
            this.errors.form_privay_policy_value = false 
            this.errors.form_term_condition_value = false 
            if(this.is_tabs_legal_privacy_policy) {
                if(!this.form_privacy_policy.value) {
                    this.errors.form_privacy_policy_value = true 
                    is_success = false 
                }
            }

            if(this.is_tabs_legal_term_condition) {
                if(!this.form_term_condition.value) {
                    this.errors.form_term_condition_value = true 
                    is_success = false 
                }
            }

            return is_success
        },
        async doSaveConfirm() {
            let is_valid = await this.validationForm()
            if(is_valid) {
                this.is_confirm = true 
            }
        },
        async doSave() {
            this.is_confirm_loading = true 
            let params = {}
            let name  = null 
            if(this.is_tabs_legal_privacy_policy) {
                params.code = this.form_privacy_policy.code 
                params.name = this.form_privacy_policy.name 
                params.value = this.form_privacy_policy.value
                params.description = this.form_privacy_policy.description

                name = 'Privacy & policy'
            }

            if(this.is_tabs_legal_term_condition) {
                params.code = this.form_term_condition.code 
                params.name = this.form_term_condition.name
                params.value = this.form_term_condition.value
                params.description = this.form_term_condition.description

                name = 'Term and condition'
            }

            Rest.put('/setting/'+params.code, params)
            .then( async(res) => {
                if(res && res.status == 200) {
                    this.$root.showNotification('Success', 'success',name+" modification successful.")
                    this.userLogActivity()
                    this.onRefreshLegal()
                }
            })
            .catch( async(err) => {
                if(err && err.response && err.response.status) {
                    if(err.response.data && err.response.data.message) {
                        this.$root.showNotification('Failed', 'error', err.response.data.message)        
                    }else {
                           let message = name+" modification failed."
                            this.$root.showNotification('Failed', 'error', message)
                    }
                }else {
                    this.$root.showNotification('Failed', 'error', 'Sorry, you do not have an internet connection.')
                }
            })
            .finally( () => {
                this.is_confirm = false
                this.is_confirm_loading = false 
            })


        },
        
        onRefreshLegal() {
            let data = []
            data.push(this.form_privacy_policy)
            data.push(this.form_term_condition)

            this.form_temp = JSON.parse(JSON.stringify(data))
            this.$emit('onRefreshLegal', data)
        },
        async userLogActivity() {
            let tabs = null 
            if(this.is_tabs_legal_privacy_policy) {
                tabs = 'lagal-privacy-policy'
            }

            if(this.is_tabs_legal_term_condition) {
                tabs = 'legal-term-and-condition'
            }

            if(this.$root.$store.state.user && this.$root.$store.state.user.full_name) {
                let route_name = {
                    name: 'setting.general-setting',
                    query: {
                        is_edit: false,
                        tabs: tabs
                    }
                } 

                let secretKey = process.env.VUE_APP_KEY_ENCRYPT_DECRYPT
                let route_name_encrypt = this.EncryptDecryptAesCbc256.encryptAesCbc256(secretKey, JSON.stringify(route_name))

                let activity_type = 'edit'
                let activity = `${this.$root.$store.state.user.full_name} has modified the <a style="cursor: pointer" class="click-detail-log" data-route="${route_name_encrypt}"> general setting legal </a>`

                let params = {
                    activity: activity,
                    feature: 'General Setting Legal',
                    activity_type: activity_type,
                    route_name: route_name
                }

                this.$root.sendUserLogActivity(params)
            }
            
        },   

    },
    computed: {
        confirm_text() {
            let txt = ''
            if(this.is_tabs_legal_privacy_policy) {
                txt = 'legal privacy & policy'
            }

            if(this.is_tabs_legal_term_condition) {
                txt = 'legal term and condition'
            }

            return txt
        }
    },
    watch: {
        unix_time: {
            handler() {
                this.defineLegal(this.legal)
            }
        },
        legal: {
            handler(val) {
                this.defineLegal(val)
            }
        },
        'form_privacy_policy.value': {
            handler(val) {
                this.errors.form_privacy_policy_value = false 
                if(this.is_tabs_legal_privacy_policy) {
                    if(!this.is_first_load_privacy_policy) {
                        if(!val) {
                            this.errors.form_privacy_policy_value = true 
                        }
                    }
                }
            }
        },
        'form_term_condition.value': {
            handler(val) {
                this.errors.form_term_condition_value = false
                if(this.is_tabs_legal_term_condition) {
                    if(!this.is_first_load_term_condition) {
                        if(!val) {
                            this.errors.form_term_condition_value = true
                        }
                    }
                }
            }
        }
    }
}
</script>

<style scoped>
    .notification-switch {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 2px;
        gap: 2px;
        align-self: stretch;
        border-radius: 12px;
        border: 1px solid #E0E0E0;
        background: #F5F5F5;
    }

    .notification-type-switch-btn {
        display: flex; padding: 8px 0px;
        justify-content: center;
        align-items: center;
        gap: 2px;
        border-radius: 10px;
        width: 100%;
        cursor: pointer;
    }

    .notification-type-switch-btn-active {
        background: #FFF;
        box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.10);
    }

    .summary-head-title.active {
        color: #6147FF;
        font-weight: 700;
    }

    .div-editor-dinamis_variable {
        padding: 10px; 
        background-color: #E0E0E0;
    }
</style>