<template>
    <div>
        <div class="box vue-box">
            <div class="box-header with-border box-header-with-border" v-if="header == true || header == 'true'">
                <slot name="header">
                    <!-- <router-link class="btn btn-default back-button" v-if="backButton" :to="backButton">
                        <i class="fa fa-chevron-left"></i> Kembali
                    </router-link> -->
                    <slot name="header-actions"></slot>
                    <h4 class="box-title" v-if="title">
                        <i class="fa" :class="icon" v-if="icon"></i>
                        {{ title }}
                    </h4>
                    <div class="btn-header-box btn-group pull-right">
                        <remove-button class="btn btn-lg btn-remove" :removeText="removeText" :loading="boxLoading" v-if="remove == true || remove == 'true'" @click="removeButton" :iconRemove="iconRemove"/>
                        <button type="button" class="btn btn-default" v-if="print == true || print == 'true'" @click="printButton">
                            <i class="fa fa-print"></i> Print
                        </button>
                        <button-switch v-if="isSwitch" :value="switchValue" :disabled="switchDisabled" :title="switchTitle" :name="switchText" @onClickSwitchButton="switchButton"></button-switch>
                        <slot name="header-buttons"></slot>
                    </div>
                </slot>
            </div>
            <div class="box-body box-processing" :class="{'active': boxLoading}">
                <slot></slot>
                <div class="process-loading">
                    <div class="panel panel-default">
                        <i class="fa fa-circle-notch fa-spin"></i> Loading ...
                    </div>
                </div>
            </div>
            <!-- <div class="box-footer" v-if="footer == true || footer == 'true'">
                <slot name="footer">
                    <div class="btn-group">
                        <save-button :loading="boxLoading" :saveText="saveText" :class="classBtn" @click="saveButton" v-if="save == true || save == 'true'" />
                        <slot name="footer-buttons"></slot>
                    </div>
                    <slot name="footer-actions"></slot>
                </slot>
            </div> -->
        </div>
        
        <div class="box-button-div" style="width:100%">
            <div class="row">
                <div class="col-md-3">
                    <div v-if="save">
                        <div v-if="cancel">
                            <div style="width:49%; display:inline-block;">
                                <button v-if="boxLoading" type="button" class="btn btn-block btn-default btn-md border-radius-8px">Cancel</button>
                                <div v-else>
                                    <router-link type="button" class="btn btn-block btn-default btn-md border-radius-8px"  v-if="backButton" :to="backButton">Cancel</router-link>
                                    <button v-else type="button" class="btn btn-block btn-default btn-md border-radius-8px" @click="cancelButton">Cancel</button>
                                </div>
                                <!-- <router-link type="button" class="btn btn-block btn-default btn-md" v-if="backButton" :to="backButton">Cancel</router-link> -->
                            </div>
                            <div style="width:2%; display:inline-block;"></div>
                            <div style="width:49%; display:inline-block;">
                                <save-button class="btn btn-block btn-md" :loading="boxLoading" :saveText="saveText" :classBtn="is_owner ? `btn-submit-owner` : `btn-submit`" @click="saveButton" v-if="save == true || save == 'true'"></save-button>
                            </div>
                        </div>
                        <div v-else>
                            <div style="width:49%; display:inline-block;">
                                <save-button class="btn btn-block btn-md" :loading="boxLoading" :saveText="saveText" :classBtn="is_owner ? `btn-submit-owner` : `btn-submit`" @click="saveButton" v-if="save == true || save == 'true'"></save-button>
                            </div>
                        </div>
                        
                    </div>
                    <div v-else>
                        <div style="width:49%; display:inline-block;">
                            <router-link type="button" class="btn btn-block btn-default btn-md border-radius-8px" v-if="backButton" :to="backButton">Cancel</router-link>
                        </div>
                        <div style="width:2%; display:inline-block;"></div>
                        <div style="width:49%; display:inline-block;">
                            
                        </div>
                    </div>
                    
                </div>
                <div class="col-md-9"></div>
            </div>
            
        </div>
    </div>
</template>

<script>
import ButtonSwitch from './ButtonSwitch.vue'
function defaultObject() {
    return {}
}
export default {
  components: { ButtonSwitch },
    props: {
        title: {
            type: String,
            required: false,
            default: null
        },
        icon: {
            type: String,
            required: false,
            default: null
        },
        iconRemove: {
            type: String,
            required: false,
            default: 'fa-trash'
        },
        back: {
            type: [String, Object]
        },
        remove: {
            type: [String, Boolean]
        },
        print: {
            type: [String, Boolean]
        },
        save: {
            type: [String, Boolean]
        },
        cancel: {
            type: [String, Boolean],
            default: true,
        },
        isSwitch: {
            type: [Boolean],
            default: false,
        },
        switchDisabled: {
            type: [String, Boolean],
            default: false
        },
        switchValue: {
            type: [String, Boolean],
            default: false,
        },
        switchTitle: {
            type: [String],
            default: 'Status'
        },
        switchText: {
            type: [String],
        },
        saveText: {
          type: [String],
          default: 'Save'
        },
        removeText: {
          type: [String],
          default: 'Delete'
        },
        loading: {
            type: Boolean,
            default: null,
            required: false
        },
        header: {
            type: [String, Boolean],
            default: true
        },
        footer: {
            type: [String, Boolean],
            default: true
        },
         classBtn: {
            type: [String],
            default: 'btn-primary'
        }
    },
    data() {
        let backButton = null
        if (this.back && typeof this.back == 'string') {
            backButton = {name: this.back}
        } else if (this.back && typeof this.back == 'object') {
            backButton = this.back
        }
        return {
            backButton: backButton
        }
    },
    computed: {
        boxLoading() {
            var loading = false
            if (null == this.loading) {
                try {
                    loading = this.$store.state.loading
                } catch (e) {
                    loading = false
                }
            } else {
                loading = this.loading
            }

            return loading
        },
        is_owner() {
            let response = false
            let temp = localStorage.getItem('_USER_ME')
            if(temp) {
                let bytes = this.CryptoJS.AES.decrypt(temp, process.env.VUE_APP_KEY_ENCRYPT_DECRYPT)
                let data = JSON.parse(bytes.toString(this.CryptoJS.enc.Utf8))
                if(data) {
                    if(data.company_id) {
                        response = true
                    }
                }
            }

            return response
        }
    },
    methods: {
        removeButton() {
            this.$emit('onRemove')
        },
        printButton() {
            this.$emit('onPrint')
        },
        saveButton() {
            this.$emit('onSave')
        },
        cancelButton() {
            this.$emit('onCancel')
        },
        switchButton() {
            this.$emit('onSwitchButton') 
        }
    }
}
</script>

<style scoped>
.vue-box>.box-header>.back-button:first-child {
    margin-right: 20px;
}
.box-body.box-processing {
    position: relative;
}
.box-processing .process-loading {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.02);
}
.box-processing.active,
.box-processing.active .process-loading {
    cursor: progress;
    touch-action: none;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.box-processing.active .process-loading {
    display: block;
}
.box-processing.active .dataTables_processing,
.box-processing.active .box-processing .process-loading {
    display: none !important;
}
.box-processing .process-loading .panel {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 200px;
    margin-left: -100px;
    margin-top: -26px;
    text-align: center;
    padding: 1em 0;
}

.box-title {
    font-weight: 700;
}

.box {
    margin: 15px 0px 0px 0px;
    border: none;
    /* border-radius: 5px; */
}

.box-header {
    padding: 20px 20px 20px 20px;
    background-color: #EDEDED
}

.box-title {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.box-body {
    padding: 20px 20px 20px 20px;
}

.btn-default,
.btn-default:hover,
.btn-default:active {
    background-color: #E0E0E0;
}

.btn-remove {
    color: red;
    background-color: #EDEDED;
    border: none;
}

.btn-remove:hover,
.btn-remove:active {
    color: red;
    background-color: #EDEDED;
    border: none;
}

.box-button-div {
    padding-top: 20px;
}

.btn-submit,
.btn-submit:hover,
.btn-submit:focus {
    background-color:#06DCF8;
    color: #FFFFFF;
    
}

.btn-submit-owner,
.btn-submit-owner:hover,
.btn-submit-owner:focus {
    background-color: #18A891;
    color: #FFFFFF;
}

.box-header-with-border {
    min-height: 70px !important;
}

@media only screen and(max-width: 712px) {
    /* .btn-header-box {
        margin-top: 50px;
    } */
}
</style>
