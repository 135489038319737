import Vue from 'vue'

const SelectFormValidate = {
    name: 'SelectValidateItem',
    props: ['value', 'validation', 'formPlaceholderName', 'formLabel', 'formItemsSelected', 'formDisabled' , 'fistOptionDisabled', 'handleChangeOption', 'id', 'formClass'],
    template:
        `<div :class="validation ? 'form-group has-error' : 'form-group'">
            <label>{{formLabel}}</label>
            <select
                class="form-control"
                v-bind:placeholder="formPlaceholderName"
                v-bind:value="value"
                v-on:change="$emit('handleChangeOption')"
                v-on:input="$emit('input', $event.target.value)"
                v-bind:disabled="formDisabled"
                v-bind:id="id"
                v-bind:class="formClass"
            >
                <option v-bind:disabled="fistOptionDisabled" value="">{{formPlaceholderName}}</option>
                <option v-for="(item, index) in formItemsSelected" :key="index" :value="item.value">{{item.name}}</option>
            </select>
            <div class="help-block">{{ validation }}</div>
        </div>`,
    data() {
        return {
        }
    },
    mounted(){
    },
    methods: {
    }
}
Vue.component('SelectValidateItem', SelectFormValidate)
export default SelectFormValidate
