<template>
  <box :back="back" v-if="back"
    :save="true"
    @onSave="doSave"
    :loading="loading"
    >
    <div class="row">
      <!-- <div class="col-md-3"></div> -->
      <div class="col-md-6">
        <div class="form-group">
          <InputValidateItem
                  v-model="formUser.username"
                  :formType="'email'"
                  :formLabel="'Masukkan Email baru*'"
                  :formPlaceholderName="'Masukkan Email'"
                  :validation="validation.firstError('formUser.username')"
          />
        </div>
        <div class="form-group">
          <InputValidateItem
                  v-model="formUser.changeusername"
                  :formType="'email'"
                  :formLabel="'Masukkan ulang Email baru*'"
                  :formPlaceholderName="'Masukkan ulang Email'"
                  :validation="validation.firstError('formUser.changeusername')"
          />
        </div>
        <div class="input-group input-group">
          <InputValidateItem
                  v-model="formUser.password"
                  :formType="isBeText ? 'text' : 'password'"
                  :formLabel="'Password*'"
                  :formPlaceholderName="'Masukkan Password'"
                  :validation="validation.firstError('formUser.password')"
          />
          <span class="input-group-btn">
            <button type="button"
              :style="validation.firstError('formUser.password') ? 'margin-top: -10px' : 'margin-top: 25px' "
              class="btn btn-flat"
              @click.prevent="showPwd()"
              >
              <i class="fa fa-eye"></i>
            </button>
          </span>
        </div>
      </div>
      <div class="col-md-3"></div>
    </div>

    <modal title="Konfirmasi Ubah Email" save="true" remove="false"
           :show="showChangeEmailConfirm"
           :saveText="'Simpan'"
           @hidden="showChangeEmailConfirm = false"
           @onSave="doSaveChangeEmail">
        <span class="span-title">Apakah anda yakin akan mengubah email ? </span>
    </modal>
  </box>
</template>

<script>
import axios from "axios"
import SimpleVueValidation from "simple-vue-validator"
const Validator = SimpleVueValidation.Validator.create({
    templates: {
        required: 'Wajib diisi',
        email: 'Mohon masukkan email yang valid.'
    }
})

const {console} = window
export default {
    data() {
      return {
        user: {
          id: null,
          name: null,
          username: null ,
          detail: {
            profile_data: {
              address: null,
              date_of_birth: null,
              email: null,
              employee_type: null,
              id_card: null,
              phone_number: null,
              salary: null,
              signature: ""
            },
            project_id: [],
            navigation: null
          }

        },
        formUser: {
          remember: true,
          id: null,
          username: "",
          changeusername: "",
          password : null,
          detail: {
              profile_data: {
                  date_of_birth: "",
                  id_card: "",
                  phone_number: "",
                  address: "",
                  email: ""
              },
              project_id: []
          }
        },
        back: null,
        loading: false,
        showChangeEmailConfirm: false,
        isBeText: false,
        role: {},
        me: {},
        project: this.$store.state.project || JSON.parse(localStorage.getItem('_PRJ_SELECT'))
      }
    },
    validators: {
        'formUser.username': function (value) {
            return Validator.value(value).required().email();
        },'formUser.changeusername': function (value) {
            return Validator.value(value).required().email();
        },'formUser.password': function (value) {
            return Validator.value(value).required();
        }
    },
    async created() {
      let temp = localStorage.getItem('_USER_ROLES')
        if(temp) {
          let bytes = this.CryptoJS.AES.decrypt(temp, process.env.VUE_APP_KEY_ENCRYPT_DECRYPT)
          let data = JSON.parse(bytes.toString(this.CryptoJS.enc.Utf8))
          if(data ) {
            this.role.id = data.role_id
            this.role.name = data.role_name
          }
        }
    },
    async mounted() {
      await this.init()
    },
    methods: {
      async saveLogActivities(type,detail) {
          let message = null
          let route_name = {
            name: this.$root._route.name
          }

          type = type.toLowerCase()
          switch (type) {
            case 'update':
              message = this.me.name+" telah mengubah email"
              break;
            default:
              break;
        }
        let possible_type = ['update']
          if(possible_type.indexOf(type) >= 0) {
            let log_activities = {
              description: message,
              menu: 'Change Email',
              user: this.formUser,
              detail: detail,
              route_name: route_name,
              type_activities: type ,
              project: this.project,
              date: moment().format('YYYY-MM-DD HH:mm:ss'),
              role: this.role
            }

            Rest.post('/user/log-activities', log_activities)
          }
      },
      async init() {
        Rest.get('/user/me/detail')
        .then( async(res) => {
          if(res.data) {
            if(res.data.detail) {
              if(res.data.detail.profile_data) {
                if(res.data.detail.profile_data.date_of_birth) {
                  try {
                    res.data.detail.profile_data.date_of_birth = moment(res.data.detail.profile_data.date_of_birth, 'YYYY-MM-DD').format('DD-MM-YYYY')
                    this.dateConfig = {
                      locale: {
                        format: 'DD-MM-YYYY'
                      }
                    }
                  } catch {}
                }

                if(!res.data.detail.profile_data.employee_type) {
                  res.data.detail.profile_data.employee_type = ''
                }
                if(!res.data.detail.profile_data.salary) {
                  res.data.detail.profile_data.salary = 0
                }

                if(res.data.detail.profile_data.signature) {
                  this.signatureUrl = this.fileUrl+res.data.detail.profile_data.signature
                }

              }
            }
          }
          this.user = res.data
          this.formUser.detail = this.user.detail
          this.formUser.id = this.user.id
          this.formUser.name = this.user.name
          this.formUser.navigations = this.user.navigations
          this.back = {
            name: 'profile',
            params: {
              id: this.user.id
            }
          }
        })
        .catch( (err) => {

        })
      },
      doSave() {
        this.$validate().then((result) => {
            if (result) {
                this.showChangeEmailConfirm = true  ;
            } else {
                this.$root.showNotification('Gagal', 'warn', 'Silahkan lengkapi data yang kosong.')
            }
        })
      },
      async doSaveChangeEmail() {
        if(this.formUser.username == this.formUser.changeusername) {
          Rest.put('/user/changeusername', this.formUser)
            .then( async(res) => {
                if (res.status == 200) {
                    this.$root.showNotification('Berhasil', 'success', 'Email berhasil diubah')
                    await this.submit(this.formUser.changeusername, this.formUser.password);
                    this.showChangeEmailConfirm = false
                }
            })
            .catch((err) => {
                this.$root.showNotification('Gagal', 'error', err.response.data.message) //tampuil barusan
            })
          }else{
            this.$root.showNotification('Gagal', 'error', 'Email baru dan konfirmasi email baru harus sama')
          }
      },
      submit(usernamebaru,password) {
        Api.login(usernamebaru, password)
        .then(res => {
            Api.setToken(res, this.formUser.remember)
                axios.get(Api.base + '/user/me/detail').then(res => {
                    // this.formUser=res.data
                    this.me = res.data
                    this.user.full_name=this.formUser.username;

                    this.$root.$emit('send_full_name', this.formUser.name)
                    this.$root.$emit('navbar_full_name', {
                      name: this.formUser.name,
                      username: this.formUser.username
                    })
                    this.saveLogActivities('update', this.formUser)
                    // let projectsIdIndexFirst = this.formUser.detail.project_id == '' ? 0 :this.formUser.detail.project_id==undefined ? 0 :this.formUser.detail.project_id.toString();

                    // Api.get('/project?per_page=1&project_id='+projectsIdIndexFirst).then(res => {
                    //     this.loading = false
                    //     var project = res.data.data.items[0]
                    //     this.$store.state.project = project
                    //     localStorage.setItem('_PRJ_SELECT', JSON.stringify(project))
                    //     window.console.clear()
                    //     // this.getUserInfo()
                    // }).catch(e => {
                    //     // this.loading = false
                    //     if (e.response == undefined) {
                    //         this.error = null;
                    //     } else {
                    //         this.error = e.response.data
                    //     }
                    //     if (this.error == null) {
                    //         this.$root.showNotification('Gagal', 'warn', this.getErorUogin("Maaf, Anda tidak memiliki koneksi internet."))
                    //     } else {
                    //         this.$root.showNotification('Gagal', 'error', this.getErorUogin(this.error.error_description))
                    //     }
                    //
                    // })
                })
            }).catch(e => {
            // this.loading = false
              if (e.response == undefined) {
                  this.error = null;
              } else {
                  this.error = e.response.data
              }
              if (this.error == null) {
                  this.$root.showNotification('Gagal', 'warn', this.getErorUogin("Maaf, Anda tidak memiliki koneksi internet."))
              } else {
                  this.$root.showNotification('Gagal', 'error', this.getErorUogin(this.error.error_description))
              }

        })
        .finally( () => {
          this.$root.$router.push({ name: 'profile'} )
        })

      },
      showPwd() {
        this.isBeText = !this.isBeText
      }
    }
  }
</script>
