export default function(date, format) {
    if (!format) {
        format = 'DD-MM-YYYY'
    }
    if (date instanceof Date) {
        return moment(date).format(format)
    }

    if (date instanceof moment) {
        return moment(moment.toDate()).format(format)
    }

    if (typeof date == 'string') {
        var formats = {
            'DD-MM-YYYY': /\d{2}-\d{2}-\d{4}/,
            'DD/MM/YYYY': /\d{2}\/\d{2}\/\d{4}/,
            'YYYY-MM-DD': /\d{4}-\d{2}-\d{2}/,
            'YYYY/MM/DD': /\d{4}\/\d{2}\/\d{2}/,
        }
        for (var f in formats) {
            if (date.match(formats[f])) {
                return moment(date, f).format(format)
            }
        }
    }

    return moment().format(format)
}
