<template>
  <div>
    <button class="btn btn-sm btn-primary" @click="show()"> show</button>
    <div class="modal fade" id="modal-show" role="dialog" aria-hidden="true" >
        <div class="modal-dialog modal-xl" style="background-color:#878282">
            <div class="modal-content" style="background-color:#878282">
                <div class="modal-body">
                  <carousel
                    :per-page="1"
                    :mouse-drag="false"
                    :autoplay="true"
                    :loop="true"
                    :navigationEnabled="true"
                    :centerMode="true"
                    :navigationNextLabel="nextLabel"
                    :navigationPrevLabel="prevLabel"
                    :paginationEnabled="true"
                    :paginationPadding="paginationPadding"
                    :paginationColor="paginationColor"
                    >

                    <slide v-for="(data, index) in images" :key="index">
                        <img :src="data.path" style="width:100%"/>
                    </slide>
                  </carousel>
                </div>
            </div>
        </div>
    </div>

    <div>
      <gallery :images="images2" :index="index" @close="index = null"></gallery>
      <div
        class="image"
        v-for="(image, imageIndex) in images2"
        :key="imageIndex"
        @click="index = imageIndex"
        :style="{ backgroundImage: 'url(' + image + ')', width: '300px', height: '200px' }"
      ></div>
    </div>
</div>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel';
// https://github.com/SSENSE/vue-carousel

import Vue from 'vue'
import VueGallery from 'vue-gallery'

Vue.component('VGallery', VueGallery)
export default {
  components: {
    Carousel,
    Slide,
    'gallery': VueGallery
  },
  data() {
    return {
      someLocalProperty: [],
      nextLabel: "<i class='fa fa-chevron-right fa-3x' style='color:#CCC; margin-left:-75px;'></i>",
      prevLabel: "<i class='fa fa-chevron-left fa-3x' style='color:#CCC; margin-right:-75px;'></i>",
      paginationPadding: 4,
      paginationColor: '#b8cbcf',
      speed: 1000,
      images: [
        {
          path: 'https://picsum.photos/1024/480/?image=51'
        },
        {
          path: 'https://picsum.photos/1024/480/?image=52'
        },
        {
          path: 'https://picsum.photos/1024/480/?image=53'
        },
        {
          path: 'https://picsum.photos/1024/480/?image=54'
        },
        {
          path: 'https://picsum.photos/1024/480/?image=55'
        },
        {
          path: 'https://picsum.photos/1024/480/?image=56'
        },
      ],
      images2: [
         'https://picsum.photos/1024/480/?image=51',
         'https://picsum.photos/1024/480/?image=52',
         'https://picsum.photos/1024/480/?image=53',
         'https://picsum.photos/1024/480/?image=54',
         'https://picsum.photos/1024/480/?image=55',
         'https://picsum.photos/1024/480/?image=56'
       ],
       index: null
    }
  },
  methods: {
    show() {
      $('#modal-show').modal('show')
    }
  }
};
</script>

<style scoped>
  .image {
    float: left;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    border: 1px solid #ebebeb;
    margin: 5px;
  }
</style>
