<template>
    <box :loading="loading" :save="true"  @onSave="doPost">
        <div class="row">
            <div class="col-md-12">
                <InputValidateItem
                    v-model="username"
                    :formLabel="'Username*'"
                    :formPlaceholderName="'Masukkan Username'"
                    :validation="validation.firstError('username')"
                />
            </div>
            <div class="col-md-12">
                <InputValidateItem
                    v-model="telp_no"
                    :formType="'number'"
                    :formLabel="'No. Telp*'"
                    :formPlaceholderName="'Masukkan Nomor Telepon'"
                    :validation="validation.firstError('telp_no')"
                />
            </div>
            <div class="col-md-12">
                <InputValidateItem
                    v-model="email"
                    :formType="'email'"
                    :formLabel="'Email*'"
                    :formPlaceholderName="'Masukkan Email'"
                    :validation="validation.firstError('email')"
                />
            </div>
            <div class="col-md-12">
                <SelectValidateItem
                    v-model="division"
                    :formLabel="'Divisi*'"
                    :formPlaceholderName="'Pilih Divisi'"
                    :validation="validation.firstError('division')"
                    :formItemsSelected="dataDivision"
                />
            </div>
            <div class="col-md-12">
                <SelectValidateItem
                    v-if="division==1"
                    v-model="position"
                    :formLabel="'Jabatan*'"
                    :formPlaceholderName="'Pilih Jabatan'"
                    :validation="validation.firstError('position')"
                    :formItemsSelected="dataPosition"
                />
            </div>
            <div class="col-md-12">
                <SelectValidateItem
                    v-model="project"
                    :formLabel="'Projek*'"
                    :formPlaceholderName="'Pilih Projek'"
                    :validation="validation.firstError('project')"
                    :formItemsSelected="dataProject"
                />
            </div>
            <div class="col-md-12">
                <MultilineTextValidateItem
                    v-model="additional_information"
                    :formLabel="'Tambahan Informasi'"
                    :formPlaceholderName="'Masukkan Tambahan Informasi'"
                    :validation="validation.firstError('additional_information')"
                />
            </div>
            <div class="col-md-12">
                <div class="form-group">
                    <b-form-checkbox
                        id="checkbox-applicant"
                        v-model="applicant"
                        name="checkbox-applicant"
                        :checked-value="true"
                        :unchecked-value="false"
                    >
                        Jadikan Sebagai Pemohon
                    </b-form-checkbox>
                </div>
            </div>
            <div class="col-md-12">
                <p>Resource : <a href="http://simple-vue-validator.magictek.cn" @click.prevent="addHeaderButton">Simple Vue-Validation</a></p>
            </div>
        </div>
    </box>
</template>

<script>
    import SimpleVueValidation from 'simple-vue-validator'
    const Validator = SimpleVueValidation.Validator.create({templates: {
        required: 'Silakan masukkan nilai',
        email: 'Mohon masuk kan email yg valid'
    }})
    const {console} = window
    export default {
        props: ['id',],
        data: () => ({
            username: "",
            position: "",
            division: "",
            project: "",
            telp_no: "",
            email: "",
            additional_information: "",
            applicant: false,
            loading: false,
            dataPosition: [{
                value: 1,
                name: 'Position 1'
            }, {
                value: 2,
                name: 'Position 2'
            }],
            dataDivision: [{
                value: 1,
                name: 'Marketing'
            }, {
                value: 2,
                name: 'Sales'
            }, {
                value: 3,
                name: 'General Manager'
            }, {
                value: 4,
                name: 'Direktur'
            }],
            dataProject: [{
                value: 1,
                name: 'Bukit Cimanggu City'
            }, {
                value: 2,
                name: 'Cilegon'
            },],
            dataUser:[],
            showFieldPosition: true
        }),
        validators: {
            username: function (value) {
                return Validator.value(value).required().integer()
            },
            telp_no: function (value) {
                return Validator.value(value).required().maxLength(13, `Maksimal alias harus terdiri dari 13 karakter`)
            },
            email: function (value) {
                return Validator.value(value).required().email()
            },
            position: function(value) {
                return Validator.value(value).required()
            },
            division: function(value) {
                return Validator.value(value).required()
            },
            project: function(value) {
                return Validator.value(value).required()
            },
            additional_information: function (value) {
                return Validator.value(value).required().maxLength(115, `Maksimal alias harus terdiri dari 115 karakter`)
            }
        },
        mounted() {
            console.dir(this.dataDivision)
        },
        methods: {
            redirectToList(){
                this.$router.push({name: 'master.organizational-structure' })
            },
            doPost() {
                this.loading = true
                this.$validate().then(result => {
                    if(result) {
                        this.loading = true
                        this.postCreateOrganizationalStructure()
                    } else {
                        this.loading = false
                        console.dir("ELSE")
                    }
                })
            },
            postCreateOrganizationalStructure(){
                Rest.post('/mdasdasd/master/organization/structure', {
                    user_id: this.username,
                    organization_position_id: this.position,
                    project_id: this.project,
                    telp: this.telp_no,
                    email: this.email,
                    applicant: this.applicant,
                    additional_information: this.additional_information
                }).then(() => {
                    this.$root.showNotification('Berhasil', 'success', 'Buat struktur organisasi baru telah berhasil')
                    this.redirectToList()
                }).catch(err => {
                    this.$root.showNotification('Gagal', 'error', err.toString())
                }).finally(() => {
                    this.loading = false
                })
            }
        },
        watch:{}
    }
</script>
