<template>
  <box :back="back" v-if="back"
    :save="true"
    @onSave="doSave"
    :loading="loading"
    >
    <div class="row">
      <div class="col-md-6">
        <div class="form-group" :class="{'has-error': !!errors.password_old}">
          <label class="control-label">Password Lama</label>
          <div class="input-group">
            <input type="password" class="form-control"
              v-model="form.password_old"
              placeholder="Masukkan password lama"
              id="password-old" />
            <div class="input-group-addon btn-change" id="password-old-view">
              <i class="fa fa-eye"></i>
            </div>
          </div>
          <p class="help help-block" v-if="errors.password_old">Wajib diisi, minimal 8 karakter maksimal 20 karakter</p>
        </div>
        <div class="form-group" :class="{'has-error': !!errors.password_new}">
          <label class="control-label">Password Baru</label>
          <div class="input-group">
            <input type="password" class="form-control"
              v-model="form.password_new"
              placeholder="Masukkan password baru"
              id="password-new" />
            <div class="input-group-addon btn-change" id="password-new-view">
              <i class="fa fa-eye"></i>
            </div>
          </div>
          <p class="help help-block" v-if="errors.password_new">Wajib diisi, minimal 8 karakter maksimal 20 karakter</p>
        </div>
        <div class="form-group" :class="{'has-error': !!errors.password_new_confirm}">
          <label class="control-label">Konfirmasi Password Baru</label>
          <div class="input-group">
            <input type="password" class="form-control"
              v-model="form.password_new_confirm"
              placeholder="Masukkan konfirmasi password baru"
              id="password-new-confirm" />
            <div class="input-group-addon btn-change" id="password-new-confirm-view">
              <i class="fa fa-eye"></i>
            </div>
          </div>
          <p class="help help-block" v-if="errors.password_new_confirm">Wajib diisi, minimal 8 karakter maksimal 20 karakter, dan harus sama dengan sand baru</p>
        </div>
      </div>
    </div>
  </box>
</template>

<script>
const {console} = window
export default {
    data() {
      return {
        user: {
          id: null,
          name: null,
          username: null ,
          detail: {
            profile_data: {
              address: null,
              date_of_birth: null,
              email: null,
              employee_type: null,
              id_card: null,
              phone_number: null,
              salary: null,
              signature: ""
            },
            project_id: [],
            navigation: null
          }
        },
        form: {
          'password_old': null,
          'password_new': null,
          'password_new_confirm': null
        },
        errors: {
          password_old: false,
          password_new: false,
          password_new_confirm: false
        },
        back: null,
        loading: false,
        me: {},
        role: {},
        project: this.$store.state.project || JSON.parse(localStorage.getItem('_PRJ_SELECT'))
      }
    },
    async created() {
      let temp = localStorage.getItem('_USER_ROLES')
        if(temp) {
          let bytes = this.CryptoJS.AES.decrypt(temp, process.env.VUE_APP_KEY_ENCRYPT_DECRYPT)
          let data = JSON.parse(bytes.toString(this.CryptoJS.enc.Utf8))
          if(data ) {
            this.role.id = data.role_id
            this.role.name = data.role_name
          }
        }
    },
    async mounted() {
      await this.init()
      let self = this
      $(document).ready(function() {
        $(document).on('click', '#password-old-view', function() {
          self.viewPassword('password-old')
        })
        $(document).on('click', '#password-new-view', function() {
          self.viewPassword('password-new')
        })
        $(document).on('click', '#password-new-confirm-view', function() {
          self.viewPassword('password-new-confirm')
        })
      })
    },
    methods: {
      async saveLogActivities(type,detail) {
          let message = null
          let route_name = {
            name: this.$root._route.name
          }

          type = type.toLowerCase()
          switch (type) {
            case 'update':
              message = this.me.name+" telah mengubah password"
              break;
            default:
              break;
        }
        let possible_type = ['update']
          if(possible_type.indexOf(type) >= 0) {
            let log_activities = {
              description: message,
              menu: 'Change Password',
              user: this.me,
              detail: detail,
              route_name: route_name,
              type_activities: type ,
              project: this.project,
              date: moment().format('YYYY-MM-DD HH:mm:ss'),
              role: this.role
            }

            Rest.post('/user/log-activities', log_activities)
          }
      },
      viewPassword(id) {
        let x = document.getElementById(id)
          if(x.type === "password") {
            x.type = "text"
          }else{
            x.type = "password"
          }
      },
      async init() {
        Rest.get('/user/me/detail')
        .then( async(res) => {
          if(res.data) {
            if(res.data.detail) {
              if(res.data.detail.profile_data) {
                if(res.data.detail.profile_data.date_of_birth) {
                  try {
                    res.data.detail.profile_data.date_of_birth = moment(res.data.detail.profile_data.date_of_birth, 'YYYY-MM-DD').format('DD-MM-YYYY')
                    this.dateConfig = {
                      locale: {
                        format: 'DD-MM-YYYY'
                      }
                    }
                  } catch {}
                }

                if(!res.data.detail.profile_data.employee_type) {
                  res.data.detail.profile_data.employee_type = ''
                }
                if(!res.data.detail.profile_data.salary) {
                  res.data.detail.profile_data.salary = 0
                }

                if(res.data.detail.profile_data.signature) {
                  this.signatureUrl = this.fileUrl+res.data.detail.profile_data.signature
                }

              }
            }
          }
          this.user = res.data
          this.back = {
            name: 'profile',
            params: {
              id: this.user.id
            }
          }
          this.me = {
              id: res.data.id,
              name: res.data.name,
              username: res.data.username,
              detail: res.data.detail
            }
        })
        .catch( (err) => {

        })
      },
      async manualValidation() {
        this.errors.password_old = false
        this.errors.password_new = false
        this.errors.password_new_confirm = false
        let success = true
        if(!this.form.password_old) {
          this.errors.password_old = true
          success = false
        }else{
          let length = this.form.password_old.toString().length
          if(length < 8 || length > 20) {
            this.errors.password_old = true
            success = false
          }
        }

        if(!this.form.password_new) {
          this.errors.password_new = true
          success = false
        }else{
          let length = this.form.password_new.toString().length
          if(length < 8 || length > 20) {
            this.errors.password_new = true
            success = false
          }
        }

        if(!this.form.password_new_confirm) {
          this.errors.password_new_confirm = true
          success = false
        }else{
          let length = this.form.password_new_confirm.toString().length
          if(length < 8 || length > 20) {
            this.errors.password_new_confirm = true
            success = false
          }else{
            if(this.form.password_new != this.form.password_new_confirm) {
              this.errors.password_new_confirm = true
              success = false
            }
          }
        }
        return success
      },
      async doSave() {
        let validate = await this.manualValidation()
        if(validate) {
          Rest.put('/user/me/change-password', this.form)
          .then( (res) => {
            if(res.status == 200) {
              this.saveLogActivities('update', this.me)
              this.$root.showNotification('Berhasil', 'success', res.data.message)
              this.$root.$router.push({
                name: 'profile'
              })
            }else{
              this.$root.showNotification('Gagal', 'error', res.data.message)
            }
          })
          .catch( (err) => {
            this.$root.showNotification('Gagal', 'error', err.response.data.message)
          })
        }
      }
    },
    watch: {
      'form.password_old' : {
        handler(val) {
          this.errors.password_old = false
          if(!val) {
            this.errors.password_old = true
          }else{
            let length = this.form.password_old.toString().length
            if(length < 8 || length > 20) {
              this.errors.password_old = true
            }
          }
        }
      },
      'form.password_new' : {
        handler(val) {
          this.errors.password_new = false
          this.errors.password_new_confirm = false
          if(!val) {
            this.errors.password_new = true
          }else{
            let length = this.form.password_new.toString().length
            if(length < 8 || length > 20) {
              this.errors.password_new = true
            }else{
              if( ( this.form.password_new && this.form.password_new_confirm) &&
                (this.form.password_new != this.form.password_new_confirm)) {
                this.errors.password_new_confirm = true
              }
            }
          }
        }
      },
      'form.password_new_confirm' : {
        handler(val) {
          this.errors.password_new_confirm = false
          if(!val) {
            this.errors.password_new_confirm = true
          }else{
            let length = this.form.password_new_confirm.toString().length
            if(length < 8 || length > 20) {
              this.errors.password_new_confirm = true
            }else{
              if(this.form.password_new != this.form.password_new_confirm) {
                this.errors.password_new_confirm = true
              }
            }
          }
        }
      }
    }
  }
</script>

<style scoped>
.btn-change{
  cursor: pointer;
  background: #ccc !important;
}
.btn-change i{
  cursor: pointer;
  color: #000;
}
</style>
