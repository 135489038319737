<template>
  <box title="test signature">
    <VueSignaturePad
      width="300px"
      height="300px"
      ref="signaturePad"
      :customStyle="customStyle"
      :options="{ onBegin, onEnd }"
    />
    <template v-slot:footer-actions>
      <div class="btn-group">
        <button class="btn btn-primary" @click="save" v-if="!isEmpty">Simpan</button>
        <button class="btn btn-warning" @click="undo" v-if="!isEmpty">Batal</button>
        <button class="btn btn-danger" @click="clear" v-if="!isEmpty">Bersihkan</button>
        <!-- <button class="btn btn-success" @click="showModal">versi modal</button> -->
      </div>
    </template>

    <modal id="modalSignature">
      <signature></signature>
    </modal>
  </box>
</template>

<script>
import Vue from 'vue'
import VueSignaturePad from 'vue-signature-pad'
import Signature from '@pages/Signature.vue'

Vue.use(VueSignaturePad)

const {console} = window
export default {
  name: 'MySignaturePad',


  component: {
    Signature
  },
  data() {
    return {
      customStyle: {
        border: 'black 1px solid'
      },
      isEmpty: true
    }
  },
  methods: {
    changeCanvas() {
      this.isEmpty = this.$refs.signaturePad.isEmpty()
    },
    onBegin() {
      this.changeCanvas()
    },
    onEnd() {
      this.changeCanvas()
    },
    undo() {
      this.$refs.signaturePad.undoSignature();
      this.changeCanvas()
    },
    save() {
      this.changeCanvas()
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature()
      if(data){
        let times = new Date().getTime()
        let filename = 'signature-'+times+'.png'
        let files = this.dataURLtoFile(data, filename)
        console.log(files)
        let params = new FormData()
        params.append('file', files)
        params.append('type', 'signature/coba')
        Rest.post('/md/file-upload', params)
        .then( (res) => {
          console.log('res', res)
          if(res.status == 200) {
            this.$root.showNotification('Berhasil', 'success', 'Sukses simpan tanda tangan')
          }
        })
        .catch( (err) => {
          console.log('upload', err.response.data)
        })
        .finally( () => {

        })
      }else{
        this.$root.showNotification('Gagal', 'error', 'Anda belum tanda tangan')
      }
    },
    dataURLtoFile(dataurl, filename) {
      let arr = dataurl.split(','),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n);

      while(n--){
          u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], filename, {type:mime});
    },
    clear() {
      this.$refs.signaturePad.clearSignature()
      this.changeCanvas()
    },
    showModal() {
      $('#modalSignature').modal('show')
    }
  }
};
</script>
