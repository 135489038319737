<template>
    <div>
        <div class="row">
            <div class="col-md-5" style="background-color: #FFFFFF; height: calc(100vh);">
                <div style="position: absolute; width: 65%; left: 30%; top: 50%; -webkit-transform: translate(-25%, -50%); transform: translate(-25%, -50%);">
                    <div>
                        <p>
                            <span>
                                <img src="@/assets/svg/logo.svg" style="margin-top: -8px"/> 
                            </span>
                            <span style="
                                color:  #06DCF8;
                                text-align: center;
                                font-family: 'Red Hat Display';
                                font-size: 22px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                letter-spacing: 0.3px;
                                padding-left: 10px;">CMS</span>
                        </p>

                    </div>
                    <br>

                    <p style="color: #0A0A0A;
                        font-family: 'Inter';
                        font-size: 24px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 44px; 
                        letter-spacing: -0.704px;">Recovery Password!</p>
                    <p style="color: #757575;
                        font-family: 'Inter';
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 20px; 
                        letter-spacing: -0.084px;">Please enter your new password for a password reset.</p>
                    <br />
                    
                    <form @submit.prevent="submit">
                        <div class="row">
                            <div class="col-md-12 col-xs-12">
                                <div class="form-group"  :class="{'has-error': !!errors.password}">
                                    <label class="control-label">Password *</label>
                                    <input :type="is_password ? 'password' : 'text'" class="form-control password"
                                        id="password"
                                        name="password"
                                        v-model="form.password"
                                        @input="inputChange"
                                    /> 
                                    <span  @click="showHidePassword" class="toggle-password">
                                        <i v-if="is_password">
                                            <img src="@/assets/svg/eye.svg"/>
                                        </i>
                                        <i v-else>
                                            <img src="@/assets/svg/eye-off.svg"/>
                                        </i>
                                    </span>
                                    <p class="help help-block" v-if="errors.password">{{ form.password ? `The password must contain lowercase letters, uppercase letters, numbers, symbols, and be at least eight characters long and at most fifty characters long.` : `Password is required.` }}</p>
                                </div>
                            </div>
                            <div class="col-md-12 col-xs-12">
                                <div class="form-group"  :class="{'has-error': !!errors.password_confirm}">
                                    <label class="control-label">Confirm Password *</label>
                                    <input :type="is_password_confirm ? 'password' : 'text'" class="form-control password_confirm"
                                        id="password_confirm"
                                        name="password_confirm"
                                        v-model="form.password_confirm"
                                        @input="inputChange"
                                    /> 
                                    <span  @click="showHidePasswordConfirm" class="toggle-password">
                                        <i v-if="is_password_confirm">
                                            <img src="@/assets/svg/eye.svg"/>
                                        </i>
                                        <i v-else>
                                            <img src="@/assets/svg/eye-off.svg"/>
                                        </i>
                                    </span>
                                    <p class="help help-block" v-if="errors.password_confirm">{{ password_confirm ? `The confirm password must contain lowercase letters, uppercase letters, numbers, symbols, and be at least eight characters long and at most fifty characters long.` : `Confirm password is required`}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xs-6">
                                <save-button :disabled="is_loading" :loading="is_loading" type="submit"
                                    class="btn-block btn-flat" saveText="Change Password" loadingText="Change Password..."/>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="col-md-7" style="background-color: #F2F3F7; height: calc(100vh);">
                <img width="100%" height="100%" src="@/assets/cms.png"/>
                
            </div>
        </div>
        
        <notifications
            group="notification"
            position="bottom right"
            :max="5"
            :width="500"
            :duration="15000"
        >
            <template slot="body" slot-scope="props">
                <div class="vue-notification-template vue-notification" :class="props.item.type" >
                    <span class="title">
                        <b>{{props.item.title}}</b>
                    </span>
                    <a class="close icon-close-notif" @click="props.close">
                        <img src="@/assets/svg/outline-x.svg" />
                    </a>
                    <div v-html="props.item.text">
                    </div>
                </div>
            </template>
        </notifications>
    </div>
</template>

<script>
import axios from 'axios'
import IconVericalLinearGradient from '@components/svg/IconVericalLinearGradient.vue'
export default {
    components: {
        IconVericalLinearGradient
    },
    data() {
        return {
            form: {
                password: null,
                password_confirm: null,
            },
            errors: {
                password: false,
                password_confirm: false,
            },
            info_code: this.$route.query.info_code,
            is_loading: false,
            is_confirm: false,
            is_loading_confirm: false,
            is_password: true,
            is_password_confirm: true,
            is_change: false,
            icon_width: 841,
            icon_height: 836,
            style_position_vertical_gradient: 'style:position: fixed'
        }
    },
    async mounted() {
        this.is_loading = false
        
        let screen = window.screen
        if(screen) {
            if(screen.width && screen.height) {  
                this.icon_height = screen.height
                // if(screen.width <= 1526) {
                //     this.icon_height = screen.height - (15/100*screen.height)
                // }
                if(screen.width == 1920) {
                    this.icon_height = screen.height - (15.9/100*screen.height)
                    // this.icon_width = this.icon_width + (100/100*this.icon_width)
                }

                if (screen <= 1280) {
                    // this.style_position_vertical_gradient = 'style: positio: relative'
                }

                
            }

            
        } 
    },
    methods: {
        async formValidation() {
            let is_success = true 
            this.errors.password = false 
            this.errors.password_confirm = false 

            if(!this.form.password) {
                this.errors.password = true 
                is_success = false 
            }else {
                let is_valid = this.$root.validationInputPassword(this.form.password.trim())
                if(!is_valid) {
                    this.errors.password = true 
                    is_success = false 
                }
            }

            if(!this.form.password_confirm) {
                this.errors.password_confirm = true 
                is_success = false 
            }else {
                let is_valid = this.$root.validationInputPassword(this.form.password_confirm.trim())
                if(!is_valid) {
                    this.errors.password_confirm = true 
                    is_success = false 
                }
            }

            return is_success
        },
        showHidePassword() {
            this.is_password = !this.is_password
        },
        showHidePasswordConfirm() {
            this.is_password_confirm = !this.is_password_confirm
        },
        async submit() {
            let baseUrl = process.env.VUE_APP_API_URL
            let is_success = await this.formValidation()
            if(is_success) {
                if(this.form.password != this.form.password_confirm) {
                    this.$root.showNotification('Failed', 'error', 'Password and confirm password does not match')
                }else {
                    this.is_loading = true 
                    let info_code = this.info_code.replaceAll(/ /g,"+");

                    let params = {
                        password: this.form.password,
                        confirm_password: this.form.password_confirm,
                        info_code: info_code 
                    }

                    axios.post(baseUrl+'/oauth/forgot-password/recovery-password', params)
                    .then( async(res) => {
                        if(res && res.status == 200) {
                            await this.$root.showNotification('Success', 'success', 'Recovery password successful, please login using your new password')
                            setTimeout(() => this.redirectToLogin(), 3000)
                        }
                    })
                    .catch( (err) => {
                        if(err && err.response && err.response.status) {
                            if(err.response.data && err.response.data.message) {
                                this.$root.showNotification('Failed', 'error', err.response.data.message)        
                            }else {
                                this.$root.showNotification('Failed', 'error', 'Recovery password failed')
                            }
                        }else {
                            this.$root.showNotification('Failed', 'error', 'Sorry, You do not have an internet connection.')
                        }
                    })
                    .finally( (res) => {
                        this.is_loading = false 
                    })
                }
                

            }
        },
        inputChange() {
            this.is_change = true
        },
        redirectToLogin() {
            this.$root.$router.push({name: 'login'})
        }
    },
    computed: {

    },
    watch: {
        'form.password': {
            handler(val) {
                this.errors.password = false 
                if(this.is_change) {
                    if(!val) {
                        this.errors.password = true 
                    }else {
                        let is_valid = this.$root.validationInputPassword(val.trim())
                        if(!is_valid) {
                            this.errors.password = true 
                        }
                    }
                }
            }
        },
        'form.password_confirm': {
            handler(val) {
                this.errors.password_confirm = false 
                if(this.is_change) {
                    if(!val) {
                        this.errors.password_confirm = true 
                    }else {
                        let is_valid = this.$root.validationInputPassword(val.trim())
                        if(!is_valid) {
                            this.errors.password_confirm = true 
                        }
                    }
                }
            }
        }
    }
}
</script>

<style lang="css" scoped>
    @import url('https://fonts.googleapis.com/css?family=Inter');
    @import url('https://fonts.googleapis.com/css?family=Red+Hat+Display');
</style>

<style lang="scss" scoped>
    .vue-notification {
        padding: 10px;
        margin: 0 5px 5px;
        
        font-size: 12px;
        
        color: #ffffff;
        background: #44A4FC;
        border-left: 5px solid #187FE7;

        &.title {
            color: #ffffff;
        }
        
        &.warn {
            background: #ffb648;
            border-left-color: #f48a06;
        }
        
        &.error {
            background: #E54D42;
            border-left-color: #B82E24;
        }
        
        &.success {
            background: #68CD86;
            border-left-color: #42A85F;
        }

        .icon-close-notif {
            opacity: 0.9 !important;
            color: #FFFFFF !important;
        }

        .icon-close-notif:hover,
        .icon-close-notif:focus {
            opacity: 1 !important;
            color: #FFFFFF !important;
        }
    }
</style>
