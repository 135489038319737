<template>
    <div :style="{'width': width}">
        <div 
            class="css-1dm3qsp">
            <input type="text" 
                :id="idName" 
                :value="localValue" 
                :min="min"
                :max="max"
                :step="step"
                :precision="precision"
                :disabled="disabled"
                :name="name"
                :class="classValue"
                autocomplete="off" 
                @input="restrictDecimal"
                @blur="onBlur"
                @keydown="onKeydown($event)"
                class="css-jsi8ta __input-decimal-standar">
            <div class="css-1uobyq7">
                <div class="css-1jns1zg" 
                    id="__input-number-step-up-button" 
                    @click="stepUpButton">
                    <svg viewBox="0 0 24 24" 
                        role="presentation" 
                        class="css-5ffdf css-3vopgu css-4oct3o">
                        <path fill="currentColor" d="M12.8,5.4c-0.377-0.504-1.223-0.504-1.6,0l-9,12c-0.228,0.303-0.264,0.708-0.095,1.047 C2.275,18.786,2.621,19,3,19h18c0.379,0,0.725-0.214,0.895-0.553c0.169-0.339,0.133-0.744-0.095-1.047L12.8,5.4z"></path>
                    </svg>
                </div>
                <div class="css-1jns1zg" 
                    id="__input-number-step-down-button" 
                    @click="stepDownButton">
                    <svg viewBox="0 0 24 24" role="presentation" class="css-5ffdf css-3vopgu css-4oct3o">
                        <path fill="currentColor" d="M21,5H3C2.621,5,2.275,5.214,2.105,5.553C1.937,5.892,1.973,6.297,2.2,6.6l9,12 c0.188,0.252,0.485,0.4,0.8,0.4s0.611-0.148,0.8-0.4l9-12c0.228-0.303,0.264-0.708,0.095-1.047C21.725,5.214,21.379,5,21,5z"></path>
                    </svg>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// const {console} = window
var defaultMin = '0'
var defaultMax = '100'
var defaultStep = '0.01'
var defaultClass = 'input-number-decimal'
var defaultId = 'percent-decimal-input'
var defaultWidth = '150px'
var defaultValue = 0
export default {
    props: {
        min: {
            type: String,
            default: function() {
                return defaultMin
            }
        },
        max: {
            type: String,
            default: function() {
                return defaultMax
            }
        },
        step: {
            type: String,
            default: function() {
                return defaultStep
            }
        },
        value: {
            type: undefined,
            default: function() {
                return 0
            }
        },
        classValue: {
            type: String,
            default: function() {
                return defaultClass
            }
        },
        id: {
            type: String,
            default: function() {
                return defaultId
            }
        },
        width: {
            type: String,
            default: function() {
                return defaultWidth
            }
        },
        name: {
            type: String,
            default: function() {
                return 'input-number-decimal'
            }
        },
        disabled: {
            type: Boolean,
            default: function() {
                return false
            }
        }
    }, 
    data: function() {
        return {
            inputType: 'number',
            localValue: this.value ? this.value : 0,
            originValue: this.value ? this.value : 0,
            locked: false,
            idName: this.id ? this.id : defaultId,
            lang: 'en',
            is_input_comma: false,
        }
    },
    computed: {
        pattern() {
            var step = this.step
            var splitComma = step.split('.')
            var defaultPattern = /^\d+\.?\d{0,2}/
            var pattern = defaultPattern
            var precision = 0
            if (splitComma.length > 1) {
                var afterComma = splitComma[1]
                var afterCommaLength = afterComma.length
                precision = afterCommaLength
                switch (afterCommaLength) {
                    case 1:
                        pattern = /^\d+\.?\d{0,1}/
                        break;
                    case 2:
                        pattern = /^\d+\.?\d{0,2}/
                        break;
                    case 3:
                        pattern = /^\d+\.?\d{0,3}/
                        break;
                    case 4:
                        pattern = /^\d+\.?\d{0,4}/
                        break;
                    case 5:
                        pattern = /^\d+\.?\d{0,5}/
                        break;
                    case 6:
                        pattern = /^\d+\.?\d{0,6}/
                        break;
                    case 7:
                        pattern = /^\d+\.?\d{0,7}/
                        break;
                    case 8:
                        pattern = /^\d+\.?\d{0,8}/
                        break;
                    case 9:
                        pattern = /^\d+\.?\d{0,9}/
                        break;
                    case 10:
                        pattern = /^\d+\.?\d{0,10}/
                        break;
                    case 11:
                        pattern = /^\d+\.?\d{0,11}/
                        break;
                    case 12:
                        pattern = /^\d+\.?\d{0,12}/
                        break;
                    default:
                        break;
                }
            }

            return {
                pattern: pattern,
                precision: precision
            }
        },
        precision() {
            return this.pattern.precision
        }
    },
    mounted() {
       
    },
    methods: {
        init: function() {
            this.idName = this.id
            if (this.value != this.originValue) {
                this.originValue = this.value
                this.localValue = this.value
            }
        },
        stepUpButton() {
            if(!this.disabled) {
                this.is_input_comma = false 
                var id = '#'+this.idName
                var value = $(id).val()
                var x = defaultValue
                if (value) {
                    x = parseFloat(value)
                }

                x = x + parseFloat(this.step)
                if (x < parseFloat(this.min)) {
                    x = parseFloat(this.min)
                }

                if (parseFloat(x) > parseFloat(this.max)) {
                    x = parseFloat(this.max)
                }

                x = x.toFixed(this.precision)
                x = Number(x)
                $(id).val(x)
                this.localValue = x
            }
        },
        stepDownButton() {
            if(!this.disabled) {
                this.is_input_comma = false 
                var id = '#'+this.idName
                var value = $(id).val()
                var x = defaultValue
                if (value) {
                    x = parseFloat(value)
                }

                x = x - parseFloat(this.step)
                if (x < parseFloat(this.min)) {
                    x = parseFloat(this.min)
                }

                if (parseFloat(x) > parseFloat(this.max)) {
                    x = parseFloat(this.max)
                }

                x = x.toFixed(this.precision)
                x = Number(x)
                $(id).val(x)
                this.localValue = x 
            }
        },
        async restrictDecimal() {
            if(!this.is_input_comma) {
                var is_max_precision = false 
                var after_comma_length = 0
                var isNullComma = false 
                var id = '#' + this.idName
 
                var value = $(id).val()
                var x_str = defaultValue.toString()
                if (value) {
                    x_str = value 
                }

                if(parseFloat(x_str) < parseFloat(this.min)) {
                    x_str = this.min.toString()
                }

                if(parseFloat(x_str) > parseFloat(this.max)) {
                    x_str = this.max.toString()
                }

                if(x_str) {

                    var first_chart = x_str.charAt(0)
                    if (first_chart == '.') {
                        x_str = '0' + x_str
                    }

                    if (x_str.indexOf('.') >= 0) {
                        var commaSplit = x_str.split('.')
                        if(commaSplit.length > 1) {
                            var afterComma = commaSplit[1]
                            var lastChar = afterComma[afterComma.length - 1]
                            var lengthOfAfterComma = afterComma.length 
                            after_comma_length = lengthOfAfterComma
                            if(lastChar == '0') {
                                isNullComma = true 
                                if(lengthOfAfterComma >= this.precision) {
                                    is_max_precision = true
                                }
                            }
                            
                        }
                    }
                }

                var pattern = this.pattern.pattern
                if(!isNullComma) {
                    x_str = x_str.match(pattern)

                    var x = parseFloat(x_str)
                    x = Number(x)

                    $(id).val(x)
                    this.localValue = x 
                }else {
                    if(is_max_precision) {
                        x_str = x_str.match(pattern)

                        var x = parseFloat(x_str)
                        x = Number(x)
                        x = x.toFixed(this.precision)

                        $(id).val(x)
                        this.localValue = x 
                    }else {
                        x_str = x_str.match(pattern)
                        var x = parseFloat(x_str)
                        x = x.toFixed(after_comma_length)

                        $(id).val(x)
                        this.localValue = x 
                    }
                }
            }
        },
        onKeydown: function(e) {
            this.is_input_comma = false
            if (e) {
                var id = '#'+ this.idName
                
                var value = $(id).val()
                var x = defaultValue
                if (value) {
                    x = parseFloat(value)
                }
                if(e.keyCode == 190) {
                    this.is_input_comma = true 
                    if(value.toString().indexOf('.') >= 0) {
                        e.preventDefault();
                        return 
                    }
                   
                }else if (e.keyCode == 38) {
                    x = parseFloat(x) + parseFloat(this.step)
                    if (parseFloat(x) > parseFloat(this.max)) {
                        x = parseFloat(this.max)
                    }

                    if (parseFloat(x) < parseFloat(this.min)) {
                        x = parseFloat(this.min)
                    }

                    x = x.toFixed(this.precision)
                    x = Number(x)

                    $(id).val(x)
                    this.localValue = x
                    e.preventDefault();
                    return;
                }else if(e.keyCode == 40) {
                    x = parseFloat(x) - parseFloat(this.step)
                    if (parseFloat(x) > parseFloat(this.max)) {
                        x = parseFloat(this.max)
                    }

                    if (parseFloat(x) < parseFloat(this.min)) {
                        x = parseFloat(this.min)
                    }

                    x = x.toFixed(this.precision)
                    x = Number(x)

                    $(id).val(x)
                    this.localValue = x
                    e.preventDefault();
                    return;
                }else if ($.inArray(e.keyCode, [46, 8, 9, 27, 13, 110, 190]) !== -1 ||
                    // Allow: Ctrl+A, Command+A
                    (e.keyCode === 65 && (e.ctrlKey === true || e.metaKey === true)) ||
                    // Allow: home, end, left, right, down, up
                    (e.keyCode >= 35 && e.keyCode <= 40)) {
                    // let it happen, don't do anything
                    return;
                }else if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
                    e.preventDefault();
                }
            }
        },
        onBlur() {
            this.$emit('input', Number(this.localValue))
            this.$emit('onChange', this.localValue)
        }

    },
    watch: {
        value: function() {
            this.init()
        },
        min: function() {
            this.init()
        },
        max: function() {
            this.init()
        },
        step: function() {
            this.init()
        },
        classValue: function() {
            this.init()
        },
        idValue: function() {
            this.init()
        },
        localValue: function(val, oldVal) {
            if(val != oldVal) {
                var id = '#'+this.idName
                var x_str = val.toString()
                var after_comma_length =  0
                if (x_str.indexOf('.') >= 0) {
                    var splitComma = x_str.split('.')
                    if(splitComma.length > 1) {
                        after_comma_length = splitComma[1].length
                    }
                }

                var x = parseFloat(x_str)
                x = Number(x)
                if(after_comma_length > 0) {
                    x = x.toFixed(after_comma_length)
                }

                $(id).val(x)
                this.localValue = x
                this.$emit('input', x)
                this.$emit('onChange', x)
            }
        }
    }
}
</script>

<style lang="css" scoped>
    .css-6ble15 {
        width: 100%;
        height: calc(100vh - 60px);
        overflow-y: scroll;
        /* padding-top: var(--chakra-space-5); */
        /* padding-bottom: var(--chakra-space-5); */
        /* -webkit-padding-start: var(--chakra-space-10); */
        /* padding-inline-start: var(--chakra-space-10); */
        /* -webkit-padding-end: var(--chakra-space-10); */
        /* padding-inline-end: var(--chakra-space-10); */
        /* font-family: var(--chakra-fonts-body); */
    }
        
    /* .css-pp1tfm {
        font-family: var(--chakra-fonts-body);
        background: var(--chakra-colors-white);
        color: var(--chakra-colors-black);
    }
        
    .css-jsi8ta:hover,
    .css-jsi8ta[data-hover] {
        border-color: var(--chakra-colors-gray-300);
    } */
        
    .css-jsi8ta {
        width: 100%;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-align-items: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        position: relative;
        -webkit-transition: all .2s;
        transition: all .2s;
        outline: 2px solid transparent;
        outline-offset: 2px;
        -webkit-appearance: none;
        -moz-appearance: none;
        -ms-appearance: none;
        appearance: none;
        /* font-size: var(--chakra-fontSizes-md);
        -webkit-padding-start: var(--chakra-space-4);
        padding-inline-start: var(--chakra-space-4);
        -webkit-padding-end: var(--chakra-space-4);
        padding-inline-end: var(--chakra-space-4);
        height: var(--chakra-sizes-10); */
        line-height: 2.6rem;
        /* border-radius: var(--chakra-radii-md); */
        border-width: 1px;
        border-color: inherit;
        /* background: var(--chakra-colors-white); */
        /* font-family: var(--chakra-fonts-body); */
        padding-left: 10px;
    }
        
    button,
    input,
    optgroup,
    select,
    textarea {
        padding: 1px 2px;
        line-height: inherit;
        color: inherit;
    }
    
    .css-1jns1zg:first-of-type {
        border-top-right-radius: 3px;
    }
        
    .css-1jns1zg {
        font-size: 15px;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-align-items: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1;
        -webkit-transition: all .3s;
        transition: all .3s;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        /* line-height: var(--chakra-lineHeights-normal); */
        /* border-left: var(--chakra-borders-1px); */
        border-color: inherit;
    }
        
    .css-5ffdf:not(:root) {
        overflow: hidden;
    }
        
    .css-4oct3o {
        height: 0.6em;
        width: 0.6em;
    }
        
    .css-3vopgu {
        width: 0.68em;
        height: 0.68em;
        display: inline-block;
        vertical-align: middle;
        color: #555;
    }
        
    .css-5ffdf {
        -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
    }
        
    .css-1dm3qsp {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-align-items: stretch;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        position: relative;
    }
        
    .css-1uobyq7 {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        /* display: none; */
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        width: 24px;
        margin: 1px;
        position: absolute;
        right: 0;
        height: calc(100% - 2px);
        z-index: 1;
    }
        
    *,
        ::after,
        ::before {
        border-width: 0;
        border-style: solid;
        border-color: #e2e8f0;
    }
    
    .css-1jns1zg:last-of-type {
        border-bottom-right-radius: 3px;
        margin-top: -1px;
        border-top-width: 1px;
    }
    /* .__input-decimal-standar:focus+.css-1uobyq7 {
        display: flex
    } */
    /* .__input-decimal-standar:hover+.css-1uobyq7 {
        display: flex
    } */

    .__input-decimal-standar:focus {
        border-color: #66afe9;
    }

    .__input-decimal-standar:not(select) {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        outline: none
    }
</style>