import Vue from 'vue'

const MultilineTextValidate = {
    name: 'MultilineTextValidateItem',
    props: ['formId', 'formName', 'value', 'validation', 'formPlaceholderName', 'formLabel', 'formDisabled'],
    template:
        `<div :class="validation ? 'form-group has-error' : 'form-group'">
            <label>{{formLabel}}</label>
            <textarea
                v-bind:id="formId"
                v-bind:name="formName"
                class="form-control"
                v-bind:value="value"
                v-on:input="$emit('input', $event.target.value)"
                v-bind:placeholder="formPlaceholderName"
                v-bind:disabled="formDisabled"
                rows="8"
            />
            <div class="help-block">{{ validation }}</div>
        </div>`,
    data() {
        return {
        }
    },
    mounted(){
    },
    methods: {
    }
}
Vue.component('MultilineTextValidateItem', MultilineTextValidate)
export default MultilineTextValidate
