<template>
    <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" :viewBox="viewBox" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M21.5999 12.0004C21.5999 17.3023 17.3018 21.6004 11.9999 21.6004C6.69797 21.6004 2.3999 17.3023 2.3999 12.0004C2.3999 6.69846 6.69797 2.40039 11.9999 2.40039C17.3018 2.40039 21.5999 6.69846 21.5999 12.0004ZM13.1999 7.20039C13.1999 7.86313 12.6626 8.40039 11.9999 8.40039C11.3372 8.40039 10.7999 7.86313 10.7999 7.20039C10.7999 6.53765 11.3372 6.00039 11.9999 6.00039C12.6626 6.00039 13.1999 6.53765 13.1999 7.20039ZM10.7999 10.8004C10.1372 10.8004 9.5999 11.3376 9.5999 12.0004C9.5999 12.6631 10.1372 13.2004 10.7999 13.2004V16.8004C10.7999 17.4631 11.3372 18.0004 11.9999 18.0004H13.1999C13.8626 18.0004 14.3999 17.4631 14.3999 16.8004C14.3999 16.1377 13.8626 15.6004 13.1999 15.6004V12.0004C13.1999 11.3376 12.6626 10.8004 11.9999 10.8004H10.7999Z" :fill="fill"/>
    </svg>
</template>

<script>
export default {
    props: {
        width: {
            type: [String, Number],
            default: '24'
        },
        height: {
            type: [String, Number],
            default: '24'
        },
        fill: {
            type: String,
            default: '#18A891'
        },
    },
    data() {
        return {
            viewBox: '0 0 24 24'
        }
    },
    mounted() {
        this.viewBox = '0 0 '+this.width+ ' '+this.height
    },
    watch: {
        'width': {
            handler(val) {
                if(val) {
                    this.viewBox = '0 0 '+val+ ' '+this.height
                }
            }
        },
        'height': {
            handler(val) {
                if(val) {
                    this.viewBox = '0 0 '+this.width+' '+val
                }
            }
        }
    }
}
</script>

<style scoped>

</style>