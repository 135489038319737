<template>
    <div>
        <div class="row">
            <div class="col-md-5" style="background-color: #FFFFFF; height: calc(100vh);">
                <div style="position: absolute; width: 65%; left: 30%; top: 50%; -webkit-transform: translate(-25%, -50%); transform: translate(-25%, -50%);">
                    <div>
                        <p>
                            <span>
                                <img src="@/assets/svg/logo.svg" style="margin-top: -8px"/> 
                            </span>
                            <span style="
                                color:  #06DCF8;
                                text-align: center;
                                font-family: 'Red Hat Display';
                                font-size: 22px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                letter-spacing: 0.3px;
                                padding-left: 10px;">CMS</span>
                        </p>

                    </div>
                    <br>

                    <p style="color: #0A0A0A;
                        font-family: 'Inter';
                        font-size: 24px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 44px; 
                        letter-spacing: -0.704px;">Forgot Password!</p>
                    <p style="color: #757575;
                        font-family: 'Inter';
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 20px; 
                        letter-spacing: -0.084px;">Please enter your email for a password reset request.</p>
                    <br />
                   
                    <form @submit.prevent="submit">
                        <div class="form-group" :class="{'has-error': !!errors.email}" id="forgot-password-email" style="margin-top: -15px">
                            <input 
                                class="form-control email" 
                                type="email" 
                                name="email" 
                                id="email" 
                                placeholder="Masukkan Email"
                                v-model="form.email"
                                maxlength="50"
                            />
                            <p class="help help-block" v-if="errors.email">Email must be at least seven characters, maximum fifty characters.</p>
                        </div>
                       
                        <div class="row">
                            <div class="col-xs-6">
                            </div>
                            <div class="col-xs-6">
                                <div class="float-right">
                                    <router-link :to="{name: 'login'}">Login</router-link>
                                </div>
                            </div>
                            
                        </div>
                        <div class="row">
                            <div class="col-xs-6">
                                <save-button :disabled="is_loading || (!form.email )" :loading="is_loading" type="submit"
                                    class="btn-block btn-flat" saveText="Forgot Password" loadingText="Forgot Password..."/>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="col-md-7" style="background-color: #F2F3F7; height: calc(100vh);">
                <img width="100%" height="100%" src="@/assets/cms.png"/>
                
            </div>
        </div>
        
        <notifications
            group="notification"
            position="bottom right"
            :max="5"
            :width="500"
            :duration="15000"
        >
            <template slot="body" slot-scope="props">
                <div class="vue-notification-template vue-notification" :class="props.item.type" >
                    <span class="title">
                        <b>{{props.item.title}}</b>
                    </span>
                    <a class="close icon-close-notif" @click="props.close">
                        <img src="@/assets/svg/outline-x.svg" />
                    </a>
                    <div v-html="props.item.text">
                    </div>
                </div>
            </template>
        </notifications>
    </div>
</template>

<script>
import axios from 'axios'
import IconVericalLinearGradient from '@components/svg/IconVericalLinearGradient.vue'
export default {
    components: {
        IconVericalLinearGradient
    },
    data() {
        return {
            form: {
                email: null
            },
            errors: {
                email: false
            },
            is_loading: false,
            icon_width: 841,
            icon_height: 836,
            style_position_vertical_gradient: 'style:position: fixed',
            is_success: false,
        }
    },
    async mounted() {
        this.is_loading = false 

        let screen = window.screen
        if(screen) {
            if(screen.width && screen.height) {  
                this.icon_height = screen.height
                // if(screen.width <= 1526) {
                //     this.icon_height = screen.height - (15/100*screen.height)
                // }
                if(screen.width == 1920) {
                    this.icon_height = screen.height - (15.9/100*screen.height)
                    // this.icon_width = this.icon_width + (100/100*this.icon_width)
                }

                if (screen <= 1280) {
                    // this.style_position_vertical_gradient = 'style: positio: relative'
                }

                
            }

            
        }
    },
    methods: {
        async formValidation() {
            this.errors.email = false 
            let is_success = true 
            if(!this.form.email) {
                this.errors.email = true  
                is_success = false 
            }else {
                let is_valid_email = this.$root.validationInputEmail(this.form.email)
                if(!is_valid_email) {
                    this.errors.email = true 
                    is_success = false
                }else{
                    let email = this.form.email.trim()
                    if(email.length < 7 || email.length > 50) {
                        this.errors.email = true 
                        is_success = false
                    }
                }
            }

            return is_success
        },
        async submit() {
            this.is_success = false
            let baseUrl = process.env.VUE_APP_API_URL
            let is_success = await this.formValidation()
            if(is_success) {
                this.is_loading = true 

                let params = {
                    email: this.form.email,
                    is_using_url: true 
                }

                axios.post(baseUrl+'/oauth/forgot-password/request', params)
                .then ( async(res) => {
                    if(res && res.status == 200) {
                        this.$root.showNotification('Success', 'success', 'Forgot password request successful, please check your email')
                    }
                    this.is_success = true 
                    this.form.email = null 
                })
                .catch( (err) => {
                    if(err && err.response && err.response.status) {
                        if(err.response.data && err.response.data.message) {
                            this.$root.showNotification('Failed', 'error', err.response.data.message)        
                        }else {
                            this.$root.showNotification('Failed', 'error', 'Forgot password request.')
                        }
                    }else {
                        this.$root.showNotification('Failed', 'error', 'Sorry, You do not have an internet connection.')
                    }
                })
                .finally( () => {
                    this.is_loading = false
                })

            }
        }
    },
    computed: {

    },
    watch: {
        'form.email': {
            handler(val) {
                this.errors.email = false 
                if(!this.id) {
                    if(!val) {
                        if(!this.is_success) {
                            this.errors.email = true
                        } 
                    }else {
                        this.is_success = false
                        let is_valid = this.$root.validationInputEmail(val)
                        if(!is_valid) {
                            this.errors.email = true
                        }else{
                            let value = val.trim()
                            if(value.length < 7 || value.length > 50) {
                                this.errors.email = true
                            }
                        }
                    }
                }
            }
        },
    }
}
</script>

<style lang="css" scoped>
    @import url('https://fonts.googleapis.com/css?family=Inter');
    @import url('https://fonts.googleapis.com/css?family=Red+Hat+Display');
</style>

<style lang="scss" scoped>
    .vue-notification {
        padding: 10px;
        margin: 0 5px 5px;
        
        font-size: 12px;
        
        color: #ffffff;
        background: #44A4FC;
        border-left: 5px solid #187FE7;

        &.title {
            color: #ffffff;
        }
        
        &.warn {
            background: #ffb648;
            border-left-color: #f48a06;
        }
        
        &.error {
            background: #E54D42;
            border-left-color: #B82E24;
        }
        
        &.success {
            background: #68CD86;
            border-left-color: #42A85F;
        }

        .icon-close-notif {
            opacity: 0.9 !important;
            color: #FFFFFF !important;
        }

        .icon-close-notif:hover,
        .icon-close-notif:focus {
            opacity: 1 !important;
            color: #FFFFFF !important;
        }
    }
</style>
