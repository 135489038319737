const LoadingButton = {
    name: 'loading-button',
    props: {
        loading: {
            type: Boolean,
            required: false,
            default: null
        },
        saveText: {
            type: String,
            default: 'Save'
        },
        loadingText: {
            type: String,
            default: 'Please wait...'
        },
        icon: {
            type: String,
            default: 'fa-check'
        },
        spinIcon: {
            type: String,
            default: 'fa-spin fa-spinner'
        },
        click: {
            type: Function,
            required: false
        },
        'click.prevent': {
            type: Function,
            required: false
        },
        classBtn: {
            type: String,
            default: 'btn-primary',
            required: false
        }
    },
    template: '<button type="button" :class="\'btn border-radius-8px show-action \'+ classBtn" @click="onClick" :disabled="loading">\
        <i class="show-action fa" :class="buttonIcon"></i>\
        {{ loadingButton ? loadingText : saveText }}\
    </button>',
    computed: {
        buttonIcon() {
            let icons = {}
            icons[this.icon] = !this.loading
            icons[this.spinIcon] = this.loading

            return icons
        },
        loadingButton() {
            var loading = false
            try {
                loading = this.$store.state.loading
            } catch (e) {
                loading = this.loading == true
            }

            return loading
        }
    },
    methods: {
        onClick(e) {
            this.$emit('click', e)
        }
    }
}
export default LoadingButton