<template>
    <div>
        <header-title-button
            title="User Role"
            textAdd="Add Role"
            :isAdd="permission_create"
            @onClickAdd="add">
        </header-title-button>
        <datatable 
            :config="config" 
            :class="datatable_class"
            :settingFilters="settingFilters"
            :defaultFilter="defaultFilter"
        />

        <modal 
            title="Confirm Deletion" 
            save="true" 
            remove="false"
            :show="is_confirm_delete"
            :loading="is_confirm_delete_loading"
            saveText="Yes, delete"
            @hidden="is_confirm_delete = false"
            @onSave="doDeleteRole()">
            <span class="span-title">Are you sure you want to delete the access permission <b>{{form.name}} </b> ?</span>
        </modal>
    </div>
    
</template>

<script>
// import func from 'vue-editor-bridge'
export default {
    data() {
        return {
            config: {},
            settingFilters: '',
            defaultFilter: null,
            alias: 'user.role',
            permission_type: 'create',
            permission_create: false,
            permission_edit: false,
            permission_delete: false,
            is_confirm_delete: false,
            is_confirm_delete_loading: false,
            form: {
                id: null,
                name: null
            },
            user: this.$root.$store.state.user || null,
        }
    },
    computed: {
        datatable_class() {
            let class_name = 'superuser-datatable'
            if(this.user) {
                if(this.user.company_id) {
                    class_name = 'owner-datatable'
                }
            }

            return class_name
        }
    },
    async mounted() {
        this.$root.checkingAccessPage(this.$root._route.name)
        let permission = await this.$root.checkingButtonsPage(this.alias, this.permission_type)
        let permissionEdit = await this.$root.checkingButtonsPage(this.alias, 'update')
        let permissionDelete = await this.$root.checkingButtonsPage(this.alias, 'delete')
        if(permission.validate) {
            this.permission_create = true 
        }

        if(permissionEdit.validate) {
            this.permission_edit = true 
        }

        if(permissionDelete.validate) {
            this.permission_delete = true 
        }

        if(!this.user) {
            if(!this.user) {
                let userMe = localStorage.getItem('_USER_ME')
                if(userMe) {
                    let userMeBytes = this.CryptoJS.AES.decrypt(userMe, process.env.VUE_APP_KEY_ENCRYPT_DECRYPT)
                    let userMeData = JSON.parse(userMeBytes.toString(this.CryptoJS.enc.Utf8))
                    if(userMeData) {
                    this.user = userMeData
                    this.$root.$store.state.user = userMeData
                    }
                }
            }
        }

        this.init()

      let self = this
      $(document).ready(function() {
        $(document).on('click', '.click-view-role', function(e) {
            e.preventDefault();
            if (self.isDoubleClicked($(this))) return;
            let data = $(this).attr('data')
            self.redirectToForm(data, false)
        })
        
        $(document).on('click', '.click-edit-role', function(e) {
            e.preventDefault();
            if (self.isDoubleClicked($(this))) return;
            let data = $(this).attr('data')
            self.redirectToForm(data, true)
        })

        $(document).on('click', '.click-delete-role', function(e) {
            e.preventDefault();
            let data = $(this).attr('data')
            let name = $(this).attr('data-name')
            if(self.permission_delete) {
                self.form.id = data 
                self.form.name = name 

                self.is_confirm_delete = true
                this.is_confirm_delete_loading = false
            }
            
        })
      })
    },
    methods: {
        init() {
            let self = this 
            let filter =  [["name", "NOT IN", ["ROLE_USER"]]]

            this.defaultFilter = filter
            this.config = {
                columns: [{
                    title: 'Role Name',
                    data: 'name'
                }, {
                    title: 'No. of User',
                    data: 'total_user'
                }, {
                    title: 'Action',
                    data: 'id',
                    searchable: false,
                    orderable: false,
                    render(value, type, row) {
                        return self.renderAction(row)
                    }
                }],
                pageStartAt: 0,
                searchPlaceholder:"Search an user role ...",
                url: Api.base + '/role'
            }
        },
        add() {
            this.$root.$router.push({name: 'master.role.form.create'})
        },
        click(row) {
            if(row.data()) {
                this.$root.$router.push({name: 'master.role.form', params: row.data()})
            }
        },
        renderAction(row) {
            let viewButton = `<span style="margin: 0px 15px 0px 0px" class="click-view-role" data="${row.id}"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path d="M12.5 10.0002C12.5 11.3809 11.3807 12.5002 10 12.5002C8.6193 12.5002 7.50001 11.3809 7.50001 10.0002C7.50001 8.61949 8.6193 7.5002 10 7.5002C11.3807 7.5002 12.5 8.61949 12.5 10.0002Z" stroke="#757575" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M2.04855 10.0002C3.11045 6.61927 6.26902 4.16687 10.0004 4.16687C13.7318 4.16687 16.8903 6.6193 17.9522 10.0002C16.8903 13.3811 13.7318 15.8335 10.0004 15.8335C6.26902 15.8335 3.11043 13.3811 2.04855 10.0002Z" stroke="#757575" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg></span>`

            let editButton = `<span style="margin: 0px 15px 0px 0px" class="click-edit-role" data="${row.id}"><svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                    <path d="M13.1221 4.36019L16.0684 7.30647M14.3721 3.11019C15.1857 2.2966 16.5048 2.2966 17.3184 3.11019C18.132 3.92379 18.132 5.24288 17.3184 6.05647L5.8453 17.5296H2.92863V14.5537L14.3721 3.11019Z" stroke="#404040" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg></span>`
            let deleteButton = `<span class="click-delete-role" data="${row.id}" data-name="${row.name}"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M19 7L18.1327 19.1425C18.0579 20.1891 17.187 21 16.1378 21H7.86224C6.81296 21 5.94208 20.1891 5.86732 19.1425L5 7M10 11V17M14 11V17M15 7V4C15 3.44772 14.5523 3 14 3H10C9.44772 3 9 3.44772 9 4V7M4 7H20" stroke="#404040" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg></span>`
            let show = viewButton
            if(this.permission_edit) {
                show += editButton
            }

            if(this.permission_delete) {
                show += deleteButton
            }

            let button = `<div>`
            button += show
            button += `</div>`

            return button
        },
        redirectToForm(id, is_edit) {
            if(id) {
                this.$root.$router.push(
                    {
                        name: 'master.role.form', 
                        params: {
                            id: id
                        },
                        query: {
                            is_edit: is_edit
                        }
                    }
                )
            }
            
        },
        isDoubleClicked(element) {
            //if already clicked return TRUE to indicate this click is not allowed
            if (element.data("isclicked")) return true;

            //mark as clicked for 1 second
            element.data("isclicked", true);
            setTimeout(function () {
                element.removeData("isclicked");
            }, 1000);

            //return FALSE to indicate this click was allowed
            return false;
        },
        async doDeleteRole() {
            if (this.permission_delete && this.form.id) {
                this.is_confirm_delete_loading = true
                Rest.delete('/role/'+this.form.id)
                .then( async(res) => {
                    if (res && res.status && res.status == 200) {
                        this.$root.showNotification('Success', 'success', 'Berhasil menghapus hak akses')

                        this.userLogActivity()
                        this.init()
                    }else {
                        this.$root.showNotification('Success', 'error', 'Gagal menghapus hak akses')
                    }
                })
                .catch( (err) => {
                    if(err && err.response && err.response.status && err.response.data && err.response.data.message) {
                        this.$root.showNotification('Failed', 'error', err.response.data.message)
                    }
                })
                .finally( () => {
                    this.is_confirm_delete_loading = false 
                    this.is_confirm_delete = false
                })
            }
            
        },
        async userLogActivity() {
            if(this.$root.$store.state.user && this.$root.$store.state.user.name) {
                let activity_type = 'delete'
                let activity = `${this.$root.$store.state.user.name} telah menghapus hak akses dengan nama ${this.form.name}`

                let params = {
                    activity: activity,
                    feature: 'Role',
                    activity_type: activity_type,
                }

                this.$root.sendUserLogActivity(params)
            }
            
        },   
    }
}
</script>
