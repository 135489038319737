<template>
    <div class="show-action">
        <button v-if="isDoClose" class="close show-action" data-dismiss="modal" v-on:click="doCloseModal">&times;</button>
        <button v-else class="close show-action" data-dismiss="modal">&times;</button>
    </div>
</template>

<script> 
    export default{
        props: {
            isDoClose: {
                type: [String, Boolean],
                default: null,
                required: false  
            }
        },
        methods: {
            doCloseModal(e) {
                this.$emit('onCloseModal', e)
            }
        }
    }
</script>