<template>
    <svg :width="width" :height="height" viewBox="0 0 24 22" :fill="fill" xmlns="http://www.w3.org/2000/svg">
        <path d="M23.53 0.964402C23.1439 0.610637 22.5952 0.492746 22.0978 0.656668L0.995214 7.61296C0.420104 7.80253 0.0301044 8.3159 0.00165128 8.92073C-0.026755 9.52561 0.313323 10.0733 0.868042 10.3161L6.08687 12.5995C6.11232 12.7176 7.6105 19.6662 7.66145 19.9024C7.72684 20.2057 7.88082 20.476 8.20703 20.5591C8.53698 20.6431 8.77009 20.4628 9.01628 20.2785C9.15329 20.1759 12.8891 17.3786 12.8891 17.3786L17.4167 21.0835C17.6804 21.2994 18.0027 21.412 18.3314 21.412C18.4878 21.412 18.6458 21.3865 18.7995 21.3344C19.276 21.1728 19.6292 20.7839 19.7444 20.2941L23.9616 2.36428C24.0814 1.85451 23.9162 1.31817 23.53 0.964402ZM9.35364 13.9079C9.35106 13.914 9.34853 13.9209 9.34614 13.9291L8.47314 16.9792L7.49889 12.4607L14.1981 8.73857L9.51432 13.636C9.44054 13.7131 9.38626 13.8064 9.35364 13.9079ZM9.63071 18.0575L10.0272 16.6721L10.4065 15.347L11.7654 16.4591L9.63071 18.0575ZM22.5894 2.0415L18.3723 19.9713C18.3702 19.9802 18.3674 19.9925 18.3468 19.9994C18.3264 20.0064 18.3164 19.9985 18.3094 19.9927L13.3551 15.9385C13.355 15.9384 13.3549 15.9383 13.3547 15.9382L11.0593 14.0598L18.4338 6.34898C18.6747 6.09712 18.6953 5.70707 18.4824 5.43121C18.2693 5.15531 17.8867 5.07651 17.5822 5.24582L6.66259 11.3128L1.43307 9.0247C1.41714 9.01776 1.40837 9.01387 1.40964 8.98692C1.4109 8.96006 1.42 8.95701 1.4365 8.95157L22.5391 1.99532C22.5493 1.992 22.5608 1.98815 22.5778 2.00362C22.5947 2.01918 22.5919 2.031 22.5894 2.0415Z" :fill="fill_path"/>
    </svg>
</template>

<script>
export default {
    props: {
        width: {
            type: String,
            default: '24'
        },
        height: {
            type: String,
            default: '24'
        },
        fill: {
            type: String,
            default: 'none'
        },
        fill_path: {
            type: String,
            default: '#6147FF'
        },
    },
    data() {
        return {
            
        }
    },
}
</script>

<style scoped>

</style>
