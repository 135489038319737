<template>
    <div class="show-action">
        <button v-if="isDoClose" type="button" class="btn btn-default border-radius-8px show-action" data-dismiss="modal" v-on:click="doCloseModal()">Close</button>
        <button v-else type="button" class="btn btn-default border-radius-8px show-action" data-dismiss="modal">{{closeText}}</button>
    </div>
    
</template>

<script> 
    export default{
        props: {
            isDoClose: {
                type: [String, Boolean],
                default: null,
                required: false  
            },
            closeText: {
                type: [String],
                default: 'Cancel',
                required: false,
            }
        },
        methods: {
            doCloseModal(e) {
                this.$emit('onCloseModal', e)
            }
        }
    }
</script>