<template>
  <div>
    <datatable :config="config" @click="click"/>
  </div>
</template>

<script>
const {console} = window
export default {
  props: [],
  data() {
    return {
      config: {},
      project: {},
      me: {},
      role: {},
    }
  },
  async mounted() {
    this.init()
  },
  methods: {
    async init(){
      this.config = {
        columns: [
              {
                title: 'Tanggal dan Waktu',
                data: 'tanggal_format',
                orderable: true,
                searchable: true,
                render(value, type, row){
                  return moment(row.tanggal, 'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY HH:mm:ss')
                }

              },
              {
                title: 'Proyek',
                data: 'project_name',
                orderable: true,
                searchable: true
              },
              {
                title: 'Nama',
                data: 'nama_user',
                orderable: true,
                searchable: true
              },
              // {
              //   title: 'SPK',
              //   data: 'spk_number',
              //   orderable: false,
              //   searchable: true
              // },
              {
                title: 'Keterangan',
                data: 'message',
                orderable: false,
                searchable: true
              },
              // {
              //   "name":"construction.detail",
              //   "query":{},
              //   "params":{
              //     "id":35}
              //   }


          ],
        order: [
            [0, 'desc']
        ],
        pageStartAt: 1,
        // url: Api.base+'/md/notifications'
      }
    },
    formatDate(value){
          return moment(value).format('DD-MM-YYYY HH:mm:ss')
    },
    click(row) {
          if(row.data()) {
          let url = JSON.parse(row.data().route)
            // this.$root.$router.push({"name":"construction.detail","query":{},"params":{"id":35}})
            this.$root.$router.push(url)
          }
        }
    
    
  }
}
</script>

<style scoped>
#log-activities tbody > tr > td{
    cursor: text !important;
}
</style>
