var crypto = require('crypto');

function generateRandomSalt8Byte() {
    return crypto.randomBytes(8);
}

function base64Encoding(input) {
    return input.toString('base64');
}

function base64Decoding(input) {
    return Buffer.from(input, 'base64')
}

const encryptAesCbc256 = (secret, data) => {
    const TRANSFORM_ROUNDS = 3;
    const salt = generateRandomSalt8Byte();
    const password = Buffer.concat([Buffer.from(secret, "binary"), salt]);
    const md5Hashes = [];
    let digest = password;
    for (let i = 0; i < TRANSFORM_ROUNDS; i++) {
        md5Hashes[i] = crypto.createHash("md5")
            .update(digest)
            .digest();
        digest = Buffer.concat([md5Hashes[i], password]);
    }
    const key = Buffer.concat([md5Hashes[0], md5Hashes[1]]);
    const iv = md5Hashes[2];
    const cipher = crypto.createCipheriv('aes-256-cbc', key, iv);
    let encryptedBase64 = '';
    cipher.setEncoding('base64');
    cipher.on('data', (chunk) => encryptedBase64 += chunk);
    cipher.on('end', () => {
        // do nothing console.log(encryptedBase64);
        // Prints: some clear text data
    });
    cipher.write(data);
    cipher.end();
    const salted = Buffer.from('Salted__', 'utf8');
    const encrypted = base64Decoding(encryptedBase64);
    const ciphertextCompleteLength = salted.length + salt.length + encrypted.length;
    const ciphertextComplete = Buffer.concat([salted, salt, encrypted], ciphertextCompleteLength);
    return base64Encoding(ciphertextComplete);
}

const decryptAesCbc256 = (secret, message) => {
    const TRANSFORM_ROUNDS = 3;
    const cypher = Buffer.from(message, "base64");
    const salt = cypher.slice(8, 16);
    const password = Buffer.concat([Buffer.from(secret, "binary"), salt]);
    const md5Hashes = [];
    let digest = password;
    for (let i = 0; i < TRANSFORM_ROUNDS; i++) {
        md5Hashes[i] = crypto.createHash("md5")
            .update(digest)
            .digest();
        digest = Buffer.concat([md5Hashes[i], password]);
    }
    const key = Buffer.concat([md5Hashes[0], md5Hashes[1]]);
    const iv = md5Hashes[2];
    const contents = cypher.slice(16);
    const decipher = crypto.createDecipheriv("aes-256-cbc", key, iv);
    return decipher.update(contents) + decipher.final();
}

export default {
    encryptAesCbc256,
    decryptAesCbc256
}