(function() {
    var defaultPrecission = 2;
    var defaultSeparator = ',';
    var defaultDecimalSeparator = '.';
    var defaultSymbol = '';
    var pluginInitialized = false;

    function numberSlice(value, prec, sep, dec) {
        if (typeof value == 'number') {
            value = value.toString().replace(/[.]+/g, dec);
        }

        if (value && value.length > 1 && value[value.length - 1] == dec && !value.match(new RegExp('[\\' + dec + ']', 'g'))) {
            value += '0'.repeat(prec - 1);
        }

        var rgx = new RegExp('^(-)?(.+)\\' + dec + '([0-9]+)?$');
        var matches = value.match(rgx);
        var values = [];
        var prefix = '';
        if (matches) {
            if (matches[1]) {
                prefix = matches[1];
            }
            values.push(prefix);
            values.push(matches[2]);
            values.push(dec);
            if (matches[3]) {
                values.push(matches[3].substring(0, prec));
            } else {
                values.push('0'.repeat(prec));
            }
        } else {
            rgx = new RegExp('^(-)?(.+)$');
            matches = value.match(rgx);
            if (matches) {
                if (matches[1]) {
                    prefix = matches[1];
                }
                values.push(prefix);
                values.push(matches[2]);
                values.push('');
                values.push('');
            } else {
                values = ['', '0', '', ''];
            }
        }

        try {
            values[1] = values[1].replace(/[^0-9]+/g, '').replace(/^0+/g, '');
            if (values[1] == '') {
                values[1] = '0';
            }
        } catch (e) {}

        return values;
    }

    function toFloat(value, prec, sep, dec, positive) {
        var values = numberSlice(value, prec, sep, dec);
        if (values[2]) {
            values[2] = '.';
        }
        if (values[3]) {
            values[3] = values[3].substring(0, prec);
        }
        var result = parseFloat(parseFloat(values.join('')));
        if (isNaN(result)) {
            result = 0;
        }
        if (positive && result < 0) {
            result = result * -1;
        }

        return result;
    }

    function currency(value, prec, sep, dec) {
        sep = sep || defaultSeparator;
        prec = typeof prec != 'undefined' ? prec : defaultPrecission;
        dec = dec ? dec : defaultDecimalSeparator;
        dec = sep == '.' && dec == '.' ? ',' : dec;

        var values = numberSlice(value, prec, sep, dec);

        try {
            values[1] = values[1].replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1" + sep);
        } catch (e) {}

        return values.join('');
    }

    var Currency = {
        name: 'input-currency',
        props: {
            type: {
                type: String,
                default: function() {
                    return 'currency';
                }
            },
            separator: {
                type: String,
                default: function() {
                    return defaultSeparator;
                }
            },
            decimalSeparator: {
                type: String,
                default: function() {
                    return defaultDecimalSeparator;
                }
            },
            value: {
                type: Number,
                default: function() {
                    return 0;
                }
            },
            precision: {
                type: Number,
                default: function() {
                    return defaultPrecission;
                }
            }
        },
        data: function() {
            return {
                inputType: 'text',
                localValue: 0,
                originValue: 0,
                locked: false
            }
        },
        template: '<input :type=inputType :value=localValue :data-origin-value=originValue @change=onChange @keypress=onKeypress @keyup=onKeyup @keydown=onKeydown @focus=onFocus @blur=onBlur>',
        mounted: function() {
            this.initType();
        },
        watch: {
            value: function() {
                this.init()
            },
            precision: function() {
                this.init()
            },
            separator: function() {
                this.init()
            },
            type: function(newVal, oldVal) {
                if (newVal != oldVal) {
                    this.initType()
                }
            }
        },
        methods: {
            init: function() {
                if (this.value != this.originValue) {
                    var start = this.$el.selectionStart;
                    this.originValue = toFloat((this.value || '0') + '', this.precision, this.separator, this.decimalSeparator);
                    this.localValue = currency((this.value || '0') + '', this.precision, this.separator, this.decimalSeparator);
                    this.$nextTick(function() {
                        this.$el.setSelectionRange(start, start);
                    });
                }
            },
            initType: function() {
                var events = ['Keypress', 'Keyup', 'Keydown', 'Blur', 'Focus', 'Change']
                for (var i in events) {
                    var fnName = events[i]
                    var fn = this[this.type + fnName]
                    if (typeof fn == 'function') {
                        this['fn' + fnName] = fn
                    }
                }
                this.init();
            },
            currencyChange: function(e) {
                this.currencyKeyup(e);
            },
            currencyKeyup: function(e) {
                this.localValue = e.target.value;
                this.originValue = toFloat(this.localValue, this.precision, this.separator, this.decimalSeparator);
                var code = e.keyCode || e.which;
                if ([37, 39].indexOf(code) >= 0) {
                    return;
                }

                var possibles = [48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 190, 189, 8, 46, 35, 36, 110, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105];
                if ((possibles.indexOf(code) >= 0 && this.localValue != '-') || e.ctrlKey === true) {
                    var start = this.$el.selectionStart;
                    this.localValue = currency(this.localValue, this.precision, this.separator, this.decimalSeparator);
                    var cutter = this.localValue.substring(0, start);
                    var matches = cutter.match(new RegExp('\\' + this.separator, 'g')) || [];
                    this.oldMatches = this.oldMatches || 0;
                    if (matches.length) {
                        if (matches.length > this.oldMatches || (this.oldMatches == 1 && cutter.replace(/^-/, '').length == 4)) {
                            start += 1;
                        }
                        this.oldMatches = matches.length;
                    }

                    if (e.originalEvent || e.isTrusted) {
                        this.$el.focus();
                        this.$nextTick(function() {
                            if (!e.ctrlKey) {
                                this.$el.setSelectionRange(start, start);
                            }
                        });
                    }
                } else {
                    this.localValue = currency(this.localValue, this.precision, this.separator, this.decimalSeparator);
                    e.preventDefault();
                    e.stopPropagation();
                }

                if ((this.localValue == "0" || this.localValue == "-0") && (e.originalEvent || e.isTrusted)) {
                    this.$el.focus();
                    try {
                        if (this.localValue.length == 1 && !e.ctrlKey) {
                            this.$el.setSelectionRange(0, 1);
                        }
                    } catch (e) {}
                }

                this.$emit('input', toFloat(this.localValue, this.precision, this.separator, this.decimalSeparator));
            },
            currencyKeydown: function(e) {
                if (e.originalEvent || e.isTrusted) {
                    var code = e.keyCode || e.which;
                    var possibles = [48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 190, 189, 188, 8, 9, 37, 39, 46, 35, 36, 110, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105];
                    if ([37, 39].indexOf(code) >= 0) {
                        return;
                    } else if ((possibles.indexOf(code) >= 0 && (e.originalEvent || e.isTrusted)) || e.ctrlKey === true) {
                        var s = this.$el.selectionStart;
                        var val = this.localValue;
                        this.removedNextSep = val.toString().charAt(s) == this.separator && code == 46;
                        this.removedPrevSep = val.toString().charAt(s - 1) == this.separator && code == 8;
                        this.$nextTick(function() {
                            if (this.removedNextSep) {
                                this.$el.setSelectionRange(s + 1, s + 1);
                            } else if (this.removedPrevSep) {
                                this.$el.setSelectionRange(s - 1, s - 1);
                            }
                        });
                        return;
                    } else {
                        e.preventDefault();
                        e.stopPropagation();
                    }
                }
            },
            onKeyup: function(e) {
                if (this.fnKeyup) return this.fnKeyup(e)
            },
            onKeypress: function(e) {
                if (this.fnKeypress) return this.fnKeypress(e)
            },
            onKeydown: function(e) {
                if (this.fnKeydown) return this.fnKeydown(e)
            },
            onBlur: function(e) {
                if (this.fnBlur) return this.fnBlur(e)
            },
            onFocus: function(e) {
                if (this.fnFocus) return this.fnFocus(e)
            },
            onChange: function(e) {
                if (this.fnFocus) return this.fnFocus(e)
            }
        }
    }
    Currency.install = function(Vue, options) {
        if (pluginInitialized) {
            pluginInitialized = true;
            return;
        }
        options = options || {};
        if (options.separator) {
            defaultSeparator = options.separator;
        }
        if (options.decimalSeparator) {
            defaultDecimalSeparator = options.decimalSeparator;
        }
        if (typeof options.precision != 'undefined') {
            defaultPrecission = parseInt(options.precision);
        }
        if (typeof options['symbol'] != 'undefined') {
            defaultSymbol = options['symbol'];
        }
        /*Vue.filter('currency', function(value, sym, precision, separator, decimalSeparator) {
            if (typeof arguments[1] == 'object') {
                separator = arguments[1].separator
                decimalSeparator = arguments[1].decimalSeparator
                sym = arguments[1]['symbol']
                precision = arguments[1].precision
            }
            if (typeof precision == 'undefined') {
                precision = defaultPrecission;
            }
            if (typeof separator == 'undefined') {
                separator = defaultSeparator;
            }
            if (typeof decimalSeparator == 'undefined') {
                decimalSeparator = defaultDecimalSeparator;
            }
            if (typeof sym == 'undefined') {
                sym = defaultSymbol;
            }
            return (sym || '') + currency(value, precision, separator, decimalSeparator);
        });*/

        Vue.component('input-currency', Currency);
    }

    if (typeof module != 'undefined') {
        module.exports = Currency;
    } else if (typeof window != 'undefined') {
        window.VueCurrency = Currency;
    }
})();