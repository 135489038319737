<template>
    <div>
        <Error404 v-if="display404"></Error404>
        <div v-else>
            <header-title-button
                :title="id ? disabledForm ?  'Ticket Package Detail' : 'Edit Ticket Package' : 'Add Ticket Package' "
                :isAdd="false">
            </header-title-button>

            <box
                back="master.ticket-package"
                title="Ticket Package Information"
                :save="!disabledForm"
                :saveText="form.id ? `Edit` : `Save`"
                @onSave="doSaveConfirm"
                :loading="is_loading"
                :remove="false"
                :isSwitch="true"
                :switchValue="form.is_active"
                :switchDisabled="disabledForm"
                switchTitle="Status"
                :switchText="form.is_active ? 'Active' : 'Not Active' "
                @onSwitchButton="changeBtnSwitch">
                <div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group" :class="{'has-error': !!errors.name}">
                                <label class="control-label">Name 
                                    <span class="warning-text-required">*</span>
                                </label>
                                <input type="text" class="form-control name"
                                    id="name"
                                    v-model="form.name"
                                    :disabled="disabledForm"
                                    maxlength="20" />

                                    <p class="help help-block" v-if="errors.name">{{  form.name ? `Ticket package name must be at least three characters, maximum twenty characters.` : `Ticket package name is required.` }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group" :class="{'has-error': !!errors.image_path}">
                                <label class="control-label">Thumbnail 
                                    <span class="warning-text-required">*</span>
                                </label>
                                <ImageUpload 
                                    :image="form.image_path ? form.image_path : ''  "
                                    @onChangeUploadImage="changeUploadImage"
                                    @onChangeDeleteImage="changeDeleteImage"
                                    :disabled="disabledForm"/>
                            </div>
                        </div>
                    </div>
                    <br>
                    <p>&nbsp;</p>
                    <p class="help help-block warning-text-required" v-if="errors.image_path" style="margin-top: 25px;">Thumbnail is required.</p>
                    <br>

                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group" :class="{'has-error': !!errors.description}">
                                <label class="control-label">Description 
                                    <span class="warning-text-required">*</span>
                                </label>
                                <textarea 
                                    class="form-control description" 
                                    id="description" 
                                    v-model="form.description"
                                    maxlength="255"
                                    rows="7"
                                    :disabled="disabledForm">
                                </textarea>
                                <p>{{ calculate_description }}/255</p>

                                <p class="help help-block" v-if="errors.description">{{  form.description ? `Description must be at least three characters, maximum two hundred fifty-five characters.` : `Description is required.` }}</p>
                            </div>
                        </div>
                    </div>
                    

                    <hr>
                    <h4 style="text-transform: lowercase;">Item(s) <span class="warning-text-required">*</span></h4>
                    <div class="row">
                        <div class="col-md-12" v-for="(data, i) in form.package_detail" :key="i">
                            <div class="box vue-box" style="padding: 10px;" >
                                <div class="row">
                                    <div class="col-md-10" >

                                        <div style="display: inline">
                                            <div style="width: 96px; border-radius: 4px; display: inline-block">
                                                <img :src="itemImageUrl(data)" width="94" height="62" style="padding: 1px; border-radius: 4px;" />
                                            </div>
                                
                                            <div style="display: inline-block; padding-left: 15px">
                                                <p >{{ data && data.items && data.items.name ? data.items.name : '' }}</p>
                                            </div>
                                        </div>
                                        
                                    </div>
                                    <div class="col-md-2" v-if="is_show_btn">
                                        <p class="p-vertical-center">
                                            <span style="padding: 5px" class="cursor-pointer" @click="deletePackageDetail(i)">
                                                <img src="@/assets/svg/trash-outline.svg"/>
                                            </span>
                                            <span>|</span>
                                            <span style="padding: 5px" class="cursor-pointer" @click="editPackageDetail(data, i)">
                                                <img src="@/assets/svg/pencil-outline.svg"/>
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <p class="help help-block warning-text-required" v-if="errors.package_detail">Items is required.</p>

                    <br>
                    <div class="row" v-show="is_show_select_item" v-if="is_show_btn">
                        <div class="col-md-11">
                            <div class="form-group" >
                                <label class="control-label" >Item</label>
                                <select type="text" class="form-control select2 select-item"
                                    style="width: 100%"
                                    id="location"
                                    v-model="item_id_selected"
                                    :disabled="disabledForm" >
                                    <option v-for="(data, i) in item_unselect" :key="i" :value="data.id">{{ data.name }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-1">
                            <div class="form-group" >
                                <label class="control-label" >&nbsp;</label>
                                <p class="p-vertical-center">
                                    <span style="padding: 5px" class="cursor-pointer" @click="hideInputSelectItem">
                                        <img src="@/assets/svg/trash-outline.svg"/>
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>

      
                    <div class="row" v-if="is_show_btn">
                        <div class="col-md-3">
                            <span class="btn btn-add-more" @click="addMoreItem">Add More Item <img src="@/assets/svg/add-2.svg" />
                            </span>
                        </div>
                    </div>

                    <hr>
                    <h4>Pricing</h4>
                    <div class="row">
                        <div class="col-md-5">
                            <div class="form-group" :class="{'has-error': !!errors.base_price}">
                                <label class="control-label">Price 
                                    <span class="warning-text-required">*</span>    
                                </label>
                                <currency-input class="form-control"
                                    v-model="form.base_price"
                                    :disabled="disabledForm"
                                />
                                <p class="help help-block" v-if="errors.base_price">Price is required.</p>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="form-group">
                                <label class="control-label">Discount</label>
                                <button-switch
                                    title=""
                                    :value="form.is_discount"
                                    :disabled="disabledForm"
                                    @onClickSwitchButton="changeBtnSwitchDiscount"></button-switch>
                            </div>
                        </div>
                        <div class="col-md-5" v-if="form.is_discount">
                            <div class="form-group" :class="{'has-error': !!errors.discount}">
                                <label class="control-label">Percentage 
                                    <span class="warning-text-required">*</span>    
                                </label>
                                <input-number-decimal 
                                    v-model="form.discount" 
                                    id="percent-2" 
                                    min="0" 
                                    max="99" 
                                    step="0.1" 
                                    :disabled="disabledForm"
                                    @onChange="changeDiscount"> 
                                </input-number-decimal>
                                <p class="help help-block" v-if="errors.discount">Discount amount is required.</p>
                            </div>
                        </div>
                    </div>
                    <div class="row" v-if="form.is_discount">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label class="control-label" style="margin-bottom: 0px">Final Price </label>
                                <div class="box vue-box" style="padding: 10px; border-top: 1px solid #E4E4E4;">
                                    <div class="row">
                                        <div class="col-md-9">  
                                            <span class="left-vertical-line-company"></span>{{ form.price | currency }}
                                        </div>
                                        <div class="col-md-3" >
                                            <div class="pull-right">
                                                <p>
                                                    <span class="btn btn-sm btn-info-discount cursor-auto"> {{ form.discount }}% OFF</span>
                                                    &nbsp;
                                                    <span><img src="@/assets/svg/check-circle-2.svg"/></span>
                                                </p>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </box>
        </div>

        <modal :title="form.id ? 'Change Confirmation' : 'Add Confirmation' " save="true" remove="false"
            :show="is_confirm"
            :saveText=" form.id ? 'Yes, Confirmation' : 'Save'"
            @hidden="is_confirm = false"
            @onSave="doSave()"
            :loading="is_confirm_loading">
            <span class="span-title">Are you sure you want to modify the ticket package ?</span>
        </modal>
    </div>
</template>

<script>
import Error404 from '@pages/Error404.vue'
import ImageUpload from '@app/components/ImageUpload.vue'
import InputNumberDecimal from '@pages/Components/Form/InputNumberDecimal.vue'
import ButtonSwitch from '@app/components/ButtonSwitch.vue'
const {console} = window
export default {
    components: {
        Error404,
        ImageUpload,
        InputNumberDecimal,
        ButtonSwitch
    },
    data() {
        return {
            alias: 'ticket-package',
            id: this.$route.params.id,
            is_edit: false,
            disabledForm: false,
            display404: false,
            page: {
                title: 'Nuanu',
                subtitle: 'Ticket Package'
            },
            form: {
                id: this.$route.params.id,
                name: null,
                description: null,
                policy: null,
                image_path: null,
                package_detail: [],
                base_price: null,
                is_discount: false,
                discount_price: 0,
                price: null,
                is_active: true,
            },
            errors: {
                name: false,
                description: false,
                image_path: false,
                package_detail: false,
                base_price: false,
                discount: false
            },
            is_confirm: false,
            is_confirm_loading: false,
            is_loading: false,
            is_error_upload: false,
            is_show_select_item: false,
            file_image: null,
            base_url: process.env.VUE_APP_UPLOAD_URL,
            items: [],
            item_id_selected: null,
            package_detail_edit_index: null,
        }
    },
    async mounted() {
        this.$root.checkingAccessPage(this.$root._route.name)
        if(this.id)  {
            switch(this.$route.query.is_edit) {
                case true: 
                    this.is_edit = true 
                    this.disabledForm = false
                    break;
                case false: 
                    this.is_edit = false 
                    this.disabledForm = true
                    break;
                case 'true': 
                    this.is_edit = true 
                    this.disabledForm = false
                    break;
                case 'false': 
                    this.is_edit = false 
                    this.disabledForm = true
                    break;
                default: 
                    this.is_edit = false 
                    this.disabledForm = true
                    break;
            } 

            if(!this.is_edit) {
                this.$store.state.title = 'Ticket Package Detail'
            }else {
                await this.$root.checkingButtonsPage(this.alias, 'update')
            }

            this.init()

        }else {
            await this.$root.checkingButtonsPage(this.alias, 'create')
        }

        this.getDataItem()

        let self = this
        $(document).ready(function(e) {
            $('.select-item').on('select2:select', (e) => {
                e.preventDefault();
                let data = e.params.data
                self.item_id_selected = data.id
            })
        })

        $('.select2').select2()
        $('.select-item').select2()

    },
    methods: {
        async getDataItem() {
            let filter = [["is_active", "=", true]]
            filter = JSON.stringify(filter)
            var encodedFilter = encodeURIComponent(filter)

            Rest.get('/item?size=10000&filters='+encodedFilter)
            .then( async(res) => {
                if(res && res.data && res.data.items && res.data.items.length > 0) {
                    this.items = res.data.items
                }
            })
            .catch( () => {

            })
        },
        async init() {
            if(this.id) {
                Rest.get('/package/'+this.id)
                .then( async(res) => {
                    if(res && res.data) {
                        this.form.name = res.data.name 
                        this.form.is_active = res.data.is_active
                        this.form.base_price = res.data.base_price
                        this.form.is_discount = res.data.is_discount
                        if(res.data.discount) {
                            this.form.discount = res.data.discount
                        }
                        if(res.data.description ) {
                            this.form.description = res.data.description
                        } 

                        if(res.data.image_path) {
                            this.form.image_path = res.data.image_path
                        }

                        if(res.data.package_detail) {
                            this.form.package_detail = res.data.package_detail
                        }
                    }
                })
                .catch( () => {
                    this.display404 = true 
                })
            }
        },
        changeBtnSwitch() {
            this.form.is_active = !this.form.is_active
        },
        async validationForm() {
            let is_success = true 
            this.errors.name = false 
            this.errors.description = false
            this.errors.image_path = false 
            this.errors.base_price = false 
            this.errors.discount = false 
            if(!this.form.name) {
                this.errors.name = true 
                is_success = false 
            }else {
                let length = this.form.name.length
                if(length < 3 || length > 20) {
                    this.errors.name = true
                    is_success = false
                }
            }

            if(!this.form.description) {
                this.errors.description = true 
                is_success = false 
            }else {
                let length = this.form.description.length
                if(length < 3 || length > 255) {
                    this.errors.description = true 
                    is_success = fale 
                }
            }

            if(!this.form.base_price) {
                this.errors.base_price = true 
                is_success = false 
            }else {
                if(this.form.base_price <= 0){
                    this.errors.base_price = true 
                    is_success = false 
                }
            }

            if(this.form.is_discount) {
                if(!this.form.discount) {
                    this.errors.discount = true
                    is_success = false 
                }else {
                    if(this.form.discount <= 0) {
                        this.errors.discount = true 
                        is_success = false 
                    }
                }
            }

            let is_valid_detail = false
            if(this.form && this.form.package_detail && this.form.package_detail.length > 0) { 
                is_valid_detail = true 
                this.form.package_detail.forEach( (item) => {
                    if(!item.item_id) {
                        is_valid_detail = false 
                    }
                })
            }

            if(!is_valid_detail) {
                this.errors.package_detail = true 
                is_success = false 
            }

            if(!this.form.image_path) {
                if(!this.file_image) {
                    this.errors.image_path = true 
                    is_success = false
                }
            } 

            return is_success
        },
        async doSaveConfirm() {
            let is_valid = await this.validationForm()
            if(is_valid) {
                if(this.id) {
                    this.is_confirm = true 

                }else {
                    this.is_loading = true
                    if(this.file_image) {
                        await this.processUploadImage()
                        if(this.is_error_upload) {
                            this.is_loading = false 
                            return 
                        }
                    }
                }
            }
        },
        async doSave() {
            this.is_confirm_loading = false
            if(this.file_image) {
                await this.processUploadImage()
                if(this.is_error_upload) {

                    this.is_confirm = false
                    this.is_confirm_loading = false
                    
                }
            }else {
                this.doSaveProcess()
            }
            
        },
        async doSaveProcess() {
            let package_detail = []
            if(this.form && this.form.package_detail && this.form.package_detail.length > 0) {
                this.form.package_detail.forEach( (item) => {
                    if(item.item_id) {
                        package_detail.push({
                            item_id: item.item_id,
                        })
                    }
                })
            }

            let params = {
                name: this.form.name,
                is_active: this.form.is_active,
                description: this.form.description,
                image_path: this.form.image_path,
                base_price: this.form.base_price,
                is_discount: this.form.is_discount,
                discount: this.form.discount,
                price: this.form.price,
                package_detail: package_detail
            }

            let method = this.id ? 'put' : 'post'
            let url = this.id ? '/package/'+this.id : '/package'
            Rest[method](url, params)
            .then( async(res) => {
                if(res && res.status == 200) {
                    this.$root.showNotification('Success', 'success', this.id ? "Ticket package modification successful." : "Ticket package addition successful.")
                    
                    this.userLogActivity()

                    if(!this.id) {
                        if(res.data && res.data.id) {
                            this.form.id = res.data.id
                        }
                    }

                    this.$root.$router.push({
                        name: "master.ticket-package"
                    })
                }
            })
            .catch( (err) => {
                if(err && err.response && err.response.status) {
                    if(err.response.data && err.response.data.message) {
                        this.$root.showNotification('Failed', 'error', err.response.data.message)        
                    }else {
                        var message = "Ticket package addition failed"
                        if(this.id) {
                            message = "Ticket package modification failed."
                        }
                        this.$root.showNotification('Failed', 'error', message)
                    }
                }else {
                    this.$root.showNotification('Failed', 'error', 'Sorry, you do not have an internet connection.')
                }
            })
            .finally( () => {
                this.is_confirm = false 
                this.is_confirm_loading = false
                this.is_loading = false
            })

        },
        changeUploadImage(data) {
            this.form.image_path = ""
            this.file_image = data.file  
            this.errors.image_path = false 
        },
        changeDeleteImage() {
            this.form.image_path = ""
            this.file_image = null 
            this.errors.image_path = true
        },
        async processUploadImage() {
            this.is_error_upload = false
            if(this.file_image) {
                var params = new FormData();
                params.append('files', this.file_image)
                Rest.post('/storage/files', params)
                .then( async(res) => {
                    if(res && res.data && res.data.length > 0 ) {
                        this.form.image_path = res.data[0].path
                        this.file_image = null

                        this.doSaveProcess()
                    }
                })
                .catch( (err) => {
                    if(err && err.response && err.response.status) {
                        if(err.response.data && err.response.data.message) {
                            this.$root.showNotification('Failed', 'error', err.response.data.message)        
                        }else {
                            var message = "Thumbnail ticket package upload failed"
                            this.$root.showNotification('Failed', 'error', message)
                        }
                    }else {
                        this.$root.showNotification('Failed', 'error', 'Sorry, you do not have an internet connection.')
                    }
                    
                    this.is_error_upload = true
                })
            }
        },
        changeBtnSwitchDiscount() {
            this.form.is_discount = !this.form.is_discount
        },
        itemImageUrl(data) {
            let image = ''
            if(data && data.items && data.items.item_images && data.items.item_images.length > 0) {
                if(data.items.item_images[0].image_path) {
                    image = this.base_url + data.items.item_images[0].image_path
                }
            }

            return image
        },
        async deletePackageDetail(index) {
            let package_detail = []
            if(this.form && this.form.package_detail && this.form.package_detail.length > 0) {
                this.form.package_detail.forEach( (item, i) => {
                    if(index != i) {
                        package_detail.push(item)
                    }
                })
            }

            this.form.package_detail = package_detail
        },
        async editPackageDetail(data, index) {
            this.is_show_select_item = true
            this.item_id_selected = null
            this.package_detail_edit_index = index 

            $('.select2').select2()
            $('.select-item').select2()
            $('.select-item').val([this.item_id_selected]).trigger('change')
        },
        async addMoreItem() {
            this.is_show_select_item = true
            this.item_id_selected = null
            this.package_detail_edit_index = null
            
            $('.select2').select2()
            $('.select-item').select2()
            $('.select-item').val([this.item_id_selected]).trigger('change')
        },
        async afterItemSelected(item_id) {
            if(this.items && this.items.length > 0) {
                this.items.forEach( (item) => {
                    if(item.id == item_id) {
                        if(this.package_detail_edit_index != null) {
                            this.form.package_detail[this.package_detail_edit_index].item_id = item_id
                            this.form.package_detail[this.package_detail_edit_index].items = item
                        }else {
                            this.form.package_detail.push({
                                item_id: item_id,
                                items: item,
                            })
                        }
                    }
                })
            }

            this.is_show_select_item = false
            this.item_id_selected = null
            this.package_detail_edit_index = null
        },
        hideInputSelectItem() {
            this.is_show_select_item = false
            this.item_id_selected = null
            this.package_detail_edit_index = null
        },
        async userLogActivity() {
            if(this.$root.$store.state.user && this.$root.$store.state.user.full_name) {
                let route_name = {
                    name: 'master.ticket-package.form',
                    params: {
                        id: this.form.id
                    },
                    query: {
                        is_edit: false
                    }
                } 

                let secretKey = process.env.VUE_APP_KEY_ENCRYPT_DECRYPT
                let route_name_encrypt = this.EncryptDecryptAesCbc256.encryptAesCbc256(secretKey, JSON.stringify(route_name))

                let activity_type = 'create'
                let activity = `${this.$root.$store.state.user.full_name} has added a ticket package with the name <a style="cursor: pointer" class="click-detail-log" data-route="${route_name_encrypt}"> ${this.form.name}</a>`
                if(this.id) {
                    activity = `${this.$root.$store.state.user.full_name} has modified the ticket package with the name <a style="cursor: pointer" class="click-detail-log" data-route="${route_name_encrypt}"> ${this.form.name}</a>`
                    activity_type = 'edit'
                }

                let params = {
                    activity: activity,
                    feature: 'Ticket Package',
                    activity_type: activity_type,
                    route_name: route_name
                }

                this.$root.sendUserLogActivity(params)
            }
            
        },
        changeDiscount() {
            this.errors.discount = false 
            if(this.form.is_discount) {
                if(!this.form.discount) {
                    this.errors.discount = true
                }else {
                    if(this.form.discount <= 0) {
                        this.errors.discount = true 
                    }
                }
            }
            this.calculateFinalPrice()
        },
        calculateFinalPrice() {
            let price = 0
            let base_price = this.form.base_price ? parseFloat(this.form.base_price) : 0
            let discount = this.form.discount ? parseFloat(this.form.discount) : 0 
            let is_discount = this.form.is_discount
            if(base_price > 0) {
                if(is_discount) {
                    let discount_price = discount/100 *base_price
                    let final_price = price = base_price - discount_price
                    price = parseFloat(final_price.toFixed(0))
                }else {
                    price = base_price
                }
            }

            this.form.price = price
        }
    },
    computed: {
        calculate_description() {
            let length = 0
            if(this.form.description) {
                length = this.form.description.length
            }
            return length
        },
        item_unselect() {
            let items = []
            let items_selected = []
            if(this.form && this.form.package_detail && this.form.package_detail.length > 0) {
                this.form.package_detail.forEach((item) => {
                    items_selected.push(item.item_id)
                })
            }

            if(this.items && this.items.length > 0) {
                this.items.forEach( (item) => {
                    let is_find =  items_selected.indexOf(item.id)
                    if(is_find < 0) {
                        items.push(item)
                    }
                })
            }

            return items
        },
        is_show_btn() {
            let is_show = true 
            if(this.id) {
                if(!this.is_edit) {
                    is_show = false
                }
            }

            return is_show
        }
    },
    watch: {
        'form.name': {
            handler(val) {
                this.errors.name = false
                if(!val) {
                    this.errors.name = true 
                }else {
                    let length = val.length
                    if(length < 3 || length > 20) {
                        this.errors.name = true
                    }
                }
            }
        },
        'form.description': {
            handler(val) {
                this.errors.description = false 
                if(!val) {
                    this.errors.description = true
                }else {
                    let length = val.length
                    if(length < 3 || length > 255) {
                        this.errors.description = true
                    }
                }
            }
        },
        'form.base_price': {
            handler(val) {
                this.errors.base_price = false 
                if(!val) {
                    this.errors.base_price = true 
                }else {
                    if(val <= 0){
                        this.errors.base_price = true 
                    }
                }

                this.calculateFinalPrice()
            }
        },
        'form.is_discount': {
            handler(val) {
                this.errors.discount = false 
                if(val) {
                    if(!this.form.discount) {
                        this.errors.discount = true 
                    }else {
                        if(this.form.discount <= 0) {
                            this.errors.discount = true
                        }
                    }
                }else {
                    this.form.discount = 0
                }

                this.calculateFinalPrice()
            }
        },
        'form.discount': {
            handler(val) {
                console.log('change discount')
                this.errors.discount = false 
                if(this.form.is_discount) {
                    if(!val) {
                        this.errors.discount = true 
                    }else {
                        if(val <= 0) {
                            this.errors.discount = true 
                        }
                    }
                }

                this.calculateFinalPrice()
            }
        },
        'item_id_selected': {
            handler(val) {
                this.afterItemSelected(val)
            }
        },
        'form.package_detail': {
            handler(val) {
                this.errors.package_detail = false 
                if(!val || val.length == 0) {
                    this.errors.package_detail = true 
                }
            }
        }
    }
}
</script>

<style scoped>
textarea {
    resize: none;
}

.p-vertical-center {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translate(-50%, 50%);
    transform: translate(-50%, 50%);
}

.box-border-top {
    border-top: 1px solid #E4E4E4;
}
</style>