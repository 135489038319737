import Main from '@pages/Main.vue'
import Error404 from '@pages/Error404.vue'
import Error500 from '@pages/Error500.vue'
import Login from '@pages/Login.vue'
import RecoverPassword from '@pages/RecoverPassword.vue'
import ForgotPassword from '@pages/ForgotPassword.vue'
import Register from '@pages/Register.vue'
import Profile from '@pages/Profile.vue'
import ProfileChangeEmail from '@pages/ProfileChangeEmail.vue'
import ProfileChangePassword from '@pages/ProfileChangePassword.vue'
import ProfileChangeSignature from '@pages/ProfileChangeSignature.vue'

import Navbar from '@components/Navbar.vue'
import Sidebar from '@components/Sidebar.vue'
import Header from '@components/Header.vue'
// import Footer from '@components/Footer.vue'

import routes from '@config/routes'

const mainPages = [{
        path: '/',
        // name: 'dashboard',
        component: {
            render(h) {
                return h('router-view')
            }
        },
        // meta: {
        //     title: "Dashboard"
        // },
        children: routes
    }, {
        path: '/error404',
        name: 'error404',
        component: Error404
    }, {
        path: '/error500',
        name: 'error500',
        component: Error500
    }, {
        path: '/profile',
        name: 'profile',
        component: Profile,
        meta: {
            title: "Profile Saya",
        }
    }, {
        path: '/profile/change-email',
        name: 'profile.change-email',
        component: ProfileChangeEmail,
        meta: {
            title: 'Ubah Email'
        }
    }, {
        path: '/profile/change-password',
        name: 'profile.change-password',
        component: ProfileChangePassword,
        meta: {
            title: 'Ubah password'
        }
    }, {
        path: '/profile/change-signature',
        name: 'profile.change-signature',
        component: ProfileChangeSignature,
        meta: {
            title: 'Ubah Tanda Tangan'
        }
    },

]

export default {
    routes: [{
        path: '',
        component: {
            template: '<router-view></router-view>'
        },
        children: [{
                path: '',
                component: Main,
                children: [{
                    path: '',
                    components: {
                        default: {
                            template: '<router-view></router-view>'
                        },
                        sidebar: Sidebar,
                        navbar: Navbar,
                        header: Header,
                    },
                    children: mainPages
                }]
            }, {
                path: '/login',
                name: 'login',
                component: Login
            }, {
                path: '/register',
                name: 'register',
                component: Register
            }, {
                path: '/forgot-password',
                name: 'forgot.password',
                component: ForgotPassword
            }, {
                path: '/recover-password',
                name: 'recover.password',
                component: RecoverPassword
            },
            {
                path: '*',
                name: 'Page Not Found',
                component: Error404
            }
        ]
    }]
}