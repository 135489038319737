import Vue from 'vue'
import 'daterangepicker/daterangepicker.css'
import 'daterangepicker/daterangepicker.js'

const { console } = window
const Datepicker = {
    name: 'datepicker',
    template: '<div class="input-group">\
    <input type="text" class="form-control" :disabled="disabled">\
        <div class="input-group-addon">\
            <i class="far fa-calendar-alt"></i>\
        </div>\
    </div>',
    props: ['config', 'value', 'formatDate', 'disabled'],
    data() {
        return {
            input: null,
            conf: {},
            defaultConfig: {
                selected: moment(),
                startDate: moment().startOf('hour'),
                endDate: moment().endOf('hour'),
                timePicker: false,
                singleDatePicker: true,
                showDropdowns: true,
                autoApply: true,
                locale: {
                    format: 'DD-MM-YYYY'
                },
                drops: "auto"
            }
        }
    },
    mounted() {
        if (typeof this.formatDate !== 'undefined') {
            this.defaultConfig.locale.format = this.formatDate
        }
        this.defaultSetting()
        this.init()
    },
    methods: {
        defaultSetting() {
            this.input = $('input', this.$el)
            this.conf = $.extend({}, this.config, this.defaultConfig)
            if (this.value && this.value != 'Invalid date') {
                if (typeof this.value == 'object') {
                    this.conf.startDate = moment(this.value[0], this.conf.locale.format)
                    this.conf.endDate = moment(this.value[1], this.conf.locale.format)
                } else {
                    this.conf.startDate = moment(this.value, this.conf.locale.format)
                    this.conf.endDate = moment(this.value, this.conf.locale.format)
                }
            }
        },
        init() {
            this.destroy()
            this.input.daterangepicker(this.conf)
            this.input.on('apply.daterangepicker', (ev, picker) => {
                var startDate = picker.startDate.format(this.conf.locale.format);
                var endDate = picker.endDate.format(this.conf.locale.format)
                if (this.conf.singleDatePicker) {
                    this.$emit('input', startDate)
                } else {
                    this.$emit('input', [startDate, endDate])
                }
            })
        },
        destroy() {
            try {
                this.input.daterangepicker('destroy')
                this.input.data('daterangepicker').remove()
            } catch (e) {}
        }
    },
    beforeDestroy() {
        this.destroy()
    },
    watch: {
        value(val, oldVal) {
            if (typeof val == 'object' && val.length == 2) {
                val = val.join(' - ')
            }
            this.input.val(val)
            if (val != oldVal) {
                this.input.trigger('change')
            }

        },
        config(val) {
            this.defaultSetting()
            this.init()
        }
    }
}
Vue.component('datepicker', Datepicker)

export default Datepicker