<template>
    <div>
        <Error404 v-if="display404"></Error404>
        <div v-else>
            <header-title-button
                title="Edit Company">
            </header-title-button>
            <box
                back="master.company"
                title=""
                :save="!disabledForm"
                saveText="Save"
                @onSave="doSaveConfirm"
                :remove="false"
                :isSwitch="true"
                :switchValue="true"
                :switchDisabled="true"
                switchTitle="Status"
                :switchText="'Active'"
                @onSwitchButton="changeBtnSwitch">
                <div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group" :class="{'has-error': !!errors.name}">
                                <label class="control-label">
                                    Company Name 
                                    <span class="warning-text-required">*</span>    
                                </label>
                                <input type="text" class="form-control name"
                                    id="name"
                                    v-model="form.name"
                                    :disabled="disabledForm"
                                    maxlength="141" />
                                    <p class="help help-block" v-if="errors.name">{{  form.name ? `Name must be at least three characters, maximum one hundred forty-one characters.` : `Name is required.` }}</p>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-group" :class="{'has-error': !!errors.address}">
                                <label class="control-label">
                                    Address 
                                    <span class="warning-text-required">*</span>
                                </label>
                                <textarea 
                                    class="form-control address" 
                                    id="address" 
                                    v-model="form.address"
                                    maxlength="50"
                                    rows="7"
                                    :disabled="disabledForm">
                                </textarea>
                                <p>{{ calculate_address }}/50</p>
                                <p class="help help-block" v-if="errors.address">{{  form.address ? `Address must be at least three characters, maximum fifty characters.` : `Address is required.` }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group" :class="{'has-error': !!errors.city}">
                                <label class="control-label">
                                    City  
                                    <span class="warning-text-required">*</span>
                                </label>
                                <input type="text" class="form-control city"
                                    id="city"
                                    v-model="form.city"
                                    :disabled="disabledForm"
                                    maxlength="50" />
                                <p class="help help-block" v-if="errors.city">{{  form.city ? `City must be at least three characters, maximum fifty characters.` : `City is required.` }}</p>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group" :class="{'has-error': !!errors.province}">
                                <label class="control-label">
                                    Province 
                                    <span class="warning-text-required">*</span>
                                </label>
                                <input type="text" class="form-control province"
                                    id="province"
                                    v-model="form.province"
                                    :disabled="disabledForm"
                                    maxlength="50" />
                                <p class="help help-block" v-if="errors.province">{{  form.province ? `Province must be at least three characters, maximum fifty characters.` : `Province is required.` }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group" :class="{'has-error': !!errors.postal_code}">
                                <label class="control-label">Postal Code 
                                    <span class="warning-text-required">*</span>
                                </label>
                                <input type="text" class="form-control postal-code"
                                    id="postal-code"
                                    v-model="form.postal_code"
                                    :disabled="disabledForm"
                                    maxlength="5"
                                    v-on:keydown="$root.validateNumberOnly($event)" />
                                    <p class="help help-block" v-if="errors.postal_code">{{  form.postal_code ? `Postal code must be at five characters.` : `Postal code is required.` }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </box>
        </div>

        <modal :title="form.id ? 'Change Confirmation' : 'Add Confirmation' " save="true" remove="false"
            :show="is_confirm"
            :saveText=" form.id ? 'Yes, Confirmation' : 'Save'"
            @hidden="is_confirm = false"
            @onSave="doSave()"
            :loading="is_confirm_loading">
            <span class="span-title">Are you sure you want to modify the company?</span>
        </modal>
    </div>
</template>

<script>
import Error404 from '@pages/Error404.vue'
const {console} = window
export default {
    components: {
        Error404
    },
    data() {
        return {
            alias: 'company',
            id: this.$route.params.id,
            is_edit: false,
            disabledForm: false,
            display404: false,
            page: {
                title: 'Nuanu',
                subtitle: 'Company'
            },
            form: {
                id: this.$route.params.id,
                name: null,
                address: null,
                city: null,
                province: null,
                is_active: true,
                postal_code: null
            },
            errors: {
                name: false,
                address: false,
                city: false,
                province: false,
                postal_code: false,
            },
            is_confirm: false,
            is_confirm_loading: false,
            location: [],
        }
    },
    async mounted() {
        this.$root.checkingAccessPage(this.$root._route.name)

        if(this.id)  {
            switch(this.$route.query.is_edit) {
                case true: 
                    this.is_edit = true 
                    this.disabledForm = false
                    break;
                case false: 
                    this.is_edit = false 
                    this.disabledForm = true
                    break;
                case 'true': 
                    this.is_edit = true 
                    this.disabledForm = false
                    break;
                case 'false': 
                    this.is_edit = false 
                    this.disabledForm = true
                    break;
                default: 
                    this.is_edit = false 
                    this.disabledForm = true
                    break;
            } 

            if(!this.is_edit) {
                this.$store.state.title = 'Company Detail'
            }else {
                let permission_edit = await this.$root.checkingButtonsPage(this.alias, 'update')
                if(!permission_edit.validate) {
                    this.disabledForm = true
                }
            }
            this.init()
        }

    },
    methods: {
        async init() {
            Rest.get('/company')
            .then( (res) => {
                if(res && res.data) {
                    this.form = res.data
                    this.form.is_active = true
                }
            })
            .catch( () => {

            })
        },
        changeBtnSwitch() {
            this.form.is_active = !this.form.is_active
        },
        async doSaveConfirm() {
            let is_valid = await this.validationForm()
            if(is_valid) {
                if(this.id) {
                    this.is_confirm = true 
                }else {
                    this.doSaveProcess()
                }
            }
        },
        async doSave() {
            this.is_confirm_loading = false
            
            this.doSaveProcess()
        },
        async doSaveProcess() {
            let params = {
                name: this.form.name,
                address: this.form.address,
                city: this.form.city,
                province: this.form.province,
                postal_code: this.form.postal_code,
                is_active: this.form.is_active
            }
            
            let method = 'post'
            let url = '/company'
            Rest[method](url, params)
            .then( async(res) => {
                if(res && res.status == 200) {
                    this.$root.showNotification('Success', 'success', "Company addition successful.")

                    this.userLogActivity()

                    if(!this.id) {
                        if(res.data && res.data.id) {
                            this.form.id = res.data.id
                        }
                    }

                    this.$root.$router.push({
                        name: "master.company"
                    })
                }
            })
            .catch( (err) => {
                if(err && err.response && err.response.status) {
                    if(err.response.data && err.response.data.message) {
                        this.$root.showNotification('Failed', 'error', err.response.data.message)        
                    }else {
                        var message = "Company modified failed"
                        
                        this.$root.showNotification('Failed', 'error', message)
                    }
                }else {
                    this.$root.showNotification('Failed', 'error', 'Sorry, you do not have an internet connection.')
                }
            })
            .finally( (res) => {
                this.is_confirm = false 
                this.is_confirm_loading = false
            })
            
        },
        async validationForm() {
            let is_success = true
            this.errors.name = false
            this.errors.address = false 
            this.errors.city = false
            this.errors.province = false 
            this.postal_code = false 
            if(!this.form.name) {
                this.errors.name = true 
                is_success = false 
            }else {
                let length = this.form.name.length
                if(length < 3 || length > 141) {
                    this.errors.name = true 
                    is_success = false 
                }
            }

            if(!this.form.address) {
                this.errors.address = true 
                is_success = false 
            }else {
                let length = this.form.address.length
                if(length < 3 || length > 50) {
                    this.errors.address = true 
                    is_success = false 
                }
            }

            if(!this.form.city) {
                this.errors.city = true 
                is_success = false 
            }else {
                let length = this.form.city.length
                if(length < 3 || length > 50) {
                    this.errors.city = true 
                    is_success = false 
                }
            }

            if(!this.form.province) {
                this.errors.province = true 
                is_success = false 
            }else {
                let length = this.form.province.length
                if(length < 3 || length > 50) {
                    this.errors.province = true 
                    is_success = false 
                }
            }

            if(!this.form.postal_code) {
                this.errors.postal_code = true 
                is_success = false 
            }else {
                let length = this.form.postal_code.length
                if(length != 5) {
                    this.errors.postal_code = true
                    is_success = false 
                }
            }

            return is_success
        },
        async userLogActivity() {
            if(this.$root.$store.state.user && this.$root.$store.state.user.full_name) {
                let route_name = {
                    name: 'master.company.form',
                    params: {
                        id: this.form.id
                    },
                    query: {
                        is_edit: false
                    }
                } 

                let secretKey = process.env.VUE_APP_KEY_ENCRYPT_DECRYPT
                let route_name_encrypt = this.EncryptDecryptAesCbc256.encryptAesCbc256(secretKey, JSON.stringify(route_name))

                let activity_type = 'create'
                let activity = `${this.$root.$store.state.user.full_name} has added a company with the name <a style="cursor: pointer" class="click-detail-log" data-route="${route_name_encrypt}"> ${this.form.name}</a>`
                if(this.id) {
                    activity = `${this.$root.$store.state.user.full_name} has modified the company with the name <a style="cursor: pointer" class="click-detail-log" data-route="${route_name_encrypt}"> ${this.form.name}</a>`
                    activity_type = 'edit'
                }

                let params = {
                    activity: activity,
                    feature: 'Company',
                    activity_type: activity_type,
                    route_name: route_name
                }

                this.$root.sendUserLogActivity(params)
            }
            
        },
    },
    computed: {
        calculate_address() {
            let length = 0
            if(this.form.address) {
                length = this.form.address.length
            }
            return length
        },
    },
    watch: {
        'form.name': {
            handler(val) {
                this.errors.name = false 
                if(!val) {
                    this.errors.name = true 
                }else {
                    let length = val.length
                    if(length < 3 || length > 141) {
                        this.errors.name = true 
                    }
                }
            }
        },
        'form.address': {
            handler(val) {
                this.errors.address = false 
                if(!val) {
                    this.errors.address = true 
                }else {
                    let length = val.length 
                    if(length < 3 || length > 50) {
                        this.errors.address = true
                    }
                }
            }
        },
        'form.city': {
            handler(val) {
                this.errors.city = false
                if(!val) {
                    this.errors.city = true 
                }else {
                    let length = val.length
                    if(length < 3 || length > 50) {
                        this.errors.city = true 
                    }
                }
            }
        },
        'form.province': {
            handler(val) {
                this.errors.province = false
                if(!val) {
                    this.errors.province = true 
                }else {
                    let length = val.length
                    if(length < 3 || length > 50) {
                        this.errors.province = true
                    }
                }
            }
        },
        'form.postal_code': {
            handler(val) {
                this.errors.postal_code = false 
                if(!val) {
                    this.errors.postal_code = true
                }else {
                    let length = val.length
                    if(length  != 5) {
                        this.errors.postal_code = true
                    }
                }
            }
        }
    }
}
</script>

<style scoped>

</style>