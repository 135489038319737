import Vue from 'vue'

const Select2 = {
    name: 'select-2',
    props: ['options', 'value'],
    template: '<select ref="select">  <slot></slot> </select>',
    
    mounted: function () {
        var vm = this
        $(this.$refs.select)
          .select2({
            data: this.options,
            tags: true,
            createTag: function (params) {
              return {
                id: params.term,
                text: params.term,
                newOption: true,
              }
            },
            templateResult: function (data) {
              var $result = $('<span></span>')
              $result.text(data.text)
              if (data.newOption) {
                $result.append(' <em>(new)</em>')
              }
              return $result
            },
            
          })
          .on('change', function (ev, args) {
            if (!(args && 'ignore' in args)) {
              vm.$emit('input', $(this).val())
            }
          })
    
        Vue.nextTick(() => {
          $(this.$refs.select).val(this.value).trigger('change', { ignore: true })
        })
      },
      watch: {
        value: function (value, oldValue) {
          // update value
          $(this.$refs.select).val(this.value).trigger('change', { ignore: true })
        },
        options: function (options) {
          // update options
          $(this.$refs.select).select2({ data: options })
        },
      },
      destroyed: function () {
        $(this.$refs.select).off().select2('destroy')
      },


}
Vue.component('select-2', Select2)
export default Select2