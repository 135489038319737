// START USER
import TableMasterUser from '@pages/MasterFile/User/TableMasterUser.vue'
import FormMasterUser from '@pages/MasterFile/User/FormMasterUser.vue'
import TableCashier from '@pages/MasterFile/Cashier/Table.vue'
import FormCashier from '@pages/MasterFile/Cashier/Form.vue'
// END USER

// START ROLE
import TableMasterRole from '@pages/MasterFile/Role/TableMasterRole.vue'
import FormMasterRole from '@pages/MasterFile/Role/FormMasterRole.vue'
// END ROLE

import FormCompany from '@pages/MasterFile/Company/Form.vue'
import ListCompany from '@pages/MasterFile/Company/List.vue'
import TableMachineLocation from '@pages/MasterFile/MachineLocation/Table.vue'
import FormMachineLocation from '@pages/MasterFile/MachineLocation/Form.vue'
import TableMachine from '@pages/MasterFile/Machine/Table.vue'
import FormMachine from '@pages/MasterFile/Machine/Form.vue'
import TableItem from '@pages/MasterFile/Item/Table.vue'
import FormItem from '@pages/MasterFile/Item/Form.vue'
import TableTicketPackage from '@pages/MasterFile/TicketPackage/Table.vue'
import FormTicketPackage from '@pages/MasterFile/TicketPackage/Form.vue'
import TablePaymentMethod from '@pages/MasterFile/PaymentMethod/Table.vue'
import FormPaymentMethod from '@pages/MasterFile/PaymentMethod/Form.vue'
import TableBanner from '@pages/MasterFile/Banner/Table.vue'
import FormBanner from '@pages/MasterFile/Banner/Form.vue'

import LogActivities from '@pages/MasterFile/Log/Log.vue'
import Visitor from '@pages/MasterFile/Visitor/Table.vue'
import { Tab } from 'bootstrap'

export default [
    /**
     * @ROUTER Master File > User Management
     */
    {
        path: 'user',
        name: 'master.user',
        component: TableMasterUser,
        props: {
            page: {
                title: "Master",
                subtitle: 'User Management'
            }
        },
        meta: {
            title: 'User',
            breadcrumb_tree: [{
                title: 'Master',
                path_name: null
            }, {
                title: 'User Management',
                path_name: null
            }]
        }
    },
    {
        path: 'user/form/:id?',
        name: 'master.user.form',
        component: FormMasterUser,
        props: true,
        meta: {
            title: 'Add User',
            breadcrumb_tree: [{
                    title: 'Master',
                    path_name: null
                },
                {
                    title: 'User Management',
                    path_name: null
                },
                {
                    title: 'User',
                    path_name: 'master.user'
                }
            ]
        }
    },
    {
        path: 'cashier',
        name: 'master.cashier',
        component: TableCashier,
        props: {
            page: {
                title: 'Master',
                subtitle: 'User Management'
            }
        },
        meta: {
            title: 'Cashier',
            breadcrumb_tree: [{
                title: 'Master',
                path_name: null,
            }, {
                title: 'User Management',
                path_name: null
            }]
        }
    },
    {
        path: 'cashier/form',
        name: 'master.cashier.form.create',
        component: FormCashier,
        props: true,
        meta: {
            title: 'Add Cashier',
            breadcrumb_tree: [{
                title: 'Master',
                path_name: null
            }, {
                title: 'User Management',
                path_name: null
            }, {
                title: 'Cashier',
                path_name: 'master.cashier'
            }]
        }
    },
    {
        path: 'cashier/form/:id',
        name: 'master.cashier.form',
        component: FormCashier,
        props: true,
        meta: {
            title: 'Detail Cashier',
            breadcrumb_tree: [{
                title: 'Master',
                path_name: null
            }, {
                title: 'User Management',
                path_name: null
            }, {
                title: 'Cashier',
                path_name: 'master.cashier'
            }]
        }
    },
    {
        path: 'role',
        name: 'master.role',
        component: TableMasterRole,
        props: {
            page: {
                title: 'User Role',
            }
        },
        meta: {
            title: 'User Role',
            breadcrumb_tree: [{
                title: 'Master',
                path_name: null
            }, {
                title: 'User Management',
                path_name: null
            }]
        },

    },
    {
        path: 'role/form',
        name: 'master.role.form.create',
        component: FormMasterRole,
        props: true,
        meta: {
            title: 'Add User Role',
            breadcrumb_tree: [{
                    title: 'Master',
                    path_name: null
                },
                {
                    title: 'User Management',
                    path_name: null
                },
                {
                    title: 'User Role',
                    path_name: 'master.role'
                }
            ]
        },
    },
    {
        path: 'role/form/:id',
        name: 'master.role.form',
        component: FormMasterRole,
        props: true,
        meta: {
            title: 'Edit User Role',
            breadcrumb_tree: [{
                    title: 'Master',
                    path_name: null
                },
                {
                    title: 'User Management',
                    path_name: null
                },
                {
                    title: 'User Role',
                    path_name: 'master.role'
                }
            ]
        }
    },
    {
        path: 'company',
        name: 'master.company',
        component: ListCompany,
        props: true,
        meta: {
            title: 'Company',
            breadcrumb_tree: [{
                title: 'Master',
                path_name: null
            }]
        }
    },
    {
        path: 'company/form/:id?',
        name: 'master.company.form',
        component: FormCompany,
        props: true,
        meta: {
            title: 'Edit Company',
            breadcrumb_tree: [{
                    title: 'Master',
                    path_name: null
                },
                {
                    title: 'Company',
                    path_name: 'master.company'
                }
            ]
        },
    },
    {
        path: 'machine-location',
        name: 'master.machine-location',
        component: TableMachineLocation,
        props: true,
        meta: {
            title: 'Location',
            breadcrumb_tree: [{
                title: 'Master',
                path_name: null
            }]
        }
    },
    {
        path: 'machine-location/form',
        name: 'master.machine-location.form.create',
        component: FormMachineLocation,
        props: true,
        meta: {
            title: 'Add Location',
            breadcrumb_tree: [{
                    title: 'Master',
                    path_name: null
                },
                {
                    title: 'Location',
                    path_name: 'master.machine-location'
                }
            ]
        },
    },
    {
        path: 'machine-location/form/:id',
        name: 'master.machine-location.form',
        component: FormMachineLocation,
        props: true,
        meta: {
            title: 'Edit Location',
            breadcrumb_tree: [{
                    title: 'Master',
                    path_name: null
                },
                {
                    title: 'Location',
                    path_name: 'master.machine-location'
                },
            ]
        }
    },
    {
        path: 'machine',
        name: 'master.machine',
        component: TableMachine,
        props: true,
        meta: {
            title: 'Machine',
            breadcrumb_tree: [{
                title: 'Master',
                path_name: null
            }]
        }
    },
    {
        path: 'machine/form',
        name: 'master.machine.form.create',
        component: FormMachine,
        props: true,
        meta: {
            title: 'Add Machine',
            breadcrumb_tree: [{
                    title: 'Master',
                    path_name: null
                },
                {
                    title: 'Machine',
                    path_name: 'master.machine'
                }
            ]
        },
    },
    {
        path: 'machine/form/:id',
        name: 'master.machine.form',
        component: FormMachine,
        props: true,
        meta: {
            title: 'Edit Machine',
            breadcrumb_tree: [{
                    title: 'Master',
                    path_name: null
                },
                {
                    title: 'Machine',
                    path_name: 'master.machine'
                },
            ]
        }
    },
    {
        path: 'item',
        name: 'master.item',
        component: TableItem,
        props: true,
        meta: {
            title: 'Item',
            breadcrumb_tree: [{
                title: 'Master',
                path_name: null
            }]
        }
    },
    {
        path: 'item/form',
        name: 'master.item.form.create',
        component: FormItem,
        props: true,
        meta: {
            title: 'Add Item',
            breadcrumb_tree: [{
                    title: 'Master',
                    path_name: null
                },
                {
                    title: 'Item',
                    path_name: 'master.item'
                }
            ]
        },
    },
    {
        path: 'item/form/:id',
        name: 'master.item.form',
        component: FormItem,
        props: true,
        meta: {
            title: 'Edit Item',
            breadcrumb_tree: [{
                    title: 'Master',
                    path_name: null
                },
                {
                    title: 'Item',
                    path_name: 'master.item'
                },
            ]
        }
    },
    {
        path: 'ticket-package',
        name: 'master.ticket-package',
        component: TableTicketPackage,
        props: true,
        meta: {
            title: 'Ticket Package',
            breadcrumb_tree: [{
                title: 'Master',
                path_name: null
            }]
        }
    },
    {
        path: 'ticket-package/form',
        name: 'master.ticket-package.form.create',
        component: FormTicketPackage,
        props: true,
        meta: {
            title: 'Add Ticket Package',
            breadcrumb_tree: [{
                    title: 'Master',
                    path_name: null
                },
                {
                    title: 'Ticket Package',
                    path_name: 'master.ticket-package'
                }
            ]
        },
    },
    {
        path: 'ticket-package/form/:id',
        name: 'master.ticket-package.form',
        component: FormTicketPackage,
        props: true,
        meta: {
            title: 'Edit Ticket Package',
            breadcrumb_tree: [{
                    title: 'Master',
                    path_name: null
                },
                {
                    title: 'Ticket Package',
                    path_name: 'master.ticket-package'
                },
            ]
        }
    },
    {
        path: 'payment-method',
        name: 'master.payment-method',
        component: TablePaymentMethod,
        props: true,
        meta: {
            title: 'Payment Method',
            breadcrumb_tree: [{
                title: 'Master',
                path_name: null
            }]
        }
    },
    {
        path: 'payment-method/form/:id',
        name: 'master.payment-method.form.edit',
        component: FormPaymentMethod,
        props: true,
        meta: {
            title: 'Edit Payment Method',
            breadcrumb_tree: [{
                    title: 'Master',
                    path_name: null
                },
                {
                    title: 'Payment Method',
                    path_name: 'master.payment-method'
                }
            ]
        },
    },
    {
        path: 'payment-method/form',
        name: 'master.payment-method.form.create',
        component: FormPaymentMethod,
        props: true,
        meta: {
            title: 'Add Payment Method',
            breadcrumb_tree: [{
                    title: 'Master',
                    path_name: null
                },
                {
                    title: 'Payment Method',
                    path_name: 'master.payment-method'
                }
            ]
        },
    },
    {
        path: 'payment-method/form/:id',
        name: 'master.payment-method.form',
        component: FormPaymentMethod,
        props: true,
        meta: {
            title: 'Edit Payment Method',
            breadcrumb_tree: [{
                    title: 'Master',
                    path_name: null
                },
                {
                    title: 'Payment Method',
                    path_name: 'master.payment-method'
                },
            ]
        }
    },
    {
        path: 'banner',
        name: 'master.banner',
        component: TableBanner,
        props: true,
        meta: {
            title: 'Banner',
            breadcrumb_tree: [{
                title: 'Master',
                path_name: null
            }]
        }
    },
    {
        path: 'banner/form',
        name: 'master.banner.form.create',
        component: FormBanner,
        props: true,
        meta: {
            title: 'Add Banner',
            breadcrumb_tree: [{
                    title: 'Master',
                    path_name: null
                },
                {
                    title: 'Banner',
                    path_name: 'master.banner'
                }
            ]
        },
    },
    {
        path: 'banner/form/:id',
        name: 'master.banner.form',
        component: FormBanner,
        props: true,
        meta: {
            title: 'Edit Banner',
            breadcrumb_tree: [{
                    title: 'Master',
                    path_name: null
                },
                {
                    title: 'Banner',
                    path_name: 'master.banner'
                },
            ]
        }
    },
    {
        path: 'user/log-activities',
        name: 'master.user.log-activities',
        component: LogActivities,
        props: true,
        meta: {
            title: 'Log',
            breadcrumb_tree: [{
                title: 'Master',
                path_name: null
            }, {
                title: 'User Management',
                path_name: null
            }]
        }
    },
    {
        path: 'visitor',
        name: 'master.visitor',
        component: Visitor,
        props: true,
        meta: {
            title: 'Visitor',
            breadcrumb_tree: [{
                title: 'Master',
                path_name: null
            }, {
                title: 'User Management',
                path_name: null
            }]
        }
    }

]