import Vue from 'vue'
import LoadingButton from './LoadingButton'
import SaveButton from './SaveButton'
import RemoveButton from './RemoveButton'

Vue.component('loading-button', LoadingButton)
Vue.component('save-button', SaveButton)
Vue.component('remove-button', RemoveButton)

export default {}
