<template>
    <div class="modal fade show-action" aria-hidden="true" data-backdrop="static" data-keyboard="false">
        <div class="modal-dialog show-action" :class="dialogClass">
            <div class="modal-content show-action">
                <div class="modal-header show-action">
                    <slot name="header" class="show-action">
                        <close-x :isDoClose="isDoClose" @onCloseModal="closeModal" />
                        <h4 class="modal-title show-action" v-if="title || icon">
                            <i class="fa show-action" :class="icon" v-if="icon"></i> {{ title }}
                            <span v-if="title_detail" class="show-action">
                                <svg class="show-action" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="5" cy="5" r="3" fill="#000000"/>
                                </svg>
                                {{ title_detail }}
                            </span>
                        </h4>
                    </slot>
                </div>
                <div class="modal-body modal-processing show-action" :class="{active: modalLoading}">
                    <slot></slot>
                    <div class="process-loading show-action">
                        <div class="panel panel-default show-action">
                            <i class="fa fa-circle-notch fa-spin show-action"></i> Loading ...
                        </div>
                    </div>
                </div>
                <div class="modal-footer show-action" v-if="footer == true || footer == 'true'">
                    <slot name="footer" class="show-action">
                        <div class="btn-group pull-right show-action">
                            <slot name="footer-actions-before" class="show-action"></slot>
                            <save-button  v-if="save == true || save == 'true'" :loading="modalLoading" :saveText="saveText" @click="saveButton" :classBtn="is_owner ? `btn-submit-owner` : `btn-submit`"/>
                            <remove-button v-if="remove == true || remove == 'true'" :loading="modalLoading" :iconRemove="iconRemove" @click="removeButton" :removeText="removeText"/>
                            <slot name="footer-actions" class="show-action"></slot>
                        </div>
                    </slot>
                    <close v-if="cancel" :isDoClose="isDoClose" @onCloseModal="closeModal" :closeText="closeText"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
const {console} = window
const defaultObject = () => {
    return {}
}
export default {
    name: 'modal',
    props: {
        title: {
            type: String,
            required: false,
            default: null
        },
        title_detail: {
            type: String,
            required: false,
            default: null
        },
        icon: {
            type: [String, Object],
            required: false,
            default: null
        },
        dialogClass: {
            type: [String, Object],
            required: false,
            default: defaultObject
        },
        footer: {
            type: [String, Boolean],
            required: false,
            default: true
        },
        loading: {
            type: Boolean,
            default: null,
            required: false
        },
        autofocus: {
            type: [String, Boolean],
            default: null,
            required: false
        },
        show: {
            type: Boolean,
            default: false,
            required: false
        },
        save: {
            type: [String, Boolean],
            default: null,
            required: false
        },
        remove: {
            type: [String, Boolean],
            default: null,
            required: false
        },
        removeText: {
            type: [String],
            default: null,
            required: false
        },
        saveText: {
            type: [String],
            default: null,
            required: false
        },
        classBtn: {
            type: [String],
            default: 'btn-primary',
            required: false
        },
        cancel: {
            type: [String, Boolean],
            default: true,
            required: false
        },
        isDoClose: {
            type: [String, Boolean],
            default: null,
            required: false
        },
        iconRemove: {
            type: [String, Object],
            required: false,
            default: null
        },
        closeText:{
            type: [String],

        }
    },
    data() {
        return {
            modal: $('.modal-dialog', this.$el).parent()
        }
    },
    mounted() {
        $('.modal-dialog', this.$el).parent()
        .on('hide.bs.modal', e => this.$emit('hide', e))
        .on('hidden.bs.modal', e => this.$emit('hidden', e))
        .on('show.bs.modal', e => this.$emit('show', e))
        .on('shown.bs.modal', (e) => {
            this.$emit('shown', e)
            var focus = this.autofocus == 'false' || this.autofocus == false
            if (!focus) {
                $('input.form-control', $(e.target).closest('.modal')).eq(0).focus()
            }
        })
    },
    methods: {
        saveButton(e) {
            this.$emit('onSave', e)
        },
        removeButton(e) {
            this.$emit('onRemove', e)
        },
        closeModal(e) {
            this.$emit('onClose', e)
        }
    },
    computed: {
        modalLoading() {
            var loading = false
            if (null == this.loading) {
                try {
                    loading = this.$store.state.loading
                } catch (e) {
                    loading = false
                }
            } else {
                loading = this.loading
            }

            return loading
        },
        is_owner() {
            let response = false
            let temp = localStorage.getItem('_USER_ME')
            if(temp) {
                let bytes = this.CryptoJS.AES.decrypt(temp, process.env.VUE_APP_KEY_ENCRYPT_DECRYPT)
                let data = JSON.parse(bytes.toString(this.CryptoJS.enc.Utf8))
                if(data) {
                    if(data.company_id) {
                        response = true
                    }
                }
            }

            return response
        }
    },
    watch: {
        show(show) {
            $('.modal-dialog', this.$el).parent().modal(show ? 'show' : 'hide')
        }
    },
    destroyed() {
        $('.modal-dialog', this.$el).parent().off()
    }
}
</script>


<style>
.modal-header {
    background-color: #F5F5F5;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
}

.modal-body.box-processing {
    position: relative;
}
.modal-body.modal-processing.active {
    min-height: 70px !important;
}
.modal-processing .process-loading {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.02);
}
.modal-processing.active,
.modal-processing.active .process-loading {
    cursor: progress;
    touch-action: none;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.modal-processing.active .process-loading {
    display: block;
}
.modal-processing.active .dataTables_processing,
.modal-processing.active .modal-processing .process-loading {
    display: none !important;
}
.modal-processing .process-loading .panel {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 200px;
    margin-left: -100px;
    margin-top: -26px;
    text-align: center;
    padding: 1em 0;
}

.btn-submit,
.btn-submit:hover,
.btn-submit:focus {
    background-color:#06DCF8;
    color: #FFFFFF !important;
    
}

.btn-submit-owner,
.btn-submit-owner:hover,
.btn-submit-owner:focus {
    background-color: #18A891;
    color: #FFFFFF !important;
}

.btn-group > .btn:first-child {
    margin-left: 5px !important;
}


.modal-content  {
    -webkit-border-radius: 16px !important;
    -moz-border-radius: 16px !important;
    border-radius: 16px !important; 
}

.modal-dialog {
    margin-top: 5% !important;
}

.modal-title {
    font-family: Inter !important;
    font-size: 20px !important;
    font-weight: 500 !important;
    line-height: 24px !important;
    letter-spacing: -0.011em !important;
    text-align: left;
    color: #404040 !important;
}
</style>
