<template>
    <svg :width="width" :height="height" viewBox="0 0 16 16" :fill="fill" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M8.23431 4.23431C8.54673 3.9219 9.05326 3.9219 9.36568 4.23431L12.5657 7.43432C12.8781 7.74673 12.8781 8.25327 12.5657 8.56569L9.36568 11.7657C9.05326 12.0781 8.54673 12.0781 8.23431 11.7657C7.92189 11.4533 7.92189 10.9467 8.23431 10.6343L10.0686 8.8L4 8.8C3.55817 8.8 3.2 8.44183 3.2 8C3.2 7.55817 3.55817 7.2 4 7.2H10.0686L8.23431 5.36569C7.92189 5.05327 7.92189 4.54673 8.23431 4.23431Z" :fill="fill_path_1"/>
    </svg>
</template>

<script>
export default {
    props: {
        width: {
            type: String,
            default: '16'
        },
        height: {
            type: String,
            default: '16'
        },
        fill: {
            type: String,
            default: 'none'
        },
        fill_path_1: {
            type: String,
            default: '#616161'
        },
    },
    data() {
        return {
            
        }
    },
}
</script>

<style scoped>

</style>