<template>
    <div class="error-page" style="margin-top: 15%;">
        <h2 class="headline text-yellow" style="margin-top: -28px;font-size:131px;margin-right: 16px;"> 404</h2>

        <div class="error-content">
          <h3><i class="fa fa-warning text-yellow"></i> Oops! Halaman tidak ditemukan.</h3>

          <p>
            Kami tidak dapat menemukan halaman yang Anda cari.<br>
            Kami tidak dapat menemukan halaman yang Anda cari. Sementara itu, Anda dapat  <a href="./">kembali ke menu dashboard</a>.
          </p>
        </div>
        <!-- /.error-content -->
    </div>
</template>

<script>
const {host} = window
export default {
  data() {
    return {

    }
  },
  created() {
    const KEY_PREFIX = btoa(host)
    const TOKEN_KEY = KEY_PREFIX + '$_access_token'
    let url = this.$router.currentRoute.path
    let token  = localStorage.getItem(TOKEN_KEY)
    let urls = [
      'login',
      '/login',
      'register',
      '/register',
      'forgot-password',
      '/forgot-password',
      '/recover-password',
      'recover-password'
    ]
    if(token && urls.indexOf(url) >= 0) {
      this.$root.$router.push({name: 'dashboard'})
    }
  }
};
</script>
