<template>
    <div>

        <header-title-button
            title="Company"
            :isAdd="false">
        </header-title-button>

        <box header="false"
            :cancel="false"
            :save="false">
            <div>
                <div class="row">
                    <div class="col-md-11 col-xs-10" style="display: inline; margin-bottom: 10px;">
                        <div style="display: inline-block;">
                            <img src="@/assets/svg/logo.svg" alt=""  class="img-logo">
                        </div>
                        <div class="div-company-info">
                            <p class="title"  style="margin-left:3%; ">
                                {{ company.name }}
                            </p>
                            
                        
                            <p style="margin-left:3%;">
                                <span>
                                    <IconLocation />
                                </span>
                                
                                <span class="description">
                                    {{company.address}} • {{company.city}} • {{company.province}} • {{company.postal_code}}
                                </span>
                            </p>
                        </div>
                    </div>
                    
                    <div class="col-md-1 col-xs-2" v-if="permission_edit">
                        <div class="icon" style="cursor:pointer;" @click="companyDetail(company.id)">
                            <IconPencil/>
                        </div>
                    </div>
                </div>
            </div>
        </box>
    </div>
</template>

<script>
import IconPencil from '@components/svg/IconPencil.vue'
import IconLocation from '@components/svg/IconLocation.vue'

const {console} = window

export default {
    components: {
        IconPencil,
        IconLocation
    },
    data() {
        return {
            company: [],
            alias: 'company',
            permission_edit: false
        }
    },
    async mounted() {
        await this.$root.checkingAccessPage(this.$root._route.name)

        let permission_edit = await this.$root.checkingButtonsPage(this.alias, 'update')
        if(permission_edit.validate) {
            this.permission_edit = true
        }

        this.init();
    },
    methods: {
        async init() {
            Rest.get('/company')
            .then( async(res) => {
                if(res && res.data) {
                    this.company = res.data
                }
            })
            .catch( () => {

            })
        },
        async companyDetail(id) {
            this.$root.$router.push(
                {
                    name: 'master.company.form',
                    params: {
                        id: id
                    },
                    query: {
                        is_edit: true
                    }
                }
            )
        },
    },
    computed: {

    },
    watch: {
        filter_status: {
            handler() {
                this.init()
            }
        },
    }
}
</script>

<style scoped>
.img-logo {
    margin-right: 20px;
}
.icon {
    margin-top: 50%;
}

.display-inline {
    display: inline;
}

.display-inline-block {
    display: inline-block;
}

.div-company-info {
    display: inline-block; 
    width: 80%;
    overflow: auto;
}

.title{
    padding-top:2%;
    font-size:18px;
    font-weight:500 !important;
    line-height: 24px;
    letter-spacing: -1.1%;
    color: #0A0A0A;
    word-wrap: break-word;
}

span.description{
    padding-left: 0.5%;
    font-size:18px;
    font-weight:500 !important;
    line-height: 20px;
    letter-spacing: -0.6%;
    color: #757575;
    word-wrap: break-word;
}

@media only screen and (min-width: 1024px) {
    .img-logo {
        margin-top: -50%; 
    }
}

</style>