<template>
    <div class="row">
        <div class="col-md-12 col-xs-12">
            <img src="@/assets/img-bg-large.png" alt="img-bg-large" style="width: 100%" />
            <box header="false"
                :cancel="false"
                :save="false"
                style="margin-top: -110px;">
                <div>
                    <div class="row">
                        <div class="col-md-9">
                            <table>
                                <tr>
                                    <td>
                                        <img v-if="days.is_moon" src="@/assets/svg/moon.svg" alt="moon">
                                        <img v-else src="@/assets/svg/sun.svg" alt="sun">
                                    </td>
                                    <td>&nbsp; &nbsp;</td>
                                    <td>
                                        <p class="info-date-dashboard">{{ days.days }}</p>
                                        <p class="info-user-dashboard">{{ days.greeting }}, {{$root.$store.state && $root.$store.state.user && $root.$store.state.user.full_name ?  $root.$store.state.user.full_name : ''}} !</p>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div class="col-md-3">
                            <div class="pull-right">
                                <div v-if="is_permission_setting"
                                    class="btn-redirect-setting"
                                    @click="redirectSetting">
                                    <span class="redirect-setting-text">Settings</span>
                                    <span class="redirect-setting-text-01">&nbsp;|&nbsp;</span>
                                    <img src="@/assets/svg/setting.svg"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </box>
        </div>
    </div>
</template>

<script>
import moment from 'moment'
export default {
    data() {
        return {
            is_permission_setting: false,
            greetings :[
                {name: "Good Morning", start: "05:00", end: "11:59"},
                {name: "Good Afternoon", start: "12:00", end: "16:59"},
                {name: "Good Evening", start: "17:00", end: "04:59"}
            ],
            days: {
                days: null,
                greeting: null,
                is_moon: true,
            }
        }
    },
    async mounted() {
        let permission_read = await this.$root.checkingButtonsPage('general-setting', 'read')
        if (permission_read.validate) {
            this.is_permission_setting = true
        }

        this.init()

        let self = this 
        $(document).ready(function() {
            setInterval(self.init, 1000);
        })
    },
    methods: {
        redirectSetting() {
            this.$root.$router.push({
                name: 'setting.general-setting'
            })
        },
        init() {
            moment.locale('EN')
            let days = moment().format('dddd, DD MMMM YYYY')
            let time = moment()
            let greeting = "Good Evening"
            let is_moon = true 
            this.greetings.forEach (( item) => {
            

                let start = moment(moment().format('YYYY-MM-DD') + ' ' +item.start+':00')
                let end =  moment(moment().format('YYYY-MM-DD') + ' ' +item.end+':00')
                if(time.isAfter(start)) {
                    if(time.isBefore(end)) {
                        greeting = item.name
                    }
                } 

                if(time.format('YYYY-MM-DD HH:mm:ss') == start.format('YYYY-MM-DD HH:mm:ss')) {
                    greeting = item.name
                }

                if(time.format('YYYY-MM-DD HH:mm:ss') == end.format('YYYY-MM-DD HH:mm:ss')) {
                    greeting = item.name
                }
            })


            switch (greeting) {
                case 'Good Morning':
                    is_moon = false 
                    break;
                case 'Good Afternoon':
                    is_moon = false 
                    break;
                case 'Good Evening':
                    is_moon = true
                    break;
                default:
                    break;
            }
            
                this.days.days = days
                this.days.greeting = greeting
                this.days.is_moon = is_moon
        }
    },
    computed: {
       
    },
    watch: {

    }
}
</script>

<style scoped>
.info-date-dashboard {
    color: #616161;
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.011em;
    text-align: left;
}

.info-user-dashboard {
    color: #0A0A0A;
    font-family: Inter;
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: -0.014em;
    text-align: left;
}

.btn-redirect-setting {
    background: #fff; 
    cursor: pointer; 
    padding: 7px 12px; 
    border: 1px solid #ccc; 
    border-radius: 6px;
    width: 100%;
    margin-top: 15px;
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.011em;
    text-align: right;
}

.redirect-setting-text {
    color: #404040
}

.redirect-setting-text-01 {
    color: #E0E0E0
}
</style>