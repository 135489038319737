<template>
    <div>
        <h3>Visitor</h3>

        <datatable
            id="table-visitor"
            :config="config" 
            class="superuser-datatable" 
            :settingFilters="settingFilters"
            :defaultFilter="defaultFilter" 
        />
    </div>
</template>

<script>
const {console} = window
export default {
    data() {
        return {
            config: {},
            alias: 'visitor',
            user: this.$root.$store.state.user || null,
            settingFilters: '',
            defaultFilter: null,
            filter_date: {
                start_date: moment().startOf('month').format('YYYY-MM-DD'), 
                end_date: moment().endOf('month').format('YYYY-MM-DD')
            },
            filter_date_temp: null,
            rangeOptions: this.$root.$store.state.rangeOptions,
            interval: null
        }
    },
    async mounted() {
        this.$root.checkingAccessPage(this.$root._route.name)
        this.destroyed()

        // this.init()

        if(!this.user) {
            let userMe = localStorage.getItem('_USER_ME')
            if(userMe) {
                let userMeBytes = this.CryptoJS.AES.decrypt(userMe, process.env.VUE_APP_KEY_ENCRYPT_DECRYPT)
                let userMeData = JSON.parse(userMeBytes.toString(this.CryptoJS.enc.Utf8))
                if(userMeData) {
                   this.user = userMeData
                   this.$root.$store.state.user = userMeData
                }
            }
        }

        this.setupSettingFilter()


        let self = this 
        $(document).ready(function() {
            self.loadDateRangePicker()

        })

        setTimeout(self.tiggerUpdateDropDown, 1500);
        
    },
    methods: {
        async init() {
            let self = this 
            let defaultFilter = null 

            this.defaultFilter = defaultFilter
            this.config = {
                columns: [{
                    title: 'Customer Name',
                    data: 'customer_name'
                }, {
                    title: 'Phone',
                    data: 'phone'
                }, {
                    title: 'Email',
                    data: 'email'
                }],
                responsive: false,
                scrollX: true,
                url: Api.base+ '/visitor',
                searchPlaceholder: 'Search a visitor ...',
            }
        },
        async setupSettingFilter() {
            // let self = this

            // this.settingFilters = `<div>
            //     <span id="reportrange" class="form-control" style="background: #fff; cursor: pointer; padding: 5px 10px; border: 1px solid #ccc; width: 290px">
            //         <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            //             <path d="M8 7V3M16 7V3M7 11H17M5 21H19C20.1046 21 21 20.1046 21 19V7C21 5.89543 20.1046 5 19 5H5C3.89543 5 3 5.89543 3 7V19C3 20.1046 3.89543 21 5 21Z" stroke="#404040" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            //         </svg>
            //         &nbsp;
            //         <span class="date-range-text-info" style="position: absolute;margin-top: 2px;"></span> 
            //     </span>
            // </div>`


            this.init()

            setTimeout(self.selectDropdown, 1000);

        },
        tiggerUpdateDropDown() {
            let tempDate = $('#reportrange span.date-range-text-info').text()
            if(!tempDate) {
                this.loadDateRangePicker()
            }
        },
        async loadDateRangePicker() {
            let self = this 
            let range_option = {}
            this.rangeOptions.forEach( (item) => {
                if(item && item.name && item.range) {
                    range_option[item.name] = item.range
                }
            })
            $(function() {

                var start = moment(self.filter_date.start_date);
                var end = moment(self.filter_date.end_date);

                var daysInYear = 366

                function cb(start, end) {
                    moment.locale('en')
                    var tempDate = start.format('D MMMM YYYY') + ' - ' + end.format('D MMMM YYYY')
                    self.rangeOptions.forEach( (item) => {
                        if(item && item.name && item.start_date && item.end_date) {
                            if(item.start_date == start.format('YYYY-MM-DD') && item.end_date == end.format('YYYY-MM-DD')) {
                                tempDate = item.name
                            }
                        }
                    })
                    var diff = end.diff(start, 'days') 
                    if(diff > daysInYear) {
                        console.log('error disini')
                        self.$root.showNotification('Failed', 'error', 'Maximum search range is 1 year.')
                    }else {
                        self.filter_date_temp = tempDate

                        var start_date = start.format('YYYY-MM-DD')
                        var end_date = end.format('YYYY-MM-DD')

                        self.filter_date.start_date = start_date
                        self.filter_date.end_date = end_date
                        $('#reportrange span.date-range-text-info').html(tempDate);
                    }
                   
                }

                $('#reportrange').daterangepicker({
                    opens: 'right',
                    startDate: start,
                    endDate: end,
                    autoApply: true,
                    alwaysShowCalendars: true,
                    showCustomRangeLabel: false,
                    ranges: range_option,
                    locale: {
                        format: "YYYY-MM-DD",
                        separator: " - ",
                        applyLabel: "Apply",
                        cancelLabel: "Cancel",
                        fromLabel: "From",
                        toLabel: "To",
                        customRangeLabel: "Custom",
                        weekLabel: "W",
                        daysOfWeek: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
                        monthNames:  ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Agu', 'Sep', 'Oct', 'Nov', 'Dec'],
                        firstDay: 1
                    },
                }, cb);

                cb(start, end);

            });
        },
        destroyed() {
            clearInterval(this.interval)
        },
        
    },
    computed: {

    },
    watch: {
        filter_date_temp: {
            handler() {
                this.setupSettingFilter()
                this.destroyed()
                this.interval = setInterval(this.tiggerUpdateDropDown, 200)
            }
        },
        interval: {
            handler(val, oldVal) {
                console.log('change interval', {
                    val: val,
                    old: oldVal
                })
            }
        }
    }
}
</script>

<style scoped>

</style>