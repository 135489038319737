<template>
  <div>
    <Error404 v-if="display404"></Error404>
    <div v-else>
      <header-title-button
          :title="$route.params.id ? disabledForm ?  'Detail User Role' : 'Edit User Role' : 'Add User Role' "
          :isAdd="false">
        </header-title-button>
    
      <box
        back="master.role"
        title="User Role Information"
        :save="!disabledForm"
        @onSave="doSave"
        :remove="isRemove"
        @onRemove="doRemove"
        :saveText="form.id ? 'Edit' : 'Save' "
      >
        <div class="row">
          <div class="col-md-6">
            <div class="form-group" :class="{'has-error': !!errors.name}">
                <label class="control-label">Role Name 
                  <span class="warning-text-required">*</span>
                </label>
                <input
                    id="name"
                    v-model="form.name"
                    class="form-control name alpha-numeric-other-char"
                    :type="'text'"
                    :disabled="disabledForm || is_disable_name"
                    placeholder="Input role name"
                    maxlength="141"
                    />
                <p class="help help-block" v-if="errors.name"> {{  form.name ? `Role name must be at least three characters, maximum one hundred forty-one characters.` : `Role name is required.` }}</p>
            </div>
          </div>
        </div>
        <div class="row" v-if="form.name">
          <div class="col-md-12">
            <table class="table table-bordered table-striped" >
              <thead>
                <tr>
                  <th class="text-center" style="width: 40%">Menu</th>
                  <th class="text-center" style="width: 15%">Create</th>
                  <th class="text-center" style="width: 15%">Read</th>
                  <th class="text-center" style="width: 15%">Edit</th>
                  <th class="text-center" style="width: 15%">Delete</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(data, index) in parent" :key="index">
                  <td :colspan="data.parent ? '5' : '' "  :style="data.parent ? 'width: 100%;' : 'width: 40%;'">
                    <span :style="data.parent ? 'font-weight: bold; padding-left:'+data.space+'px;' : 'padding-left: '+data.space+'px;' "
                    >
                      {{ data.title }}
                    </span>
                  </td>
                  <td class="text-center" v-if="!data.parent" style="width: 15%">
                    <label class="label-checkbox" v-if="data.detail.checkbox.create.show"
                      data-toggle="tooltip" :title="'Buat Data '+data.title "
                      data-placement="top"
                    >
                      <input type="checkbox"
                        :id="'checkbox-create-'+index"
                        :name="index"
                        class="checkbox-create"
                        :checked="data.detail.checkbox.create.value"
                        :value="data.detail.checkbox.create.value"
                        :disabled="data.detail.checkbox.create.disabled || disabledForm"
                      />
                      <span class="checkmark" :style="data.detail.checkbox.create.disabled ? 'cursor: not-allowed; opacity: 0.65;' : '' ||
                      disabledForm ? 'cursor: not-allowed; opacity: 0.65;' : '' ">&nbsp;</span>
                    </label>
                  </td>
                  <td class="text-center" v-if="!data.parent" style="width: 15%">
                    <label class="label-checkbox" v-if="data.detail.checkbox.read.show"
                    data-toggle="tooltip" :title="'Lihat Data '+data.title "
                    data-placement="top"
                    >
                      <input type="checkbox"
                        :id="'checkbox-read-'+index"
                        :name="index"
                        class="checkbox-read"
                        :checked="data.detail.checkbox.read.value"
                        :value="data.detail.checkbox.read.value"
                        :disabled="data.detail.checkbox.read.disabled || disabledForm"
                      />
                      <span class="checkmark" :style="data.detail.checkbox.read.disabled ? 'cursor: not-allowed; opacity: 0.65;' : '' ||
                      disabledForm ? 'cursor: not-allowed; opacity: 0.65;' : '' ">&nbsp;</span>
                    </label>
                  </td>
                  <td class="text-center" v-if="!data.parent" style="width: 15%">
                    <label class="label-checkbox" v-if="data.detail.checkbox.update.show"
                      data-toggle="tooltip" :title="'Edit Data '+data.title "
                      data-placement="top"
                    >
                      <input type="checkbox"
                        :id="'checkbox-update-'+index"
                        :name="index"
                        class="checkbox-update"
                        :checked="data.detail.checkbox.update.value"
                        :value="data.detail.checkbox.update.value"
                        :disabled="data.detail.checkbox.update.disabled || disabledForm"
                      />
                      <span class="checkmark" :style="data.detail.checkbox.update.disabled ? 'cursor: not-allowed; opacity: 0.65;' : '' ||
                      disabledForm ? 'cursor: not-allowed; opacity: 0.65;' : '' ">&nbsp;</span>
                    </label>
                  </td>
                  <td class="text-center" v-if="!data.parent" style="width: 15%">
                    <label class="label-checkbox" v-if="data.detail.checkbox.delete.show"
                      data-toggle="tooltip" :title="'Hapus Data '+data.title "
                      data-placement="top"
                    >
                      <input type="checkbox"
                        :id="'checkbox-delete-'+index"
                        :name="index"
                        class="checkbox-delete"
                        :checked="data.detail.checkbox.delete.value"
                        :value="data.detail.checkbox.delete.value"
                        :disabled="data.detail.checkbox.delete.disabled || disabledForm"
                      />
                      <span class="checkmark" :style="data.detail.checkbox.delete.disabled ? 'cursor: not-allowed; opacity: 0.65;' : '' ||
                      disabledForm ? 'cursor: not-allowed; opacity: 0.65;' : '' ">&nbsp;</span>
                    </label>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <modal :title="form.id ? 'Change Confirmation' : 'Add Confirmation' " save="true" remove="false"
              :show="isModalConfirm"
              :saveText=" form.id ? 'Yes, Confirmation' : 'Save'"
              @hidden="isModalConfirm = false"
              @onSave="doSaveConfirm()">
            <span class="span-title">Are you sure you want to {{ messageConfirm }} access permission {{form.name}} ?</span>
        </modal>


        <modal 
            title="Confirm Deletion" 
            id="modal-delete-role"
            save="true" 
            remove="false"
            :show="is_confirm_delete"
            :loading="is_confirm_delete_loading"
            saveText="Yes, delete"
            @hidden="is_confirm_delete = false"
            @onSave="doRemoveProcess()">
            <span class="span-title">Are you sure you want to delete the access permission <b>{{temp_name}} </b> ?</span>
        </modal>

        <!-- <div class="modal fade" id="modal-delete-role">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <close-x/>
                        <h4 class="modal-title">Confirm Deletion</h4>
                    </div>
                    <div class="modal-body">
                        <span class="font-size-16">Are you sure you want to delete the access permission  <b>{{ temp_name }}</b> ?</span>
                    </div>
                    <div class="modal-footer">
                        <remove-button class="pull-left" @click="doRemoveProcess"
                          v-if="isRemove"></remove-button>
                        <close/>
                    </div>
                </div>
            </div>
        </div> -->

      </box>
      </div>
  </div>
</template>

<script>
  import Error404 from '@pages/Error404.vue'
  import dataMenu from '@config/datamenu.json'

  const {console} = window
  export default {
    components: {
      Error404
    },
    props: [],
    data() {
      return {
        is_edit: this.$route.query.is_edit,
        id: this.$route.params.id,
        old_role_name: null,
        form: {
          id: this.$route.params.id,
          name: null,
          type: 'user_role',
          permission_detail: {
            navigations: [],
            permission: []
          }
        },
        errors: {
          name: false
        },
        form_temp: null,
        alias: 'user.role',
        permission_type: this.$route.params.id ? 'update' : 'create',
        permission_type_delete: 'delete',
        disabledForm: false,
        isRemove: false,
        myRoleId: null,
        role_name: null,
        menu: [],
        sidebarListCheckbox: [],
        parent: [],
        disabledRole: false,
        existRole: ["ROLE_SUPERUSER", "ROLE_ADMIN", "ROLE_USER", "ROLE_MACHINE", "ROLE_GATE_SCAN"],
        button: ['create', 'read', 'update', 'delete', 'print', 'approval'],
        navigations: ['dashboard', 'sales.booking-list', 'sales.event', 'sales.event.form'],
        permission: [
          {
            title: "Dashboard",
            alias: "Dashboard",
            parent: false,
            space: "",
            detail: {
              checkbox: {
                create : {
                  show : false,
                  value : false,
                  disabled: false,
                  route : {
                    name : ""
                  },
                  selected_on: [

                  ]
                },
                read : {
                  show : true,
                  value: true,
                  disabled: true,
                  route: {
                    name : "dashboard"
                  },
                  selected_on: [
                    "profile",
                    "profile.change-email",
                    "profile.change-password",
                    "profile.change-signature"
                  ],
                  endpoint: {
                    get: [
                      "api/v1/user/me/detail",
                      "api/v1/user/changeusername",
                      "api/v1/user/me/change-profile",
                      "api/v1/user/me/change-password",
                      "api/v1/user/me/change-signature",
                    ],
                    post: [

                    ],
                    put: [

                    ],
                    delete: [

                    ],
                    options: [

                    ],
                    match: [

                    ]
                  }
                },
                update : {
                  show : false,
                  value : false,
                  disabled : false,
                  route : {
                    name : ""
                  },
                  selected_on: [

                  ],
                  endpoint: {
                    get: [

                    ],
                    post: [

                    ],
                    put: [

                    ],
                    delete: [

                    ],
                    options: [

                    ],
                    match: [

                    ]
                  }
                },
                delete : {
                  show: false,
                  value : false,
                  disabled : false,
                  route : {
                    name : ""
                  },
                  selected_on: [

                  ],
                  endpoint: {
                    get: [

                    ],
                    post: [

                    ],
                    put: [

                    ],
                    delete: [

                    ],
                    options: [

                    ],
                    match: [

                    ]
                  }
                }
              }
            }
          }
        ],
        isModalConfirm: false,
        messageConfirm: 'saved',
        me: {},
        role: {
          id: null,
          name: null,
          detail: null,
          company_id: null
        },
        project: {},
        display404: false,
        is_confirm_delete: false,
        is_confirm_delete_loading: false,
        is_disable_name: false,
        role_protected: ["ROLE_SUPERUSER", "ROLE_ADMIN", "ROLE_CASHIER", "ROLE_GATE_SCAN", "ROLE_MACHINE"],
      }
    },
    computed: {
      temp_name() {
        let name = null
        let temp = JSON.parse(this.form_temp)
        if(temp) {
          name = temp.name
        }
        return name
      }
    },
    async mounted() {
        this.$root.checkingAccessPage(this.$root._route.name)
        let permission = await this.$root.checkingButtonsPage(this.alias, this.permission_type)
        this.myRoleId = permission.role_id
        this.role_name = permission.role_name
        if(!permission.validate) {
          this.disabledForm = true
        }

        await this.getUserMe()

        if(this.$route.params.id) {
          if(this.old_role_name == "ROLE_SUPERUSER") {
            this.disabledForm = true
          }

          if(this.role_protected.includes(this.old_role_name)) {
            this.is_disable_name = true 
          }

          let is_edit = this.is_edit 
          if(typeof is_edit == 'string') {
              switch (is_edit) {
                  case 'true':
                      this.is_edit = true 
                      break;
                  case 'false':   
                      this.is_edit = false 
                      break;
                  default:
                      this.is_edit = false
                      break;
              }
          }

          if(!this.is_edit) {
            this.disabledForm = true
            this.$store.state.title = 'Detail User Role'
          }
        }

        setTimeout(function(){
          $('[data-toggle="tooltip"]').tooltip()
        }, 3000);

        let self = this
        $(document).ready(function() {
          $('[data-toggle="tooltip"]').tooltip()
          $(document).on('change', '.checkbox-create, .checkbox-update, .checkbox-delete , .checkbox-approval', function() {
            let id = $(this).attr('id')
            let name = $(this).attr('name')
            let className = $(this).attr('class')
            let split = className.split('-')
            let check = document.getElementById(id)
            let isTrue = check.checked
            try {
              self.parent[name].detail.checkbox[split[1]].value = isTrue
            } catch {}

            try{
              if(isTrue){
                 document.getElementById('checkbox-read-'+name).checked = true
                 self.parent[name].detail.checkbox.read.value = true
              }else{
                self.parent[name].detail.checkbox.read.value = true
              }
            }catch{}
          })

          $(document).on('change', '.checkbox-read', function() {
            let id = $(this).attr('id')
            let name = $(this).attr('name')
            let className = $(this).attr('class')
            let split = className.split('-')
            let check = document.getElementById(id)
            let isTrue = check.checked
            self.parent[name].detail.checkbox.read.value = isTrue
            if(!isTrue) {
              let type = ['create', 'update', 'delete', 'approval']
              for(let i = 0; i < type.length ; i++) {
                let idName = 'checkbox-'+type[i]+'-'+name
                try{
                  document.getElementById(idName).checked = false
                  self.parent[name].detail.checkbox[type[i]].value = false
                }catch{

                }
              }
            }
          })
        })

        let temp = localStorage.getItem('_USER_ROLES')
        if(temp) {
          let bytes = this.CryptoJS.AES.decrypt(temp, process.env.VUE_APP_KEY_ENCRYPT_DECRYPT)
          let data = JSON.parse(bytes.toString(this.CryptoJS.enc.Utf8))
          if(data ) {
            
            this.role.id = data.id
            this.role.name = data.name
            this.role.company_id = data.company_id
          }
        }

        await this.init()
      },
      methods: {
        async init() {
          if(this.form.id) {
            let permission = await this.$root.checkingButtonsPage(this.alias, this.permission_type_delete)
            if(permission.validate) {
              if (this.$route.query.is_edit == 'true' || this.$route.query.is_edit == true) {
                this.isRemove = true
              } 
              
            }

            Rest.get('/role/'+this.form.id)
            .then( async(res) => { 
              if(res) {
                if(this.existRole.indexOf(res.data.name) >= 0) {
                  this.disabledRole = true
                }
                this.form.name = res.data.name
                this.old_role_name = res.data.name
                if(this.form.name == 'ROLE_SUPERUSER') {
                  this.isRemove = false
                  this.disabledForm = true
                }

                if(this.role_protected.includes(this.old_role_name)) {
                  this.is_disable_name = true 
                }

                if(res.data.permission_detail) {
                  if(res.data.permission_detail.navigations) {
                    this.navigations = res.data.permission_detail.navigations
                    this.form.permission_detail.navigations = res.data.permission_detail.navigations
                  }

                  if(res.data.permission_detail.permission) {
                    this.permission = res.data.permission_detail.permission
                    this.form.permission_detail.permission = res.data.permission_detail.permission
                    await this.getDataShowPermission()
                  }else{
                    await this.getDataShowPermission()
                  }
                }else{
                  await this.getDataShowPermission()
                }
              }else{
                await this.getDataShowPermission()
              }
            })
            .catch( async(err) => {
              console.log('err', err)
              this.display404 = true

              await this.getDataShowPermission()
            })
          }else{
            await this.getDataShowPermission()
          }
        },
        async getDataShowPermission() {
          this.form_temp = JSON.stringify(this.form)
          let typeButton = this.button
          let menuList = JSON.stringify(dataMenu.admin)

          if(this.old_role_name == "ROLE_USER") {
            menuList = JSON.stringify(dataMenu.user)
          }

          if(this.old_role_name == "ROLE_MACHINE") {
            menuList = JSON.stringify(dataMenu.machine)
          }

          if(this.old_role_name == "ROLE_GATE_SCAN") {
            menuList = JSON.stringify(dataMenu.gate_scan)
          }

          if(this.old_role_name == "ROLE_CASHIER") {
            menuList = JSON.stringify(dataMenu.cashier)
          }

          let parent = JSON.parse(menuList)
          let data = []
          parent.forEach( async(element ) => {
            let search = 'alias'
            if(element.title) {
              if(element.alias == 'Dashboard') {
                element.detail.checkbox.read.value = true
                data.push(element)
              }else{
                if(this.form && this.form.name && this.form.name == 'ROLE_SUPERUSER') {
                  for(let i = 0 ; i < typeButton.length; i++) {
                    let method = typeButton[i]
                    if(element && element.detail && element.detail.checkbox && element.detail.checkbox[method]) {
                      element.detail.checkbox[method].disabled = true
                      if(element.detail.checkbox[method].show) {
                        element.detail.checkbox[method].value = true
                      }
                    }
                  }
                }else if(this.old_role_name == "COMPANY_ROOT") {
                  for(let i = 0 ; i < typeButton.length; i++) {
                    let method = typeButton[i]
                    if(element && element.detail && element.detail.checkbox && element.detail.checkbox[method]) {
                      element.detail.checkbox[method].disabled = true
                      if(element.detail.checkbox[method].show) {
                        element.detail.checkbox[method].value = true
                      }
                    }
                  }
                }else{
                  let replace = await this.searchArray(element[search], search)
                  if(replace) {
                    element.alias = replace.alias
                    for(let i = 0 ; i < typeButton.length; i++) {
                      let type = typeButton[i]
                      try{
                        element.detail.checkbox[type].value = replace.detail.checkbox[type].value
                        if( (this.form.id == 1) && (this.form.id != this.myRoleId )) {
                            element.detail.checkbox[type].disabled =  true
                        }
                      }catch{}
                    }
                  }
                }
                data.push(element)
              }
            }
          })
          this.parent = data
        },
        async searchArray(value, keysName) {
          let array = this.permission
          for(let i = 0; i < array.length; i++) {
            if ( (array[i][keysName] === value) && !array[i].parent) {
              return array[i]
            }
          }
        },
        async validationForm() {
          let is_success = true 
          this.errors.name = false 
          if(!this.form.name) {
              this.errors.name = true 
              is_success = false 
          }else{
              let name = this.form.name.trim()
              if(name.length < 3 || name.length > 141) {
                  this.errors.name = true 
                  is_success = false 
              }
          }

          return is_success
        },
        async doSave () {
          let is_valid = await this.validationForm()
          if(is_valid) {
            this.showModalConfirm()
          }
        },
        showModalConfirm() {
          this.isModalConfirm = true
          this.messageConfirm = this.form.id ? 'modified' : 'saved'
        },
        async doSaveConfirm() {
          let data = []
          const endpoint = {
            get: [],
            post: [],
            put: [],
            delete: [],
            options: [],
            match: []
          }
          let endpointType = ['get', 'post', 'put', 'delete', 'options', 'match']
          let typeButton = this.button
          this.parent.forEach( (element) => {
            if(element.detail.checkbox ) {
              for(let i = 0 ; i < typeButton.length; i++) {
                if(element.detail.checkbox[typeButton[i]]) {
                  if(element.detail.checkbox[typeButton[i]].value == true) {
                    if(element.detail.checkbox[typeButton[i]].route.name) {
                      data.push(element.detail.checkbox[typeButton[i]].route.name)
                    }
                    if(element.detail.checkbox[typeButton[i]].selected_on) {
                      data = data.concat(element.detail.checkbox[typeButton[i]].selected_on)
                    }

                    if(element.detail.checkbox[typeButton[i]].endpoint) {
                      for(let a = 0; a < endpointType.length; a++) {
                        if(element.detail.checkbox[typeButton[i]].endpoint[endpointType[a]]) {
                          endpoint[endpointType[a]] = endpoint[endpointType[a]].concat(element.detail.checkbox[typeButton[i]].endpoint[endpointType[a]])
                        }
                      }
                    }

                  }
                }
              }
            }
          })

          this.navigations = [ ...new Set(data) ] // remove duplicat array value
          // console.log("lihat endpoint : ",endpoint)
          for(let a = 0 ; a < endpointType.length; a++) {
            endpoint[endpointType[a]] = [ ...new Set(endpoint[endpointType[a]])]
          }

          let permission = this.parent 
          permission.forEach( (item) => {
            if(item.detail && item.detail.checkbox) {
                if(item.detail.checkbox.read) {
                    if(item.detail.checkbox.read.endpoint) {
                        delete item.detail.checkbox.read.endpoint
                    }
                }

                if(item.detail.checkbox.update) {
                  if(item.detail.checkbox.update.endpoint) {
                    delete item.detail.checkbox.update.endpoint
                  }
                }

                if(item.detail.checkbox.create) {
                  if(item.detail.checkbox.create.endpoint) {
                    delete item.detail.checkbox.create.endpoint
                  }
                }

                if(item.detail.checkbox.delete) {
                  if(item.detail.checkbox.delete.endpoint) {
                    delete item.detail.checkbox.delete.endpoint
                  }
                }
            }
          })
          // console.log(endpoint , "ini yg kedua")
          this.form.permission_detail = {
            navigations: this.navigations,
            endpoint: endpoint,
            permission: permission
          }
          this.permission = this.parent

          let method = this.form.id ? 'put' : 'post'
          let url = this.form.id ? '/role/'+this.form.id : '/role'
          let message = this.form.id ? 'Successfully modified access permissions' : 'Access permissions successfully saved'

          this.form.name = this.$root.regexAlphaNumericOtherChar(this.form.name)
          Rest[method](url, this.form)
          .then( async(res) => {
            // var company_id = null 
            // if(res && res.data && res.data.data && res.data.data.company_id) {
            //   company_id = res.data.data.company_id
            // } 
            
            this.$root.showNotification('Success', 'success', message)

            this.userLogActivity()

            if(method == 'post') {
              if(res && res.data && res.data.id) {
                this.form.id = res.data.id 
              }
            }

            if(this.$route.params.id == this.myRoleId) {
              const data = {
                id : this.form.id,
                name: this.form.name,
                permission_detail: this.form.permission_detail
              }

              var navigationsEncrypt = this.CryptoJS.AES.encrypt(JSON.stringify(this.form.permission_detail.navigations), process.env.VUE_APP_KEY_ENCRYPT_DECRYPT).toString() 
              var permission = this.CryptoJS.AES.encrypt(JSON.stringify(this.form.permission_detail.permission), process.env.VUE_APP_KEY_ENCRYPT_DECRYPT).toString()
              var role = this.CryptoJS.AES.encrypt(JSON.stringify(data), process.env.VUE_APP_KEY_ENCRYPT_DECRYPT).toString()
              localStorage.setItem('navigations', navigationsEncrypt)
              localStorage.setItem('_USER_PERMISSION', permission)
              localStorage.setItem('_USER_ROLES', role)
            }

            if(this.id) {
              this.broadCastInfoNotif(this.form)
            }

            this.$root.$router.push({
              name: 'master.role'
            })
          })
          .catch( async(err) => {
            console.log('err', err)
            if(err && err.response && err.response.status) {
                let message = "Access permissions save failed."
                if(this.form.id) {
                  message = "Failed to modify access permissions"
                }

                if(err.response.data && err.response.data.message) {
                  message = err.response.data.message
                }
                this.$root.showNotification('Error', 'error', message)
            }else {
              this.$root.showNotification('Error', 'error', 'Sorry, You do not have an internet connection.')
            }
          })
        },
        doRemove() {
          this.is_confirm_delete = true
        },
        async doRemoveProcess() {
          this.is_confirm_delete_loading = true 
          Rest.delete('/role/'+this.form.id)
          .then( async (res) => {
            this.$root.showNotification('Success', 'success', 'Access permissions successfully removed')
            
            this.is_confirm_delete = false 
            this.userLogActivity(true)
            this.$root.$router.push({
              name: 'master.role'
            })
          })
          .catch( (err) => {
            try{
              let message = "Failed to remove access permissions."
              if (err && err.response && err.response.data && err.response.data.message) {
                message = err.response.data.message
              }
              this.$root.showNotification('Error', 'error', message)
            }catch {

            }
          })
          .finally( () => {
            this.is_confirm_delete = false 
            this.is_confirm_delete_loading = false
          })
        },
        async userLogActivity(is_remove = false) {
            if(this.$root.$store.state.user && this.$root.$store.state.user.full_name) {
                let route_name = {
                    name: 'master.role.form',
                    params: {
                        id: this.form.id
                    },
                    query: {
                      is_edit: false
                    }
                } 

                let secretKey = process.env.VUE_APP_KEY_ENCRYPT_DECRYPT
                let route_name_encrypt = this.EncryptDecryptAesCbc256.encryptAesCbc256(secretKey, JSON.stringify(route_name))

                let activity_type = 'create'
                let activity = `${this.$root.$store.state.user.full_name} has added a role with the name <a style="cursor: pointer" class="click-detail-log" data-route="${route_name_encrypt}"> ${this.form.name}</a>`
                if(this.id) {
                  activity = `${this.$root.$store.state.user.full_name} has modified the role with the name <a style="cursor: pointer" class="click-detail-log" data-route="${route_name_encrypt}"> ${this.form.name}</a>`
                    activity_type = 'edit'
                }

                if(is_remove) {
                  activity_type = 'delete'
                  activity = `${this.$root.$store.state.user.full_name} has been remove role with the name ${this.old_role_name}`
                  route_name = null
                }

                let params = {
                    activity: activity,
                    feature: 'Role',
                    activity_type: activity_type,
                    route_name: route_name
                }

                this.$root.sendUserLogActivity(params)
            }
            
        },
        async getUserMe() {
          Rest.get('/user/me/detail').then( async(res) => {
            let role_name = null 
            let id = null 
            let username = null 
            let detail = null 
            let name = null
            if(res && res.data && res.data) {
              id = res.data.id 
              username = res.data.email 
              name = res.data.full_name

              if(res.data.roles && res.data.roles.length > 0 && res.data.roles[0].name) {
                role_name = res.data.roles[0].name
                detail = res.data.roles[0].permission_detail
              }

            }

            this.me = {
              id: id,
              name: name,
              username: username,
              detail: detail,
              role_name: role_name
            }
          })
        },
        async broadCastInfoNotif(data) {
          this.$socket.emit('info-notif', {
            notif_type: 'change-role',
            role_id: data.id,
            data: data
          })
        }
      },
    watch: {
      'form.name': {
        handler(val) {
            this.errors.name = false 
            if(!val) {
                this.errors.name = true 
            }else {
                let value = val.trim()
                if (value.length < 3 || value.length > 141) {
                    this.errors.name = true
                }
            }
        }
      }
    }
  }
</script>

<style scoped>
.text-center{
  text-align: center;
}

tbody {
    display: block;
    height: 310px;
    width: 100%;
    overflow-y: auto;
}
thead, tbody tr {
    display:table;
    width:100%;
    table-layout:fixed;
}
table {
    width: 100%;
}

th {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  background-color: #E0DBFF;
}

td{
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}

.label-checkbox {
  /* display: block; */
  position: relative;
  padding-left: 35px;
  margin-bottom: 15px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.label-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border: 1px solid #6147FF;
  background-color: #f4f4f4;
}

/* On mouse-over, add a grey background color */
.label-checkbox:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.label-checkbox input:checked ~ .checkmark {
  background-color: #6147FF
	/* #2196F3; */
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.label-checkbox input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.label-checkbox .checkmark:after {
  left: 5px;
  top: 2px;
  width: 8px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.font-size-16 {
  font-size: 16px;
}
.font-size-17{
  font-size: 17px;
}
</style>
