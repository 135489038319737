<template>
    <div>
        <box header="false"
            :cancel="false"
            :save="permission_edit"
            @onSave="doSaveConfirm">
            <div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group" :class="{'has-error': !!errors.form_header_value}">
                            <label class="control-label">
                                Header Wording 
                                <span class="warning-text-required">*</span>
                            </label>
                            <vue-editor
                                v-model="form_header.value"
                                id="form-header-value"
                                name="form_header_value"
                                class="form-header-value"
                                :editor-toolbar="customToolbar"
                                :style="disabledForm ? 'background-color: #eeeeee; opacity: 1;': ''"
                                :disabled="disabledForm || !permission_edit" >
                            </vue-editor>
                            <!-- <p>{{ calculate_header }}/144</p> -->
                            <p class="help help-block" v-if="errors.form_header_value">Header Wording  is required.</p>
                        </div>
                    </div>
                </div>

                <br>

                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group" :class="{'has-error': !!errors.form_footer_value}">
                            <label class="control-label">
                                Footer Wording 
                                <span class="warning-text-required">*</span>
                            </label>
                            <vue-editor
                                v-model="form_footer.value"
                                id="form-footer-value"
                                name="form_footer_value"
                                class="form-footer-value"
                                :editor-toolbar="customToolbar"
                                :style="disabledForm ? 'background-color: #eeeeee; opacity: 1;': ''"
                                :disabled="disabledForm || !permission_edit" >
                            </vue-editor>
                            <!-- <p>{{ calculate_footer }}/144</p> -->
                            <p class="help help-block" v-if="errors.form_footer_value">Footer Wording  is required.</p>
                        </div>
                    </div>
                </div>

                <br>

                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group" :class="{'has-error': !!errors.form_note_value}">
                            <label class="control-label">
                                Additional Wording
                                <span class="warning-text-required">*</span>
                            </label>
                            <vue-editor
                                v-model="form_note.value"
                                id="form-note-value"
                                name="form_note_value"
                                class="form-note-value"
                                :editor-toolbar="customToolbar"
                                :style="disabledForm ? 'background-color: #eeeeee; opacity: 1;': ''"
                                :disabled="disabledForm || !permission_edit" >
                            </vue-editor>
                            <!-- <p>{{ calculate_footer }}/144</p> -->
                            <p class="help help-block" v-if="errors.form_note_value">Footer Wording  is required.</p>
                        </div>
                    </div>
                </div>
            </div>
        </box>

        <modal :title="'Change Confirmation'" save="true" remove="false"
            :show="is_confirm"
            saveText="Yes, Confirmation"
            @hidden="is_confirm = false"
            @onSave="doSave"
            :loading="is_confirm_loading">
            <span class="span-title">Are you sure you want to modify ticket layout ?</span>
        </modal>

    </div>
</template>

<script>
import { VueEditor } from "vue2-editor"
const {console} = window
export default {
    components: {
        VueEditor
    },
    props: {
        ticket_layout: {
            type: Array
        },
        unix_time: {
            type: Number
        }
    },
    data() {
        return {
            alias: 'general-setting',
            permission_edit: false,
            form_header: {
                id: null,
                code: 'TCK-HEAD',
                name: null,
                value: null,
                description: null,
            },
            form_footer: {
                id: null,
                code: 'TCK-WORD',
                name: null,
                value: null,
                description: null,
            },
            form_note: {
                id: null,
                code: 'PRINT-NOTE',
                name: null,
                value: null,
                description: null,
            },
            customToolbar: [
                ["bold", "italic", "underline"],
                [
                    { align: "" },
                    { align: "center" },
                    { align: "right" },
                    { align: "justify" }
                ],
                [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
            ],
            errors: {
                form_header_value: false,
                form_footer_value: false,
                form_note_value: false,
            },
            disabledForm: false,
            is_confirm: false,
            is_confirm_loading: false,
            is_first_load_header: true,
            is_first_load_footer: true,
            is_first_load_note: true,
        }
    },
    async mounted() {
        this.defineFormHeaderFooter(this.ticket_layout)

        let self = this 
        $(document).ready(function() {
            $(document).on('keydown', '#form_header_value', function(e) {
                // let value = $('#form_header_value').text()
                // console.log('keyCode', e.code)
                // console.log('keydown disini', value)
            })
        })
    },
    methods: {
        async defineFormHeaderFooter(data) {
            if(data && data.length > 0) {
                try {
                    data.forEach( async(item) => {
                        switch (item.code) {
                            case 'TCK-HEAD':
                                this.form_header.id = item.id 
                                this.form_header.code = item.code 
                                this.form_header.name = item.name
                                this.form_header.value = item.value
                                this.form_header.description = item.description

                                
                                break;
                            case 'TCK-WORD':
                                this.form_footer.id = item.id 
                                this.form_footer.code = item.code
                                this.form_footer.name = item.name
                                this.form_footer.value = item.value
                                this.form_footer.description = item.description
                                break;
                            case 'PRINT-NOTE':
                                this.form_note.id = item.id 
                                this.form_note.code = item.code
                                this.form_note.name = item.name
                                this.form_note.value = item.value
                                this.form_note.description = item.description
                                break;
                            default:
                                break;
                        }
                    })

                } catch  {
                    
                }finally {
                    const delay = ms => new Promise(res => setTimeout(res, ms))
                    await delay(0.001);
                    this.errors.form_header_value = false 
                    this.errors.form_footer_value = false 
                    this.errors.form_note_value = false 

                    this.is_first_load_header = false
                    this.is_first_load_footer = false
                    this.is_first_load_note = false 
                }
            } 

            let permission_edit = await this.$root.checkingButtonsPage(this.alias, 'update')
            if (permission_edit.validate) {
                this.permission_edit = true
            } 
        },
        async validationForm() {
            let is_success = true 
            this.errors.form_header_value = false 
            this.errors.form_footer_value = false 
            this.errors.form_note_value = false  
            if(!this.form_header.value) {
                this.errors.form_header_value = true 
                is_success = false 
            // }else {
            //     let length = this.form_header.value.length
            //     // let length = this.form_header.value.replace(/<[^>]*>/g, '').length
            //     if(length < 3 || length > 144) {
            //         this.errors.form_header_value = true 
            //         is_success = false 
            //     }
            }

            if(!this.form_footer.value) {
                this.errors.form_footer_value = true 
                is_success = false 
            // }else {
            //     let length = this.form_footer.value.length
            //     // let length = this.form_footer.value.replace(/<[^>]*>/g, '').length
            //     if(length < 3 || length > 144) {
            //         this.errors.form_footer_value = true 
            //         is_success = false 
            //     }
            }

            if(!this.form_note.value) {
                this.errors.form_note_value = true 
               is_success = false  
            }

            return is_success
        },
        async doSaveConfirm() {
            let is_valid = await this.validationForm()
            if(is_valid) {
                this.is_confirm = true 
            }
        },
        async doSave(){
            this.is_confirm_loading = true 
            let params = []
            params.push(this.form_header)
            params.push(this.form_footer)
            params.push(this.form_note)

            Rest.put('/setting', params)
            .then( async(res) => {
                if(res && res.status == 200) {
                    this.$root.showNotification('Success', 'success', "Setting ticket layout modification successful.")
                    this.userLogActivity()

                    if(!this.id) {
                        if(res.data && res.data.id) {
                            this.form.id = res.data.id
                        }
                    }
                    
                }
                this.onRefreshTicketLayout(params)
            })
            .catch( async(err) => {
                if(err && err.response && err.response.status) {
                    if(err.response.data && err.response.data.message) {
                        this.$root.showNotification('Failed', 'error', err.response.data.message)        
                    }else {
                        var message = "Setting ticket layout addition failed"
                        if(this.id) {
                            message = "Setting ticket layout modification failed."
                        }
                        this.$root.showNotification('Failed', 'error', message)
                    }
                }else {
                    this.$root.showNotification('Failed', 'error', 'Sorry, you do not have an internet connection.')
                }
            })
            .finally( () => {
                this.is_confirm = false 
                this.is_confirm_loading = false 
            })
        },
        onRefreshTicketLayout() {
            let data = []
            data.push(this.form_header)
            data.push(this.form_footer)
            this.$emit('onRefreshTicketLayout', data)
        },
        inputKeydownHeaderWording(event) {
            console.log('event', event)
            console.log('changeInputHeaderWording' , this.form_header.value)
        },
        async userLogActivity() {
            if(this.$root.$store.state.user && this.$root.$store.state.user.full_name) {
                let route_name = {
                    name: 'setting.general-setting',
                    query: {
                        is_edit: false,
                        tabs: 'ticket-layout'
                    }
                } 

                let secretKey = process.env.VUE_APP_KEY_ENCRYPT_DECRYPT
                let route_name_encrypt = this.EncryptDecryptAesCbc256.encryptAesCbc256(secretKey, JSON.stringify(route_name))

                let activity_type = 'edit'
                let activity = `${this.$root.$store.state.user.full_name} has modified the <a style="cursor: pointer" class="click-detail-log" data-route="${route_name_encrypt}"> general setting ticket layout </a>`
               

                let params = {
                    activity: activity,
                    feature: 'General Setting Ticket Layout',
                    activity_type: activity_type,
                    route_name: route_name
                }

                this.$root.sendUserLogActivity(params)
            }
            
        },   
    },
    computed: {
        calculate_header() {
            let length = 0
            if(this.form_header.value) {
                length = this.form_header.value.length
                // this.form_header.value.replace(/<[^>]*>/g, '').length
            }
            return length
        },
        calculate_footer() {
            let length = 0
            if(this.form_footer.value) {
                length =  this.form_footer.value.length
                // this.form_footer.value.replace(/<[^>]*>/g, '').length
            }
            return length
        },
    },
    watch: {
        ticket_layout: {
            handler(val) {
                this.defineFormHeaderFooter(val)
            }
        },
        unix_time: {
            handler() {
                
                this.defineFormHeaderFooter(this.ticket_layout)
            }
        },
        'form_header.value': {
            handler(val) {
                this.errors.form_header_value = false 
                // console.log('watch form_header_value', this.is_first_load_header)
                if(!this.is_first_load_header) {
                    if(!val) {
                        this.errors.form_header_value = true 
                    // }else {
                    //     let length = val.length 
                    //     //let length val.replace(/<[^>]*>/g, '').length
                    //     if(length < 3 || length > 144) {
                    //         this.errors.form_header_value = true 
                    //     }
                    }
                }
            }
        },
        'form_footer.value': {
            handler(val) {
                this.errors.form_footer_value = false 
                if(!this.is_first_load_footer) {
                    if(!val) {
                        this.errors.form_footer_value = true 
                    // }else {
                    //     let length = length
                    //     //let length val.replace(/<[^>]*>/g, '').length
                    //     if(length < 3 || length > 144) {
                    //         this.errors.form_footer_value = true 
                    //     }
                    }
                }
            }
        },
        'form_note.value': {
            handler(val) {
                this.errors.form_note_value = false 
                if(!this.is_first_load_note) {
                    if(!val) {
                        this.errors.form_note_value = true
                    }
                }
            }
        }
    }
}
</script>

<style scoped>

</style>