<template>
    <div>
        <Error404 v-if="display404"></Error404>
        <div v-else>
            <header-title-button
                :title="id ? disabledForm ?  'Machine Detail' : 'Edit Machine' : 'Add Machine' "
                :isAdd="false">
            </header-title-button>
            <box
                back="master.machine"
                title="Machine Information"
                :save="!disabledForm"
                :saveText="form.id ? `Edit` : `Save`"
                @onSave="doSaveConfirm"
                :remove="false"
                :isSwitch="true"
                :switchValue="form.is_active"
                :switchDisabled="disabledForm"
                switchTitle="Status"
                :switchText="form.is_active ? 'Active' : 'Not Active' "
                @onSwitchButton="changeBtnSwitch">
                <div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="control-label">Code </label>
                                <input type="text" class="form-control code"
                                    id="code"
                                    v-model="form.code"
                                    :disabled="true"
                                    maxlength="141" />
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="control-label">Serial Number </label>
                                <input type="text" class="form-control serial_number"
                                    id="serial_number"
                                    v-model="form.serial_number"
                                    :disabled="disabledForm"
                                    maxlength="50" />
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label class="control-label">Manufacturig Date </label>
                                <datepicker v-model="form.manufacturing_date"
                                    :formatDate="'YYYY-MM-DD'"
                                    :config="dateConfig"
                                    :disabled="disabledForm"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="control-label">Color </label>
                                <input type="text" class="form-control color"
                                    id="color"
                                    v-model="form.color"
                                    :disabled="disabledForm"
                                    maxlength="20" />
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="control-label">Type </label>
                                <input type="text" class="form-control type"
                                    id="type"
                                    v-model="form.type"
                                    :disabled="disabledForm"
                                    maxlength="20" />
                            </div>
                        </div>
                    </div>
                    <h4>Location</h4>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group" :class="{'has-error': !!errors.location_id}">
                                <label class="control-label" >Choose Location 
                                    <span class="warning-text-required">*</span>
                                </label>
                                <select type="text" class="form-control select2 select-location"
                                    style="width: 100%"
                                    id="location"
                                    v-model="form.location_id"
                                    :disabled="disabledForm" >
                                    <option v-for="(data, i) in location" :key="i" :value="data.id">{{ data.name }}</option>
                                </select>
                                
                                <p class="help help-block" v-if="errors.location_id">Location is required.</p>
                            </div>
                        </div>
                    </div>

                    <h4 v-if="form.qr">Access Code</h4>
                    <div v-if="form.qr" class="row">
                        <div class="col-md-12">
                            <div class="box-info-warning-primary">
                                <IconInfoCircle :fill="'#0083FF'"/>
                                <span :class="`box-info-warning-primary-span`">
                                    A QR code appears when the machine location have been set.
                                </span>
                            </div>
                            <br />
                        </div>
                    </div>
                    <div v-if="form.qr" class="row">
                        <div class="col-md-2">
                            <div style="padding: 0px">
                                <img :src="form.qr"  />
                            </div>
                            
                        </div>
                        <div class="col-md-10">
                            <div style="background-color: #F5F5F5; padding: 10px">
                                <p>To connect the machine into the system, follow these steps:</p>
                                <ol>
                                    <li>Turn on the machine</li>
                                    <li>In the start-up page, click "Activate"</li>
                                    <li>Scan the QR with the machine scanner, or</li>
                                    <li>Input the special link within the available field in the machine</li>
                                </ol>

                            </div>
                        </div>
                    </div>


                </div>
            </box>
        </div>

        <modal :title="form.id ? 'Change Confirmation' : 'Add Confirmation' " save="true" remove="false"
            :show="is_confirm"
            :saveText=" form.id ? 'Yes, Confirmation' : 'Save'"
            @hidden="is_confirm = false"
            @onSave="doSave()"
            :loading="is_confirm_loading">
            <span class="span-title">Are you sure you want to modify the machine?</span>
        </modal>
    </div>
</template>

<script>
import Error404 from '@pages/Error404.vue'
import IconInfoCircle from '@components/svg/IconInfoCircle.vue'
const {console} = window
export default {
    components: {
        Error404,
        IconInfoCircle
    },
    data() {
        return {
            alias: 'machine',
            id: this.$route.params.id,
            is_edit: false,
            disabledForm: false,
            display404: false,
            page: {
                title: 'Nuanu',
                subtitle: 'Machine'
            },
            form: {
                id: this.$route.params.id,
                code: null,
                serial_number: null,
                manufacturing_date: null,
                location_id: null,
                is_active: true,
                color: null,
                type: null,
                qr: null,
            },
            errors: {
                location_id: false,
            },
            dateConfig: {
                maxDate: moment().format('YYYY-MM-DD'),
                locale: {
                    format: 'YYYY-MM-DD'
                }
            },
            is_confirm: false,
            is_confirm_loading: false,
            location: [],
        }
    },
    async mounted() {
        this.$root.checkingAccessPage(this.$root._route.name)

        await this.getLocation()
        if(this.id)  {
            switch(this.$route.query.is_edit) {
                case true: 
                    this.is_edit = true 
                    this.disabledForm = false
                    break;
                case false: 
                    this.is_edit = false 
                    this.disabledForm = true
                    break;
                case 'true': 
                    this.is_edit = true 
                    this.disabledForm = false
                    break;
                case 'false': 
                    this.is_edit = false 
                    this.disabledForm = true
                    break;
                default: 
                    this.is_edit = false 
                    this.disabledForm = true
                    break;
            } 

            if(!this.is_edit) {
                this.$store.state.title = 'Machine Detail'
            }else {
                let permission_edit = await this.$root.checkingButtonsPage(this.alias, 'update')
                if(!permission_edit.validate) {
                    this.disabledForm = true
                }
            }

            this.init()

        }else {
            let permission_create =  await this.$root.checkingButtonsPage(this.alias, 'create')
            if(!permission_create.validate) {
                this.disabledForm = true
            }
        }

        let self = this
        $(document).ready(function(e) {
            $('.select-location').on('select2:select', (e) => {
                e.preventDefault();
                let data = e.params.data
                self.form.location_id = data.id
            })
        })

        $('.select2').select2()
        $('.select-location').select2()

        

    },
    methods: {
        async init() {
            if(this.id) {
                Rest.get('/machine/'+this.id) 
                .then( async(res) => {
                    if(res && res.data) {
                        this.form.code = res.data.code 
                        this.form.serial_number = res.data.serial_number
                        this.form.manufacturing_date = res.data.manufacturing_date
                        this.form.is_active = res.data.is_active
                        this.form.color = res.data.color
                        this.form.type = res.data.type
                        if(res.data.qr) {
                            
                            this.form.qr = process.env.VUE_APP_UPLOAD_URL + res.data.qr
                        }
                        if(res.data.location_id) {
                            this.form.location_id = res.data.location_id
                            $('.select-location').val([res.data.location_id]).trigger('change')
                        }
                    }
                })
                .catch( () => {
                    this.display404 = true
                })
            }
        },
        async getLocation() {
            let filter = [["is_active", "=", true]]
            filter = JSON.stringify(filter)
            var encodedFilter = encodeURIComponent(filter)

            Rest.get('/location?size=10000&filters='+encodedFilter)
            .then( async(res) => {
                if(res && res.data && res.data.items && res.data.items.length > 0) {
                    this.location = res.data.items
                }
            })
            .catch( async() => {

            })
        },
        changeBtnSwitch() {
            this.form.is_active = !this.form.is_active
        },
        async doSaveConfirm() {
            let is_valid = await this.validationForm()
            if(is_valid) {
                if(this.id) {
                    this.is_confirm = true 
                }else {
                    this.doSaveProcess()
                }
            }
        },
        async doSave() {
            this.is_confirm_loading = false
            
            this.doSaveProcess()
        },
        async doSaveProcess() {
            let params = {
                location_id: this.form.location_id,
                color: this.form.color,
                type: this.form.type,
                manufacturing_date: this.form.manufacturing_date,
                serial_number: this.form.serial_number,
                is_active: this.form.is_active
            }
            
            let method = this.id ? 'put': 'post'
            let url = this.id ? '/machine/'+this.id : '/machine'
            Rest[method](url, params)
            .then( async(res) => {
                if(res && res.status == 200) {
                    this.$root.showNotification('Success', 'success', this.id ? "Machine modification successful." : "Machine addition successful.")
                    this.userLogActivity()

                    if(!this.id) {
                        if(res.data && res.data.id) {
                            this.form.id = res.data.id
                        }
                    }

                    
                    this.$root.$router.push({
                        name: "master.machine"
                    })
                }
            })
            .catch( (err) => {
                if(err && err.response && err.response.status) {
                    if(err.response.data && err.response.data.message) {
                        this.$root.showNotification('Failed', 'error', err.response.data.message)        
                    }else {
                        var message = "Machine addition failed"
                        if(this.id) {
                            message = "Machine modification failed."
                        }
                        this.$root.showNotification('Failed', 'error', message)
                    }
                }else {
                    this.$root.showNotification('Failed', 'error', 'Sorry, you do not have an internet connection.')
                }
            })
            .finally( (res) => {
                this.is_confirm = false 
                this.is_confirm_loading = false
            })
            
        },
        async validationForm() {
            let is_success = true
            this.errors.location_id = false 
            if(!this.form.location_id) {
                this.errors.location_id = true 
                is_success = false 
            }

            return is_success
        },
        async userLogActivity() {
            if(this.$root.$store.state.user && this.$root.$store.state.user.full_name) {
                let route_name = {
                    name: 'master.machine.form',
                    params: {
                        id: this.form.id
                    },
                    query: {
                        is_edit: false
                    }
                } 

                let secretKey = process.env.VUE_APP_KEY_ENCRYPT_DECRYPT
                let route_name_encrypt = this.EncryptDecryptAesCbc256.encryptAesCbc256(secretKey, JSON.stringify(route_name))

                let activity_type = 'create'
                let activity = `${this.$root.$store.state.user.full_name} has added a machine with the code <a style="cursor: pointer" class="click-detail-log" data-route="${route_name_encrypt}"> ${this.form.code}</a>`
                if(this.id) {
                    activity = `${this.$root.$store.state.user.full_name} has modified the machine with the code <a style="cursor: pointer" class="click-detail-log" data-route="${route_name_encrypt}"> ${this.form.code}</a>`
                    activity_type = 'edit'
                }

                let params = {
                    activity: activity,
                    feature: 'Machine',
                    activity_type: activity_type,
                    route_name: route_name
                }

                this.$root.sendUserLogActivity(params)
            }
            
        },
    },
    computed: {

    },
    watch: {
        'form.location_id': {
            handler(val) {
                this.errors.location_id = false
                if(!val) {
                    this.errors.location_id = true
                }
            }
        }
    }
}
</script>

<style scoped>

</style>