<template>
    <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" :viewBox="viewBox" fill="none">
        <path d="M312.5 -320H527.5V567.802L841 899H0L312.5 567.802V-320Z" fill="url(#paint0_linear_157_37210)"/>
        <defs>
            <linearGradient id="paint0_linear_157_37210" x1="421" y1="-4.5" x2="371" y2="799.5" gradientUnits="userSpaceOnUse">
            <stop stop-color="#2996F4"/>
            <stop offset="1" stop-color="#0A75DA"/>
            </linearGradient>
        </defs>
    </svg>
</template>

<script>
export default {
    props: {
        width: {
            type: [String, Number],
            default: '841'
        },
        height: {
            type: [String, Number],
            default: '836'
        }
    },
    data() {
        return {
            viewBox: '0 0 841 836'
        }
    },
    mounted() {
        this.viewBox = '0 0 '+this.width+ ' '+this.height
    },
    watch: {
        'width': {
            handler(val) {
                if(val) {
                    this.viewBox = '0 0 '+val+ ' '+this.height
                }
            }
        },
        'height': {
            handler(val) {
                if(val) {
                    this.viewBox = '0 0 '+this.width+' '+val
                }
            }
        }
    }
}
</script>

<style scoped>

</style>
